import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import PageHeader from 'antd/lib/page-header';
import { Row, Icon } from 'antd';
import { CenteredTitlePageHeader } from './TitleHeader.Styled';
import { debounce } from 'lodash';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  hideBackButton?: boolean;
}

export interface State { }

export class TitleHeaderWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
    this.onBack = debounce(this.onBack, 100);
  }

  onBack = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  showBackIcon = () => {
    return <Icon type="arrow-left" />;
  };

  render() {
    return (
      <Row>
        <CenteredTitlePageHeader backIcon={this.props.hideBackButton == true ? <></> : this.showBackIcon()} title={this.props.title} onBack={this.onBack}>
          {this.props.children}
        </CenteredTitlePageHeader>
      </Row>
    );
  }
}

const TitleHeader = withRouter(TitleHeaderWithoutRouter);

export default TitleHeader;
