import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import NotificationService from '../../../Core/NotificationService';
import { Notice } from '../../../Core/models/Notice';
export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

export class NavigationEventNotifierWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentWillReceiveProps(nextProps: Props) {
    if (nextProps.location !== this.props.location) {
      NotificationService.CloseNonPersistentNotices();
      NotificationService.scrollToTop();
      NotificationService.resetHider();

    }
  }

  render() {
    return <div>{this.props.children}</div>;
  }
}

const NavigationEventNotifier = withRouter(NavigationEventNotifierWithoutRouter);
export default NavigationEventNotifier;



