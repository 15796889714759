import { Col, Form, Row, Typography, Input, Button, DatePicker, Icon, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import AuthContext from 'Context/AuthContext';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AccessLevel,
  FilterType,
  IAddKnowledgeShareCommandRequest,
  IEditCategoryCommandRequest,
  JoinType,
  Knowledge,
  KnowledgeCategory,
  KnowledgeShare,
} from '../../../Core/Api/Api';
import FormControl from 'components/Forms/FormControl';
import ValidationUtility from 'Core/ValidationUtility';
import TextArea from 'antd/lib/input/TextArea';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import { SubmitButton } from 'components/Forms/SubmitButton';
import ProfileHandler from 'components/UserProfile/ProfileHandler';
import ITableRequest from 'Core/TableUtility/Models/TableRequest';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import SimpleSelect from 'components/Forms/SimpleSelect';
import { KnowledgeSelector } from 'components/Forms/KnowledgeSelector';
import KnowledgeShareHandler from 'components/Admin/KnowledgeShareHandler';
import { HideContentSimpleSelect } from 'components/Knowledge/KnowledgeDetailsForm/KnowledgeDetailsForm.Styled';
import Utility from 'Core/Utility';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import DetailsData from 'Core/models/DetailsData';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import moment from 'moment';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
  NewRequestKnowledge?: Knowledge; // this will be supplied by the parent to let us know a new request is being called.
  // This is indented to be set by the context provider.
  onRequestComplete?: (knowledgeShare: KnowledgeShare) => void;
  onCancel?: Function;
  fullWith?: boolean;
}

export interface State {
  knowledgeCategory?: KnowledgeCategory;
  users?: ISelectOption[];
  accessLevels?: ISelectOption[];
  jurisdictions?: ISelectOption[];
  selectedJurisdictionId?: string;
  detailsMode: DetailsMode;
  preselectedKnowledge?: Knowledge;
  showKnowledgeSelector?: boolean;
}

const SelectedKnowledgeText = "Select Specific Knowledge(s)...";

export class KnowledgeShareDetailsFormWithoutFormAndRouter extends React.Component<
  Props,
  State
  > {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>
  profileHandler: ProfileHandler;
  sharedKnowledgeHandler: KnowledgeShareHandler;
  knowledgeHandler: KnowledgeHandler;


  constructor(props: Props) {
    super(props);

    this.state = {
      detailsMode: DetailsMode.New,
    };
    this.profileHandler = new ProfileHandler(props);
    this.sharedKnowledgeHandler = new KnowledgeShareHandler(props);
    this.knowledgeHandler = new KnowledgeHandler(props);
  }

  componentDidMount() {
    this.getAccessLevel();
    this.getJurisdictionUsers();
    this.getJurisdictions();
    if (this.props.NewRequestKnowledge) {
      this.getDefaultInfo(this.props.NewRequestKnowledge);
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.NewRequestKnowledge != this.props.NewRequestKnowledge && this.props.NewRequestKnowledge != undefined) {
      this.getDefaultInfo(this.props.NewRequestKnowledge);
    }
  }

  getDefaultInfo(knowlege: Knowledge) {

    let preselectedKnowledge = knowlege;
    let data: any = { knowledges: [preselectedKnowledge.id], jurisdictionId: preselectedKnowledge.jurisdictionId };
    let user = this.context?.data;
    data.accessors = [user?.id];
    data.description = `Share ${preselectedKnowledge?.title} with ${user?.firstName} ${user?.lastName}`;
    data.requestedAccessLevel = SelectedKnowledgeText;
    data.expiryDate = moment().add(20, "days");
    this.props.form.setFieldsValue(data);

    this.setState({ users: [{ value: user?.id, text: `${user?.firstName}, ${user?.lastName}` }] })
  }

  getAccessLevel() {
    var accessLevels: ISelectOption[] = [];
    Utility.enumToMap(AccessLevel).forEach((x, y) => {
      if (x == 'NoAccess' || x == 'Public') return;
      let text = (x as string).replace('Level', 'Level ');
      accessLevels.push({ text: <div>{text} <i>{this.accessLevelDescription(x)}</i></div>, value: y });
    });
    accessLevels.push({ text: SelectedKnowledgeText, value: undefined });
    this.setState({ accessLevels });
  }

  accessLevelDescription = (accessLevel?: AccessLevel) => {
    if (!accessLevel) {
      return <></>;
    }
    return Utility.RenderAccessLevelDescriptions(accessLevel, "100%");
  }

  getJurisdictions() {
    this.sharedKnowledgeHandler.GetTop100Jurisdictions().then(x => {
      const jurisdictions: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions });
    });
  }

  onJurisdictionChange(value: string) {
    this.setState({ selectedJurisdictionId: value });
  }

  getJurisdictionUsers(filterText?: string) {
    if (this.context.data) {
      let request: ITableRequest = new TableRequest();
      request.filters = [];
      request.amountPerPage = 10;

      if (filterText) {
        request.filters.push({ field: "firstName", value: filterText, filterType: FilterType.Contains, joinType: JoinType.Or });
        request.filters.push({ field: "lastName", value: filterText, filterType: FilterType.Contains, joinType: JoinType.Or });
        request.filters.push({ field: "userName", value: filterText, filterType: FilterType.Contains, joinType: JoinType.Or });
        request.filters.push({ field: "email", value: filterText, filterType: FilterType.Contains, joinType: JoinType.Or });

      }
      this.profileHandler.GetUserProfiles(request, false, false).then(
        x => {
          let users: ISelectOption[] | undefined = x.results
            ?.map(user => ({ value: user.id, text: `${user.firstName}, ${user.lastName} (${user.userName})` }));
          this.setState({ users });
        },
        x => {
          NotificationService.error(x.message);
          this.setState({ users: undefined });
        }
      );
    }
  }

  shouldShowKnowledgeSelector = (value: string) => {
    let formValue = value;
    console.log("value", formValue);
    if (!formValue || formValue == SelectedKnowledgeText) {
      this.setState({ showKnowledgeSelector: true })
    } else {
      this.setState({ showKnowledgeSelector: false });
    }
  }

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    this.props.form.validateFieldsAndScroll((error, values: IAddKnowledgeShareCommandRequest) => {
      if (error) {
        return;
      }
      if (values.requestedAccessLevel as any == SelectedKnowledgeText) {
        values.requestedAccessLevel = undefined;
      }
      this.sharedKnowledgeHandler.AddSharedKnowledgeRequest(values)
        .then(x => {
          NotificationService.success(`Knowledge Share Request Created`, `"${values.description}" was created. It will be processed.`, 10, true);
          if (this.props.onRequestComplete) {
            this.props.onRequestComplete(x);
          } else {
            this.props.history.goBack();
          }
        })
    });
  };

  onCancel = (event: React.SyntheticEvent) => {
    if (this.props.onCancel) {
      this.props.onCancel?.call(this);
    } else {
      this.props.history.goBack()
    }
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let fullWidth = this.props.fullWith;
    return (
      <LeftAlignedRow>
        <Col md={fullWidth ? 24 : 10}>
          <Form layout="horizontal" onSubmit={this.onSubmit}>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="description"
              label="Subject"
              rules={[...ValidationUtility.required('Subject is required')]}>
              <Input />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="notes"
              label="Notes">
              <TextArea />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="expiryDate"
              label={<span>
                Access End Date&nbsp;
                <Tooltip title="Information Shared via this knowledge share will be accessible up until this date.">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>}
              rules={[...ValidationUtility.required('Expiry Date is required'),]}>
              <DatePicker disabledDate={x => x!.isSameOrBefore()} />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('Users is required')]}
              name="accessors"
              label="Users">
              <SimpleSelect onSearch={x => this.getJurisdictionUsers(x)} mode="multiple"
                options={this.state.users}></SimpleSelect>
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[
                ...ValidationUtility.required('juristiction is required'),
              ]}
              name="jurisdictionId"
              label="Jurisdiction Of Knowledge Base">
              <SimpleSelect
                options={this.state.jurisdictions} onChange={x => this.onJurisdictionChange(x)}></SimpleSelect>
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('Access Level is required')]}
              name="requestedAccessLevel"
              extra={<span>{this.accessLevelDescription(this.props.form.getFieldValue("accessLevel"))}</span>}
              label="Access Level">
              <HideContentSimpleSelect onChange={this.shouldShowKnowledgeSelector} options={this.state.accessLevels}></HideContentSimpleSelect>
            </FormControl>

            <ShowIfTrue condition={this.state.showKnowledgeSelector || this.props.NewRequestKnowledge}>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Knowledge is required')]}
                name="knowledges"
                label="Knowledges">
                <KnowledgeSelector filteredJurisdictionId={this.state.selectedJurisdictionId} />
              </FormControl>
            </ShowIfTrue>


            <Row>
              <Col span={fullWidth ? 5 : 3}>
                <Button type="default" onClick={this.onCancel} htmlType="button">
                  Cancel
                </Button>
              </Col>
              <Col offset={fullWidth ? 2 : 1} span={fullWidth ? 10 : 3}>
                <SubmitButton type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </LeftAlignedRow>
    );
  }
}

const KnowledgeShareDetailsFormWithRouter = Form.create<Props>({
  name: 'KnowledgeCategoryDetails',
})(KnowledgeShareDetailsFormWithoutFormAndRouter);

const KnowledgeShareDetailsForm = withRouter(KnowledgeShareDetailsFormWithRouter);

export default KnowledgeShareDetailsForm;
