import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import {
  User,
  GetAllUsersByFilterQueryRequest,
  SlimUser,
  GetFilteredJurisdictionsQueryRequest,
  OrderBy,
  GetFilteredCountriesQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetUserByIdQueryRequest,
  EditUserCommandRequest,
  AddUserCommandRequest,
  RemoveUserCommandRequest,
  IRemoveUserCommandRequest,
  GenerateEnabledUsersCsvCommandRequest,
  IGenerateEnabledUsersCsvCommandRequest, GetAllUsersReportDataQueryRequest, IGetAllUserInfoForPublicByFilterQueryRequest, GetAllUserInfoForPublicByFilterQueryRequest, IGetUserLoginReportDataQueryRequest, GetUserLoginReportDataQueryRequest, RegisterUserCommandRequest, GetAllUserRegistrationByFilterQueryRequest, SlimRegistration, GetUserRegistrationByIdQueryRequest, ApproveUserRegistrationCommandRequest, RejectUserRegistrationCommandRequest
} from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { SecuredClient } from 'Core/Api/SecuredClient';

export default class RegistrationHandler implements ITableDataFetcher<SlimRegistration> {
  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<SlimRegistration>> {
    return this.GetAllUserRegistrationByFilter(tableRequest);
  }

  GetAllUserRegistrationByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllUserRegistrationByFilterQueryRequest = new GetAllUserRegistrationByFilterQueryRequest(request);
    return client.getAllUserRegistrationByFilter(getAllUserRegistrationByFilterQueryRequest);
  }

  GetAllUserReportData(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllUsersReportDataQueryRequest = new GetAllUsersReportDataQueryRequest(request);
    return client.getAllUsersReportData(getAllUsersReportDataQueryRequest);
  }

  GetTop100Jurisdictions() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredJurisdictionsQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredJurisdictions(request);
  }

  GetTop500Countries() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredCountriesQueryRequest();
    request.amountPerPage = 500;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredCountries(request);
  }

  GetFilteredRoles(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 20;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRolesByFilter(request);
  }

  GetPublicFilteredRole(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 20;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRoleInfosForPublicByFilter(request);
  }

  GetUserRegistrationById(userId: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetUserRegistrationByIdQueryRequest();
    request.id = userId;
    return client.getUserRegistrationById(request);
  }

  EditUser(request: EditUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.editUser(request);
  }

  ApproveRegistration(request: ApproveUserRegistrationCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.approveUserRegistrationById(request);
  }

  RejectRegistration(request: RejectUserRegistrationCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.rejectUserRegistrationById(request);
  }

  //ApproveRegistration(request: ApproveUserRegistrationCommandRequest) {
  //    const client = new SecuredClient();
  //    client.showLoading = true;
  //    client.handleGeneralError = true;

  //    return client.r(request);
  //}


  RemoveUser(iRequest: IRemoveUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new RemoveUserCommandRequest(iRequest);
    return client.removeUser(request);
  }

  AddUser(request: AddUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.addUser(request);
  }

  RegisterUser(request: RegisterUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.registerUser(request);
  }

  GetUserInfoForPublic(model: IGetAllUserInfoForPublicByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new GetAllUserInfoForPublicByFilterQueryRequest(model);
    return client.getAllUserInfoForPublicByFilter(request);
  }

  GetUserLoginReportData(model: IGetUserLoginReportDataQueryRequest | undefined) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new GetUserLoginReportDataQueryRequest(model);
    return client.getUserLoginReportData(request);
  }

  GenerateReport(request: IGenerateEnabledUsersCsvCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let initRequest = new GenerateEnabledUsersCsvCommandRequest(request);

    return client.generateUsersCsv(initRequest);

  }
}
