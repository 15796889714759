import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility';
import RoleHandler from 'components/Admin/RoleHandler';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import KnowledgeTemplateHandler from 'components/Knowledge/KnowledgeTemplateHandler';
import { orderBy } from 'lodash';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  categories: IFilterSelectOption[];
}
export default class KnowledgeTemplateListFilterBar extends React.Component<Props, State> {
  handler: KnowledgeTemplateHandler;
  knowledgeHandler: KnowledgeHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
    };
    this.handler = new KnowledgeTemplateHandler(props);
    this.knowledgeHandler = new KnowledgeHandler(props);
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    this.knowledgeHandler.GetTop100KnowledgeCategories().then(x => {
      const categories: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];

      this.setState({ categories: orderBy(categories, 'text', 'asc') });
    });
  }

  render() {
    return (
      <FilterBar>
        <Row type="flex">
          <Col md={8} xs={24}>
            <Form.Item labelAlign="left">
              <FilterTextbox
                filterFields="name,description"
                filterType={FilterType.Contains}
                allowClear={true}
                placeholder={'Search'}
              />
            </Form.Item>
          </Col>
          <Col md={8} xs={24}>
            <Form.Item labelAlign="left">
              <FilterSelectbox
                filterFields="knowledgeCategoryId"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                mode={'multiple'}
                filterJoin={JoinType.And}
                placeholder={'Select Categories'}
                options={this.state.categories}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
