import * as React from 'react';
import { SurveyTemplate, Questions } from './model';
import { Descriptions, Typography } from 'antd';
import { SearchTextHighlight } from '../SearchTextHighlight';

export interface Props {
  children?: React.ReactNode;
  template?: string;
  surveyValues?: string;
  maxItems?: number;
  layout?: 'vertical' | 'horizontal';
  size?: 'middle' | 'small' | 'default';
  showMore?: boolean | { LinkUrl };
  style?: React.CSSProperties;
}

export interface State {}

export default class SurveyViewer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  renderViewer = () => {
    if (!this.props.surveyValues || !this.props.template) {
      //one is null, cannot setup viewer
      return <></>;
    }

    const surveyTemplateModel = JSON.parse(this.props.template) as SurveyTemplate;
    const values = JSON.parse(this.props.surveyValues) as { [key: string]: string };

    var firstPage = surveyTemplateModel.pages[0];

    return (
      <Descriptions
        layout={this.props.layout ?? 'horizontal'}
        size={this.props.size}
        style={this.props.style}
        colon={true}
        bordered={true}
        column={1}>
        {firstPage.elements
          .filter((v, i) => !this.props.maxItems || i < this.props.maxItems)
          .map(x => this.renderQuestion(x, values))}
        {this.props.showMore && (
          <Typography.Text style={{ fontSize: '.2em' }} type={'secondary'}>
            {'More..'}
          </Typography.Text>
        )}
      </Descriptions>
    );
  };

  renderQuestion(question: Questions, data: { [key: string]: string }): any {
    switch (question.type) {
      case 'comment':
      case 'text':
        return (
          <Descriptions.Item
            key={question.name}
            label={question.title ?? question.name ?? question.type}>
            {question.inputType === 'url' ? (
              <a target="_blank" href={data[question.name]}>
                {data[question.name]}
              </a>
            ) : (
              <SearchTextHighlight field="templateSearchData">
                {data[question.name]}
              </SearchTextHighlight>
            )}
          </Descriptions.Item>
        );
        break;
      case 'dropdown':
      case 'radiogroup':
        return (
          <Descriptions.Item
            key={question.name}
            label={question.title ?? question.name ?? question.type}>
            <SearchTextHighlight field="templateSearchData">
              {question.choices?.find(y => y.value == data[question.name])?.text}
            </SearchTextHighlight>
          </Descriptions.Item>
        );
        break;
      case 'checkbox':
        let values: Array<any> | undefined = this.getValues(data, question);
        if (values && values.filter) {
          let renderedCheckboxString =
            (values?.reduce(
              (prev: string, cur: string) =>
                `${question.choices?.find(y => y.value == cur)?.text}, ` + prev
            ) as string) ?? '   ';
          renderedCheckboxString = renderedCheckboxString?.substr(
            0,
            renderedCheckboxString.length - 2
          );
          return (
            <Descriptions.Item
              key={question.name}
              label={question.title ?? question.name ?? question.type}>
              <SearchTextHighlight field="templateSearchData">
                {renderedCheckboxString}
              </SearchTextHighlight>
            </Descriptions.Item>
          );
        } else {
          return (
            <Descriptions.Item
              key={question.name}
              label={question.title ?? question.name ?? question.type}>
              <></>
            </Descriptions.Item>
          );
        }
        break;
    }
  }

  private getValues(data: { [key: string]: string }, x: Questions): any[] | undefined {
    const valueJson = `{"i":[${(((data[x.name] as any) ?? []) as Array<any>)?.reduce(
      (p, c) => p + `,"${c}"`,
      '""'
    )}]}`;

    return JSON.parse(valueJson ?? '{"i": []}')['i'];
  }

  render() {
    return this.renderViewer();
  }
}
