import * as React from 'react';
import { Row, Col } from 'antd';
import { CommentsList, CommentForm } from '.';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Operations } from 'Core/Api/Api';

export interface Props {
  children?: React.ReactNode;
  knowledgeId: string;
}

export interface State {
  updateCount: number;
}

export default class CommentsContainer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { updateCount: 0 };
  }

  commentsUpdate = () => {
    this.setState({ updateCount: this.state.updateCount + 1 });
  };

  render() {
    return (
      <Row>
        <Col>
          <Row>
            <Col>
              <CommentsList
                updateCounter={this.state.updateCount}
                knowledgeId={this.props.knowledgeId}
              />
            </Col>
          </Row>
          <ShowIfHavePermission operations={[Operations.Is_Authenticated]}>
            <Row>
              <CommentForm
                knowledgeId={this.props.knowledgeId}
                commentAddedUpdated={this.commentsUpdate}
              />
            </Row>
          </ShowIfHavePermission>
        </Col>
      </Row>
    );
  }
}
