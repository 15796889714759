import * as React from 'react';
import FilterBar from '../../../Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from '../../../Forms/DataGrid/FilterTextbox';
import InfoIcon from '../../../Forms/InfoIcon';
import FilterDatePicker from '../../../Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType } from '../../../../Core/Api/Api';
import FilterDateRangePicker from '../../../Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from '../../../Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from '../../../Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from '../../../../Core/Utility';
import RoleHandler from '../../../Admin/RoleHandler';
import KnowledgeHandler from '../../KnowledgeHandler';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  jurisdictions: IFilterSelectOption[];
}
export default class KnowledgeJurisdictionsListFilterBar extends React.Component<Props, State> {
  handler: KnowledgeHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      jurisdictions: [],
    };
    this.handler = new KnowledgeHandler(props);
  }

  componentDidMount() {
    this.getJurisdictions();
  }

  getJurisdictions() {
    this.handler.GetTop100KnowledgeJurisdictions().then(x => {
      const jurisdictions: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions });
    });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Col md={5} xs={24}>
            <Form.Item labelAlign="left">
              <FilterTextbox
                filterFields="name,shortDescription,description"
                filterType={FilterType.Contains}
                allowClear={true}
                placeholder={'Search'}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
