import React, { useDebugValue } from 'react';
import { UpdatableContext } from './UpdatableContext';

const data = undefined;
const Update = () => { };

const CollapsedMenuContext = React.createContext<UpdatableContext<boolean>>({ data, Update });

export default CollapsedMenuContext;



