import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, AuditHeader, Knowledge, AccessLevel } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import ShowIfhaveAccessLevel from '../../../Forms/ShowIfHaveAccessLevel';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import { DocumentPreview } from 'components/Knowledge/KnowledgeDetailsView/DocumentListView';
import { Card, Row, Col, Typography, Button } from 'antd';
import AttachmentPill from '../../../Styled/AttachmentPill';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { KnowledgeListItem } from '.';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
    knowledge: Knowledge[];
}

export default class KnowledgeListTable extends React.Component<Props, State> {
    handler: KnowledgeHandler;
    config = ConfigService.getSync();

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
            knowledge: []
        };
        this.handler = new KnowledgeHandler(props);
    }

    componentDidMount() {
        this.getKnowledge();
    }

    getKnowledge() {
        this.handler.getAllKnowledgeByFilter().then(x => {
            this.setState({ knowledge: x.results ?? [] }
            );
        });

        // <KnowledgeListItem {...this.props} knowledge={this.state.knowledge} />
        //<SimpleTable<Knowledge>
        //    tableKey="id"
        //    dataFetcher={this.handler}
        //    columns={this.knowledgeListTableColumns}
        //    pagination={{ position: 'both', showTotal: (total) => <p> {total} Result(s)</p> }}
        ///>
    }

    knowledgeListTableColumns: Array<ColumnProps<Knowledge>> = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text: any, record: Knowledge, index: number) => {
                return (
                    <NavLink
                        title={record.description}
                        to={{
                            pathname: `knowledge/v/${encodeURIComponent(record.title ?? '')}`,
                            state: new DetailsData(DetailsMode.View, record.id, record.title),
                        }}>
                        {record.title}
                    </NavLink>
                );
            },
        },
        {
            title: 'Documents',
            render: (text: any, record: Knowledge, index: number) => {
                return (<ul style={{ listStyle: 'none', paddingLeft: "0px" }}>
                    {record.documents?.map(x =>
                        <li>
                            <DocumentPreview accessLevel={record.accessLevel!} text={x.name} document={{ ...x, ...x.dataFile, url: `api/file/${x.dataFile?.fileId}` }} />
                        </li>
                    )}
                </ul>);

            },
        },
        {
            title: 'Category',
            dataIndex: 'categoryId',
            render: (text: any, record: Knowledge, index: number) => {
                return record.category?.name;
            },
        },
        {
            title: 'Created',
            dataIndex: 'creationDate',
            render: (text: any, record: Knowledge, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {!text ? '' : moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: 'Author',
            dataIndex: 'createdBy',
        },
        {
            title: 'Last Modified',
            dataIndex: 'modificationDate',
            render: (text: any, record: Knowledge, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {!text ? '' : moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: '',
            render: (text: any, record: Knowledge, index: number) => {
                console.log(record);
                return (
                    <ShowIfhaveAccessLevel mode={DetailsMode.Edit} accessLevel={record.accessLevel!}>
                        <NavLink
                            to={{
                                pathname: `/knowledge/${encodeURIComponent(record.title ?? '')}`,
                                state: new DetailsData(DetailsMode.Edit, record.id),
                            }}>
                            Edit
                        </NavLink>
                    </ShowIfhaveAccessLevel>
                );
            },
        },
    ];

    tempColumns: Array<ColumnProps<Knowledge>> = [
        {
            title: '',
            dataIndex: 'title',
            render: (text: any, item: Knowledge, index: number) => {
                return (<KnowledgeListItem knowledge={item} pathRoute="knowledge" />);
            },
        },

    ];

    render() {
        return (
            <>

                <SimpleTable<Knowledge>
                    tableKey="id"
                    dataFetcher={this.handler}
                    columns={this.tempColumns}
                    pagination={{ position: 'both', showTotal: (total) => <p> {total} Result(s)</p> }}
                />

            </>
        );
    }
}
