import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import { IChangePasswordCommandRequest, IResetPasswordCommandRequest, IUpsertUserPreferenceCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import ProfileHandler from '../ProfileHandler';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import UserPreferenceHandler from '../UserPreferenceHandler';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import SimpleSelect from 'components/Forms/SimpleSelect';
import UserPreferenceContext from 'Context/UserPreferenceContext';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
  userId?: string;
}

export interface State {
  token?: string;
  emailAddress?: string;
  categories: ISelectOption[];
}

export class UpdatePreferenceFormWithoutFormsAndRouter extends React.Component<Props, State> {
  handler: ProfileHandler;
  userPreferenceHandler: UserPreferenceHandler;
  knowledgeHandler: KnowledgeHandler;
  static contextType = UserPreferenceContext;
  context!: React.ContextType<typeof UserPreferenceContext>;


  constructor(props: Props) {
    super(props);

    this.state = { categories: [] };
    this.handler = new ProfileHandler(props);
    this.userPreferenceHandler = new UserPreferenceHandler(props);
    this.knowledgeHandler = new KnowledgeHandler(props);
  }



  componentDidMount() {
    this.getCategories();
    this.userPreferenceHandler.GetUserPreference()?.then(x => {
      this.props.form.setFieldsValue(x);
    })
  }

  getCategories() {
    this.knowledgeHandler.GetTop100KnowledgeCategories().then(x => {
      const categories: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories });
    });
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((error, values: IUpsertUserPreferenceCommandRequest) => {
      values.userId = this.props.userId;
      this.userPreferenceHandler.UpsertUserPreference(values).then((x) => {
        NotificationService.success('Updated preferences');
        this.context.Update(x);
      });
    });
  };

  render() {
    let { getFieldDecorator } = this.props.form;
    return (
      <LeftAlignedRow>
        <Col>
          <Form onSubmit={this.onSubmit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label="Recently Added Knowledge Categories"
              name="recentlyAddedKnowledgeCategories">
              <SimpleSelect
                options={this.state.categories} mode="multiple" maxTagCount={3}></SimpleSelect>
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label="Recently Modified Knowledge Categories"

              name="recentlyModifiedKnowledgeCategories">
              <SimpleSelect
                options={this.state.categories} mode="multiple" maxTagCount={3}></SimpleSelect>
            </FormControl>
            <FormItem>
              <Row>
                <Col>
                  <Row>
                    <Col lg={5} md={24} >
                      <SubmitButton disableTime={1000} type="primary" htmlType="submit">Update Preference</SubmitButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Col>
      </LeftAlignedRow>
    );
  }
}

const UpdatePreferenceFormWithoutRouter = Form.create<Props>({
  name: 'userPreferenceForm',
})(UpdatePreferenceFormWithoutFormsAndRouter);

const UpdatePreferenceForm = withRouter(UpdatePreferenceFormWithoutRouter);

export default UpdatePreferenceForm;
