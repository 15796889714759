import { Popover } from 'antd';
import { AccessLevel } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import React from 'react';

interface Props {
  children?: React.ReactNode;
  accessLevel?: AccessLevel;
}

export default (props: Props) => {

  const accessLevelTitle = (accessLevel?: AccessLevel) => {
    if (!accessLevel) {
      return <></>;
    }
    switch (accessLevel) {
      case AccessLevel.Level1:
        return <h3>Access Level 1</h3>;
      case AccessLevel.Level2:
        return <h3>Access Level 2</h3>;
      case AccessLevel.Level3:
        return <h3> AccessLevel 3</h3>;
      case AccessLevel.Level4:
        return <h3>Access Level 4</h3>;
      case AccessLevel.Level5:
        return <h3>Access Level 5</h3>;
      case AccessLevel.Public:
        return <h3>Access Level Public</h3>;
    }
  }

  const accessLevelDescription = (accessLevel?: AccessLevel) => {
    if (!accessLevel) {
      return <></>;
    }
    return Utility.RenderAccessLevelDescriptions(accessLevel);
  }

  return (<Popover style={{ maxWidth: "300px" }} title={accessLevelTitle(props.accessLevel)} content={accessLevelDescription(props.accessLevel)}>
    {props.children}
  </Popover>);
}