import * as React from 'react';
import { Row, Col, Button, Menu, Dropdown, Icon } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { KnowledgeShareListFilterBar, KnowledgeShareListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { UserStatus, Operations } from 'Core/Api/Api';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import KnowledgeShareHandler from '../KnowledgeShareHandler';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  isRegional?: boolean;
}

export interface State { }

export class KnowledgeShareListViewWithoutRouter extends React.Component<Props, State> {

  handler: KnowledgeShareHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new KnowledgeShareHandler(props);
  }

  AddSharedKnowledgeRequest = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('/knowledge-share-new', newDetailsData);
  };

  GotoUserReport = (status: UserStatus) => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.View;
    newDetailsData.id = status;
    this.props.history.push('/admin/users/v/reports', newDetailsData);
  }


  render() {
    return (
      <TableFilterContextProvider>
        <Row>
          <Col>
            <Button type="primary" onClick={x => this.AddSharedKnowledgeRequest()}>Create Shared Knowledge Request</Button>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <KnowledgeShareListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <KnowledgeShareListTable isRegional={this.props.isRegional} />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const KnowledgeShareListView = withRouter(KnowledgeShareListViewWithoutRouter);

export default KnowledgeShareListView;
