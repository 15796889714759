import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Operations, SlimRegistration, KnowledgeShare, SlimKnowledgeShare } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { ScrollableTable } from 'components/Forms/DataGrid/SimpleTable/SimpleTable.Styled';
import KnowledgeShareHandler from '../../KnowledgeShareHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
import { Row, Col, Button, Icon } from 'antd';
import { NavButton } from 'components/Forms/NavButton';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import Utility from 'Core/Utility';
import AuthContext from 'Context/AuthContext';
import { UserProfileLink } from 'components/UserProfile/UserProfileLink';
export interface Props {
    children?: React.ReactNode;
    isRegional?: boolean;
}

export interface State {
    countries: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class KnowledgeShareListTable extends React.Component<Props, State> {
    handler: KnowledgeShareHandler;
    tableRef = React.createRef<SimpleTable<any>>();
    config = ConfigService.getSync();

    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;


    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            roles: [],
        };

        this.handler = new KnowledgeShareHandler(props);
    }

    userListTableColumns: Array<ColumnProps<SlimKnowledgeShare>> = [
        {
            title: 'Subject',
            dataIndex: "description"
        },
        {
            title: 'Requestor',
            dataIndex: 'requestorName',
            render: (text: any, record: SlimKnowledgeShare, index: number) => {
                return (<UserProfileLink userId={record.requestorId}>{record.requestorName}</UserProfileLink>)
            },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: SlimKnowledgeShare, index: number) => {
                if (text == "Pending" && record.approvalCount) {
                    return <p>Pending with {record.approvalCount} Approval</p>
                } else {
                    return text;
                }
            },
        },
        {
            title: 'Created',
            dataIndex: 'creationDate',
            render: (text: any, record: SlimKnowledgeShare, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {!text ? '' : moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: 'Expiration',
            dataIndex: 'expiryDate',
            render: (text: any, record: SlimKnowledgeShare, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {!text ? '' : moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: '',
            width: "200px",
            render: (text: any, record: SlimKnowledgeShare, index: number) => {
                let iconType = record.status == "Pending" ? 'edit' : 'read';
                iconType = this.context.data?.jurisdictionId == record.jurisdictionId ? iconType : 'read';
                return (
                    <Row gutter={10} type="flex" justify="space-around">
                        <Col>
                            <NavButton
                                to={{
                                    pathname: 'knowledge-share/' + encodeURIComponent(record.description ?? ""),
                                    state: { id: record.id, mode: DetailsMode.View },
                                }}>
                                <Button><Icon type={iconType} /></Button>
                            </NavButton>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    render() {
        return (
            <ScrollableTable>
                <SimpleTable<SlimRegistration>
                    tableKey="id"
                    ref={this.tableRef}
                    dataFetcher={this.handler}
                    columns={this.userListTableColumns}

                />
            </ScrollableTable>
        );
    }
}
