import * as React from 'react';
import KnowledgeHandler from '../KnowledgeHandler';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import DetailsMode from 'Core/models/DetailsMode';
import {
    GetKnowledgeByIdQueryResponse,
    KnowledgeModel,
    DocumentModel,
    AccessLevel,
    Operations, Knowledge
} from 'Core/Api/Api';
import { Row, Col, PageHeader, Tag, Typography, Skeleton, Divider } from 'antd';
import { Descriptions } from 'antd';
import { mime } from 'Core/models/Mime';
import moment from 'moment';
import { create } from 'domain';
import Utility from 'Core/Utility';
import ConfigService from 'Core/ConfigService';
import { DocumentListView } from '.';
import CommentsContainer from '../CommentsContainer';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import SurveyControl from 'components/Forms/SurveyControl';
import ReactGA from 'react-ga';
import SurveyViewer from 'components/Forms/SurveyViewer';
import ShowIfHaveAccessLevel from 'components/Forms/ShowIfHaveAccessLevel';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AnchorLink from 'antd/lib/anchor/AnchorLink';
import { UserProfileLink } from 'components/UserProfile/UserProfileLink';
import { JurisdictionFlag } from 'components/Shared/JurisdictionFlag';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { SearchTextHighlight } from 'components/Forms/SearchTextHighlight';
import DetailsData from '../../../Core/models/DetailsData';
import { ScrollableTable } from 'components/Forms/DataGrid/SimpleTable/SimpleTable.Styled';


export interface Props extends RouteComponentProps<string> {
    children?: React.ReactNode;
}

export interface State {
    knowledge?: KnowledgeModel;
    tableFilterName?: string;
    isWindowSmall?: boolean;
}

const smallWindowSize = 600;

export class KnowledgeDetailsViewWithoutRouter extends React.Component<Props, State> {
    handler: KnowledgeHandler;
    constructor(props: Props) {
        super(props);
        var tableFilterName = this.props.location.pathname?.includes("public-knowledge") ? "PublicKnowledgeList" : "KnowledgeListViewTable";

        this.state = { tableFilterName, isWindowSmall: window.innerWidth < smallWindowSize ? true : false };
        this.handler = new KnowledgeHandler();
    }

    componentDidMount() {
        const urlState = this.props.location.state as { id: string; mode: DetailsMode } | undefined;
        console.log(this.props.location);
        let promise: Promise<GetKnowledgeByIdQueryResponse> | undefined;
        if (urlState) {
            promise = this.handler.GetKnowledgeDetailsById(urlState.id);
        } else {
            // Not from state, try query string
            const id = this.props.match.params ?? 'NOTANID';

            var decodedId = decodeURIComponent(id["name"]);
            this.props.location.state = new DetailsData(DetailsMode.View, id["name"], decodedId);
            promise = this.handler.GetKnowledgeDetailsByName(decodedId);
        }

        promise.then(x => {

            //console.log(this.props.location.state);
            ////if (this.props.location.state) { } else {
            //console.log(x.knowledge?.title);
            //}
            this.props.location.state = new DetailsData(DetailsMode.View, x.knowledge?.id, x.knowledge?.title);
            this.setState({ knowledge: x.knowledge });

            console.log(this.props.location.state);
            this.TrackAnalyticsEvent(x.knowledge, this.props.location.pathname);
        });
        window.addEventListener("resize", this.handleResize);
        // Call handler right away so state gets updated with initial window size
    }

    componentWillUnmount = () => {
        window.removeEventListener("resize", this.handleResize);
    }

    handleResize = () => {
        this.setState({ isWindowSmall: window.innerWidth < smallWindowSize ? true : false });
    }



    TrackAnalyticsEvent = (knowledge: KnowledgeModel | undefined, path: string) => {

        try {
            var category = path?.includes("public-knowledge") ? "Public Knowledge" : "Internal Knowledge";

            ReactGA.event({
                category: category,
                action: 'View Knowledge',
                label: knowledge?.title,
                value: 1
            });
            ReactGA.event({
                category: category,
                action: 'View Knowledge Category',
                label: knowledge?.categoryName,
                value: 1
            });
        } catch (e) {

        }
    }

    renderTitleLine = () => {
        const attachmentsNumber = this.state.knowledge?.documents?.length ?? ""
        return (<>
            <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
            <Row style={{ marginBottom: "20px" }} >
                <Col span={10} xs={24}>
                    <a title={`${attachmentsNumber} Attachment(s)`} href="#documents-section">{attachmentsNumber} Attachment(s)</a>
                </Col>
                <ShowIfTrue condition={this.state.knowledge?.accessLevel != AccessLevel.Public} >
                    <Col span={10} xs={24}>
                        <a title="Comment(s)" href="#comment-section">Comments</a>
                    </Col>
                </ShowIfTrue>
            </Row>
        </>)
    }

    renderDetails(knowledge?: KnowledgeModel): React.ReactNode {

        //var screens = useBreakpoint();

        //console.log(screens);
        let createDate = moment(knowledge?.createdDate);
        let modifiedDate = moment(knowledge?.modifiedDate);
        let dateFormat = ConfigService.getSync().Display.DateFormat;
        return (
            <>
                <Descriptions column={4} layout={this.state.isWindowSmall ? 'vertical' : 'horizontal'}>
                    <Descriptions.Item label="Creator" span={4}>
                        <UserProfileLink userName={knowledge?.authorId}>{knowledge?.author}</UserProfileLink>
                    </Descriptions.Item>
                    <Descriptions.Item label="Jurisdiction" span={4}>
                        {knowledge?.jurisdictionName} <JurisdictionFlag jurisdictionId={knowledge?.jurisdictionId} />
                    </Descriptions.Item>
                    <Descriptions.Item label="Category" span={4}>
                        {knowledge?.categoryName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Access Level" span={4}>
                        {knowledge?.accessLevel?.replace("Level", "Level ")}
                    </Descriptions.Item>
                    <Descriptions.Item span={this.state.isWindowSmall ? 2 : 1} label="Create Date">
                        {Utility.renderDateWithReference(createDate, dateFormat)}
                    </Descriptions.Item>
                    <Descriptions.Item span={this.state.isWindowSmall ? 2 : 3} label="Last Update Date">
                        {Utility.renderDateWithReference(modifiedDate, dateFormat)}
                    </Descriptions.Item>
                </Descriptions>
                {this.renderTitleLine()}
                <h2>Description</h2>

                <Descriptions column={1} colon={false} >
                    {!knowledge?.knowledgeDataTemplateId && (
                        <Descriptions.Item label="" span={1}>
                            <SearchTextHighlight field="description" >{knowledge?.description}</SearchTextHighlight>
                        </Descriptions.Item>
                    )}
                    {knowledge?.knowledgeDataTemplateId && (
                        <>
                            {/* <Descriptions.Item label="" span={4}>
                <></>
              </Descriptions.Item> */}
                            <Descriptions.Item label="" span={1}>
                                <SurveyViewer
                                    surveyValues={knowledge?.templateDataJSON}
                                    template={knowledge?.currentlySelectedTemplateJSON}
                                    layout={this.state.isWindowSmall ? 'vertical' : 'horizontal'}
                                />
                            </Descriptions.Item>
                        </>
                    )}
                </Descriptions>
            </>
        );
    }

    render() {
        var { knowledge } = this.state;
        return (
            <TableFilterContextProvider tableRequestName={this.state.tableFilterName}>
                <Row>
                    <Col>
                        <Row>{this.renderDetails(knowledge)}</Row>
                        <Row style={{ marginTop: "30px" }}>
                            <Col>
                                <h2 id="documents-section">Attachments</h2>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <ScrollableTable>
                                    <DocumentListView
                                        accessLevel={knowledge?.accessLevel ?? AccessLevel.Level1}
                                        documents={knowledge?.documents}
                                    />
                                </ScrollableTable>
                            </Col>
                        </Row>
                        <ShowIfTrue condition={this.state.knowledge?.accessLevel !== AccessLevel.Public}>
                            <Row style={{ marginTop: "30px" }}>
                                <Col>
                                    <h2 id="comment-section">Comments</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Skeleton active loading={!this.state.knowledge}>
                                        {this.state.knowledge && (
                                            <CommentsContainer knowledgeId={this.state.knowledge!.id!} />
                                        )}
                                    </Skeleton>
                                </Col>
                            </Row>

                        </ShowIfTrue>
                    </Col>
                </Row>
            </TableFilterContextProvider>
        );
    }
}

const KnowledgeDetailsView = withRouter(KnowledgeDetailsViewWithoutRouter);

export default KnowledgeDetailsView;
