import { LoginFormModel } from './Models/LoginFormModel';
import { SecuredClient } from '../../../Core/Api/SecuredClient';
import { SlimUser, LoginCommandRequest } from '../../../Core/Api/Api';
import Utility from 'Core/Utility';

export class LoginFormHandler {
  props: any;
  constructor(props) {
    this.props = props;
  }

  RequestLogin(LoginformModel: LoginFormModel): Promise<SlimUser | undefined> {
    const client = new SecuredClient();
    const request = new LoginCommandRequest();
    request.init(LoginformModel);
    client.showLoading = true;
    client.handleGeneralError = true;
    return client.login(request).then(x => {
      // the user is logged in. store access token
      // and user object for reference
      let cacheKey = `${x?.user?.id}_userPreference`;
      Utility.cacheInMemory(cacheKey, x.user?.userPreference);

      if (x.user) {
        sessionStorage.setItem('user', JSON.stringify(x.user));
      }
      return x.user;
    });
  }
}



