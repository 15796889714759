import * as React from "react";
import { Filter, IFilter, Knowledge } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import KnowledgeHandler from "components/Knowledge/KnowledgeHandler";
import { List } from "antd";
import KnowledgeShareHandler from "components/Admin/KnowledgeShareHandler";
import { KnowledgeListItem } from "components/Knowledge/KnowledgeListView/KnowledgeListTable";
import SharedKnowledgeHandler from "../SharedKnowledgeHandler";
import SharedKnowledgeListItem from "../SharedKnowledgeListItem";

export interface Props {
    children?: React.ReactNode;
    pathRoute?: string;
    isOnPublic?: boolean;
}

export interface State {
}

export default class SharedKnowledgeList extends React.Component<Props, State> {
    handler: SharedKnowledgeHandler;

    constructor(props: Props) {
        super(props)

        this.state = {
        }
        this.handler = new SharedKnowledgeHandler(props);
    }

    render() {
        return (
            <SimpleViewList<Knowledge>
                grid={{ gutter: 12, sm: 1, md: 1, lg: 2 }}
                dataFetcher={this.handler}
                pagination={{ position: "both", showTotal: (x) => `${x} Results`, pageSize: 10, showSizeChanger: true }}
                renderItem={x => <List.Item>
                    <SharedKnowledgeListItem knowledge={x} pathRoute="knowledge/v" />
                </List.Item>} />
        )
    }
}
