import { Row, Col } from 'antd';
import { RDLReportViewer } from 'components/Reports/RDLReportViewer';
import AuthContext from 'Context/AuthContext';
import { AccessLevel } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import Utility from 'Core/Utility';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import KnowledgeHandler from '../KnowledgeHandler';
import KnowledgeFileReportFilterBar from './KnowledgeFileReportFilterBar';

const Wrap = styled.div``;

interface Props {

}

export default ({ }: Props) => {

  const { data: user } = useContext(AuthContext);

  const handler: KnowledgeHandler = new KnowledgeHandler({});
  const [dataSource, setDataSource] = useState<[] | undefined>();

  const generateReport = (tableRequest?: TableRequest) => {

    let groupbyValue = Utility.getFirstFieldValueFromFilters(tableRequest?.filters, "groupBy");

    if (!groupbyValue) {
      NotificationService.error("Group By value required");
      return;
    }

    setDataSource(undefined);

    Utility.changeFilterFieldValue("accessLevel", tableRequest?.filters, Utility.getIntValueOfAccessLevel)

    handler.GetKnowledgeFileReportData(tableRequest).then(x => {
      let sources: any = [];
      let filterDataItem = {
        Jurisdiction: x.jurisdictions,
        AccessLevel: x.accessLevel == "0" ? "Public" : x.accessLevel,
        Categories: x.categories,
        ReportDate: x.reportDate,
        GroupBy: x.groupBy,
        Total: x.count,
      };

      sources.push({ name: 'KnowledgeDataSource', value: x.results });
      sources.push({ name: 'filterDataSource', value: [filterDataItem] });

      setDataSource(sources);
    })
  }

  return (<Row>
    <Col>
      <Row>
        <Col>
          <KnowledgeFileReportFilterBar user={user} generateReport={generateReport}></KnowledgeFileReportFilterBar>
        </Col>
      </Row>
      <Row>
        <Col>
          {dataSource && < RDLReportViewer dataSource={dataSource} fileName="KnowledgeFileReport" />}
        </Col>
      </Row>
    </Col>
  </Row>)
}