import * as React from 'react';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import ConfigService from 'Core/ConfigService';
import AuthUser from 'Core/models/AuthUser';
import moment from 'moment';

export default class TokenService {
  public static GetUserFromToken() {
    var config = ConfigService.getSync();
    var tokenString = Cookies.get(config.Security.tokenName);

    if (!tokenString) {
      return undefined;
    }

    var token = jwtDecode(tokenString) as { [key: string]: any } | undefined;

    if (!token) {
      return undefined;
    }

    let user: AuthUser = {};

    user.id = token[config.Security.tokenProperties.id];
    user.username = token[config.Security.tokenProperties.username];
    user.firstName = token[config.Security.tokenProperties.firstName];
    user.lastName = token[config.Security.tokenProperties.lastName];
    user.sessionId = token[config.Security.tokenProperties.sessionId];
    user.email = token[config.Security.tokenProperties.email];
    user.jurisdictionId = token[config.Security.tokenProperties.jurisdictionId];

    const roleStringOrArray = token[config.Security.tokenProperties.role] as string[] | any;
    if (roleStringOrArray?.filter /*Check if it has an array method */) {
      user.roles = roleStringOrArray;
    } else {
      // is not an array
      user.roles = [roleStringOrArray];
    }
    const operationsStringOrArray = token[config.Security.tokenProperties.operation] as
      | string[]
      | any;
    if (operationsStringOrArray?.filter /*Check if it has an array method */) {
      user.operations = operationsStringOrArray;
    } else {
      // is not an array
      user.operations = token[operationsStringOrArray];
    }

    user.expirationTime = moment.unix(token[config.Security.tokenProperties.expirationDate]);
    return user;
  }
}
