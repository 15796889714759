import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility';
import RoleHandler from 'components/Admin/RoleHandler';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  categories: IFilterSelectOption[];
}
export default class KnowledgeCategoriesListFilterBar extends React.Component<Props, State> {
  handler: KnowledgeHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
    };
    this.handler = new KnowledgeHandler(props);
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    this.handler.GetTop100KnowledgeCategories().then(x => {
      const categories: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories });
    });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Col md={5} xs={24}>
            <Form.Item labelAlign="left">
              <FilterTextbox
                filterFields="name,description"
                filterType={FilterType.Contains}
                allowClear={true}
                placeholder={'Search'}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
