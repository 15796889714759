import React, { useEffect, useState } from 'react';
import { Avatar, Col, Row, Spin, Typography } from 'antd';
import { IGetUserProfileQueryResponse } from 'Core/Api/Api';
import ProfileHandler from 'components/UserProfile/ProfileHandler';
import DetailsData from 'Core/models/DetailsData';
import { useHistory, useLocation } from 'react-router-dom';
import { JurisdictionFlag } from 'components/Shared/JurisdictionFlag';

interface Props {
  profile?: IGetUserProfileQueryResponse;
  cacheProfile?: (profile: IGetUserProfileQueryResponse) => void;
  userIdOrUserName: string;
}

export default ({ profile, cacheProfile, userIdOrUserName }: Props) => {

  const getImageUrl: (IGetMyUserProfileQueryResponse) => string = (profile: IGetUserProfileQueryResponse) => {
    return profile?.imageUrl ?? `https://ui-avatars.com/api/?name=${profile?.firstName}  ${profile?.lastName}&rounded=true&background=d0ea${profile?.id?.substr(0, 2)}&size=256`
  }

  const handler = new ProfileHandler({});
  const location = useLocation<DetailsData | undefined>();
  const history = useHistory();
  const [profileFromState, setProfile] = useState<IGetUserProfileQueryResponse>();



  useEffect(() => {

    if (!profile) {
      handler.GetProfile(userIdOrUserName, false, false).then(x => {
        setProfile(x);
        cacheProfile?.call({}, x);
      }).catch(x => { });
    }
  }, [])


  return (
    <Spin spinning={!profile} >
      <Row type={"flex"} align={"middle"} justify={"start"} style={{ width: "150px" }}>
        <Col span={5}>
          <Avatar shape="square" icon={<JurisdictionFlag size="small" jurisdictionId={profile?.jurisdictionId} />} ></Avatar>
        </Col>
        <Col offset={2} span={16}>
          <Row>
            <Col><Typography.Text style={{ fontSize: '15px' }}>{`${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`}</Typography.Text></Col>
          </Row>
          <Row>
            {profile && <Col><Typography.Text style={{ fontSize: '9px' }}>Jurisdiction: {profile?.jurisdictionName}</Typography.Text></Col>}
          </Row>
        </Col>
      </Row>
    </Spin>);
}