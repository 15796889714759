import * as React from 'react';
import { Layout, Row, Col } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';

export const TallCol = styled(Col)`
  &.ant-col {
    min-height: 89.9vh;
    //padding-top: 15vh;
    margin-bottom: 130px;
    ${ScreenHelper.down('sm')} {
      padding-top: 5vh;
      margin-bottom: 0px;
    }
  }
`;
