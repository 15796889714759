import * as React from 'react';
import { Icon, Col, Row } from 'antd';
import AppRoute from '../../../Core/models/AppRoute';
import { NavLink, withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import Utility from '../../../Core/Utility';
import logo from '../../../assets/images/logo.svg';
import AuthContext from 'Context/AuthContext';
import {
    Menu,
    MenuItem,
    MenuButton,
    MenuProps,
    SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { AntdIconMenu, MobileTitleSpan } from 'components/Styled/Layout/AntdIconMenu';
import styled from 'styled-components';
import ScreenHelper from 'components/Styled/ScreenHelper';

export declare type MenuMode =
    | 'vertical'
    | 'vertical-left'
    | 'vertical-right'
    | 'horizontal'
    | 'inline';


const StyledMenuButton = styled(MenuButton)`
${ScreenHelper.up('sm')} {
    display:none;
  }
${ScreenHelper.up('md')} {
    display:none;
  }
  ${ScreenHelper.up('lg')} {
    display:none;
  }
`;

const StyledMenu = styled(Menu)`
background-color:#044666;
color: rgba(255, 255, 255, 0.65);

li {
    background-color:#044666;
    color: rgba(255, 255, 255, 0.65);
    
    &.active {
        background-color: rgba(255, 255, 255, 0.445);
    }

    &:hover {
        color: white;
    }
}

${ScreenHelper.up('sm')} {
    display:none;
  }
${ScreenHelper.up('md')} {
    display:none;
  }
  ${ScreenHelper.up('lg')} {
    display:none;
  }
`;

export interface Props extends Omit<MenuProps, "menuButton">, RouteComponentProps {
    children?: React.ReactNode;
    menu: AppRoute[];
    mode?: MenuMode;
    onlySubMenuNavigates?: boolean;
}

export interface State { }

export class CollapsedMenuWithoutRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    RenderNavLink(menu: AppRoute, fullPath: string = ''): React.ReactNode {
        if (menu.hideInMainMenu) {
            return;
        }

        return (
            <React.Fragment>
                <MobileTitleSpan>{menu.title}</MobileTitleSpan>
                <NavLink to={fullPath} />
            </React.Fragment>
        );
    }

    RenderChildMenuItems(menuItems: AppRoute[], parentPath: string = ''): React.ReactNode {
        return menuItems.map((menuItem: AppRoute) => this.getMenuItem(menuItem, parentPath));
    }

    getMenuItem(menu: AppRoute, parentPath: string = ''): React.ReactNode {
        // Hide from menu if option is true or it required permission that the user does not have
        const user = this.context.data;
        if (menu.hideInMainMenu || !Utility.HasOperations(user, menu.requiredOperations)) {
            return null;
        }

        const fullPath = Utility.combinePath(parentPath, menu.path as string);

        const clickEvent = () => this.props.history.push(fullPath);

        const hasVisibleSubItems: boolean = !!menu.routes?.some(x =>
            this.isVisibleOrhasVisibleSubItems(x)
        );
        let Element: any = hasVisibleSubItems ? SubMenu : MenuItem;
        const title: any = hasVisibleSubItems ? this.GetTitle(menu) : menu.title;

        // we are going to spread this object on the menu element to handle to click action
        // if it is a submenu the it should be onTitleClick else, onClick
        let clickHandler: any = hasVisibleSubItems
            ? { onClick: clickEvent }
            : { onClick: clickEvent };
        clickHandler = this.props.onlySubMenuNavigates && hasVisibleSubItems ? {} : clickHandler;
        // should we show menu
        const path = { path: this.props.match.path, exact: true };
        const showActive = Utility.isPathOrSubpathAMatch(fullPath, path);
        const activeClass = showActive ? 'active' : '';

        // if menu skip, don't show this route, just render the children
        if (menu.skip) {
            return hasVisibleSubItems
                ? this.RenderChildMenuItems(menu.routes as any, fullPath)
                : this.RenderNavLink(menu, fullPath);
        } else {
            return (
                <Element key={menu.path as string} className={activeClass} title={title} onClick={() => undefined} {...clickHandler} onItemHover={() => undefined}  >
                    {hasVisibleSubItems
                        ? this.RenderChildMenuItems(menu.routes as any, fullPath)
                        : this.RenderNavLink(menu, fullPath)}
                </Element>
            );
        }
    }

    isVisibleOrhasVisibleSubItems(route: AppRoute): boolean {
        return (
            !!route.routes?.filter(y => this.isVisibleOrhasVisibleSubItems(y)) || !route.hideInMainMenu
        );
    }

    GetTitle(menu: AppRoute): any {
        return (
            <React.Fragment>
                <span>{menu.title}</span>
            </React.Fragment>
        );
    }

    render() {
        const { mode, menu, ...rest } = this.props;
        return (
            <StyledMenu theme="dark"
                menuButton={<StyledMenuButton><Icon type="menu" /></StyledMenuButton>}
                style={{ borderBottomWidth: "0px" }}
                {...rest}>
                {this.props.menu.map(item => this.getMenuItem(item))}
            </StyledMenu>
        );
    }
}

const CollapsedMenu = withRouter(CollapsedMenuWithoutRouter);
export default CollapsedMenu;
