import * as React from 'react';
import { NoAccessViewContainer } from './NoAccessView.Styled';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Button } from 'antd';
export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  hasError?: boolean;
}

export class NoAccessViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
  }

  resetErrorAndGoHome = () => {
    this.setState({ hasError: false });
    this.props.history.push('/');
  };

  resetErrorAndGoBack = () => {
    this.setState({ hasError: false });
    this.props.history.push('/logout');
  };

  renderError() {
    return (
      <NoAccessViewContainer>
        <img src={logo} className="logo" width="192" />
        <div className="browser">
          <div className="controls">
            <i></i>
            <i></i>
            <i></i>
          </div>

          <div className="eye"></div>
          <div className="eye"></div>
        </div>

        <h1>You are trying to access a page that you don't have permission to access.</h1>
        <p>
          You can try and request access from your administrator or login with credentials that have
          access{' '}
        </p>
        <CenterAlignedRow>
          <Button onClick={this.resetErrorAndGoBack} type="link">
            Logout and try again
          </Button>
        </CenterAlignedRow>
        <CenterAlignedRow>
          <Button onClick={this.resetErrorAndGoHome} type="link">
            Go Home
          </Button>
        </CenterAlignedRow>
      </NoAccessViewContainer>
    );
  }

  render() {
    return this.state.hasError ? this.renderError() : <>{this.props.children}</>;
  }
}

const NoAccessView = withRouter(NoAccessViewWithoutRouter);

export default NoAccessView;
