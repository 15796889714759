import { Icon, message } from 'antd';
import UserPreferenceHandler from 'components/UserProfile/UserPreferenceHandler';
import AuthContext from 'Context/AuthContext';
import UserPreferenceContext from 'Context/UserPreferenceContext';
import { GetUserPreferenceCommandResponse, UserPreference } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import { debounce } from 'lodash';
import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import { PinnerIcon } from './KnowledgePinner.Styled';


interface Props {
  knowledgeId?: string;
  title?: string;
}

const KnowledgePinner: React.FC<Props> = (props) => {
  let userPreferenceHandler = new UserPreferenceHandler({});
  let authContext = useContext(AuthContext);
  let userPreferenceContext = useContext(UserPreferenceContext);
  let [pinned, setPinned] = useState<Boolean>(false);

  useEffect(() => {

    if (!authContext.data) {
      setPinned(false);
      return;
    }

    let cacheKey = `${authContext.data?.id}_userPreference`;
    let userPreference: GetUserPreferenceCommandResponse | undefined = userPreferenceContext.data;

    if (!userPreference) {
      userPreferenceHandler.GetUserPreference(false, false)?.then(x => {
        userPreferenceContext.Update(x);

        let shouldBePinned = userPreference?.pinnedKnowledge?.some(x => props.knowledgeId == x) ?? false;
        setPinned(shouldBePinned);

      });

    } else {
      let shouldBePinned = userPreference?.pinnedKnowledge?.some(x => props.knowledgeId == x) ?? false;
      setPinned(shouldBePinned);
    }
  }, [userPreferenceContext]);

  let starClick = debounce((event: SyntheticEvent) => {
    event.persist();
    event.stopPropagation();
    event.preventDefault();

    if (!props.knowledgeId) { return; }

    setPinned(!pinned);
    if (pinned) {

      userPreferenceHandler.UnPinKnowledge(props.knowledgeId).then(x => {
        userPreferenceContext.Update(x);
        message.info(`${props.title} unpinned`);

      });
    } else {
      userPreferenceHandler.PinKnowledge(props.knowledgeId).then(x => {
        userPreferenceContext.Update(x);
        message.info(`${props.title} pinned`);
      });
    }
  }, 1000);

  // render
  if (!authContext.data) { return <></>; }

  if (pinned) {
      return <PinnerIcon onClick={e => e.stopPropagation() == Utility.voidmethod() && starClick(e)} type="star" theme="filled" style={{ fontSize: '1.2em', color: '#ffba3b' }} ></PinnerIcon>;
  } else {
      return <PinnerIcon onClick={e => e.stopPropagation() == Utility.voidmethod() && starClick(e)} type="star" theme="outlined" style={{ fontSize: '1.2em', color: '#ffba3b' }} ></PinnerIcon>;
  }
}

export default KnowledgePinner;
