import * as React from 'react';
import { DocumentModel, AccessLevel } from 'Core/Api/Api';
import Table, { ColumnProps } from 'antd/lib/table';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button, Skeleton } from 'antd';
import { DocumentPreview } from '.';
import ShowIfHaveAccessLevel from 'components/Forms/ShowIfHaveAccessLevel';
import DetailsMode from 'Core/models/DetailsMode';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import { random } from 'lodash';
import { UserProfileLink } from 'components/UserProfile/UserProfileLink';

export interface Props {
  children?: React.ReactNode;
  documents?: DocumentModel[];
  accessLevel: AccessLevel;
}

export interface State { }

export default class DocumentListView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  config = ConfigService.getSync();

  onPreview = (document: DocumentModel) => { };

  columns: Array<ColumnProps<DocumentModel>> = [
    {
      title: 'Document',
      render: (text: any, record: DocumentModel, index: number) => {
        return (
          <DocumentPreview accessLevel={this.props.accessLevel} document={record} text={record.name} />
        )
      },
    },
    {
      title: 'Upload By',
      render: (text: any, record: DocumentModel, index: number) => {
        return (<UserProfileLink userName={record.upLoaderId}>{record.uploaderName}</UserProfileLink>);
      },
    },
    {
      title: 'Uploaded',
      render: (text: any, record: DocumentModel, index: number) => {
        return (
          <p title={moment(record.uploadDate).fromNow()}>
            {!record.uploadDate
              ? ''
              : moment(record.uploadDate).format(this.config.Display.DateTimeFormat)}
          </p>
        );
      },
    },
    {
      title: '',
      render: (text: any, record: DocumentModel, index: number) => {
        console.log(record);
        return (
          <>
            <ShowIfHaveAccessLevel
              mode={DetailsMode.Download}
              accessLevel={this.props.accessLevel}>
              <a style={{ marginRight: '10px' }} href={record.url}>
                Download
              </a>
            </ShowIfHaveAccessLevel>
          </>
        );
      },
    },
  ];

  render() {
    return (
      <Row>
        <Col>
          <Skeleton loading={!this.props.documents}>
            <Table<DocumentModel>
              pagination={this.props.documents && this.props.documents.length > 3 && { pageSize: 3 }}
              columns={this.columns}
              rowKey={(d) => d?.id ?? d?.name ?? random().toString()}
              dataSource={this.props.documents}
            />
          </Skeleton>
        </Col>
      </Row>
    );
  }
}
