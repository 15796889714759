import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility';
import RoleHandler from 'components/Admin/RoleHandler';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import { orderBy } from 'lodash';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  categories: IFilterSelectOption[];
}
export default class KnowledgeListFilterBar extends React.Component<Props, State> {
  handler: KnowledgeHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
    };
    this.handler = new KnowledgeHandler(props);
  }

  componentDidMount() {
    this.getCategories();
  }

  getCategories() {
    this.handler.GetTop100KnowledgeCategories().then(x => {
      const categories: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories: orderBy(categories, 'text', 'asc') });
    });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Col span={5}>
            <Form.Item label="Title" labelAlign="left">
              <FilterTextbox
                filterFields="title"
                filterType={FilterType.Contains}
                allowClear={true}
                placeholder={'Enter title'}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Category" labelAlign="left">
              <FilterSelectbox
                filterFields="categoryId"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Categories'}
                options={this.state.categories}
              />
            </Form.Item>
          </Col>
          <Col span={5}>
            <Form.Item label="Author" labelAlign="left">
              <FilterTextbox
                filterFields="createdBy"
                filterJoin={JoinType.And}
                suffix={<InfoIcon text="Search Author" />}
              />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item label="Created Date" labelAlign="left">
              <FilterDateRangePicker
                filterFields="creationDate"
                filterType={FilterType.Group}
                allowClear={true}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
