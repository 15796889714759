import * as React from 'react';
import { User, SlimUser, AuditHeader, Knowledge, AccessLevel } from 'Core/Api/Api';
import { Row, Col, Typography, List, Card, Avatar, Button, Tag, Descriptions, Modal } from 'antd';
import ConfigService from 'Core/ConfigService';
import moment from 'moment';
import Title from 'antd/lib/typography/Title';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { CategoryCol, TitleRow, TimeText } from './SharedKnowledgeListItem.Style'
import SurveyViewer from 'components/Forms/SurveyViewer';
import TextClip from 'components/Forms/TextClip';
import { JurisdictionFlag } from 'components/Shared/JurisdictionFlag';
import AuthContext from 'Context/AuthContext';
import Utility from 'Core/Utility';
import KnowledgePinner from 'components/Knowledge/KnowledgePinner';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { modalview } from 'react-ga';
import { DisplayAccessLevelPopup } from 'components/Shared/DisplayAccessLevelPopup';
import { DocumentPreview } from 'components/Knowledge/KnowledgeDetailsView/DocumentListView';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import ShowIfHaveAccessLevel from 'components/Forms/ShowIfHaveAccessLevel';
import { SearchTextHighlight } from 'components/Forms/SearchTextHighlight';

export interface Props extends RouteComponentProps {
    children?: React.ReactNode,
    knowledge?: Knowledge;
    accessLevel?: AccessLevel;
    pathRoute?: string;
    isOnPublic?: boolean;
}

export interface State {
    hasPermission: boolean;
}

export class SharedKnowledgeListItemWithoutRouter extends React.Component<Props, State> {
    static defaultProps: Props = {
        pathRoute: "public-knowledge/v"
    } as any;

    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    config = ConfigService.getSync();

    constructor(props: Props) {
        super(props)

        this.state = {
            hasPermission: false,
        }

    }

    componentDidMount() {
        const operation = Utility.GetOperationForAccessLevel(DetailsMode.View, this.props.knowledge?.accessLevel!);
        const hasPermission = Utility.HasOperations(this.context.data, [operation]);
        this.setState({ hasPermission });
    }

    renderAccessLevel = (item: Knowledge) => {
        const accessLevelText = item.accessLevel == AccessLevel.Public ? "Public" : "Level " + item.accessLevel?.substr(5, 1);

        if (item.accessLevel == AccessLevel.Public) {
            return <Tag style={{ marginRight: "3px" }} color="green">Public</Tag>
        }
        else {
            return <Tag style={{ marginRight: "3px" }} color="#DB425A">{accessLevelText}</Tag>
        }
    }

    renderTitle = (item: Knowledge) => {
        return (
            <TitleRow>
                <Col>
                    <Row type="flex" justify="space-between" >
                        <Col>
                            <KnowledgePinner knowledgeId={item.id} title={item.title} />
                            <JurisdictionFlag addPadding={true} size="small" jurisdictionId={item.jurisdictionId} />
                        </Col>
                        <CategoryCol>
                            <DisplayAccessLevelPopup accessLevel={item.accessLevel} >
                                {this.renderAccessLevel(item)}
                            </DisplayAccessLevelPopup>
                            <Tag style={{ marginRight: "0px" }} color="#044767">{item.category?.name}</Tag>
                        </CategoryCol>
                    </Row>
                    <Row>
                        <Col style={{ marginTop: "7px", minHeight: "42px", maxHeight: "42px", overflow: "hidden" }}>
                            <NavLink
                                title={item.title}
                                to={{
                                    pathname: `${this.props.pathRoute ?? "public-knowledge"}/v/${encodeURIComponent(item.title ?? '')}`,
                                    state: new DetailsData(DetailsMode.View, item.id, item.title),
                                }}>
                                <Typography.Text strong={true}> <SearchTextHighlight field="title">{item.title}</SearchTextHighlight> </Typography.Text>
                            </NavLink>
                        </Col>
                    </Row>
                </Col>
            </TitleRow>
        )
    }

    renderTime = (item: Knowledge) => {
        return (
            <Row type="flex" align="bottom" justify="end">
                <TimeText title={moment(item.creationDate).fromNow()}>
                    {!item.creationDate ? '' : moment(item.creationDate).format(this.config.Display.DateTimeFormat)}
                </TimeText>
            </Row>
        )
    }

    renderDescription = (item: Knowledge) => {
        return (
            <>
                <Row style={{ marginBottom: "5px" }}>
                    <Col>
                        <Typography.Text type="secondary" >Description</Typography.Text>
                    </Col>
                </Row>
                <Row style={{ marginBottom: "10px", width: '100%', wordWrap: 'break-word', minHeight: '130px', maxHeight: '130px', overflow: "hidden" }}>
                    {!item?.knowledgeDataTemplateId && (
                        <SearchTextHighlight field="description" >{item?.description ?? ""}</SearchTextHighlight>


                    )}
                    {item?.knowledgeDataTemplateId && (
                        <Col style={{ marginBottom: "10px", width: '100%', wordWrap: 'break-word', minHeight: '130px', maxHeight: '130px', overflow: "hidden" }} >
                            <Descriptions.Item label="Description" span={4}>
                                <></>
                            </Descriptions.Item>
                            <Descriptions.Item label="" span={4}>
                                <SurveyViewer
                                    style={{ fontSize: ".83em" }}
                                    surveyValues={item?.templateDataJSON}
                                    template={item?.currentlySelectedTemplateJSON}
                                    maxItems={2}
                                    size={"small"}
                                    showMore={true}
                                />

                            </Descriptions.Item>
                        </Col>
                    )}
                </Row>
            </>
        )
    }

    navToKnowledgeDetails = (event: React.SyntheticEvent) => {
        event.persist();
        event.stopPropagation();
        const { pathname, state } = this.getNavigationState(this.props.knowledge!, DetailsMode.View);
        this.props.history.push(pathname, state);
    }

    renderNoAccessContent = (
        <>
            <p>You do not have access to view this knowledge.</p>
            <p>
                <ul>
                    <li>You can login with credentials that do. <Button style={{ padding: '0px' }} type="link" onClick={e => this.props.history.push("/login") == Utility.voidmethod() && Modal.destroyAll() == Utility.voidmethod()} >Click here to Login</Button></li>
                    <li>If you do not have a login you can request access. <Button style={{ padding: '0px' }} type="link" onClick={e => this.props.history.push("/register") == Utility.voidmethod() && Modal.destroyAll() == Utility.voidmethod()} >Click here to request access</Button></li>
                </ul>
            </p>
        </>
    );

    renderNavLink(item: Knowledge, label: string = "Edit", type: 'button' | 'plain' = 'button', detailsMode: DetailsMode = DetailsMode.Edit): React.ReactNode {
        const LabelWrapper = type == 'button' ? Button : React.Fragment;
        return (
            <NavLink onClick={x => x.stopPropagation()}
                to={this.getNavigationState(item, detailsMode)}>
                <LabelWrapper type="primary">{label}</LabelWrapper>
            </NavLink>
        )
    }

    getNavigationState = (item: Knowledge, detailsMode: DetailsMode = DetailsMode.Edit) => {
        let path = this.props.pathRoute;
        if (detailsMode == DetailsMode.Edit) {
            path = path?.replace("/v", "");
        }
        return {
            pathname: `/${path}/${encodeURIComponent(item.title ?? '')}`,
            state: new DetailsData(detailsMode, item.id, item.title),
        }
    }

    renderAttachements(item: Knowledge): React.ReactNode {

        return (
            <Row style={{ maxHeight: "75px", minHeight: "75px", overflow: "hidden" }}>
                <Col span={16}>
                    <ShowIfTrue condition={(item.documents?.length ?? 0) > 0}>
                        <Row>
                            <Col>
                                <Typography.Text type="secondary" > Attachment{item.documents?.length! > 1 ? "s" : ""}: </Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                {item.documents?.filter((x, i) => i < 2).map(x =>
                                    <DocumentPreview disabled={!this.state.hasPermission} isTag={true} textClipStyle={{ backgroundColor: "#209acd", padding: "3px 7px", marginBottom: "2px" }} accessLevel={AccessLevel.Public} text={x.name} document={{ ...x, ...x.dataFile, url: `api/file/${x.dataFile?.fileId}` }} />

                                )}
                                {(item.documents?.length ?? 0) > 2 && <Tag color="#108ee9">{this.renderNavLink(item, "More...", 'plain', DetailsMode.View)}</Tag>}
                            </Col>
                        </Row>

                    </ShowIfTrue>
                </Col>
            </Row>
        )
    }

    renderFooter(item: Knowledge): React.ReactNode {
        return (
            <Row type="flex" justify="space-between" align="bottom" style={{ marginTop: 'auto' }}>
                <Col span={2}>
                    <ShowIfTrue condition={!!!this.props.isOnPublic}>
                        <ShowIfHaveAccessLevel juristictionId={item.jurisdictionId} mode={DetailsMode.Edit} accessLevel={item.accessLevel!} >
                            {this.renderNavLink(item)}
                        </ShowIfHaveAccessLevel>
                    </ShowIfTrue>
                </Col>
                <Col >
                    {this.renderTime(item)}
                </Col>
            </Row>
        )
    }

    render() {
        const item = this.props.knowledge!;

        const canView = this.props.knowledge?.accessLevel == AccessLevel.Public || this.state.hasPermission;
        return (
            <Card onClick={this.navToKnowledgeDetails} style={{ backgroundColor: canView ? 'white' : '#FFFFFD', width: '100%', minHeight: '340px', height: '420px' }} hoverable={true}  >
                {this.renderTitle(item)}
                {this.renderDescription(item)}
                {this.renderAttachements(item)}
                {this.renderFooter(item)}
            </Card>

        )
    }
}

const SharedKnowledgeListItem = withRouter(SharedKnowledgeListItemWithoutRouter);
export default SharedKnowledgeListItem;