import { Icon } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button/button';
import { debounce } from 'lodash';
import React, { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props extends ButtonProps {
  children?: React.ReactNode;
  disableTime?: number;
}

export default (props: Props) => {
  let [disabled, setDisabled] = useState<boolean | undefined>(props.disabled)
  let [icon, setIcon] = useState<string | undefined>();
  let onClick = debounce((event: React.MouseEvent<any, any>) => {
    setDisabled(true);
    props.onClick?.call(null, event);
    setIcon("loading");
    setTimeout(x => {
      setIcon(undefined);
      setDisabled(props.disabled);
    }, props.disableTime ?? 5000);
  }, 100);
  let { disableTime, ...propsWithoutDisabledTime } = props;
  return <Button {...propsWithoutDisabledTime} onClick={onClick} disabled={disabled ?? props.disabled} icon={icon} />;
}