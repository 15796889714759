import * as React from 'react';
import antd from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import styled from 'styled-components';
import FormControl from '../../Forms/FormControl';

const { Form } = antd;

export const StyleLoginForm = styled(Form)`
  width: 100%;

  .login-form-forgot {
    margin-left: auto;
    margin-right: auto;
    margin-top: -25px;
    margin-bottom: 5px;
  }     

  .login-form-button {
    width: 100%;
  }

    .login-form-request{
        text-align: right;
    }
`;



