import * as React from 'react';
import styled from 'styled-components';
import { Layout, Menu, Row } from 'antd';
import ScreenHelper from '../../Styled/ScreenHelper';
const { Sider } = Layout;

export const SlimSider = styled(Sider)`
z-index: 10; 

${ScreenHelper.down('sm')} {
    display:none;
  }

`;
