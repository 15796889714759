import * as React from 'react';
import KnowledgeShareListView from 'components/Admin/KnowledgeShareListView/KnowledgeShareListView';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class KnowledgeShareListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Knowledge Share Request">
        <KnowledgeShareListView />
      </PageContainer>
    );
  }
}
