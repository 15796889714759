import * as React from 'react';
import Button, { ButtonProps } from 'antd/lib/button';
import { Popconfirm, Spin } from 'antd';
import styled from 'styled-components';

export interface Props extends ButtonProps {
  children?: React.ReactNode;
  onConfirm: () => Promise<any>;
  warningMessage?: React.ReactNode;
}

export interface State {
  isLoading: boolean;
}

var InLineSpin = styled(Spin)`
  display: inline-block;
`;

export default class ConfirmRemoveButton extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { isLoading: false };
  }

  onConfirmedClicked = () => {
    this.setState({ isLoading: true }, () => {
      this.props.onConfirm().finally(() => {
        this.setState({ isLoading: false });
      });
    });
  };

  render() {
    let { warningMessage, onConfirm, ...rest } = this.props;
    let message = this.props.warningMessage ?? 'Are you sure?';
    rest.children = rest.children ?? 'Remove';
    return (
      <InLineSpin style={{ display: 'inline-block' }} spinning={this.state.isLoading}>
        <Popconfirm title={message} okText="Yes" onConfirm={this.onConfirmedClicked}>
          <Button {...rest} />
        </Popconfirm>
      </InLineSpin>
    );
  }
}
