import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType, UserStatus } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import UserHandler from '../../UserHandler';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility';
import { orderBy } from 'lodash';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
  userStatus: IFilterSelectOption[];
}
export default class RegistrationListFilterBar extends React.Component<Props, State> {
  handler: UserHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
      userStatus: [],
    };
    this.handler = new UserHandler(props);
  }

  componentDidMount() {
    this.getJurisdictions();
    this.getCountries();
    this.getRoles();
    this.getStatus();
  }

  getJurisdictions() {
    this.handler.GetTop100Jurisdictions().then(x => {
      const jurisdictions: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions: orderBy(jurisdictions, 'text', 'asc') });
    });
  }

  getCountries() {
    this.handler.GetTop500Countries().then(x => {
      const countries: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ countries });
    });
  }

  getRoles() {
    this.handler.GetPublicFilteredRole().then(x => {
      const roles: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ roles });
    });
  }

  getStatus() {
    const userStatus = Utility.enumToSelectionOptionArray(UserStatus);
    this.setState({ userStatus });
  }

  render() {
    return (
      <FilterBar>
        <Row type="flex">
          <Col xl={{ span: 8 }} sm={{ span: 12 }} xs={24}>
            <Form.Item labelAlign="left">
              <FilterTextbox
                filterFields="firstName,lastName,emailAddress"
                filterJoin={JoinType.And}
                suffix={<InfoIcon text="Search name" />}
                placeholder={'Search'}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} sm={{ span: 12 }} xs={24}>
            <Form.Item labelAlign="left">
              <FilterSelectbox
                filterFields="UserRoles.RoleId"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Role'}
                options={this.state.roles}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} sm={{ span: 12 }} xs={24}>
            <Form.Item labelAlign="left">
              <FilterSelectbox
                filterFields="Jurisdiction.Id"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Jurisdiction'}
                options={this.state.jurisdictions}
              />
            </Form.Item>
          </Col>
          {/* <Col xl={{ span: 8 }} sm={{ span: 12 }} >
            <Form.Item label="Countries" labelAlign="left">
              <FilterSelectbox
                filterFields="Jurisdiction.Countries.Id"
                filterType={FilterType.Equal}
                placeholder={'Select Country'}
                filterOption={true}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                options={this.state.countries}
              />
            </Form.Item>
          </Col> */}
          <Col xl={{ span: 8 }} sm={{ span: 12 }} xs={24}>
            <Form.Item labelAlign="left">
              <FilterSelectbox
                filterFields="status"
                filterType={FilterType.Equal}
                filterJoin={JoinType.And}
                placeholder={'Status'}
                options={this.state.userStatus}
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} sm={{ span: 12 }}>
            <Form.Item label="Creation Date" labelAlign="left">
              <FilterDateRangePicker
                filterFields="creationDate"
                filterType={FilterType.GreaterThanOrEqualTo}
                filterJoin={JoinType.And}
              />
            </Form.Item>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
