import { Form, Icon, Input, Button, Checkbox, Col, Row } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import React from 'react';
import { Redirect, RouteProps, NavLink, RouteComponentProps } from 'react-router-dom';
import AuthContext from '../../../Context/AuthContext';
import FormControl from '../../Forms/FormControl';
import { StyleLoginForm } from './LoginForm.Style';
import { LoginFormHandler } from './LoginFormHandler';
import AuthUser from 'Core/models/AuthUser';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps<any, any, any> {
  children?: React.ReactNode;
}

export interface State {
  isLoggedIn: boolean;
  currentUser?: AuthUser;
}

class BaseLoginForm extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  loginFormHandler: LoginFormHandler;

  constructor(props: Props) {
    super(props);
    this.loginFormHandler = new LoginFormHandler(props);
    this.state = { isLoggedIn: false, currentUser: this.context?.data };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.loginFormHandler.RequestLogin(values).then(user => {
          this.context.Update(user);
          this.setState({ isLoggedIn: true });
        });
      }
    });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    let state: { from?: any; noPermission?: boolean } = {};

    if (this.props.location) {
      state = this.props.location.state || {};
    }

    if (this.state.isLoggedIn) {
      return this.ShouldRedirectTo(state) ? <Redirect to={state.from} /> : <Redirect to="/" />;
    }

    return (
      <StyleLoginForm onSubmit={this.handleSubmit}>
        <FormControl
          getFieldDecorator={getFieldDecorator}
          rules={[{ required: true, message: 'Please input your username!' }]}
          name="username">
          <Input
            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Username"
          />
        </FormControl>
        <FormControl
          getFieldDecorator={getFieldDecorator}
          rules={[{ required: true, message: 'Please input your Password!' }]}
          name="password">
          <Input.Password
            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
            type="password"
            placeholder="Password"
          />
        </FormControl>
        <Form.Item className="login-form-forgot">
          <NavLink to="register">Request Access</NavLink>
        </Form.Item>
        <Form.Item className="login-form-forgot">
          <NavLink to="user/reset-password">Forgot password</NavLink>
        </Form.Item>
        <SubmitButton disableTime={1500} type="primary" htmlType="submit" className="login-form-button">
          Log in
        </SubmitButton>
        <Form.Item>
          <NavLink to="/">Go To Dashboard</NavLink>
        </Form.Item>
      </StyleLoginForm>
    );
  }

  private ShouldRedirectTo(state: { from?: any; noPermission?: boolean | undefined }): boolean {
    return !!state.from && state.from.pathname !== '/logout';
  }
}

const LoginForm = Form.create<Props>({
  name: 'Login',
})(BaseLoginForm);

export default LoginForm;
