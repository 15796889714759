import React, { MouseEventHandler, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { Button, Popover } from 'antd';
import ProfileHandler from '../ProfileHandler';
import { profile } from 'console';
import { useHistory } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { ProfileLinkPopup } from './ProfileLinkPopup';
import { IGetMyUserProfileQueryResponse } from 'Core/Api/Api';
import AuthContext from 'Context/AuthContext';

interface Props {
  userName?: string,
  userId?: string;
  children?: React.ReactNode,
}

export default ({ userName, userId, children }: Props) => {

  const userProfileHandler = new ProfileHandler({});
  const history = useHistory();
  const [popupVisible, setPopVisible] = useState<boolean>(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (!children) {
      userProfileHandler.GetProfile(userId!, false, false).then(x => setProfile(x));
    }
  }, [])

  const [profile, setProfile] = useState<IGetMyUserProfileQueryResponse | undefined>(undefined);

  const onClick = (event: SyntheticEvent) => {
    event.preventDefault();
    const details: DetailsData = {
      mode: DetailsMode.View,
      id: userId ?? userName,
      pageTitle: `${children ?? userName ?? children} Profile`
    }
    history.push(`/user-profile/${encodeURI(userName ?? "User")}`, details)
  }

  if (!authContext.data?.id) {
    return <>{children}</>;
  } else {
    return (
      <Popover content={<ProfileLinkPopup profile={profile} userIdOrUserName={userName ?? userId!} cacheProfile={(x) => setProfile(x)} />}>
        <Button style={{ padding: "0px" }} type={"link"} onClick={onClick} >{children ?? userName ?? `${profile?.firstName} ${profile?.lastName}`}</Button>
      </Popover>);
  }
}