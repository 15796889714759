import React, { CSSProperties, useEffect, useState } from 'react';
import styled, { CSSProp } from 'styled-components';
import Flag from "react-flags";
import JurisdictionHandler from './JurisdictionHandler';
import Utility from 'Core/Utility';
import { Jurisdiction } from 'Core/Api/Api';

const Wrap = styled.div``;

interface Props {
  jurisdictionId?: string;
  size?: 'small' | 'large';
  addPadding?: boolean;
}

let InlineDiv = styled.div`
  display: inline-block;
  img {
    margin-top: -7px;
    margin-left: 7px;
  }
`;

export default ({ jurisdictionId, size, addPadding }: Props) => {

  const handler = new JurisdictionHandler({});

  let [flagSource, setFlagSource] = useState<string>();

  useEffect(() => {
    let cachedJurisidctions = Utility.getFromCache<Jurisdiction[] | undefined>("Jurisdictions");
    if (!cachedJurisidctions) {
      handler.GetJurisdictions().then(x => {
        Utility.cacheInMemory("Jurisdictions", x.results);
        cachedJurisidctions = x.results;
        let foundJurisdiction = cachedJurisidctions!.filter(x => x.id == jurisdictionId)[0];
        setFlagSource(foundJurisdiction?.flagIconPathOrName);
      });
    } else {
      let foundJurisdiction = cachedJurisidctions!.filter(x => x.id == jurisdictionId)[0];
      if (foundJurisdiction) {
        setFlagSource(foundJurisdiction.flagIconPathOrName);
      }
    }
  }, [jurisdictionId])

  const actualSize = size == "large" ? 64 : 32;
  const Wrapper = addPadding ? InlineDiv : React.Fragment;
  return (flagSource ? <Wrapper><Flag
    name={flagSource}
    format="png"
    pngSize={actualSize}
    shiny={true}

    basePath="/img/flags"
    alt={`${flagSource} flag`}
  /></Wrapper> : <></>);
}