import React from 'react';
import Input, { InputProps } from 'antd/lib/input';
import { AutoComplete } from 'antd';
import { AutoCompleteProps } from 'antd/lib/auto-complete';

interface Props extends AutoCompleteProps {
}

const titleSource = [
  "Dr."
  , "Esq."
  , "Hon."
  , "Jr."
  , "Mr."
  , "Mrs."
  , "Ms."
  , "Messrs."
  , "Mmes."
  , "Msgr."
  , "Prof."
  , "Rev."
  , "Rt. Hon."
  , "Sr."
  , "St."
];

export default (props: Props) => {
  return <AutoComplete dataSource={titleSource} {...props} />;
}