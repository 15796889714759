import ITableDataFetcher from '../../components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import {
  User,
  GetAllUsersByFilterQueryRequest,
  SlimUser,
  GetFilteredJurisdictionsQueryRequest,
  OrderBy,
  GetFilteredCountriesQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetUserByIdQueryRequest,
  EditUserCommandRequest,
  AddUserCommandRequest,
  AddRoleCommandRequest,
  EditRoleCommandRequest,
  GetRoleByIdQueryRequest,
  RoleListView,
  IRemoveRoleCommandRequest,
  RemoveRoleCommandRequest,
} from '../../Core/Api/Api';
import TableRequest from '../../Core/TableUtility/Models/TableRequest';
import ITableDataResponse from '../../components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { SecuredClient } from '../../Core/Api/SecuredClient';
import { Role } from '../../Core/Api/Api';

export default class RoleHandler implements ITableDataFetcher<RoleListView> {
  constructor(props?: any) {}

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<RoleListView>> {
    return this.GetAllRolesByFilter(tableRequest);
  }

  GetAllRolesByFilter(tableRequest: TableRequest): Promise<ITableDataResponse<RoleListView>> {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllRolesByFilterQueryRequest(tableRequest);
    return client.getAllRolesByFilter(request);
  }

  GetAllUserByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getFilteredMealsForOwnerRequest = new GetAllUsersByFilterQueryRequest(request);
    return client.getAllUsersByFilter(getFilteredMealsForOwnerRequest);
  }

  GetTop100Jurisdictions() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredJurisdictionsQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredJurisdictions(request);
  }

  GetOperations() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    return client.getOperations();
  }

  GetTop500Countries() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredCountriesQueryRequest();
    request.amountPerPage = 500;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredCountries(request);
  }

  GetRoleById(roleId?: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetRoleByIdQueryRequest();
    request.roleId = roleId;
    return client.getRoleById(request);
  }

  EditRole(request: EditRoleCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.editRole(request);
  }

  removeRole(iRequest: IRemoveRoleCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    let request = new RemoveRoleCommandRequest(iRequest);

    return client.removeRole(request);
  }

  AddRole(request: AddRoleCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.addRole(request);
  }
}
