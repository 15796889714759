import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import {
  User,
  GetAllUsersByFilterQueryRequest,
  SlimUser,
  GetFilteredJurisdictionsQueryRequest,
  OrderBy,
  GetFilteredCountriesQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetUserByIdQueryRequest,
  EditUserCommandRequest,
  AddUserCommandRequest,
  GetAllAuditHeaderByFilterModel,
  GetAuditByIdQueryRequest,
  AuditHeader,
  GetAllAuditTypesQueryRequest,
} from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { SecuredClient } from 'Core/Api/SecuredClient';

export default class AuditHandler implements ITableDataFetcher<AuditHeader> {
  constructor(props?: any) {}

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<AuditHeader>> {
    return this.GetAuditHeaderByFilter(tableRequest);
  }

  GetAuditHeaderByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllAuditHeaderByFilterModel = new GetAllAuditHeaderByFilterModel(request);
    return client.getAllAuditHeaderByFilter(getAllAuditHeaderByFilterModel);
  }

  GetTop100Jurisdictions() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredJurisdictionsQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredJurisdictions(request);
  }

  GetAllAuditTypes() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllAuditTypesQueryRequest();
    return client.getAllAuditTypes(request);
  }

  GetFilteredRoles(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 20;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRolesByFilter(request);
  }

  GetAuditDetailsById(auditHeaderId: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetAuditByIdQueryRequest();
    request.id = auditHeaderId;
    return client.getAuditById(request);
  }
}
