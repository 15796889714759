import { Row, Col } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import Paragraph from 'antd/lib/typography/Paragraph';
import { NavLink } from 'react-router-dom';
import { SFooter } from './Footer.Styled';

interface Props {
}


export default ({ }: Props) => {
  return (<SFooter>
    <Row gutter={16}>
      <Col xs={24} sm={12} md={12}>
        <Title style={{ color: 'white' }} level={4}>
          Need Help?
        </Title>
        <React.Fragment>
          <Paragraph style={{ color: 'white' }}>
            Phone: 868.873-2838
          </Paragraph>
          {/* <Paragraph style={{ color: 'white' }}>
            <Icon type="mail" /> Email Support
          </Paragraph> */}
          <Paragraph style={{ color: 'white' }}>
            <NavLink style={{ color: 'white' }} to="/jurist-project">juristproject.org</NavLink>
           </Paragraph>
          <Paragraph style={{ color: 'white' }}>
            <a target="_blank" style={{ color: 'white' }} href="https://juristproject.org/cjis-help-documentation/">Help Documentation</a>
          </Paragraph>
        </React.Fragment>
      </Col>
      <Col xs={24} sm={12} md={12}>
        <Title style={{ color: 'white' }} level={4}>
          Helpful Links
        </Title>
        <Paragraph style={{ color: 'white' }}><NavLink style={{ color: 'white', paddingRight: '50px' }} to="/about-us">About Us</NavLink></Paragraph>
        <Paragraph style={{ color: 'white' }}><NavLink style={{ color: 'white' }} to="/faq">Frequently Asked Questions</NavLink></Paragraph>
        <Paragraph style={{ color: 'white' }}><NavLink style={{ color: 'white' }} to="/jurisdictions">Jurisidictions</NavLink></Paragraph>
      </Col>
    </Row>
  </SFooter>);
}