import * as React from 'react';
import { Row, Col, Button, List, Dropdown, Icon, Menu } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { Filter, IFilter, Knowledge, Operations, UserStatus } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import { debounce } from 'lodash';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AuthContext from 'Context/AuthContext';
import SharedKnowledgeList from './SharedKnowledgeList/SharedKnowledgeList';
import { AdvanceKnowledgeListFilterBar } from 'components/Knowledge/KnowledgeListView';
import SharedKnowledgeListItem from './SharedKnowledgeListItem';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

export class SharedKnowledgeListViewWithoutRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  debouncedLogSearchToAnalytics: Function = debounce(Utility.logSearchToAnalytics, 1000);

  constructor(props: Props) {
    super(props);

    this.state = {};

  }

  beforeFilterUpdate = (tableRequest: TableRequest) => {
    Utility.makeKnowledgeDocumentFilterIngored(tableRequest);
    this.debouncedLogSearchToAnalytics(tableRequest, this.props.location.pathname)
    return tableRequest;
  }

  render() {
    return (
      <TableFilterContextProvider tableRequestName="SharedKnowledgeListViewTable" persistOnSubPathOnly={true} beforeUpdate={this.beforeFilterUpdate}>
        <Row type="flex" justify="center" align="middle">
          <Col md={16}>
            <AdvanceKnowledgeListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col offset={1} md={22}>
            <SharedKnowledgeList pathRoute="knowledge" />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const SharedKnowledgeListView = withRouter(SharedKnowledgeListViewWithoutRouter);

export default SharedKnowledgeListView;
