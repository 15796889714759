import Utility from 'Core/Utility';
import { escapeRegExp } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import TableFilterContext from '../DataGrid/TableFilterContextProvider/TableFilterContext';

interface Props {
  children?: React.ReactNode;
  field: string;
}

const StyledMark = styled.mark`
  margin: 0px;
  padding: 0px;
  background-color: yellow;
  &.ant-typography {
    background-color: yellow;
  }
`;

export default (props: Props) => {

  const tableFilterContext = useContext(TableFilterContext);
  const [values, setValues] = useState<string[] | undefined>(undefined);

  useEffect(() => {

    if (tableFilterContext.data?.filters && props.field) {
      let values = Utility.getFieldValuesFromFilters(tableFilterContext.data?.filters, props.field);
      setValues(values);
    }
  }, [tableFilterContext])

  const Highlighted = ({ text = '', highlights }) => {
    if (!highlights || !highlights.length) {
      return <span title={text}>{text}</span>
    }

    let regString = highlights.map(x => `(${escapeRegExp(x)})`).join("|");

    const regex = new RegExp(regString, 'gi')
    const parts = text.split(regex)
    return (
      <span title={text}>
        {parts.filter(part => part).map((part, i) => (
          regex.test(part) ? <StyledMark key={i}>{part}</StyledMark> : <span key={i}>{part}</span>
        ))}
      </span>
    )
  }

  return <Highlighted text={props.children as string} highlights={values} />;
}