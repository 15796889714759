import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Operations, SlimRegistration } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { ScrollableTable } from 'components/Forms/DataGrid/SimpleTable/SimpleTable.Styled';
import RegistrationHandler from '../../RegistrationHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
import { Row, Col, Button, Icon } from 'antd';
import { NavButton } from 'components/Forms/NavButton';
import Paragraph from 'antd/lib/skeleton/Paragraph';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import Utility from 'Core/Utility';
import AuthContext from 'Context/AuthContext';
export interface Props {
    children?: React.ReactNode;
    isRegional?: boolean;
}

export interface State {
    countries: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class RegistrationListTable extends React.Component<Props, State> {
    handler: RegistrationHandler;
    tableRef = React.createRef<SimpleTable<any>>();
    config = ConfigService.getSync();

    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;


    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            roles: [],
        };

        this.handler = new RegistrationHandler(props);
    }

    removeUser = (user: User): Promise<any> => {
        return this.handler.RemoveUser({ id: user.id }).then(x => {
            NotificationService.success(`Removed "${user.firstName} ${user.lastName}" user`);
            this.tableRef.current?.refresh();
        });
    };

    userListTableColumns: Array<ColumnProps<SlimRegistration>> = [
        {
            title: 'Name',
            render: (text: any, record: SlimRegistration, index: number) => {
                return (
                    <>
                        {record.firstName} {record.lastName}
                    </>
                );
            },

        },
        {
            title: 'Email',
            dataIndex: 'emailAddress',
        },
        {
            title: 'Juristiction',
            dataIndex: 'jurisdictionName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text: any, record: SlimRegistration, index: number) => {
                if (text == "Pending" && record.approvalCount) {
                    return <p>Pending with {record.approvalCount} Approval</p>
                } else {
                    return text;
                }
            },
        },
        {
            title: 'Created',
            dataIndex: 'creationDate',
            render: (text: any, record: SlimRegistration, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {!text ? '' : moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: '',
            width: "200px",
            render: (text: any, record: SlimRegistration, index: number) => {
                let iconType = record.status == "Pending" ? 'edit' : 'read';
                iconType = this.context.data?.jurisdictionId == record.jurisdictionId ? iconType : 'read';
                return (
                    <Row gutter={10} type="flex" justify="space-around">
                        <Col>
                            <ShowIfHavePermission operations={[Operations.Edit_User]}>
                                <NavButton
                                    to={{
                                        pathname: 'registrations/' + encodeURIComponent(record.emailAddress ?? ""),
                                        state: { userId: record.id, mode: DetailsMode.View },
                                    }}>
                                    <Button><Icon type={iconType} /></Button>
                                </NavButton>
                            </ShowIfHavePermission>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    render() {
        return (
            <ScrollableTable>
                <SimpleTable<SlimRegistration>
                    tableKey="id"
                    ref={this.tableRef}
                    dataFetcher={this.handler}
                    columns={this.userListTableColumns}

                />
            </ScrollableTable>
        );
    }
}
