import { Divider } from 'antd';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import PublicKnowledgeHandler from 'components/Knowledge/PublicKnowledgeHandler';
import { FilterType, IFilter, JoinType, Knowledge } from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import Utility from 'Core/Utility';
import React, { useEffect, useState } from 'react';
import SimpleSelect from '../SimpleSelect';
import ISelectOption from '../SimpleSelect/ISelectOption';
import { KnowledgeSimpleSelect } from './KnowledgeSimpleSelect';
import { SelectableKnowledgeTable } from './SelectableKnowledgeTable';

interface Props {
  value?: any;
  onChange?: Function;
  filteredJurisdictionId?: string;
}

export default (props: Props) => {
  let [selectedKnowledges, setSelectedKnowledges] = useState<Knowledge[]>([]);
  let [alreadySetIntialValue, setalreadySetIntialValue] = useState<boolean>(false);

  const handler = new PublicKnowledgeHandler(props);

  useEffect(() => {
    if (props.value && !alreadySetIntialValue) {
      let request = Utility.CreateTableRequest();
      request.filters = [];
      request.amountPerPage = 1000;

      if (props.value.push) {
        //is array
        (props.value as []).forEach(x => request.filters?.push({ field: "id", filterType: FilterType.Equal, value: x, joinType: JoinType.Or }));
      } else {
        request.filters?.push({ field: "id", filterType: FilterType.Equal, value: props.value, joinType: JoinType.Or });
      }

      handler.getAllKnowledgeByFilter(request).then(x => {
        setSelectedKnowledges(x.results ?? []);
      });

      // This is a clean-up method that will run next time value changes
      // This is done so as to not run this again, because it is only to set the initial knowledge
      return () => setalreadySetIntialValue(true);
    }
  }, [props.value])

  let removeKnowledge = (knowledges: Knowledge[]) => {
    selectedKnowledges = selectedKnowledges?.filter(x => !knowledges.some(knowledge => knowledge.id == x.id));
    setSelectedKnowledges(selectedKnowledges);
    let valueids = selectedKnowledges?.map(x => x.id);
    props.onChange?.call(undefined, valueids);
  }

  let addKnowledge = (knowledge: Knowledge) => {
    if (!selectedKnowledges?.some(x => x.id == knowledge?.id)) {
      let newSelectedKnowledges = [...selectedKnowledges, knowledge];
      setSelectedKnowledges(newSelectedKnowledges);
      let valueids = newSelectedKnowledges?.map(x => x.id);
      props.onChange?.call(undefined, valueids);
    }
  }

  return (<>
    <KnowledgeSimpleSelect filteredJurisdictionId={props.filteredJurisdictionId} knowledgeSelected={addKnowledge} />
    <Divider />
    <SelectableKnowledgeTable onRemoved={removeKnowledge} knowledges={selectedKnowledges} />

  </>);
}