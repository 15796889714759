import * as React from 'react';
import { Modal, Button, Tag } from 'antd';
import { DocumentModel, AccessLevel, IDocumentModel } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import { mime } from 'Core/models/Mime';
import ShowIfHaveAccessLevel from 'components/Forms/ShowIfHaveAccessLevel';
import DetailsMode from 'Core/models/DetailsMode';
//import ReactAudioPlayer from 'react-audio-player';
//import VideoPlayer from 'components/Forms/VideoPlayer';
import styled from 'styled-components';
import TextClip from 'components/Forms/TextClip';
import { CSSProperties } from 'react';
//import { WordDocumentViewer } from '../WordDocumentViewer';
import PdfViewer from 'components/Forms/PdfViewer';
import { SearchTextHighlight } from 'components/Forms/SearchTextHighlight';
//import SynfunsionPdfViewer from '../SynfunsionPdfViewer/SynfunsionPdfViewer';


export interface Props {
  children?: React.ReactNode;
  document?: IDocumentModel;
  accessLevel: AccessLevel;
  text?: string;
  isTag?: boolean;
  maxTextLength?: number;
  disabled?: boolean;
  textClipStyle?: CSSProperties;
}

export interface State {
  visible?: boolean;
  viewer?: any;
}

export default class DocumentPreview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { viewer: React.Fragment };
  }

  handleOk = (event: React.SyntheticEvent) => {
    event.persist();
    event.stopPropagation();
    Utility.downloadURI(this.props.document?.url, this.props.document?.name);
  };

  handleCancel = (event: React.SyntheticEvent) => {
    event.persist();
    event.stopPropagation();
    this.setState({ visible: false });
  };

  onPreview = (event: React.SyntheticEvent) => {
    event.persist();
    if (this.props.disabled) { return; }

    event.stopPropagation();
    this.setState({ visible: true });
  };

  async componentDidUpdate(prevProps: Props, prevState: State) {

    if (this.state.visible != prevState.visible && this.state.visible) {
      this.pickRenderer().then(viewer => {
        this.setState({ viewer: viewer });
      });

    }

  }

  renderFooter = () => {
    return (
      <footer>
        <Button type="default" onClick={this.handleCancel}>
          Cancel
        </Button>
        <ShowIfHaveAccessLevel mode={DetailsMode.Download} accessLevel={this.props.accessLevel}>
          <Button type="primary" onClick={this.handleOk}>
            Download
          </Button>
        </ShowIfHaveAccessLevel>
      </footer>
    );
  };

  pickRenderer = async () => {
    let mimeType = mime[this.props.document?.mimeType ?? ''];

    if (!mimeType) {
      mimeType = this.props.document?.extension?.replace('.', '');
    }

    switch (mimeType) {
      case 'png':
      case 'jpg':
        return <img src={this.props.document?.url} />;
      case 'mp4':
      case 'mov':
      case 'mkv':
        let VideoPlayer: any = (await import('components/Forms/VideoPlayer')).default;
        return <VideoPlayer src={this.props.document?.url} />;
      case 'mp3':
      case 'aac':
      case 'ogg':
      case 'm4a':
      case 'wav':
      case 'aiff':
      case 'ftr':
      case 'flac':
      case 'wma':
      case 'oga':
      case 'pcm':
        let ReactAudioPlayer: any = (await import('react-audio-player')).default;
        return <ReactAudioPlayer src={this.props.document?.url} autoPlay controls />;
      case 'pdf':
        let SynfunsionPdfViewer: any = (await import('../SynfunsionPdfViewer/SynfunsionPdfViewer')).default;
        return <SynfunsionPdfViewer fileId={this.props.document?.fileId} />;
      //return <PdfViewer documentUrl={this.props.document?.url} />
      case 'docx':
      case 'doc':
      case 'txt':
      case 'xslx':
      case 'csv':
      default:
        let WordDocumentViewer: any = (await import("../WordDocumentViewer")).WordDocumentViewer;
        return <WordDocumentViewer fileId={this.props.document?.fileId} />;

    }
  };

  render() {
    const Render = this.state.viewer;
    const Wrapper = this.props.isTag ? Tag : Button;
    return (
      <>
        <Wrapper type="link" onClick={this.onPreview} color="#108ee9" style={{ paddingRight: '5px', paddingLeft: '5px', ...this.props.textClipStyle }} >
          <SearchTextHighlight field="documents.Name" children={Utility.ClipTextLength(this.props.text ?? "view", this.props.maxTextLength ?? 70) ?? "View"} ></SearchTextHighlight>
        </Wrapper>
        <div onClick={x => x.stopPropagation()}>
          <Modal
            title={this.props.document?.name}
            visible={this.state.visible}
            okText="Download"

            style={{ top: 20, minHeight: "max-content" }}
            onCancel={this.handleCancel}
            footer={this.renderFooter()}
            width="70vw"
            bodyStyle={{ minHeight: '50vh', maxHeight: '70vh', overflowX: 'hidden', overflowY: 'scroll' }}>
            {this.state.visible && Render}
          </Modal>
        </div>
      </>
    );
  }
}
