import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
  GetAllCategoryByFilterQueryRequest,
  KnowledgeCategory,
  IAddCategoryCommandRequest,
  AddCategoryCommandRequest,
  IEditCategoryCommandRequest,
  EditCategoryCommandRequest,
  GetCategoryByIdQueryRequest,
  IRemoveCategoryCommandRequest,
  RemoveCategoryCommandRequest,
} from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class KnowledgeCategoryHandler implements ITableDataFetcher<KnowledgeCategory> {
  constructor(props?: any) {}

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<KnowledgeCategory>> {
    return this.getAllKnowledgeCategoriesByFilter(tableRequest);
  }

  getAllKnowledgeCategoriesByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllAuditHeaderByFilterModel = new GetAllCategoryByFilterQueryRequest(request);
    return client.getAllCategoryByFilter(getAllAuditHeaderByFilterModel);
  }

  GetKnowledgeCategoryDetailsById(id: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetCategoryByIdQueryRequest();
    request.id = id;
    var result = client.getCategoryById(request);
    return result;
  }

  AddKnowledgeCategory(values: IAddCategoryCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    var request = new AddCategoryCommandRequest(values);
    return client.addCategory(request);
  }

  EditKnowledgeCategory(values: IEditCategoryCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditCategoryCommandRequest(values);
    return client.editCategory(request);
  }
  RemoveCategory(values: IRemoveCategoryCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new RemoveCategoryCommandRequest(values);
    return client.removeCategory(request);
  }
}
