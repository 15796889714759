import { Col, Form, Row, Typography, Input, Button, Upload, Icon, Modal, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import AuthContext from 'Context/AuthContext';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AddUserCommandRequest,
  SlimUser,
  User,
  Knowledge,
  IEditKnowledgeCommandRequest,
  KnowledgeModel,
  GetKnowledgeByIdQueryResponse,
  DocumentModel,
  IDeleteCommentCommandRequest,
  IDeleteDocumentCommandRequest,
  IEditCategoryCommandRequest,
  KnowledgeCategory,
} from '../../../Core/Api/Api';
import {
  doubleColFormColSize,
  FullWithButton,
  singleColFormColSize,
  singleCol2FormColSize,
  CategoryColSize,
} from '../../Forms/Form.Styled';
import KnowledgeHandler from '../KnowledgeHandler';
import FormControl from 'components/Forms/FormControl';
import ValidationUtility from 'Core/ValidationUtility';
import TextArea from 'antd/lib/input/TextArea';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import KnowledgeCategoryHandler from '../KnowledgeCategoryHandler';
import { SubmitButton } from 'components/Forms/SubmitButton';

const { Title } = Typography;

type onRemoveFunc = (file: UploadFile) => void | boolean | Promise<void | boolean> | undefined;
type beforeUploadFunc = (file: RcFile, FileList: RcFile[]) => boolean | PromiseLike<void>;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  knowledgeCategory?: KnowledgeCategory;
  detailsMode: DetailsMode;
}

export class KnowledgeCategoryDetailsFormWithoutFormAndRouter extends React.Component<
  Props,
  State
  > {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: KnowledgeCategoryHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      detailsMode: DetailsMode.New,
    };
    this.handler = new KnowledgeCategoryHandler(props);
  }

  componentDidMount() {
    this.getKnowledgeCategoryDetails();
  }

  getKnowledgeCategoryDetails() {
    const urlState = this.props.location.state as { id: string; mode: DetailsMode } | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetKnowledgeCategoryDetailsById(urlState.id).then(
        x => {
          this.setState({ knowledgeCategory: x.category, detailsMode: DetailsMode.Edit });
          this.props.form.setFieldsValue(x.category ?? {});
        },
        x => NotificationService.error(x.message)
      );
    } else {
      this.setState({ detailsMode: DetailsMode.New });
    }
  }

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    var me = this;
    this.props.form.validateFieldsAndScroll((error, values: IEditCategoryCommandRequest) => {
      if (error) {
        return;
      }

      if (this.state.detailsMode == DetailsMode.Edit) {
        values.id = this.state.knowledgeCategory?.id;

        this.handler.EditKnowledgeCategory(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Updated ${values.name}`);
        });
      } else {
        console.log(values);

        this.handler.AddKnowledgeCategory(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Added ${values.name}`);
        });
      }
    });
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <LeftAlignedRow>
        <Col md={10}>
          <Form layout="horizontal" onSubmit={this.onSubmit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="name"
              label="Name"
              rules={[...ValidationUtility.required('Name is required')]}>
              <Input />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="description"
              label="Description"
              rules={[...ValidationUtility.required('Description is required')]}>
              <TextArea />
            </FormControl>
            <Row>
              <Col span={3}>
                <Button type="default" onClick={() => this.props.history.goBack()} htmlType="button">
                  Cancel
                </Button>
              </Col>
              <Col offset={1} span={3}>
                <SubmitButton type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </LeftAlignedRow>
    );
  }
}

const KnowledgeCategoryDetailsFormWithRouter = Form.create<Props>({
  name: 'KnowledgeCategoryDetails',
})(KnowledgeCategoryDetailsFormWithoutFormAndRouter);

const KnowledgeCategoryDetailsForm = withRouter(KnowledgeCategoryDetailsFormWithRouter);

export default KnowledgeCategoryDetailsForm;
