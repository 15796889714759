import * as React from 'react';
import { Row, Col, Button, Menu, Dropdown, Icon } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { RegistrationListFilterBar, RegistrationListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import RegistrationHandler from '../RegistrationHandler';
import { UserStatus, Operations } from 'Core/Api/Api';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  isRegional?: boolean;
}

export interface State { }

export class RegistrationListViewWithoutRouter extends React.Component<Props, State> {

    handler: RegistrationHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
      this.handler = new RegistrationHandler(props);
  }

  AddNewUser = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('users/new', newDetailsData);
  };

  GotoUserReport = (status: UserStatus) => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.View;
    newDetailsData.id = status;
    this.props.history.push('/admin/users/v/reports', newDetailsData);
  }

  renderUserReportButtons = () => {
    return (
      <Menu>
        <Menu.Item onClick={x => this.GotoUserReport(UserStatus.Active)} key="1">Enabled Users</Menu.Item>
        <Menu.Item onClick={x => this.GotoUserReport(UserStatus.Disabled)} key="2">Disabled Users</Menu.Item>
      </Menu>
    )
  }

  render() {
    return (
      <TableFilterContextProvider>
        <Row>
          <Col md={24}>
            <RegistrationListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <RegistrationListTable isRegional={this.props.isRegional} />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const RegistrationListView = withRouter(RegistrationListViewWithoutRouter);

export default RegistrationListView;
