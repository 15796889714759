import * as React from 'react';
import { Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import MenuAvatar from '../MenuAvatar';
import styled from 'styled-components';
import AuthContext from '../../../Context/AuthContext';
import { JurisdictionFlag } from 'components/Shared/JurisdictionFlag';
import { GrGroup } from 'react-icons/gr';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class UserBar extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Menu style={{ borderBottom: 'none' }} overflowedIndicator={<Icon type="user" />} mode="horizontal" theme="light">
        <Menu.SubMenu
          title={
            <span>
              <MenuAvatar shape="circle" icon={<JurisdictionFlag size="small" jurisdictionId={this.context.data?.jurisdictionId} />} />
              &nbsp;
              {this.context.data ? this.context.data.username : ''}
            </span>
          }>
          <Menu.Item>
            <NavLink to="/user-profile">
              <Icon type="user" />
              User Profile
            </NavLink>
          </Menu.Item>
          <Menu.Item>
            <NavLink to="/user-directory">
              <GrGroup style={{ marginRight: "10px" }} />
              User Directory
            </NavLink>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item>
            <NavLink to="/logout">
              <Icon type="power" />
              Logout
            </NavLink>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );
  }
}



