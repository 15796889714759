import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
  User,
  SlimUser,
  AuditHeader,
  Knowledge,
  KnowledgeDataTemplate,
  Operations,
} from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import KnowledgeTemplateHandler from 'components/Knowledge/KnowledgeTemplateHandler';
import { Button, Icon } from 'antd';
import { ScrollableTable } from 'components/Forms/DataGrid/SimpleTable/SimpleTable.Styled';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
}

export default class KnowledgeTemplateListTable extends React.Component<Props, State> {
  handler: KnowledgeTemplateHandler;
  config = ConfigService.getSync();

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
    };
    this.handler = new KnowledgeTemplateHandler(props);
  }

  componentDidMount() { }

  knowledgeTemplateListTableColumns: Array<ColumnProps<KnowledgeDataTemplate>> = [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
    },
    {
      title: 'Category',
      dataIndex: 'knowledgeCategory.name',
      sorter: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: '',
      width: "250px",
      render: (text: any, record: KnowledgeDataTemplate, index: number) => {
        return (
          <ShowIfHavePermission operations={[Operations.Edit_Knowledge_Data_Template]}>
            <NavLink
              to={{
                pathname: `/templates/${encodeURIComponent(record.name ?? "")}`,
                state: new DetailsData(DetailsMode.Edit, record.id),
              }}>
              <Button><Icon type="edit" /></Button>
            </NavLink>
          </ShowIfHavePermission>
        );
      },
    },
  ];

  render() {
    return (
      <ScrollableTable>
        <SimpleTable<KnowledgeDataTemplate>
          tableKey="id"
          dataFetcher={this.handler}
          columns={this.knowledgeTemplateListTableColumns}
        />
      </ScrollableTable>
    );
  }
}
