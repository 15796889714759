import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button } from 'antd';
import ScreenHelper from 'components/Styled/ScreenHelper';

const { Footer } = Layout;

export const SFooter = styled(Footer)`
  min-height: 190px;
  bottom: 0;
  left: 0;
  padding-left: 150px;
  width: 100%;
  color: white !important;
  ${ScreenHelper.down('sm')} {
    min-height: 100px;
    padding-left: 30px;
    margin: auto auto;
    h4 {
      font-size:1.52em;
      margin-top:10px;
      margin-bottom: 10px;
    }
  }
`;
