import React, { SyntheticEvent } from 'react';
import { Form, Row, Input, Col, Button, Select, Typography, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import UserHandler from '../../Admin/UserHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
  UserStatus,
  SlimUser,
  User,
  EditUserCommandRequest,
  AddUserCommandRequest,
  IRole,
  IRoleListView,
  Operations,
  GetUserByIdModel,
  IGetMyUserProfileQueryResponse,
  IEditUserProfileCommandRequest,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import {
  singleColFormColSize,
  FullWithButton, doubleColFormColSize,
} from '../../Forms/Form.Styled';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import ProfileHandler from '../ProfileHandler';
import { SubmitButton } from 'components/Forms/SubmitButton';
import { TitlesTextBox } from 'components/Forms/TitlesTextBox';

const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
  profile?: IGetMyUserProfileQueryResponse;
  profileUpdated?: (IGetMyUserProfileQueryResponse) => void;
  canceledUpdate?: () => void;
}

export interface State {
}

export class UserProfileFormWithoutFormAndRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: ProfileHandler;


  constructor(props: Props) {
    super(props);

    this.state = {
    };
    this.handler = new ProfileHandler(props);
  }

  componentDidMount() {
    this.getStatus();
    this.getUserDetails();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {

  }

  getUserDetails() {
    this.props.form.setFieldsValue(this.props.profile ?? {});
  }

  getStatus() {
    var status: ISelectOption[] = [];
    Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
    this.setState({ status });
  }


  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, values: IGetMyUserProfileQueryResponse) => {
      if (error) {
        return;
      }

      const request: IEditUserProfileCommandRequest = {};
      request.userId = this.props.profile?.id;
      request.firstName = values.firstName;
      request.lastName = values.lastName;
      request.title = values.title;

      request.department = values.department;
      request.otherPhoneNumber = values.otherPhoneNumber;
      request.otherPhoneNumberType = values.otherPhoneNumberType;
      request.organization = values.organization;
      request.phoneNumber = values.phoneNumber;

      this.handler.EditProfile(request).then(x => {
        this.props.profileUpdated?.call(this, x);
        NotificationService.success(`Updated ${x.userName} profile`);
      });

    });
  };

  onCancelClick = (event: SyntheticEvent) => {
    if (this.props.form.isFieldsTouched()) {
      Modal.confirm({
        title: 'Cancel Update',
        content: `You have made changes, are you sure you wish to cancel them?`,
        okText: 'Yes',
        cancelText: 'Cancel',
        onOk: () => this.props.canceledUpdate?.call(this),
      });
    } else {
      this.props.canceledUpdate?.call(this);
    }
  }

  setRoles(request: { roles?: string[] }, arg1: any) {
    if (arg1.map) {
      request.roles = arg1;
    } else {
      request.roles = [];
      request.roles.push(arg1);
    }
  }

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col>
          <Form layout="horizontal" onSubmit={this.onSubmit}>
            <Row>
              <Col md={24} xl={24} >
                <Form.Item label="Email \ Username">
                  {this.props.profile?.userName}
                </Form.Item>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('First name is required')]}
                  name="firstName"
                  label="First Name">
                  <Input />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Last name is required')]}
                  name="lastName"
                  label="Last Name">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="title"
                  label="Title">
                  <TitlesTextBox />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="department"
                  label="Department">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('contact number is required')]}
                  name="phoneNumber"
                  label="Contact Number">
                  <MaskedInput mask="(999) 999-9999" />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="otherPhoneNumber"
                  label="Other Phone Number">
                  <MaskedInput mask="(999) 999-9999" />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="otherPhoneNumberType"
                  label="Other Phone Number Type">

                  <Select>
                    <option key="Home" value="Home">Home</option>
                    <option key="Mobile" value="Mobile">Mobile</option>
                    <option key="Alternate Office Number" value="Alternate Office Number">Alternate Office Number</option>
                  </Select>
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="organization"
                  label="Organization">
                  <Input />
                </FormControl>

              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <Col lg={5} md={24}>
                    <Button type="default" onClick={this.onCancelClick}>
                      Cancel
                    </Button>
                  </Col>
                  <Col lg={5} md={24} >
                    <SubmitButton type="primary" htmlType="submit">
                      Submit
                    </SubmitButton>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const UserProfileFormWithRouter = Form.create<Props>({
  name: 'UserProfileForm',
})(UserProfileFormWithoutFormAndRouter);

const UserProfileForm = withRouter(UserProfileFormWithRouter);

export default UserProfileForm;
