import * as React from 'react';
import KnowledgeListView from 'components/Knowledge/KnowledgeListView';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class KnowledgeListViewPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Knowledge">
        <KnowledgeListView />
      </PageContainer>
    );
  }
}
