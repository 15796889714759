import * as React from 'react';
import { Layout, Row, Col, Icon } from 'antd';
import { CenteredContent, MainLogo, LoginWithBackground, LoginContent, ContentCol } from './LoginLayout.Style';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import { NavLink } from 'react-router-dom';
import { LayoutFooter } from '../LayoutFooter';
const { Header, Content, Footer } = Layout;

export interface Props {
  children?: React.ReactNode;
}

export interface State { }


export default class LoginLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <LoginWithBackground
        className="react-transition fade-in"
        style={{ animationDuration: '750ms' }}>
        <CenteredContent>
          <Row type="flex" align="middle" justify="center">
            <ContentCol xl={9} sm={14} xs={20} style={{ backgroundColor: 'white' }}>
              <LoginContent>
                <MainLogo />
                {this.props.children}
              </LoginContent>
            </ContentCol>
          </Row>
        </CenteredContent>
        <LayoutFooter />
      </LoginWithBackground>
    );
  }
}
