import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import { IChangePasswordCommandRequest, IResetPasswordCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import ProfileHandler from '../ProfileHandler';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
  passwordChanged?: () => void;
  cancelled?: () => void;
}

export interface State {
  token?: string;
  emailAddress?: string;
}

export class ChangePasswordFormWithoutFormsAndRouter extends React.Component<Props, State> {
  handler: ProfileHandler;
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;


  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new ProfileHandler(props);
  }

  componentDidMount() {
    // const query = new URLSearchParams(this.props.location.search);
    // const emailAddress = query.get('emailAddress');
    // const token = query.get('token');

    // if (!emailAddress || !token) {
    //   this.props.history.push('user/reset-password');
    //   const errorMessage =
    //     'Please try reseting your password again or if this issue persist contact us at ' +
    //     ConfigService.getSync().SiteInfo.ContactUsEmail;
    //   NotificationService.error('Invalid Url', errorMessage, 0, false);
    // } else {
    //   this.setState({ token: token, emailAddress: emailAddress });
    // }
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((error, values: IChangePasswordCommandRequest) => {
      values.emailAddress = this.context.data?.email;
      this.handler.ChangePassword(values).then(() => {
        NotificationService.success('Password changed!');
        this.props.form.resetFields();
        this.props.passwordChanged?.call(this);
      });
    });
  };

  cancelClicked = () => {
    this.props.cancelled?.call(this);
  }

  render() {
    let { getFieldDecorator } = this.props.form;
    let passwordValidations = ValidationUtility.password;
    let requiredValidations = ValidationUtility.required;
    let compareValidations = ValidationUtility.compare;
    return (
      <LeftAlignedRow>
        <Col>
          <Form onSubmit={this.onSubmit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label="Current Password"
              name="oldPassword"
              rules={[...requiredValidations()]}>
              <Input type="password" />
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label="Password"
              name="newPassword"
              rules={[...passwordValidations()]}>
              <Input type="password" />
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...compareValidations(this.props.form, 'newPassword', 'Passwords do not match')]}
              label="Confirm Password"
              name="confirmationPassword">
              <Input type="password" />
            </FormControl>
            <FormItem>
              <Row>
                <Col>
                  <Row>
                    <Col lg={5} md={24}>
                      <Button type="default" onClick={this.cancelClicked} >Cancel</Button>
                    </Col>
                    <Col lg={5} md={24} >
                      <SubmitButton type="primary" htmlType="submit">Change Password</SubmitButton>
                    </Col>
                  </Row>
                </Col>
              </Row>

              {/* <CenterAlignedRow>
                <Col>
                  <NavLink to="/login">Go to Login</NavLink>
                </Col>
              </CenterAlignedRow> */}
            </FormItem>
          </Form>
        </Col>
      </LeftAlignedRow>
    );
  }
}

const ChangePasswordFormWithoutRouter = Form.create<Props>({
  name: 'ConfirmResetPassword',
})(ChangePasswordFormWithoutFormsAndRouter);

const ChangePasswordForm = withRouter(ChangePasswordFormWithoutRouter);

export default ChangePasswordForm;
