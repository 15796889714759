import { Row } from 'antd';
import { UserLoginReportView } from 'components/Admin/AuditListView/UserLoginReportView';
import PageContainer from 'components/Layout/PageContainer';
import React from 'react';

interface Props {
}

export default ({ }: Props) => {
  return (<PageContainer title="User Login Report">
    <UserLoginReportView />
  </PageContainer>);
}