import { Modal, Button } from 'antd';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import AuthContext from 'Context/AuthContext';
import { KnowledgeModel, IChangeKnowledgeStatusCommandRequest, KnowledgeStatus, IKnowledgeModel, Operations } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import Utility from 'Core/Utility';
import React, { useContext } from 'react';

interface Props {
  knowledge?: IKnowledgeModel;
  newStatus?: KnowledgeStatus;
  knowledgeStatusUpdatedCallback?: (newStatus: KnowledgeStatus) => void;
}

export default ({ knowledge, knowledgeStatusUpdatedCallback, newStatus }: Props) => {

  const handler: KnowledgeHandler = new KnowledgeHandler({});;
  const { Update, data: user } = useContext(AuthContext);


  const renderPublishMessage = () => {
    if (newStatus && newStatus == KnowledgeStatus.Published) {
      return (<p>
        This will publish the knowledge and make it viewable by persons with the right access level (Public will be viewable by everyone).
      </p>);
    } else {
      return (<p>
        This will unpublish the knowledge and only person with permission will be able to see it.
      </p>);
    }
  }

  const onClickPublish = (event: React.SyntheticEvent) => {
    event.preventDefault();

    Modal.confirm({
      title: `${newStatus} "${knowledge?.title}"`,
      content: renderPublishMessage(),
      okText: `${newStatus == KnowledgeStatus.Draft ? "Unpublish" : "Publish"}`,
      cancelText: 'Cancel',
      onOk: () => publishKnowledge(),
    });

  }

  const publishKnowledge = () => {
    let request: IChangeKnowledgeStatusCommandRequest = {
      id: knowledge?.id,
      newStatus: newStatus,
    }

    handler.ChangeKnowledgeStatus(request).then(x => {
      knowledgeStatusUpdatedCallback?.call(null, newStatus!);
      NotificationService.success(`"${knowledge?.title}" ${newStatus == KnowledgeStatus.Draft ? "Unpublished" : "Published"}`, null, 10000, true);
    });
  };

  const requiredOperation = newStatus == KnowledgeStatus.Published ? Operations.Publish_Knowledge : Operations.Unpublish_Knowledge;

  if (Utility.HasOperations(user, [requiredOperation])) {
    return <Button type="primary" onClick={onClickPublish}>{newStatus == KnowledgeStatus.Draft ? "Unpublish" : "Publish"}</Button>;
  } else {
    return <></>;
  }
}