import React from 'react';
import { Form, Row, Input, Col, Button, Select, Typography, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import UserHandler from '../UserHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
    UserStatus,
    SlimUser,
    User,
    EditUserCommandRequest,
    AddUserCommandRequest,
    IRole,
    IRoleListView,
    Operations,
    GetUserByIdModel,
    GetUserRegistrationByIdModel,
    ApproveUserRegistrationCommandRequest,
    RegistrationStatus,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
    singleCol2FormColSize2,
    singleCol2FormColSizeForUserForm,
} from '../../Forms/Form.Styled';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import RegistrationHandler from '../RegistrationHandler';
import TextArea from 'antd/lib/input/TextArea';
import { ApprovalCard } from './ApprovalCard';
import { SubmitButton } from 'components/Forms/SubmitButton';

const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    jurisdictions: ISelectOption[];
    roles: ISelectOption[];
    AllRoles: IRoleListView[];
    status: ISelectOption[];
    user?: GetUserRegistrationByIdModel;
    detailsMode: DetailsMode;
    canApprove?: boolean;
}

export class RegistrationDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    handler: RegistrationHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            jurisdictions: [],
            roles: [],
            AllRoles: [],
            status: [],
            detailsMode: DetailsMode.New,
        };
        this.handler = new RegistrationHandler(props);
    }

    componentDidMount() {
        this.getJurisdictions();
        this.getRoles();
        this.getStatus();
        this.getUserDetails();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.state.user !== prevState.user) {
            let jurisdictionId = this.state.user?.jurisdictionId ?? this.context.data?.jurisdictionId;
            this.updateRoleList(jurisdictionId);
            this.props.form.setFieldsValue({
                roleIds: this.state.user?.roleIds ?? [],
            });
        }
    }

    getUserDetails() {
        const urlState = this.props.location.state as { userId: string; mode: DetailsMode } | undefined;
        const query = this.props.match.params;

        if ((urlState && urlState.mode == DetailsMode.View) || !!query["uid"]) {
            let userId = urlState?.userId ?? query["uid"]!;
            this.handler.GetUserRegistrationById(userId).then(
                x => {
                    this.setState({ user: x.user, detailsMode: DetailsMode.View, canApprove: this.canUserApprove(x.user!) });
                    this.props.form.setFieldsValue(x.user ?? {});
                },
                x => NotificationService.error(x.message)
            );
        } else {
            this.setState({ detailsMode: DetailsMode.New });
        }
    }

    canUserApprove(user: GetUserRegistrationByIdModel) {

        let isApprovable = user.status == RegistrationStatus.Pending;
        let hasThisUserAlreadyApproved = user.approvals?.some(x => x.approverId == this.context.data?.id);
        let isInJurisdiction = user.jurisdictionId == this.context.data?.jurisdictionId;
        return isApprovable && !hasThisUserAlreadyApproved && isInJurisdiction;
    }

    getStatus() {
        var status: ISelectOption[] = [];
        Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
        this.setState({ status });
    }

    getJurisdictions() {
        this.handler.GetTop100Jurisdictions().then(x => {
            const jurisdictions: ISelectOption[] =
                x.results?.map(y => ({
                    text: y.name,
                    value: y.id,
                })) ?? [];
            this.setState({ jurisdictions });
        });
    }

    getRoles() {
        this.handler.GetFilteredRoles().then(x => {
            this.setState({ AllRoles: x.results ?? [] }, () => {
                let jurisdictionId = this.state.user?.jurisdictionId ?? this.context.data?.jurisdictionId;
                this.updateRoleList(jurisdictionId);
                this.props.form.setFieldsValue({
                    roles: this.state.user?.roleIds ?? [],
                });
            });
        });
    }

    updateRoleList = (jurisdictionId?: string) => {
        let filteredRoles: IRoleListView[];
        if (jurisdictionId) {
            filteredRoles = this.state.AllRoles.filter(x => x.jurisdictionId == jurisdictionId) ?? [];
        } else {
            filteredRoles = this.state.AllRoles ?? [];
        }

        const roles: IFilterSelectOption[] =
            filteredRoles?.map(y => ({
                text: y.name,
                value: y.id,
            })) ?? [];
        this.setState({ roles });
        this.props.form.setFieldsValue({ roles: null })
    };

    approve = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: ApproveUserRegistrationCommandRequest) => {
            if (error && error.roles) {
                return;
            }

            const request = new ApproveUserRegistrationCommandRequest();
            request.id = this.state.user?.id;
            request.comment = values.comment;
            request.roles = (values.roles?.filter ? values.roles : [(values.roles as any as string)]);
            var user = this.state.user;
            this.handler.ApproveRegistration(request).then(x => {
                this.props.history.goBack();
                NotificationService.success(`Approved ${user?.emailAddress}`, null, 10, true);
            });

        });
    };

    reject = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: ApproveUserRegistrationCommandRequest) => {
            if (error && error.comment) {
                return;
            }

            const request = new ApproveUserRegistrationCommandRequest();
            request.id = this.state.user?.id;
            request.comment = values.comment;

            this.handler.RejectRegistration(request).then(x => {
                this.props.history.goBack();
                NotificationService.success(`Rejected ${this.state.user?.emailAddress}`, null, 10, true);
            });

        });
    };

    CanCreateNonRegionUser(): boolean {
        return Utility.HasOperations(this.context.data, [Operations.Edit_User]);
    }

    setRoles(request: { roles?: string[] }, arg1: any) {
        if (arg1.map) {
            request.roles = arg1;
        } else {
            request.roles = [];
            request.roles.push(arg1);
        }
    }

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col>
                    <Form layout="horizontal" >
                        <Row gutter={16}>
                            <Col xl={10} offset={1} sm={16}>


                                <Row>
                                    <Col md={24} >

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="emailAddress"
                                            label="Email / Username">
                                            <Input disabled={true} />
                                        </FormControl>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="firstName"
                                            label="First Name">
                                            <Input disabled={true} />
                                        </FormControl>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="lastName"
                                            label="Last Name">
                                            <Input disabled={true} />
                                        </FormControl>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="title"
                                            label="Title">
                                            <Input disabled={true} />
                                        </FormControl>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="organization"
                                            label="Organization">
                                            <Input disabled={true} />
                                        </FormControl>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="department"
                                            label="Department">
                                            <Input disabled={true} />
                                        </FormControl>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="phoneNumber"
                                            label="Contact Number">
                                            <MaskedInput mask="(999) 999-9999" disabled={true} />
                                        </FormControl>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="notes"
                                            label="notes">
                                            <TextArea rows={3} disabled={true} />
                                        </FormControl>

                                        <ShowIfTrue condition={!this.context.data?.jurisdictionId}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                name="jurisdictionId"
                                                label="Jurisdiction">
                                                <SimpleSelect disabled={true}
                                                    style={{ width: '100%' }}
                                                    onChange={this.updateRoleList}
                                                    options={this.state.jurisdictions}></SimpleSelect>
                                            </FormControl>
                                        </ShowIfTrue>


                                        <ShowIfTrue condition={this.state.detailsMode != DetailsMode.New}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                name="status"
                                                label="Status">
                                                <Input disabled={true} />
                                            </FormControl>
                                        </ShowIfTrue>

                                    </Col>
                                </Row>

                            </Col>
                            <Col xl={{ span: 11, offset: 1 }} sm={17}>
                                <Typography.Title level={3}>Approvals</Typography.Title>
                                {this.state.user?.approvals?.map(approval => {
                                    return (<Row>
                                        <ApprovalCard approval={approval} roles={this.state.AllRoles} />
                                    </Row>)
                                })}
                                <ShowIfTrue condition={this.state.canApprove}>
                                    <Row style={{ border: "1px solid lightgray", padding: '30px', marginTop: '10px' }}>
                                        <Typography.Text>
                                            <ul>
                                                <li>Comments are required</li>
                                                <li>For Approvals, select the role for which the user will be associated with.</li>
                                                <li>For Rejection, comments will be sent the user explaining why the request was rejected.</li>
                                            </ul>
                                        </Typography.Text>
                                        <Col span={24}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('role is required')]}
                                                name="roles"
                                                label="Role">
                                                <SimpleSelect options={this.state.roles}></SimpleSelect>
                                            </FormControl>

                                        </Col>
                                        <Col span={24}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('comment is required')]}
                                                name="comment"
                                                label="Comments">
                                                <TextArea rows={4} />
                                            </FormControl>

                                        </Col>

                                        <Col span={24}>
                                            <SubmitButton type="primary" onClick={this.approve}>
                                                Approve
                                            </SubmitButton>
                                            <SubmitButton type="danger" onClick={this.reject} style={{ marginLeft: '20px' }}>
                                                Reject
                                            </SubmitButton>
                                        </Col>
                                    </Row>
                                </ShowIfTrue>
                            </Col>
                        </Row>
                    </Form>


                </Col>
            </Row>
        );
    }
}

const RegistrationDetailsFormWithRouter = Form.create<Props>({
    name: 'RegistrationDetailsForm',
})(RegistrationDetailsFormWithoutFormAndRouter);

const RegistrationDetailsForm = withRouter(RegistrationDetailsFormWithRouter);

export default RegistrationDetailsForm;
