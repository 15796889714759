import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, AuditHeader, Knowledge, AccessLevel } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import ShowIfhaveAccessLevel from '../../../Forms/ShowIfHaveAccessLevel';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import PublicKnowledgeHandler from 'components/Knowledge/PublicKnowledgeHandler';
import { DocumentPreview } from 'components/Knowledge/KnowledgeDetailsView/DocumentListView';
import { Card, Row, Col, Typography } from 'antd';
import AttachmentPill from '../../../Styled/AttachmentPill';
import { KnowledgeListItem } from 'components/Knowledge/KnowledgeListView/KnowledgeListTable';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
    knowledge: Knowledge[];
    knowledgeCount?: number;
}

export default class PublicKnowledgeListTable extends React.Component<Props, State> {
    handler: PublicKnowledgeHandler;
    config = ConfigService.getSync();

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
            knowledge: []
        };
        this.handler = new PublicKnowledgeHandler(props);
    }

    componentDidMount() {
        this.getKnowledge();
    }

    getKnowledge() {
        this.handler.getAllKnowledgeByFilter().then(x => {
            this.setState({ knowledge: x.results ?? [], knowledgeCount: x.count }
            );
        });

        //      <KnowledgeListItem {...this.props} knowledge={this.state.knowledge} count={this.state.knowledgeCount} />
        //
        //<SimpleTable<Knowledge>
        //    tableKey="id"
        //    dataFetcher={this.handler}
        //    columns={this.publicKnowledgeListTableColumns}
        //    pagination={{ position: 'both', showTotal: (total) => <p> {total} Result(s)</p> }}
        ///>
    }
    tempColumns: Array<ColumnProps<Knowledge>> = [
        {
            title: '',
            dataIndex: 'title',
            render: (text: any, item: Knowledge, index: number) => {
                return (<KnowledgeListItem knowledge={item} isOnPublic={true} />);
            },
        },

    ];

    publicKnowledgeListTableColumns: Array<ColumnProps<Knowledge>> = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text: any, record: Knowledge, index: number) => {
                return (
                    <NavLink
                        title={record.description}
                        to={{
                            pathname: `public-knowledge/v/${encodeURIComponent(record.title ?? '')}`,
                            state: new DetailsData(DetailsMode.View, record.id, record.title),
                        }}>
                        {record.title}
                    </NavLink>
                );
            },
        },
        {
            title: 'Documents',
            render: (text: any, record: Knowledge, index: number) => {
                return (<ul style={{ listStyle: 'none', paddingLeft: "0px" }}>
                    {record.documents?.map(x =>
                        <li>
                            <DocumentPreview accessLevel={AccessLevel.Public} text={x.name} document={{ ...x, ...x.dataFile, url: `api/file/${x.dataFile?.fileId}` }} />

                        </li>
                    )}
                </ul>);

            },
        },
        {
            title: 'Category',
            dataIndex: 'categoryId',
            render: (text: any, record: Knowledge, index: number) => {
                return record.category?.name;
            },
        },
        {
            title: 'Created',
            dataIndex: 'creationDate',
            render: (text: any, record: Knowledge, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {!text ? '' : moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: 'Author',
            dataIndex: 'createdBy',
        },
    ];

    render() {
        return (
            <>
                <SimpleTable<Knowledge>
                    tableKey="id"
                    dataFetcher={this.handler}
                    columns={this.tempColumns}
                    pagination={{ position: 'both', showTotal: (total) => <p> {total} Result(s)</p> }}
                />
            </>
        );
    }
}
