import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form, Icon, Button, Tooltip } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import { AccessLevel, FilterType, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import ShowIfTrue from './../../../Forms/ShowIfTrue';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { TableFilterContext } from 'components/Forms/DataGrid/TableFilterContextProvider';
import UserHandler from 'components/Admin/UserHandler';
import AuthUser from 'Core/models/AuthUser';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import Utility from 'Core/Utility';
import { orderBy } from 'lodash';

export interface Props {
  children?: React.ReactNode;
  generateReport?: (tableRequest?: TableRequest) => void;
  user?: AuthUser;
}
const documentTypes = [
  { text: 'Audio', value: 'aac,ogg,m4a,wav,aiff,ftr,flac,wma,oga,pcm' },
  { text: 'Documents', value: 'pdf,docx,xslx,csv' },
  { text: 'Video', value: 'mp4,mov,mkv,mp3' },
];

export interface State {
  categories: IFilterSelectOption[];
  jurisdiction: IFilterSelectOption[];
  accessLevels: IFilterSelectOption[];
  groupBy: IFilterSelectOption[];
  advance?: boolean;
  canGenerateReport?: boolean;
}
export default class KnowledgeFileReportFilterBar extends React.Component<Props, State> {
  handler: KnowledgeHandler;
  userhandler: UserHandler;

  static contextType = TableFilterContext;
  context!: React.ContextType<typeof TableFilterContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
      jurisdiction: [],
      accessLevels: [],
      groupBy: [],
    };
    this.handler = new KnowledgeHandler(props);
    this.userhandler = new UserHandler(props);
  }

  componentDidMount() {
    this.getCategories();
    this.getJurisdiction();
    this.getAccessLevel();
    this.getGroupBy();
  }

  SendTableRequest = () => {
    this.props.generateReport?.call(this, this.context.data);
  };

  getJurisdiction() {
    this.userhandler.GetTop100Jurisdictions().then(x => {
      const jurisdiction: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdiction });
    });
  }

  getGroupBy() {
    const options = ['Category', 'Jurisdiction'];
    const groupBy: IFilterSelectOption[] =
      options.map(y => ({
        text: y,
        value: y,
      })) ?? [];
    this.setState({ groupBy });
  }

  getAccessLevel() {
    var accessLevels: ISelectOption[] = [];
    Utility.enumToMap(AccessLevel).forEach((x, y) => {
      if (x == 'NoAccess') return;
      let text = (x as string).replace('Level', 'Level ');
      accessLevels.push({ text: text, value: y });
    });
    this.setState({ accessLevels });
  }

  getCategories() {
    this.handler.GetTop100KnowledgeCategories().then(x => {
      const categories: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories: orderBy(categories, 'text', 'asc') });
    });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Col sm={24}>
            <Form.Item
              required={true}
              help="You must select a grouping"
              labelCol={{ span: 2 }}
              label="Group By"
              labelAlign="left">
              <FilterSelectbox
                filterFields="groupBy"
                filterType={FilterType.Equal}
                filterJoin={JoinType.And}
                allowClear={true}
                onSelect={x => this.setState({ canGenerateReport: !x })}
                ignore={false}
                options={this.state.groupBy}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row></Row>
        <Row></Row>
        <Row>
          <Col sm={12} md={8} lg={8}>
            <Form.Item label="Filters" labelAlign="left">
              <FilterSelectbox
                filterFields="categoryId"
                filterType={FilterType.Equal}
                showSearch={true}
                allowClear={true}
                filterJoin={JoinType.And}
                placeholder={'Select Categories'}
                options={this.state.categories}
              />
            </Form.Item>
          </Col>
          <Col sm={12} md={8} lg={8}>
            <Form.Item labelAlign="left">
              <FilterSelectbox
                filterFields="accessLevel"
                filterType={FilterType.Equal}
                filterJoin={JoinType.And}
                allowClear={true}
                placeholder={'Access Level'}
                options={this.state.accessLevels}
              />
            </Form.Item>
          </Col>
          <ShowIfTrue condition={this.props.user && !this.props.user.jurisdictionId}>
            <Col sm={12} md={8} lg={8}>
              <Form.Item labelAlign="left">
                <FilterSelectbox
                  filterFields="jurisdictionId"
                  filterType={FilterType.Equal}
                  filterJoin={JoinType.And}
                  allowClear={true}
                  placeholder={'Jurisdiction'}
                  options={this.state.jurisdiction}
                />
              </Form.Item>
            </Col>
          </ShowIfTrue>
        </Row>
        <Row></Row>
        <Row>
          <Col lg={24}>
            <Button
              disabled={this.state.canGenerateReport}
              style={{ marginTop: '10px' }}
              type="primary"
              onClick={this.SendTableRequest}>
              Generate Report
            </Button>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
