import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
  User,
  SlimUser,
  AuditHeader,
  Knowledge,
  KnowledgeCategory,
  Operations,
} from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import KnowledgeCategoryHandler from 'components/Knowledge/KnowledgeCategoryHandler';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Button, Col, Icon, Row } from 'antd';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
}

export default class KnowledgeCategoriesListTable extends React.Component<Props, State> {
  handler: KnowledgeCategoryHandler;
  config = ConfigService.getSync();
  tableRef = React.createRef<SimpleTable<any>>();

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
    };
    this.handler = new KnowledgeCategoryHandler(props);
  }

  componentDidMount() {}
  removeCategory = (category: KnowledgeCategory): Promise<any> => {
    return this.handler.RemoveCategory({ id: category.id }).then(x => {
      NotificationService.success(`Removed "${category.name}" category`);
      this.tableRef.current?.refresh();
    });
  };
  knowledgeCategoriesListTableColumns: Array<ColumnProps<KnowledgeCategory>> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: '',
      render: (text: any, record: KnowledgeCategory, index: number) => {
        return (
          <Row gutter={10} type="flex" justify="space-between">
            <Col>
              <ShowIfHavePermission operations={[Operations.Edit_Knowledge_Categories]}>
                <NavLink
                  to={{
                    pathname: `/categories/${record.name}`,
                    state: new DetailsData(DetailsMode.Edit, record.id),
                  }}>
                  <Button>
                    <Icon type="edit" />
                  </Button>
                </NavLink>
              </ShowIfHavePermission>
            </Col>
            <Col>
              <ShowIfHavePermission operations={[Operations.Edit_Knowledge_Categories]}>
                <ConfirmRemoveButton
                  warningMessage={
                    <>
                      <p>Remove Category?</p>
                    </>
                  }
                  onConfirm={() => this.removeCategory(record)}>
                  <Icon type="delete" />
                </ConfirmRemoveButton>
              </ShowIfHavePermission>
            </Col>
          </Row>
        );
      },
    },
  ];

  render() {
    return (
      <SimpleTable<KnowledgeCategory>
        ref={this.tableRef}
        tableKey="id"
        dataFetcher={this.handler}
        columns={this.knowledgeCategoriesListTableColumns}
      />
    );
  }
}
