import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button } from 'antd';
import { ColProps } from 'antd/lib/col';

type Cols = { [name: string]: ColProps };

export const singleColFormColSize: Cols = {
  labelCol: {
    xs: { span: 10 },
  },
  wrapperCol: {
    xs: { span: 12 },
  },
};

export const CategoryColSize: Cols = {
  labelCol: {
    xs: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 12 },
  },
};

export const doubleColFormColSize: Cols = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 14 },
    md: { span: 17 },
  },
};

export const singleCol2FormColSize: Cols = {
  labelCol: {
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 22 },
  },
};

export const singleCol2FormColSize2: Cols = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 2 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 22 },
  },
};

export const singleCol2FormColSizeForUserForm: Cols = {
  labelCol: {
    xs: { span: 24 },
    md: { span: 3 },
  },
  wrapperCol: {
    xs: { span: 24 },
    md: { span: 13 },
  },
};

export const FullWithButton = styled(Button)`
  width: 100%;
`;
