import * as React from 'react';
import { Row, Col, Divider, PageHeader } from 'antd';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import { PaddedCol } from './PageContainer.Styled';
import TitleHeader from './TitleHeader';
import DetailsData from 'Core/models/DetailsData';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  title?: React.ReactNode;
  hideDivider?: boolean;
  hideBackButton?: boolean;
}

export interface State {
  title?: string;
}

export class PageContainerWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    const urlState = this.props.location.state as DetailsData | undefined;

    if (urlState) {
      this.setState({ title: urlState.pageTitle });
    }
  }

  render() {
    return (
      <Row>
        <Col>
          <Row style={{ backgroundColor: '#F0F2F5' }}>
            <Col>
              <TitleHeader hideBackButton={this.props.hideBackButton} title={this.state.title ?? this.props.title} />
              <Row></Row>
              <Row>
                <Divider style={{ marginBottom: '0px', display: this.props.hideDivider ? "none" : "block" }} />
              </Row>
            </Col>
          </Row>
          <Row style={{ backgroundColor: 'White' }}>
            <PaddedCol>{this.props.children}</PaddedCol>
          </Row>
        </Col>
      </Row>
    );
  }
}

const PageContainer = withRouter(PageContainerWithoutRouter);

export default PageContainer;
