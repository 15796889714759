import * as React from 'react';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { Col, Button, Row } from 'antd';
import { UserListFilterBar, UserListTable } from '../UserListView';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import RolesListFilterBar from './RolesListFilterBar';
import RolesListTable from './RolesListTable';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class RolesListViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  AddNewRole = (event: React.SyntheticEvent) => {
    this.props.history.push('roles/new', new DetailsData(DetailsMode.New));
  };

  render() {
    return (
      <TableFilterContextProvider>
        <LeftAlignedRow>
          <Col>
            <Button size="large" type="primary" onClick={this.AddNewRole}>
              Add Role
            </Button>
          </Col>
        </LeftAlignedRow>
        <Row>
          <Col md={24}>
            <RolesListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <RolesListTable />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const RolesListView = withRouter(RolesListViewWithoutRouter);

export default RolesListView;
