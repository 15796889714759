import { Row, Col, Icon } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import knowledgeIcon from '../../../../assets/images/knowledge icon.png';
import knowledgeTemplateIcon from '../../../../assets/images/knowledge temp icon.png';
import dashboardIcon from '../../../../assets/images/dashboard icon.png';
import auditIcon from '../../../../assets/images/audit icon.png';
import categoryIcon from '../../../../assets/images/category icon.png';
import userDirectoryIcon from '../../../../assets/images/user directory icon.png';
import userIcon from '../../../../assets/images/user icon.png';
import userRolesIcon from '../../../../assets/images/user roles icon.png';
import jurisdictionIcon from '../../../../assets/images/jury icon.png';
import faqIcon from '../../../../assets/images/forum icon.png';
import helpIcon from '../../../../assets/images/help icon.png';
import UserPreferenceContext from 'Context/UserPreferenceContext';
import { UserPreference } from 'Core/Api/Api';
import ShowIfTrue from 'components/Forms/ShowIfTrue';

const Wrap = styled.div`
  
  padding-top: 30px;
  width: 200px;
  height: 200px;
  background-color: #54595F;
  box-shadow: -2px 4px 10px #54595F;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: auto;
  color: white;
  p{
    font-size:20px;
    font-weight:bold;
  }
`;

interface Props {
}

export default ({ }: Props) => {
  let userPreferenceContext = useContext(UserPreferenceContext);
  let [hasUserPreference, setHasUserPreference] = useState<Boolean>(false);

  useEffect(() => {
    if (userPreferenceContext.data) {
      let hasUserPreference = !!(userPreferenceContext.data as unknown as UserPreference)?.pinnedKnowledge?.length ||
        !!(userPreferenceContext.data as unknown as UserPreference)?.recentlyAddedKnowledgeCategories?.length ||
        !!(userPreferenceContext.data as unknown as UserPreference)?.recentlyModifiedKnowledgeCategories?.length;
      setHasUserPreference(hasUserPreference);

    }

  }, [userPreferenceContext.data]);

  return (
    <ShowIfTrue condition={!hasUserPreference}>
      <Row type="flex" justify="center" style={{ marginBottom: '20px' }}  >
        <Col md={14} xs={24}>
          <Row type="flex" justify="center" style={{ textAlign: 'center' }} >
            <Col xl={8} md={24}>

              <NavLink to={{ pathname: "/about-us" }}>
                <Wrap>
                  <img src={helpIcon} width={74} height={74} />
                  <p>About Us</p>
                </Wrap>
              </NavLink>
            </Col>
            <Col xl={8} md={24}>
              <NavLink to={{ pathname: "/faq" }}>
                <Wrap>
                  <img src={faqIcon} width={74} height={74} />
                  <p>Frequently Asked Questions</p>
                </Wrap>
              </NavLink>
            </Col>
            <Col xl={8} md={24}>
              <NavLink to={{ pathname: "/jurisdictions" }}>
                <Wrap>
                  <img src={jurisdictionIcon} width={74} height={74} />
                  <p>Jurisdictions</p>
                </Wrap>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </Row>
    </ShowIfTrue>);
}