import * as React from 'react';
import { ErrorViewContainer } from './ErrorView.Styled';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import logo from '../../../assets/images/logo.png';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Button } from 'antd';
export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  hasError?: boolean;
}

export class ErrorViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error) {
    this.setState({ hasError: true });
  }

  resetErrorAndGoHome = () => {
    this.setState({ hasError: false });
    this.props.history.push('/');
  };

  resetErrorAndGoBack = () => {
    this.setState({ hasError: false });
    this.props.history.goBack();
  };

  renderError() {
    return (
      <ErrorViewContainer>
        <img src={logo} className="logo" width="192" />
        <div className="browser">
          <div className="controls">
            <i></i>
            <i></i>
            <i></i>
          </div>

          <div className="eye"></div>
          <div className="eye"></div>
        </div>

        <h1>Unfortunately, something has gone wrong.</h1>
        <p>
          We're unable to fulfill your request. Rest assured we have been notified and are looking
          into the issue. Please refresh your browser. If the error continues, please contact our{' '}
          <NavLink to="/contact-us">support team</NavLink>.
        </p>
        <CenterAlignedRow>
          <Button onClick={this.resetErrorAndGoBack} type="link">
            Back
          </Button>
        </CenterAlignedRow>
        <CenterAlignedRow>
          <Button onClick={this.resetErrorAndGoHome} type="link">
            Go Home
          </Button>
        </CenterAlignedRow>
      </ErrorViewContainer>
    );
  }

  render() {
    return this.state.hasError ? this.renderError() : <>{this.props.children}</>;
  }
}

const ErrorView = withRouter(ErrorViewWithoutRouter);

export default ErrorView;
