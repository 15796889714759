import * as React from "react";
import { TableFilterContext } from "components/Forms/DataGrid/TableFilterContextProvider";
import { Button } from "antd";

export interface Props {
    children?: React.ReactNode
    submitHandle: (any) => Promise<any>;
}

export interface State {
    disabled?: boolean;
}

export default class UserReportButton extends React.Component<Props, State> {
    static contextType = TableFilterContext;
    context!: React.ContextType<typeof TableFilterContext>;

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    submitReport = (event: React.SyntheticEvent) => {
        event.stopPropagation();
        this.setState({ disabled: true });

        this.props.submitHandle(this.context.data).finally(() => this.setState({ disabled: false }))
    }

    render() {
        return (
            <Button disabled={this.state.disabled} onClick={this.submitReport} loading={this.state.disabled} >
                {this.props.children}
            </Button>
        )
    }
}
