import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form } from 'antd';

export const RounedForm = styled(Form)`
  padding: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fbfbfb;
  border: 1px solid #bbb;
  border-radius: 6px;

  .ant-form-item {
    display: flex;
  }

  .ant-form-item-control-wrapper {
    flex: 1;
  }
`;
