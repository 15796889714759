import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Operations, UserStatus, IResendConfirmationEmailCommandRequest } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { ScrollableTable } from 'components/Forms/DataGrid/SimpleTable/SimpleTable.Styled';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
import { Row, Col, Button, Icon } from 'antd';
import { NavButton } from 'components/Forms/NavButton';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
export interface Props {
    children?: React.ReactNode;
    isRegional?: boolean;
}

export interface State {
    countries: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class UserListTable extends React.Component<Props, State> {
    handler: UserHandler;
    tableRef = React.createRef<SimpleTable<any>>();

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            roles: [],
        };

        this.handler = new UserHandler(props);
    }

    removeUser = (user: User): Promise<any> => {
        return this.handler.RemoveUser({ id: user.id }).then(x => {
            NotificationService.success(`Removed "${user.firstName} ${user.lastName}" user`);
            this.tableRef.current?.refresh();
        });
    };

    resendConfirmationEmail = (user: SlimUser): Promise<any> => {
        let request: IResendConfirmationEmailCommandRequest = {
            emailAddress: user.emailAddress
        };
        return this.handler.ResendConfirmationEmail(request).then(x => {
            NotificationService.success(`Resent Confirmation email for "${user.firstName} ${user.lastName}" user`);

        });
    };

    userListTableColumns: Array<ColumnProps<SlimUser>> = [
        {
            title: 'User Name',
            dataIndex: 'username',
            sorter: true,
        },
        {
            title: 'Roles',
            render: (text: any, record: SlimUser, index: number) => {
                return record.roles!.length > 0 ? record.roles?.reduce((x, y) => x + ',' + y) : <></>;
            },
        },
        {
            title: 'jurisdiction',
            dataIndex: 'jurisdictionName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: '',
            width: "250px",
            render: (text: any, record: SlimUser, index: number) => {
                return (
                    <Row gutter={10} type="flex" justify="space-between">
                        <Col>
                            <ShowIfHavePermission operations={[Operations.Edit_User]}>
                                <NavButton
                                    type="default"
                                    to={{
                                        pathname: 'users/' + encodeURIComponent(record.username ?? ""),
                                        state: { userId: record.id, mode: DetailsMode.Edit },
                                    }}>
                                    <Icon type="edit" />
                                </NavButton>
                            </ShowIfHavePermission>
                        </Col>
                        <Col>
                            <ShowIfHavePermission operations={[Operations.Remove_User]}>
                                <ConfirmRemoveButton warningMessage={<><p>Remove user?</p></>} onConfirm={() => this.removeUser(record)} >
                                    <Icon type="delete" />
                                </ConfirmRemoveButton>
                            </ShowIfHavePermission>
                        </Col>
                        <Col>
                            <ShowIfTrue condition={record.status == UserStatus.Disabled}>
                                <ConfirmRemoveButton warningMessage={<><p>Resend Confirmation Email?</p></>} onConfirm={() => this.resendConfirmationEmail(record)} >
                                    <Icon type="mail" />
                                </ConfirmRemoveButton>
                            </ShowIfTrue>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    render() {
        return (
            <ScrollableTable>
                <SimpleTable<SlimUser>
                    tableKey="id"
                    ref={this.tableRef}
                    dataFetcher={this.handler}
                    columns={this.userListTableColumns}

                />
            </ScrollableTable>
        );
    }
}
