import * as React from 'react';
import { Row, Col, Button } from 'antd';
import TableFilterContextProvider from '../../../components/Forms/DataGrid/TableFilterContextProvider';
import { KnowledgsJurisdictionsListFilterBar, KnowledgeJurisdictionsListTable } from './';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from '../../../Core/models/DetailsData';
import DetailsMode from '../../../Core/models/DetailsMode';
import { RightAlignedRow } from '../../../components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from '../../../components/Styled/Layout/LeftAlignedRow';
import ShowIfHavePermission from '../../../components/Forms/ShowIfHavePermission';
import { Operations } from '../../../Core/Api/Api';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

export class KnowledgeJurisdictionsListViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  AddNewJurisdiction = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('jurisdictions/s/new', newDetailsData);
  };

  render() {
    return (
      <TableFilterContextProvider>
        <Row>
          <Col md={24}>
            <ShowIfHavePermission operations={[Operations.Create_Knowledge_Categories]}>
              <Button type="primary" onClick={this.AddNewJurisdiction}>
                Add New Jurisdiction
              </Button>
            </ShowIfHavePermission>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <KnowledgsJurisdictionsListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <KnowledgeJurisdictionsListTable />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const KnowledgeJurisdictionsListView = withRouter(KnowledgeJurisdictionsListViewWithoutRouter);

export default KnowledgeJurisdictionsListView;
