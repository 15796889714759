import { Button, Col, Icon, Row, Table, Tag } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import { NavButton } from 'components/Forms/NavButton';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { UserProfileLink } from 'components/UserProfile/UserProfileLink';
import { AccessLevel, AuditHeader, Knowledge, Operations } from 'Core/Api/Api';
import ConfigService from 'Core/ConfigService';
import DetailsMode from 'Core/models/DetailsMode';
import moment from 'moment';
import React, { useState } from 'react';

interface Props {
  onRemoved?: (knowledge: Knowledge[]) => void;
  knowledges?: Knowledge[];
}

export default (props: Props) => {


  let config = ConfigService.getSync();

  let columsDef: Array<ColumnProps<Knowledge>> = [
    {
      title: "Title",
      dataIndex: 'title'
    },
    {
      title: "Access Level",
      dataIndex: "accessLevel",
      render: (text: any, item: Knowledge, index: number) => {

        const accessLevelText = item.accessLevel == AccessLevel.Public ? "Public" : "Level " + item.accessLevel?.substr(5, 1);

        if (item.accessLevel == AccessLevel.Public) {
          return <Tag style={{ marginRight: "3px" }} color="green">Public</Tag>
        }
        else {
          return <Tag style={{ marginRight: "3px" }} color="#DB425A">{accessLevelText}</Tag>
        }

      },
    },
    {
      title: "Author",
      dataIndex: "createdById",
      render: (text: any, item: Knowledge, index: number) => {
        return (<UserProfileLink userId={item.createdById} />)
      },
    }
  ];

  return (<><Table<Knowledge> rowKey="id" pagination={{ hideOnSinglePage: true }} dataSource={props.knowledges} columns={columsDef} /></>);
}