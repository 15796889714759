import UserPreferenceHandler from 'components/UserProfile/UserPreferenceHandler';
import AuthContext from 'Context/AuthContext';
import UserPreferenceContext from 'Context/UserPreferenceContext';
import { UserPreference } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import * as React from 'react';
import { useContext, useEffect } from 'react';
// This is an example script - don't forget to change it!

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  userPreference?: UserPreference;
}

const UserPreferenceContextProvider: React.FC<Props> = (props) => {

  let authContext = useContext(AuthContext);
  let userPreferenceHandler = new UserPreferenceHandler({});
  let [userPreference, setUserPreference] = React.useState<UserPreference | undefined>(undefined);
  let update = userPreference => setUserPreference(userPreference);

  useEffect(() => {
    if (!authContext.data) {
      update(undefined);
    } else {
      userPreferenceHandler.GetUserPreference(false)?.then(x => {
        update(x);
      })

    }
  }, [authContext.data?.id]);

  return (
    <UserPreferenceContext.Provider value={{ Update: update, data: userPreference }}>
      {props.children}
    </UserPreferenceContext.Provider>
  );
}


export default UserPreferenceContextProvider;



