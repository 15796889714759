import { Icon, Modal } from 'antd';
import Button, { ButtonProps } from 'antd/lib/button/button';
import { debounce } from 'lodash';
import { useHistory } from 'react-router-dom';
import React, { SyntheticEvent, useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props extends ButtonProps {
  children?: React.ReactNode;
  isFormDirty?: boolean;
}

export default (props: Props) => {
  let history = useHistory();

  let onClick = (event: SyntheticEvent) => {
    event.preventDefault();

    if (props.isFormDirty) {

      Modal.confirm({
        title: "You Have Unsaved Changes",
        content: "You have unsaved changes, are you sure you wish to discard them?",
        onOk: () => history.goBack(),
      })

    } else {
      history.goBack();
    }
  }
  let { isFormDirty, ...propsWithoutDisabledTime } = props;
  return <Button {...propsWithoutDisabledTime} onClick={onClick}>
    {props.children ?? "Cancel"}
  </Button>;
}