import * as React from 'react';
import { Row, Col, Button } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { KnowledgeListFilterBar, KnowledgeListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { AdvanceKnowledgeListFilterBar } from '../KnowledgeListView';
import Utility from 'Core/Utility';
import PublicKnowledgeList from './PublicKnowledgeList';
import { debounce } from 'lodash';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  embedded?: boolean;
}

export interface State {}

export class PublicKnowledgeListViewWithoutRouter extends React.Component<Props, State> {
  debouncedLogSearchToAnalytics: Function = debounce(Utility.logSearchToAnalytics, 1000);

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  beforeFilterUpdate = (tableRequest: TableRequest) => {
    Utility.makeKnowledgeDocumentFilterIngored(tableRequest);
    this.debouncedLogSearchToAnalytics(tableRequest, this.props.location.pathname);
    return tableRequest;
  };

  render() {
    return (
      <TableFilterContextProvider beforeUpdate={this.beforeFilterUpdate}>
        <Row type="flex" justify="center" align="middle">
          <Col xs={24} md={16}>
            <AdvanceKnowledgeListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 22, offset: 1 }} xs={24}>
            <PublicKnowledgeList
              pathRoute={this.props.embedded ? 'public-knowledge/embedded' : undefined}
            />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const PublicKnowledgeListView = withRouter(PublicKnowledgeListViewWithoutRouter);

export default PublicKnowledgeListView;
