import * as React from 'react';
import RegistrationDetailsForm from 'components/Admin/RegistrationDetailsForm/RegistrationDetailsForm';
import PageContainer from 'components/Layout/PageContainer';
import KnowledgeShareApprovalDetailsForm from 'components/Admin/KnowledgeShareApprovalDetailsForm';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class KnowledgeShareApprovalDetailsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Knowledge Share Approval">
        <KnowledgeShareApprovalDetailsForm />
      </PageContainer>
    );
  }
}
