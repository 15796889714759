import * as React from 'react';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { AppRoutes } from 'Core/AppRoutes';
import Utility from 'Core/Utility';
import { NotFoundElement } from './NotFoundView.Styled';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  shouldRender?: boolean;
}

export class NotFoundViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { shouldRender: false };
  }

  componentDidMount = () => {
    this.setState({ shouldRender: this.checkIfShouldRender(this.props.location.pathname) });
  };

  componentDidUpdate = (nextProps: Props) => {
    if (nextProps.location !== this.props.location) {
      this.setState({ shouldRender: this.checkIfShouldRender(this.props.location.pathname) });
    }
  };

  checkIfShouldRender = (path?: string): boolean => {
    const appRoutesWithoutBaseWildcard = AppRoutes.filter(x => x.path !== '/*');
    const currentPath = path ?? this.props.location.pathname;
    console.log('current path:', currentPath);
    const shouldNotRender = Utility.convertToFullPath(appRoutesWithoutBaseWildcard)?.some(x => {
      return Utility.isPathOrSubpathAMatch(currentPath, x);
    });
    console.log('results shouldNotRender: ', shouldNotRender);
    return !shouldNotRender;
  };

  render() {
    return this.state.shouldRender && this.render404Page();
  }

  render404Page(): React.ReactNode {
    return (
      <NotFoundElement>
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
          </div>
          <h2>Oops! This Page Could Not Be Found</h2>
          <p>
            Sorry but the page you are looking for does not exist, have been removed. name changed
            or is temporarily unavailable
          </p>
          <NavLink to="/">Go To Homepage</NavLink>
        </div>
      </NotFoundElement>
    );
  }
}

const NotFoundView = withRouter(NotFoundViewWithoutRouter);

export default NotFoundView;



