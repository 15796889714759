import { Button, Col, Icon, Row, Table } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import { NavButton } from 'components/Forms/NavButton';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { AuditHeader, Knowledge, Operations } from 'Core/Api/Api';
import ConfigService from 'Core/ConfigService';
import DetailsMode from 'Core/models/DetailsMode';
import moment from 'moment';
import React, { useState } from 'react';

interface Props {
  onRemoved?: (knowledge: Knowledge[]) => void;
  knowledges?: Knowledge[];
}

export default (props: Props) => {

  let [selectedRowKeys, setSelectedRowKeys] = useState<string[] | undefined>(undefined);

  let config = ConfigService.getSync();

  let columsDef: Array<ColumnProps<Knowledge>> = [
    {
      title: "Title",
      dataIndex: 'title'
    },
    // {
    //   title: "Created",
    //   dataIndex: "creationDate",
    //   render: (text: any, record: Knowledge, index: number) => {
    //     return (
    //       <p title={moment(text).fromNow()}>
    //         {moment(text).format(config.Display.DateTimeFormat)}
    //       </p>
    //     );
    //   },
    // },
    {
      title: "",
      render: (text: any, record: Knowledge, index: number) => {
        return (
          <Row gutter={10} type="flex" justify="space-around">
            <Col>
              <Button type="primary" icon="close" onClick={x => props.onRemoved?.call(undefined, [record])} />
            </Col>
          </Row>
        );
      },
    },
  ];

  let onSelectedRowsChanged = selectedRowKeys => {
    setSelectedRowKeys(selectedRowKeys);
  }

  let removeAllSelected = () => {
    if (selectedRowKeys && selectedRowKeys.length) {

      let selectedKnowledge = props.knowledges?.filter(x => selectedRowKeys?.some(key => x.id == key));
      if (selectedKnowledge && selectedKnowledge.length) {
        props.onRemoved?.call(undefined, selectedKnowledge);
        setSelectedRowKeys([]);
      }
    }
  }

  return (<><Button onClick={removeAllSelected} size="small">Remove Selected</Button><Table<Knowledge> rowKey="id" rowSelection={{ selectedRowKeys, onChange: onSelectedRowsChanged }} pagination={{ hideOnSinglePage: true }} dataSource={props.knowledges} columns={columsDef} /></>);
}