import React, { useContext, useEffect, useState } from 'react';
import AuthContext from 'Context/AuthContext';
import UserPreferenceHandler from 'components/UserProfile/UserPreferenceHandler';
import Utility from 'Core/Utility';
import { UserPreference } from 'Core/Api/Api';
import { Row, Col } from 'antd';
import SimpleKnowledgeListView from '../SimpleKnowledgeListView';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import UserPreferenceContext from 'Context/UserPreferenceContext';

interface Props {
}

export default ({ }: Props) => {

  let authContext = useContext(AuthContext);
  let userPreferenceContext = useContext(UserPreferenceContext);

  let userPreferenceHandler = new UserPreferenceHandler({});
  let [showAnything, setShowAnything] = useState<Boolean>(false);
  let [showRecentlyModified, setShowRecentlyModified] = useState<Boolean>(false);
  let [showRecentlyAdded, setShowRecentlyAdded] = useState<Boolean>(false);
  let [showRecentlyPinnedKnowledge, setShowRecentlyPinnedKnowledge] = useState<Boolean>(false);



  useEffect(() => {
    if (!authContext.data) { return; }
    let userPreference = userPreferenceContext.data;

    if (userPreference) {
      setComponentState(userPreference);
    } else {

      userPreferenceHandler.GetUserPreference(false, false)?.then(x => {
        userPreference = x;
        userPreferenceContext.Update(x);
        setComponentState(userPreference);
      });
    }
  }, [userPreferenceContext.data]);

  let setComponentState = (userPreference: UserPreference) => {
    let showRecentlyAdded = !!userPreference.recentlyAddedKnowledgeCategories?.length ?? false;
    let showRecentlyModifed = !!userPreference.recentlyModifiedKnowledgeCategories?.length ?? false;
    let showRecentlyPinned = !!userPreference.pinnedKnowledge?.length ?? false;
    let showAnything = showRecentlyAdded || showRecentlyModifed || showRecentlyPinned;
    setShowAnything(showAnything);
    setShowRecentlyAdded(showRecentlyAdded);
    setShowRecentlyModified(showRecentlyModifed);
    setShowRecentlyPinnedKnowledge(showRecentlyPinned);
  }

  return (
    <ShowIfTrue condition={showAnything}>
      <Row>
        <Col>
          <Row>
            <Col>
              <ShowIfTrue condition={showRecentlyPinnedKnowledge}>
                <SimpleKnowledgeListView title="My Pinned Knowledge" DataFetcherMethod={userPreferenceHandler.GetUserPinnedKnowledgeList} />
              </ShowIfTrue>
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowIfTrue condition={showRecentlyModified}>
                <SimpleKnowledgeListView title="Recently Modified" DataFetcherMethod={userPreferenceHandler.GetUserMostRecentlyModifiedKnowledgeList} />
              </ShowIfTrue>
            </Col>
          </Row>
          <Row>
            <Col>
              <ShowIfTrue condition={showRecentlyAdded}>
                <SimpleKnowledgeListView title="Recently Added" DataFetcherMethod={userPreferenceHandler.GetUserMostRecentlyAddedKnowledgeList} />
              </ShowIfTrue>
            </Col>
          </Row>
        </Col>
      </Row>
    </ShowIfTrue>
  );
}


