import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, AuditHeader } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import AuditHandler from 'components/Admin/AuditHandler';
import DetailsData from 'Core/models/DetailsData';
import { ScrollableTable } from '../../../Forms/DataGrid/SimpleTable/SimpleTable.Styled';
import { Button, Icon } from 'antd';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class AuditListTable extends React.Component<Props, State> {
    handler: AuditHandler;
    config = ConfigService.getSync();

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new AuditHandler(props);
    }

    componentDidMount() {
        this.handler.GetTop100Jurisdictions().then(x => {
            const jurisdictions: IFilterSelectOption[] =
                x.results?.map(y => ({
                    text: y.name,
                    value: y.id,
                })) ?? [];
            this.setState({ jurisdictions });
        });
    }

    auditListTableColumns: Array<ColumnProps<AuditHeader>> = [
        {
            title: 'Time',
            dataIndex: 'auditTime',
            render: (text: any, record: AuditHeader, index: number) => {
                return (
                    <p title={moment(text).fromNow()}>
                        {moment(text).format(this.config.Display.DateTimeFormat)}
                    </p>
                );
            },
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
        },
        {
            title: 'User',
            dataIndex: 'auditedUserName',
        },
        {
            title: 'Status',
            dataIndex: 'auditStatus',
        },
        {
            title: '',
            render: (text: any, record: AuditHeader, index: number) => {
                console.log(record);
                return (
                    <NavLink
                        to={{
                            pathname: 'audits/' + record.id,
                            state: new DetailsData(DetailsMode.View, record.id),
                        }}>
                        <Button><Icon type="read" /></Button>
                    </NavLink>
                );
            },
        },
    ];

    render() {
        return (
            <ScrollableTable>
                <SimpleTable<SlimUser>
                    tableKey="id"
                    dataFetcher={this.handler}
                    columns={this.auditListTableColumns}
                />
            </ScrollableTable>
        );
    }
}
