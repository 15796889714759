import { Col, Form, Row, Typography, Input, Button, Upload, Icon, Modal, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import AuthContext from 'Context/AuthContext';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AddUserCommandRequest,
  SlimUser,
  User,
  Knowledge,
  IEditKnowledgeCommandRequest,
  KnowledgeModel,
  GetKnowledgeByIdQueryResponse,
  DocumentModel,
  IDeleteCommentCommandRequest,
  IDeleteDocumentCommandRequest,
  IEditCategoryCommandRequest,
  KnowledgeCategory,
  KnowledgeDataTemplate,
} from '../../../Core/Api/Api';
import {
  doubleColFormColSize,
  FullWithButton,
  singleColFormColSize,
  singleCol2FormColSize,
  singleCol2FormColSize2,
} from '../../Forms/Form.Styled';
import KnowledgeHandler from '../KnowledgeHandler';
import FormControl from 'components/Forms/FormControl';
import ValidationUtility from 'Core/ValidationUtility';
import TextArea from 'antd/lib/input/TextArea';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleUpload from 'components/Forms/SimpleUpload';

import 'survey-react/survey.css';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import KnowledgeCategoryHandler from '../KnowledgeCategoryHandler';
import KnowledgeTemplateHandler from '../KnowledgeTemplateHandler';
import SurveyCreatorControl from 'components/Forms/SurveyCreatorControl';
import { SubmitButton } from 'components/Forms/SubmitButton';
import { CancelButton } from 'components/Forms/CancelButton';

const { Title } = Typography;

type onRemoveFunc = (file: UploadFile) => void | boolean | Promise<void | boolean> | undefined;
type beforeUploadFunc = (file: RcFile, FileList: RcFile[]) => boolean | PromiseLike<void>;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  knowledgeTemplate?: KnowledgeDataTemplate;
  detailsMode: DetailsMode;
  categories?: ISelectOption[];
}

export class KnowledgeTemplateDetailsFormWithoutFormAndRouter extends React.Component<
  Props,
  State
> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: KnowledgeTemplateHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      detailsMode: DetailsMode.New,
    };
    this.handler = new KnowledgeTemplateHandler(props);
  }

  componentDidMount() {
    this.getKnowledgeCategoryDetails();
    this.getCategories();
  }

  getKnowledgeCategoryDetails() {
    const urlState = this.props.location.state as { id: string; mode: DetailsMode } | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetKnowledgeTemplateDetailsById(urlState.id).then(
        x => {
          this.setState({ knowledgeTemplate: x, detailsMode: DetailsMode.Edit });
          this.props.form.setFieldsValue(x ?? {});
        },
        x => NotificationService.error(x.message)
      );
    } else {
      this.setState({ detailsMode: DetailsMode.New });
    }
  }

  getCategories() {
    this.handler.GetTop100KnowledgeCategories().then(x => {
      const categories: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories });
    });
  }

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    var me = this;
    this.props.form.validateFieldsAndScroll((error, values: IEditCategoryCommandRequest) => {
      if (error) {
        return;
      }

      if (this.state.detailsMode == DetailsMode.Edit) {
        values.id = this.state.knowledgeTemplate?.id;

        this.handler.EditKnowledgeTemplate(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Updated ${values.name}`);
        });
      } else {
        this.handler.AddKnowledgeTemplate(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Added ${values.name}`);
        });
      }
    });
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <LeftAlignedRow>
        <Col md={24}>
          <Form layout="horizontal" onSubmit={this.onSubmit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="name"
              label="Name"
              rules={[...ValidationUtility.required('Name is required')]}>
              <Input />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="description"
              label="Description"
              rules={[...ValidationUtility.required('Description is required')]}>
              <TextArea />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...ValidationUtility.required('category is required')]}
              name="knowledgeCategoryId"
              label="Category">
              <SimpleSelect
                loading={!this.state.categories}
                options={this.state.categories}></SimpleSelect>
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="templateJSON"
              label="Template"
              rules={[...ValidationUtility.required('Template is required'),
              ...ValidationUtility.SurveyJSHasQuestions('Template must have atleast one question')]}>
              <SurveyCreatorControl prefix={this.props.form.getFieldValue("name")} />
              {/* <SurveyCreator /> */}
              {/**/}
              {/* <input></input> */}
            </FormControl>
            <Row>
              <Col span={2}>
                <CancelButton isFormDirty={this.props.form.isFieldsTouched()} type="default" />
              </Col>
              <Col span={2}>
                <SubmitButton type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </LeftAlignedRow>
    );
  }
}

const KnowledgeTemplateDetailsFormWithRouter = Form.create<Props>({
  name: 'KnowledgeCategoryDetails',
})(KnowledgeTemplateDetailsFormWithoutFormAndRouter);

const KnowledgeTemplateDetailsForm = withRouter(KnowledgeTemplateDetailsFormWithRouter);

export default KnowledgeTemplateDetailsForm;
