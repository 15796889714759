import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import {
  User,
  GetAllUsersByFilterQueryRequest,
  SlimUser,
  GetFilteredJurisdictionsQueryRequest,
  OrderBy,
  GetFilteredCountriesQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetUserByIdQueryRequest,
  EditUserCommandRequest,
  AddUserCommandRequest,
  RemoveUserCommandRequest,
  IRemoveUserCommandRequest,
  GenerateEnabledUsersCsvCommandRequest,
  IGenerateEnabledUsersCsvCommandRequest, GetAllUsersReportDataQueryRequest, IGetAllUserInfoForPublicByFilterQueryRequest, GetAllUserInfoForPublicByFilterQueryRequest, IGetUserLoginReportDataQueryRequest, GetUserLoginReportDataQueryRequest, RegisterUserCommandRequest, IResendConfirmationEmailCommandRequest, ResendConfirmationEmailCommandRequest
} from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { SecuredClient } from 'Core/Api/SecuredClient';

export default class UserHandler implements ITableDataFetcher<SlimUser> {
  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<SlimUser>> {
    return this.GetAllUserByFilter(tableRequest);
  }

  GetAllUserByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllUsersByFilterQueryRequest = new GetAllUsersByFilterQueryRequest(request);
    return client.getAllUsersByFilter(getAllUsersByFilterQueryRequest);
  }

  GetAllUserReportData(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllUsersReportDataQueryRequest = new GetAllUsersReportDataQueryRequest(request);
    return client.getAllUsersReportData(getAllUsersReportDataQueryRequest);
  }

  GetTop100Jurisdictions() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredJurisdictionsQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredJurisdictions(request);
  }

  GetTop500Countries() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredCountriesQueryRequest();
    request.amountPerPage = 500;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredCountries(request);
  }

  GetFilteredRoles(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRolesByFilter(request);
  }

  GetPublicFilteredRole(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRoleInfosForPublicByFilter(request);
  }
  GetUserById(userId: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetUserByIdQueryRequest();
    request.id = userId;
    return client.getUserById(request);
  }

  EditUser(request: EditUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.editUser(request);
  }

  RemoveUser(iRequest: IRemoveUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new RemoveUserCommandRequest(iRequest);
    return client.removeUser(request);
  }

  ResendConfirmationEmail(iRequest: IResendConfirmationEmailCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new ResendConfirmationEmailCommandRequest(iRequest);
    return client.resendConfirmationEmail(request);
  }

  AddUser(request: AddUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.addUser(request);
  }

  RegisterUser(request: RegisterUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.registerUser(request);
  }

  GetUserInfoForPublic(model: IGetAllUserInfoForPublicByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new GetAllUserInfoForPublicByFilterQueryRequest(model);
    return client.getAllUserInfoForPublicByFilter(request);
  }

  GetUserLoginReportData(model: IGetUserLoginReportDataQueryRequest | undefined) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new GetUserLoginReportDataQueryRequest(model);
    return client.getUserLoginReportData(request);
  }

  GenerateReport(request: IGenerateEnabledUsersCsvCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let initRequest = new GenerateEnabledUsersCsvCommandRequest(request);

    return client.generateUsersCsv(initRequest);

  }
}
