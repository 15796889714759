import * as React from 'react';
import KnowledgeDetailsView from 'components/Knowledge/KnowledgeDetailsView';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class PublicKnowledgeDetailsViewPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer hideDivider={true}>
        <KnowledgeDetailsView />
      </PageContainer>
    );
  }
}
