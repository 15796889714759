import * as React from 'react';
import AuthContext from '../../../Context/AuthContext';
import { SlimUser } from '../../../Core/Api/Api';
import AuthUser from 'Core/models/AuthUser';
import TokenService from 'Core/TokenService';
import ConfigService from 'Core/ConfigService';
import { withRouter, RouteComponentProps } from 'react-router';
import LogRocket from 'logrocket';
import Utility from 'Core/Utility';
import Cookies from 'js-cookie';
// This is an example script - don't forget to change it!

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  currentUser?: AuthUser;
  shouldRenderChildren?: boolean;
  userCheckInterval?: any;
  userCookie?: string;
}

export default class AuthContextProvider extends React.Component<Props, State> {
  update: (newUser: any) => void;

  constructor(props: Props) {
    super(props);

    this.state = { currentUser: undefined, shouldRenderChildren: false };
    this.updateUserBasedOnToken = this.updateUserBasedOnToken.bind(this);
    this.update = newUser => this.setState({ currentUser: newUser });
  }

  async componentDidMount() {
    window.addEventListener('load', this.updateUserBasedOnToken);

    const config = await ConfigService.get();
    const currentUser = TokenService.GetUserFromToken();

    //Peridically check if user data has changed
    const userCheckInterval = setInterval(
      this.updateUserBasedOnToken,
      config.Security.cookieCheckIntervalMs || 2000
    );
    this.setState({ currentUser, shouldRenderChildren: !!currentUser, userCheckInterval });
  }

  componentWillUnmount() {
    window.removeEventListener('load', this.updateUserBasedOnToken);
    clearInterval(this.state.userCheckInterval);
  }

  updateUserBasedOnToken(): AuthUser | undefined {

    if (!this.hasUserCookieChanged()) {
      if (!this.state.shouldRenderChildren) {
        this.setState({ shouldRenderChildren: true });
      }
      return this.state.currentUser;
    }

    const tokenUser = TokenService.GetUserFromToken();
    const config = ConfigService.getSync();

    const currentUser = this.state.currentUser;

    if (!tokenUser && !currentUser) {
      let cookie = Cookies.get(config?.Security.tokenName ?? "notthere");
      this.setState({ shouldRenderChildren: true, userCookie: cookie });
      return;
    } else if (tokenUser && !currentUser) {
      let cookie = Cookies.get(config?.Security.tokenName ?? "notthere");

      this.setState({ currentUser: tokenUser, shouldRenderChildren: true, userCookie: cookie });
      return;
    } else if (!tokenUser?.expirationTime?.isSame(currentUser?.expirationTime)) {
      let cookie = Cookies.get(config?.Security.tokenName ?? "notthere");

      this.setState({ currentUser: tokenUser, shouldRenderChildren: true, userCookie: cookie });
      return;
    }
  }

  hasUserCookieChanged(): boolean {
    const config = ConfigService.getSync();
    return this.state.userCookie != Cookies.get(config?.Security.tokenName ?? "notthere");
  }

  render() {
    return (
      <AuthContext.Provider value={{ Update: this.update, data: this.state.currentUser }}>
        {this.state.shouldRenderChildren && this.props.children}
      </AuthContext.Provider>
    );
  }
}






