import * as React from 'react';
import PageContainer from '../../../components/Layout/PageContainer';
import KnowledgeJurisdictionsListView from '../../../components/Knowledge/KnowledgeJurisdictionListView';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class KnowledgeJurisdictionsListViewPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Jurisdictions">
        <KnowledgeJurisdictionsListView />
      </PageContainer>
    );
  }
}
