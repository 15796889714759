import * as React from 'react';
import { Col, Layout, Row } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';
import logoSvg from '../../../assets/images/logo.png';
import logo3Svg from '../../../assets/images/logo_3in1.png';
import loginBackground from '../../../assets/images/background_ologin.jpg';

const { Header, Content, Footer } = Layout;

export const CenteredContent = styled(Content)`
  height: 100%;
  min-width: 200px;
  ${ScreenHelper.down('md')} {
    padding-top: 1vh;
  }

  ${ScreenHelper.up('md')} {
    padding-top: 15vh;
  }
`;

export const LoginWithBackground = styled(Layout)`
  background: url(${loginBackground});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;
`;

export const LoginContent = styled.div`
  background-color: white;
  padding: 20px 60px 40px 60px;
  ${ScreenHelper.down('sm')} {
     padding: 20px 10px 20px 10px;
  }
`;

export const ContentCol = styled(Col)`
  margin-bottom: 210px;
  ${ScreenHelper.down('sm')} {
    margin-bottom: 20px;
  }
`;

export const MainLogo = styled.div`
  height: 14em;
  /*width: 21em;*/

  border: none;
  background: url(${logo3Svg});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  ${ScreenHelper.down('sm')} {
    height: 8em;
    /*width: 10em;*/
  }
`;
