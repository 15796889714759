import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { IUpsertUserPreferenceCommandRequest, IActivateUserCommandRequest, ActivateUserCommandRequest, GetUserProfileQueryRequest, ChangePasswordCommandRequest, IChangePasswordCommandRequest, IEditUserProfileCommandRequest, EditUserProfileCommandRequest, GetAllUserProfileByFilterQueryRequest, IGetAllUserProfileByFilterQueryRequest, UserProfileListItemModel, IUserProfileListItemModel, GetUserPreferenceCommandRequest, PinKnowledgeCommandRequest, UnPinKnowledgeCommandRequest, GetMyRecentKnowledgeByFilterQueryRequest, IGetMyRecentKnowledgeByFilterQueryRequest, UserPreferenceListType, IGetMyPinnedKnowledgesByFilterQueryRequest, GetMyPinnedKnowledgesByFilterQueryRequest, UpsertUserPreferenceCommandRequest } from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { debounce } from 'lodash';

export default class UserPreferenceHandler {

  constructor(params: any) {

    //this.GetUserPreference = debounce(this.GetUserPreference, 100);
  }

  GetMyProfile() {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.getMyUserProfile();
  }

  GetUserPreference(showLoading: boolean = true, handleGeneralError: boolean = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    return client.getUserPreference(new GetUserPreferenceCommandRequest());
  }


  UpsertUserPreference(model: IUpsertUserPreferenceCommandRequest, showLoading: boolean = true, handleGeneralError: boolean = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    let request = new UpsertUserPreferenceCommandRequest(model);
    return client.upsertUserPreference(request);
  }



  PinKnowledge(id: string, showLoading: boolean = true, handleGeneralError: boolean = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    let request = new PinKnowledgeCommandRequest({ knowledgeId: id })
    return client.pinKnowledge(request);
  }

  UnPinKnowledge(id: string, showLoading: boolean = true, handleGeneralError: boolean = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    let request = new UnPinKnowledgeCommandRequest({ knowledgeId: id })
    return client.unPinKnowledge(request);
  }

  ChangePassword(model: IChangePasswordCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new ChangePasswordCommandRequest(model)
    return client.changePassword(request);
  }

  GetUserProfiles(model: IGetAllUserProfileByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new GetAllUserProfileByFilterQueryRequest(model)
    return client.getAllUserProfileByFilter(request);

  }

  GetUserMostRecentlyModifiedKnowledgeList(model: IGetMyRecentKnowledgeByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    let request = new GetMyRecentKnowledgeByFilterQueryRequest(model);
    request.typeOfRecentList = UserPreferenceListType.RecentlyModified;
    request.amountPerPage = 3;
    return client.getMyRecentKnowledgeByFilter(request);
  }

  GetUserMostRecentlyAddedKnowledgeList(model: IGetMyRecentKnowledgeByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    let request = new GetMyRecentKnowledgeByFilterQueryRequest(model);
    request.typeOfRecentList = UserPreferenceListType.RecentlyAdded;
    request.amountPerPage = 3;
    return client.getMyRecentKnowledgeByFilter(request);
  }

  GetUserPinnedKnowledgeList(model: IGetMyPinnedKnowledgesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    let request = new GetMyPinnedKnowledgesByFilterQueryRequest(model);
    request.amountPerPage = 3;
    return client.getMyPinnedKnowledgeByFilter(request);
  }

}
