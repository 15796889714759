import * as React from 'react';
import { Row, Col, Button, Menu, Dropdown, Icon } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { UserListFilterBar, UserListTable, UserReportButton } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import UserHandler from '../UserHandler';
import { UserStatus, Operations } from 'Core/Api/Api';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  isRegional?: boolean;
}

export interface State { }

export class UserListViewWithoutRouter extends React.Component<Props, State> {

  userHandler: UserHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.userHandler = new UserHandler(props);
  }

  AddNewUser = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('users/new', newDetailsData);
  };

  GotoUserReport = (status: UserStatus) => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.View;
    newDetailsData.id = status;
    this.props.history.push('/admin/users/v/reports', newDetailsData);
  }

  renderUserReportButtons = () => {
    return (
      <Menu>
        <Menu.Item onClick={x => this.GotoUserReport(UserStatus.Active)} key="1">Enabled Users</Menu.Item>
        <Menu.Item onClick={x => this.GotoUserReport(UserStatus.Disabled)} key="2">Disabled Users</Menu.Item>
      </Menu>
    )
  }

  render() {
    return (
      <TableFilterContextProvider>
        <LeftAlignedRow>
          <Col>
            <Button size="large" type="primary" onClick={this.AddNewUser}>
              Add User
            </Button>
            <ShowIfHavePermission operations={[Operations.Generate_User_Reports]}>
              <Dropdown overlayStyle={{ marginLeft: "5px" }} overlay={this.renderUserReportButtons()}>
                <Button style={{ marginLeft: "5px" }} size="large" type="primary" >
                  Reports <Icon type="down" />
                </Button>
              </Dropdown>
            </ShowIfHavePermission>
          </Col>
        </LeftAlignedRow>
        <Row>
          <Col md={24}>
            <UserListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <UserListTable isRegional={this.props.isRegional} />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const UserListView = withRouter(UserListViewWithoutRouter);

export default UserListView;
