import * as React from 'react';
import RequestPasswordResetForm from 'components/UserManagement/RequestPasswordResetForm';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class RequestPasswordResetPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return <RequestPasswordResetForm />;
  }
}
