import * as React from "react";
import UserHandler from "components/Admin/UserHandler";
import TableRequest from "Core/TableUtility/Models/TableRequest";
import { FilterType, SlimUser, UserStatus } from "Core/Api/Api";
import { RDLReportViewer } from "components/Reports/RDLReportViewer";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DetailsMode from "Core/models/DetailsMode";
import moment from "moment";

export interface Props extends RouteComponentProps {
    children?: React.ReactNode
}

export interface State {
    UserDataSource?: any[];
}

export class EnabledUserReportViewWithoutRouter extends React.Component<Props, State> {
    userHandler: UserHandler;
    constructor(props: Props) {
        super(props)

        this.state = {
        }
        this.userHandler = new UserHandler(props);
    }

    componentDidMount() {

        const urlState = this.props.location.state as { id: string; mode: DetailsMode, Extra: any } | undefined;

        var userStatus = urlState?.id;
        var tableRequest = new TableRequest();
        tableRequest.filters = [];
        tableRequest.filters.push({ field: 'Status', filterType: FilterType.Equal, value: userStatus });
        this.userHandler.GetAllUserReportData(tableRequest).then(x => {
            let dataSources: any[] = [];
            const filterItem = [{
                Jurisdiction: x.jurisdiction,
                Status: x.status,
                ReportDate: moment().toISOString(true),
                Total: x.count
            }]
            dataSources.push({ name: "userDataSource", value: x.results });
            dataSources.push({ name: "filterDataSource", value: filterItem });
            this.setState({ UserDataSource: dataSources });
        })
    }

    render() {
        var hasDataSource = !!this.state.UserDataSource;
        return (hasDataSource && <RDLReportViewer dataSource={this.state.UserDataSource} fileName="UserStatusReport" />);
    }
}

const EnabledUserReportView = withRouter(EnabledUserReportViewWithoutRouter);
export default EnabledUserReportView;
