import * as React from 'react';
import CollapsedMenuContext from '../../../../Context/CollapsedMenuContext';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  collapsedMenu: boolean;

}

export default class CollapsedMenuContextProvider extends React.Component<Props, State> {
  update: (newUser: any) => void;

  constructor(props: Props) {
    super(props);
    this.state = {
      collapsedMenu: false,
    };
    this.update = newValue => this.setState({ collapsedMenu: newValue });
  }

  render() {
    return (
      <CollapsedMenuContext.Provider value={{ Update: this.update, data: this.state.collapsedMenu }}>
        {this.props.children}
      </CollapsedMenuContext.Provider>
    );
  }
}



