import React from 'react';
import LoginPage from '../Pages/LoginPage';
import AppRoute, { LayoutType } from './models/AppRoute';
import { AdminLayout, ClassicLayout, Col, LoginLayout, Row } from '../components/Layout';
import LogoutPage from 'Pages/LogoutPage';
import { Operations } from './Api/Api';
import NotFoundView from 'components/Error/NotFoundView';
import EmptyLayout from 'components/Layout/EmptyLayout';
import UsersListPage from 'Pages/Admin/UsersListPage';
import RegistrationListPage from 'Pages/Admin/RegistrationListPage';
import RegistrationDetailPage from 'Pages/Admin/RegistrationDetailPage';
import { UserStatusReportPage } from 'Pages/Admin/UserStatusReportPage';
import KnowledgeShareListPage from '../Pages/Admin/KnowledgeShareListPage';
import UsersDetailPage from 'Pages/Admin/UsersDetailPage';
import RolesListPage from 'Pages/Admin/RolesListPage';
import AuditListPage from 'Pages/Admin/AuditListPage';
import AuditDetailsPage from 'Pages/Admin/AuditDetailsPage';
import RolesDetailsPage from 'Pages/Admin/RolesDetailsPage';
import RequestPasswordResetPage from 'Pages/UserProfile/RequestPasswordResetPage';
import ResetPasswordPage from 'Pages/UserProfile/ResetPasswordPage';
import ActivateUserPage from 'Pages/UserProfile/ActivateUserPage';
import KnowledgeListViewPage from 'Pages/Knowledge/KnowledgeListViewPage';
import KnowledgeEditPage from 'Pages/Knowledge/KnowledgeEditPage';
import KnowledgeCategoriesListViewPage from 'Pages/Knowledge/KnowledgeCategoriesListViewPage';
import KnowledgeJurisdictionsListViewPage from 'Pages/Knowledge/KnowledgeJurisdictionsListViewPage';
import KnowledgeJurisdictionsEditPage from 'Pages/Knowledge/KnowledgeJurisdictionsEditPage';
import KnowledgeCategoriesEditPage from 'Pages/Knowledge/KnowledgeCategoriesEditPage';
import KnowledgeDetailsViewPage from 'Pages/Knowledge/KnowledgeDetailsViewPage/KnowledgeDetailsViewPage';
import PublicKnowledgeListViewPage from 'Pages/Knowledge/PublicKnowledgeListViewPage';
import PublicKnowledgeDetailsViewPage from 'Pages/Knowledge/PublicKnowledgeDetailsViewPage';
import KnowledgeTemplateListViewPage from 'Pages/Knowledge/KnowledgeTemplateListViewPage';
import KnowledgeTemplateDetailsViewPage from 'Pages/Knowledge/KnowledgeTemplateDetailsViewPage';
import { KnowledgeSummaryReportPage } from 'Pages/Knowledge/KnowledgeSummaryReportPage';
import { KnowledgeFileReportPage } from 'Pages/Knowledge/KnowledgeFileReportPage';
import { MyProfilePage } from 'Pages/UserProfile/MyProfilePage';
import { UserLoginReportPage } from 'Pages/Admin/AuditListPage/UserLoginReportPage';
import KnowledgeDashboardPage from 'Pages/Knowledge/KnowledgeDashboardPage';
import { AboutUsViewPage } from 'Pages/Information/AboutUsViewPage';
import { FAQPage } from 'Pages/Information/FAQPage';
import { KnowledgeContributorReportPage } from 'Pages/Knowledge/KnowledgeContributorReportPage';
import NoAccessView from 'components/Error/NoAccessView';
import { GrGroup } from 'react-icons/gr';
import { EmbeddedPage } from 'Pages/Information/EmbeddedPage';
import { Icon } from 'antd';
import knowledgeIcon from '../assets/images/knowledge icon.png';
import knowledgeTemplateIcon from '../assets/images/knowledge temp icon.png';
import dashboardIcon from '../assets/images/dashboard icon.png';
import auditIcon from '../assets/images/audit icon.png';
import categoryIcon from '../assets/images/category icon.png';
import jurisdictionIcon from '../assets/images/jury icon.png';
import userDirectoryIcon from '../assets/images/user directory icon.png';
import userIcon from '../assets/images/user icon.png';
import userRolesIcon from '../assets/images/user roles icon.png';
import sharedKnowledgeIcon from '../assets/images/shared knowledge.png';
import knowledgeSharedIcon from '../assets/images/knowledge shared icon.png';
import accessRequestIcon from '../assets/images/access request icon.png';
import { UserDirectoryListPage } from 'Pages/UserProfile/UserDirectoryListPage';
import RegistrationPage from '../Pages/RegistrationPage/RegistrationPage';
import { JurisdictionInfoPage } from 'Pages/Information/JurisdictionInfoPage';
import KnowledgeShareApprovalDetailsPage from 'Pages/Admin/KnowledgeShareApprovalDetailsPage';
import SharedKnowledgeListPage from 'Pages/SharedKnowledge/SharedKnowledgeListPage';
import AddKnowledgeSharePage from 'Pages/SharedKnowledge/AddKnowledgeSharePage';
import AddKnowledgeShareDrawer from 'Pages/SharedKnowledge/AddKnowledgeShareDrawer';
import EmbeddedPublicKnowledgeListViewPage from 'Pages/Knowledge/EmbeddedPublicKnowledgeListViewPage';

import { NavErrorPage } from 'Pages/NavErrorPage';


export const AppRoutes: AppRoute[] = [
  {
    title: 'Dashboard',
    component: () => <h1> Home !</h1>,
    path: '/',
    redirect: 'public-knowledge',
    icon: 'star',
    exact: true,
    skip: true,
    routes: [
      {
        title: 'Embedded',
        icon: <img src={dashboardIcon} />,
        layout: (props: any) => <EmptyLayout {...props} />,
        path: 'public-knowledge/embedded',
        hideInMainMenu: true,
        component: EmbeddedPublicKnowledgeListViewPage,
        routes: [
          {
            title: 'Knowledge',
            path: ':name',
            icon: <img src={knowledgeIcon} />,
            hideInMainMenu: true,
            layout: (props: any) => <EmptyLayout {...props} />,
            exact: true,
            requiredOperations: [],
            component: PublicKnowledgeDetailsViewPage,
          },
        ],
      },
      {
        title: 'Dashboard',
        icon: <img src={dashboardIcon} />,
        layout: (props: any) => <AdminLayout {...props} />,
        path: 'public-knowledge',
        component: KnowledgeDashboardPage,
        routes: [
          {
            title: 'Knowledge',
            path: 'v/:name',
            icon: <img src={knowledgeIcon} />,
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: PublicKnowledgeDetailsViewPage,
          },
          {
            title: 'Knowledge',
            path: 'v/v/:name',
            icon: <img src={knowledgeIcon} />,
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: NavErrorPage,
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'cog',
        path: 'settings',
        hideInMainMenu: true,
        component: () => <h1> settings </h1>,
        routes: [
          {
            title: 'Account',
            icon: <img src={userIcon} />,
            layout: (props: any) => <ClassicLayout {...props} />,
            path: 'account',
            hideInMainMenu: true,
            component: () => <h1> Account </h1>,
          },
        ],
      },
      {
        title: 'User Profile',
        path: '/user-profile',
        icon: 'folder',
        hideInMainMenu: true,
        exact: true,
        component: MyProfilePage,
      },
      {
        title: 'User Profile',
        path: '/user-profile/:name',
        icon: 'folder',
        hideInMainMenu: true,
        exact: true,
        component: MyProfilePage,
      },
      {
        title: 'My Knowledge',
        path: '/knowledge',
        icon: <img src={knowledgeIcon} />,
        hideInMainMenu: false,
        exact: true,
        requiredOperations: [Operations.View_Knowledge],
        component: KnowledgeListViewPage,
        routes: [
          {
            title: 'Knowledge Summary Report',
            path: 'v/reports/knowledge-summary-report',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: KnowledgeSummaryReportPage,
          },
          {
            title: 'Knowledge Storage Report',
            path: 'v/reports/knowledge-storage-report',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: KnowledgeFileReportPage,
          },
          {
            title: 'Knowledge Contributor Report',
            path: 'v/reports/knowledge-contributor-report',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: KnowledgeContributorReportPage,
          },
          {
            title: 'Knowledge Edit',
            path: 's/new',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: KnowledgeEditPage,
          },
          {
            title: 'Knowledge',
            path: ':name',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [],
            component: KnowledgeEditPage,
          },
          {
            title: 'Knowledge',
            path: 'v/:name',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.View_Knowledge],
            component: KnowledgeDetailsViewPage,
          },
        ],
      },
      {
        title: 'Shared Knowledge',
        path: '/shared-knowledge',
        icon: <img src={sharedKnowledgeIcon} />,
        hideInMainMenu: false,
        exact: true,
        requiredOperations: [Operations.Is_Authenticated],
        component: SharedKnowledgeListPage,
      },
      {
        title: 'Knowledge Share',
        path: '/knowledge-share-new',
        icon: 'folder',
        hideInMainMenu: true,
        exact: true,
        component: AddKnowledgeSharePage,
      },
      {
        title: 'Jurisdictions',
        path: '/jurisdictions',
        icon: <img src={jurisdictionIcon} />,
        hideInMainMenu: false,
        exact: true,
        requiredOperations: [],
        component: KnowledgeJurisdictionsListViewPage,
        routes: [
          {
            title: 'Jurisdictions New',
            path: 's/new',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.Add_Edit_Jurisdiction],
            component: KnowledgeJurisdictionsEditPage,
          },
          {
            title: 'Jurisdictions Edit',
            path: ':name',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.Add_Edit_Jurisdiction],
            component: KnowledgeJurisdictionsEditPage,
          },
        ],
      },
      {
        title: 'Categories',
        path: '/categories',
        icon: <img src={categoryIcon} />,
        hideInMainMenu: false,
        exact: true,
        requiredOperations: [Operations.View_Knowledge_Categories],
        component: KnowledgeCategoriesListViewPage,
        routes: [
          {
            title: 'Knowledge Categories New',
            path: 's/new',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.Edit_Knowledge_Categories],
            component: KnowledgeCategoriesEditPage,
          },
          {
            title: 'Knowledge Categories Edit',
            path: ':name',
            icon: 'folder',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.Edit_Knowledge_Categories],
            component: KnowledgeCategoriesEditPage,
          },
        ],
      },
      {
        title: 'Knowledge Template',
        path: '/templates',
        icon: <img src={knowledgeTemplateIcon} />,
        hideInMainMenu: false,
        exact: true,
        requiredOperations: [Operations.View_Knowledge_Template],
        component: KnowledgeTemplateListViewPage,
        routes: [
          {
            title: 'Knowledge Template New',
            path: 's/new',
            icon: 'snippets',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.Create_Knowledge_Data_Template],
            component: KnowledgeTemplateDetailsViewPage,
          },
          {
            title: 'Knowledge Template Edit',
            path: ':name',
            icon: 'snippets',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.Edit_Knowledge_Data_Template],
            component: KnowledgeTemplateDetailsViewPage,
          },
        ],
      },
      {
        title: 'Knowledge Share Requests',
        path: 'knowledge-share',
        icon: <img src={knowledgeSharedIcon} />,
        hideInMainMenu: false,
        requiredOperations: [Operations.View_Shared_Knowledge],
        exact: true,
        component: KnowledgeShareListPage,
        routes: [
          {
            title: 'Knowledge Share Request Details',
            path: ':name',
            icon: 'user',
            hideInMainMenu: true,
            requiredOperations: [Operations.View_Shared_Knowledge],
            exact: true,
            component: KnowledgeShareApprovalDetailsPage,
          },
          {
            title: 'Knowledge Share Request Details',
            path: 'l/:uid',
            icon: 'user',
            hideInMainMenu: true,
            requiredOperations: [Operations.View_Shared_Knowledge],
            exact: true,
            component: KnowledgeShareApprovalDetailsPage,
          },
        ]
      },
      {
        title: 'Admin',
        path: '/admin',
        icon: 'user',
        exact: true,
        skip: true,
        layout: (props: any) => <AdminLayout {...props} />,
        redirect: '/admin/dashboard',
        requiredOperations: [],
        routes: [
          {
            title: 'Users',
            path: 'users',
            icon: <img src={userIcon} />,
            exact: true,
            requiredOperations: [Operations.View_User],
            component: UsersListPage,
            routes: [
              {
                title: 'Reports',
                path: 'v/reports',
                icon: 'user',
                exact: true,
                hideInMainMenu: true,
                requiredOperations: [Operations.Generate_User_Reports],
                component: UserStatusReportPage,
              }
            ]
          },
          {
            title: 'Access Requests',
            path: 'registrations',
            icon: <img src={accessRequestIcon} />,

            hideInMainMenu: false,
            requiredOperations: [Operations.View_User],
            exact: true,
            component: RegistrationListPage,
          },
          {
            title: 'Access Request Details',
            path: 'registrations/:name',
            icon: 'user',
            hideInMainMenu: true,
            requiredOperations: [Operations.View_User],
            exact: true,
            component: RegistrationDetailPage,
          },
          {
            title: 'Access Request Details',
            path: 'registrations/l/:uid',
            icon: 'user',
            hideInMainMenu: true,
            requiredOperations: [Operations.View_User],
            exact: true,
            component: RegistrationDetailPage,
          },
          {
            title: 'User Details',
            path: 'users/:name',
            icon: 'user',
            hideInMainMenu: true,
            requiredOperations: [Operations.Edit_User],
            exact: true,
            component: UsersDetailPage,
          },
          {
            title: 'Roles',
            path: 'roles',
            icon: <img src={userRolesIcon} />,
            exact: true,
            requiredOperations: [Operations.View_Roles],
            component: RolesListPage,
          },
          {
            title: 'Role Details',
            path: 'roles/:name',
            icon: 'usergroup-add',
            hideInMainMenu: true,
            requiredOperations: [Operations.Edit_Role],
            exact: true,
            component: RolesDetailsPage,
          },
          {
            title: 'Audit',
            path: 'audits',
            icon: <img src={auditIcon} />,
            exact: true,
            requiredOperations: [Operations.View_Audit],
            component: AuditListPage,
          },
          {
            title: 'User Login Report',
            path: 'audits/v/user-login-report',
            icon: 'time',
            exact: true,
            hideInMainMenu: true,
            requiredOperations: [Operations.Generate_User_Login_Reports],
            component: UserLoginReportPage,
          },
          {
            title: 'Audit',
            path: 'audits/:id',
            icon: 'clock-circle',
            hideInMainMenu: true,
            exact: true,
            requiredOperations: [Operations.View_Audit],
            component: AuditDetailsPage,
          },
        ],
      },
    ],
  },
  {
    title: 'Reset Password',
    path: '/user/reset-password',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <LoginLayout {...props} />,
    component: RequestPasswordResetPage,
  },
  {
    title: 'Reset Password',
    path: '/user/reset-password-confirm',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <LoginLayout {...props} />,
    component: ResetPasswordPage,
  },
  {
    title: 'Request Access',
    path: '/register',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <LoginLayout {...props} />,
    component: RegistrationPage,
  },
  {
    title: 'About Us',
    path: '/about-us',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <AdminLayout {...props} />,
    component: AboutUsViewPage,
  },
  {
    title: 'FAQ',
    path: '/faq',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <AdminLayout {...props} />,
    component: FAQPage,
  },
  {
    title: 'User Directory',
    path: '/user-directory',
    icon: <img src={userDirectoryIcon} />,
    exact: true,
    hideInMainMenu: false,
    requiredOperations: [Operations.Is_Authenticated],
    layout: (props: any) => <AdminLayout {...props} />,
    component: UserDirectoryListPage
  },
  // {
  //   title: 'Jurisdictions',
  //   path: '/jurisdictions',
  //   icon: 'power',
  //   exact: true,
  //   hideInMainMenu: true,
  //   layout: (props: any) => <AdminLayout {...props} />,
  //   component: JurisdictionInfoPage,
  // },
  {
    title: 'Activate User',
    path: '/user/confirm',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <LoginLayout {...props} />,
    component: ActivateUserPage,
  },
  {
    title: 'Logout',
    path: '/logout',
    icon: 'power',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <LoginLayout {...props} />,
    component: LogoutPage,
  },
  {
    title: 'Login',
    path: '/login',
    icon: 'up',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <LoginLayout {...props} />,
    component: LoginPage,
  },
  {
    title: 'Jurist Project',
    path: '/jurist-project',
    icon: 'up',
    exact: true,
    hideInMainMenu: true,
    layout: (props: any) => <AdminLayout {...props} />,
    component: EmbeddedPage,
  },
  {
    title: '403',
    path: '/403',
    hideInMainMenu: true,
    layout: (props: any) => <EmptyLayout {...props} />,
    component: NoAccessView,
  },
  {
    title: '404',
    path: '/*',
    hideInMainMenu: true,
    layout: (props: any) => <EmptyLayout {...props} />,
    component: NotFoundView,
  },
];
