import * as React from 'react';
import AuditDetailsView from 'components/Admin/AuditDetailsView';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class AuditDetailsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Audit Details">
        <AuditDetailsView />
      </PageContainer>
    );
  }
}
