import * as React from 'react';
import UserListView from 'components/Admin/UserListView/UserListView';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class UsersListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Users">
        <UserListView />
      </PageContainer>
    );
  }
}
