import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { IActivateUserCommandRequest, ActivateUserCommandRequest, GetUserProfileQueryRequest, ChangePasswordCommandRequest, IChangePasswordCommandRequest, IEditUserProfileCommandRequest, EditUserProfileCommandRequest, GetAllUserProfileByFilterQueryRequest, IGetAllUserProfileByFilterQueryRequest, UserProfileListItemModel, IUserProfileListItemModel } from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class ProfileHandler implements ITableDataFetcher<IUserProfileListItemModel> {

  constructor(params: any) {

  }
  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<IUserProfileListItemModel>> {
    return this.GetUserProfiles(tableRequest);
  }

  GetMyProfile() {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    return client.getMyUserProfile();
  }

  GetProfile(id: string, showLoading: boolean = true, handleGeneralError: boolean = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    let request = new GetUserProfileQueryRequest({ userNameOrId: id })
    return client.getUserProfile(request);
  }

  EditProfile(model: IEditUserProfileCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new EditUserProfileCommandRequest(model)
    return client.editUserProfile(request);
  }

  ChangePassword(model: IChangePasswordCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new ChangePasswordCommandRequest(model)
    return client.changePassword(request);
  }

  GetUserProfiles(model: IGetAllUserProfileByFilterQueryRequest, showLoading = true, handleGeneralError = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    let request = new GetAllUserProfileByFilterQueryRequest(model)
    return client.getAllUserProfileByFilter(request);

  }

}
