import { Card } from 'antd';
import { IUserRegistrationApprovalModel } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import moment from 'moment';
import React from 'react';
import ConfigService from 'Core/ConfigService';

interface Props {
  approval?: IUserRegistrationApprovalModel;
  roles?: any[];
}

export default ({ approval, roles }: Props) => {

  return (<Card style={{ backgroundColor: 'white', marginTop: '10px' }} title={approval?.approvalType} extra={Utility.renderDateWithReference(moment(approval?.creationDate), ConfigService.getSync().Display.DateTimeFormat)} >
    <p>Comment: {approval?.comment}</p>
    {!!approval?.roles?.length && roles && <p>Selected Role: {approval.roles?.map(x => roles!.find(y => y.id == x)?.name)}</p>}
    <p>By: {approval?.approverName}</p>
    <Card.Meta ></Card.Meta>
  </Card>);
}