import { Row, Col, Collapse } from 'antd';
import React from 'react';

const { Panel } = Collapse;

interface Props {
}

export default ({ }: Props) => {
    return (<Row>
        <Col md={16}>
            <Collapse defaultActiveKey={['1']} accordion>
                <Panel header={<h2>Searching & Viewing Knowledge</h2>} key="1">
                    <ol>
                        <li>Use basic search for knowledge, public or otherwise classified knowledge</li>
                        <li>Use advanced search for public knowledge, public or otherwise classified knowledge</li>
                    </ol>
                    <h2>Basic Search</h2>
                    <p>Objective - Use Basic <b>Search</b> ​for knowledge, public or otherwise classified knowledge.
                        <br />
                    Step 1. Enter Search Criteria in the search box as seen in diagram (a) - test search phrase = impact Diagram a.</p>

                    <p>
                        <b>Key tips to remember when searching for knowledge.
                        <ol>
                                <li>Ensure that your role has adequate permissions to view desired level.</li>
                                <li>Ensure proper search criteria is populated in respective categories.</li>
                            </ol>
                        </b>
                    </p>
                    <h2>Advanced Search for Non-Account Holders</h2>
                    <p>Objective - Use <b>Advanced Search</b>​ for public knowledge, public or otherwise classified knowledge.</p>
                    <p><b>Understanding your basic searchability:-</b> The KMS has been designed with a multi-pronged,
                        robust approach to searching which allows the user to search for knowledge uploaded on the KMS
                        as well as knowledge gathered about the KMS, however for the purposes of the NON-ACCOUNT HOLDER SEARCH tutorial,
                        we will only focus on the information uploaded on the KMS. The knowledge can be searched using a range of search
                        criteria making your search more accurate, the search can be done using the entire range of criteria or just a few
                        or just one, depending on your need. Read more on diagram b.</p>
                    <ol>
                        <li>Select Advanced Search as is highlighted in Diagram a </li>
                        <li>Select your search criteria by selecting one or more of the search criteria seen in diagram b
                            <p>
                                <ol type="a">
                                    <li><b>Filter Type</b>​, whose options are “<b>​AND</b>​” or “<b>​OR</b>​”</li>
                                    <li><b>Search Title</b></li>
                                    <li>Select by ​<b>Category</b></li>
                                    <li> Select by <b>​File Type</b></li>
                                    <li>Select by ​<b>Jurisdiction</b></li>
                                    <li>Select by ​<b>Date</b></li>
                                    <li>Select by ​<b>Search Description</b></li>
                                    <li>Or ​<b>Search Document</b> ​(<i>search a phrase inside of the document</i>)</li>
                                </ol>
                            </p>
                        </li>
                    </ol>
                </Panel>



                <Panel header={<h2>Requesting Account Creation</h2>} key="2">
                    <p>When using the KMS there are generally two options to requesting the creation of a new account.</p>
                    <p>
                        <ol>
                            <li>Option 1.</li>
                            <li>Option 2. Use "Create account" link</li>
                        </ol>
                        <h2>Option 1 - Create an account when prompted to do so</h2>
                        <p>
                            <ul>
                                <li>Follow the link to sign in or create an account when prompted to do so.</li>
                            </ul>
                        </p>
                        <p>
                            1. The first process is prompted when the user attempts to access information that
                            cannot be viewed by non-account holders. The user will be presented with a dialogue
                            box that requests that they sign in, or if they are not an account holder, they
                            will have the opportunity to start the account creation process. This process will
                            be approved or denied by administrators within the jurisdiction based on their
                            internal policies. Please see the image below labelled ‘diagram a’ which depicts
                            the first request for a new account creation option that is mentioned above.
                            Diagram a

                        </p>
                        <h2>Option 2 -  Use the "Create account" link located next to the Login button</h2>
                        <p>
                            The second process is prompted when the user for their specific reason requests
                            that an account be created for them. If the user clicks on the Login button located
                            at the top right hand side of the screen, they will be taken to the login/request
                            access screen. Above the login button is a 'Request Access' link that makes a
                            request on the users behalf, while inviting the user to populate a form with
                            information pertinent to the creation of an account. This process will be
                            approved or denied by administrators within the jurisdiction based on their
                            internal policies.

                        </p>
                    </p>
                </Panel>

                <Panel header={<h2>How to Create Knowledge</h2>} key="3">
                    <p>
                        To create a new knowledge there are some prerequisites that must be followed. Note if
                        the appropriate Category and Knowledge Category Template already exists, a user with the
                        permission to create knowledge can skip step 1 and 2 below and proceed to 3.
                        </p>
                    <ol>
                        <li>Create a Knowledge Category.</li>
                        <li>Create a Knowledge Category Template.</li>
                        <li>Then Create the Knowledge</li>
                    </ol>
                    <h2>Create a Knowledge Category</h2>
                    <p>A user with the required permissions can create a knowledge category that will be used when creating knowledge.</p>
                    <ol>
                        <li>Using the main menu to the left of the screen click on the Category icon</li>
                        <li>Then click the ‘New Category’ button to the top left of the Category page. This will take you to the page to create a Category</li>
                        <li>Enter the Category name, and description then click Submit.</li>
                        <li>To edit an existing category, select the edit link to the far right of the existing category in the list. Then repeat step 3 above.</li>
                    </ol>
                    <h2>Create a Knowledge Category Template</h2>
                    <p>The category template is a dynamic way for the user to create a section that captures the important details of a knowledge for quick viewing and the ability to search more effectively.</p>
                    <ol>
                        <li>Using the main menu to the left of the screen click on Knowledge Template</li>
                        <li>Click on New Template</li>
                        <li>Enter Required information, Name, Description, Category.</li>
                        <li>Select the appropriate template fields to be used for the information you would like to capture.
                            <p>
                                <ol type="a">
                                    <li>Note that template fields will vary based on the information required to be captured
                                        <ul>
                                            <li>Text fields (single & multi column)</li>
                                            <li>Dropdown select boxes</li>
                                            <li>Check boxes & radio buttons</li>
                                            <li>And more</li>
                                        </ul>
                                    </li>
                                </ol>
                            </p>
                        </li>
                        <li>Click Submit to complete your newly created template.</li>
                        <li>To edit an existing template, select the edit link to the far right of the existing template in the list. Then repeat steps 3 through 5 above.</li>
                    </ol>
                    <h2>Create Knowledge</h2>
                    <ol>
                        <li>Using the main menu to the left of the screen click on Knowledge</li>
                        <li>Click on New Knowledge</li>
                        <li>Populate the required fields
                            <ul>
                                <li>Title</li>
                                <li>Category</li>
                                <li>Access Level - Determines which user roles can view this knowledge, depending on their roles access level.</li>
                                <li>Description Type</li>
                                <li>Description</li>
                                <li>Click to upload an attachment or several attachments.</li>
                            </ul>
                        </li>
                        <li>Click on submit</li>
                    </ol>
                    <h2>Update Knowledge (edit / publish / unpublish / delete)</h2>
                    <p>Depending on a user's permissions, a user may have the ability to edit, publish, unpublish or even delete knowledge.</p>
                    <ol>
                        <li>Perform a search for your recently created knowledge.</li>
                        <li>Click on Edit. This will open the Knowledge where you can make necessary changes.
                            <ol>
                                <li>If the knowledge was not yet published, then click on Publish to publish knowledge.</li>
                                <li>If the knowledge was published, then there will be an option to unpublish the knowledge. (unpublished knowledge, will be hidden from other users)</li>
                                <li>If the user has the ability to delete knowledge, then will see a delete button to which they can use. (deleted knowledge, will be removed)</li>
                            </ol>
                        </li>
                        <li>Make any necessary changes to the fields on the screen to update the knowledge</li>
                        <li>Click submit to complete the changes.</li>
                    </ol>
                </Panel>
            </Collapse>
        </Col>

    </Row>);
}