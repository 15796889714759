import * as React from 'react';
import { Modal } from 'antd';
import KnowledgeCategoryDetailsForm from 'components/Knowledge/KnowledgeCategoryDetailsForm';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class KnowledgeCategoriesEditPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Knowledge Category Details">
        <KnowledgeCategoryDetailsForm />
      </PageContainer>
    );
  }
}
