import * as React from 'react';
import { Col, Layout, Row } from 'antd';
import { AppRoutes } from '../../../../Core/AppRoutes';
import logo from '../../../assets/images/logo.svg';
import AppRoute from '../../../../Core/models/AppRoute';
import AppMenu from '../../AppMenu';
import Logo from '../../../Styled/Logo';
import { CollapseType } from 'antd/lib/layout/Sider';
import CollapsedMenuContext from 'Context/CollapsedMenuContext';
import { SlimSider } from 'components/Styled/Layout/SlimSider';
import styled from 'styled-components';
import LogoPng from '../../../../assets/images/logo.png';
import { NavLink } from 'react-router-dom';

const { Sider } = Layout;
export interface Props {
  children?: React.ReactNode;
  menu: AppRoute[];
}

export interface State {
}

export const MainLogo = styled.div`
  background-color: white;  
  background-image: url(${LogoPng});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 73% 73%;
`;

export default class Sidebar extends React.Component<Props, State> {
  static contextType = CollapsedMenuContext;
  context!: React.ContextType<typeof CollapsedMenuContext>;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }



  onCollapsedClick = (collapsed: boolean, type: CollapseType) => {
    this.context.Update(collapsed);
  }

  render() {
    return (
      <>
        <SlimSider width={100} collapsed={this.context.data} onCollapse={this.onCollapsedClick} theme="dark" >
          <NavLink to={"/"}><MainLogo style={{ height: "115px", width: "180px" }}></MainLogo></NavLink>
          <AppMenu mode="inline" inlineCollapsed={this.context.data} onlySubMenuNavigates={true} menu={this.props.menu} />
        </SlimSider>
      </>
    );
  }
}
