import ITableDataFetcher from '../../components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from '../../components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
  GetFilteredJurisdictionsQueryRequest,
  KnowledgeCategory,
  IAddJurisdictionCommandRequest,
  AddJurisdictionCommandRequest,
  IEditJurisdictionCommandRequest,
  EditJurisdictionCommandRequest,
  GetJurisdictionByIdQueryRequest,
  Jurisdiction,
  IRemoveJurisdictionCommandRequest,
} from '../../Core/Api/Api';
import { SecuredClient } from '../../Core/Api/SecuredClient';
import TableRequest from '../../Core/TableUtility/Models/TableRequest';

export default class KnowledgeJurisdictionsHandler implements ITableDataFetcher<Jurisdiction> {
  constructor(props?: any) {}

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<Jurisdiction>> {
    return this.getAllJurisdictionsByFilter(tableRequest);
  }

  getAllJurisdictionsByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllAuditHeaderByFilterModel = new GetFilteredJurisdictionsQueryRequest(request);
    var result = client.getFilteredJurisdictions(getAllAuditHeaderByFilterModel);
    return result;
  }

  GetJurisdictionDetailsById(id: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetJurisdictionByIdQueryRequest();
    request.id = id;
    var result = client.getJurisdictionById(request);
    return result;
  }

  AddJurisdiction(values: IAddJurisdictionCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    var request = new AddJurisdictionCommandRequest(values);
    return client.addJurisdiction(request);
  }

  EditJurisdiction(values: IEditJurisdictionCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditJurisdictionCommandRequest(values);
    return client.editJurisdiction(request);
  }
  RemoveJurisdiction(values: IRemoveJurisdictionCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditJurisdictionCommandRequest(values);
    return client.removeJurisdiction(request);
  }
}
