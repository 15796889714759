import React from 'react';
import { InputProps } from 'antd/lib/input';
import ReactInputMask, { InputState, MaskOptions } from 'react-input-mask';
import NumberFormat, { NumberFormatProps, NumberFormatValues } from 'react-number-format';

export interface Props extends Omit<NumberFormatProps, 'onChange'> {
  value?: any;
  onChange?: Function;
}

export interface State {
  classNames?: string;
}

export default class MaskedInputV2 extends React.Component<Props, State> {

  constructor(props: Props) {
    super(props);

    this.state = { classNames: 'ant-input ' + this.props.className };
  }

  onValueChange = (values: NumberFormatValues) => {
    if (this.props.onChange) {
      this.props.onChange?.call(undefined, values.value);

    }
  }

  render() {
    const { className, onChange, ...rest } = this.props;
    return <NumberFormat className={this.state.classNames} isNumericString={true} defaultValue={this.props.value} onValueChange={this.onValueChange} {...(rest as any)} />;
  }
}
