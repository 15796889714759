import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
  GetAllCategoryByFilterQueryRequest,
  IAddCategoryCommandRequest,
  AddCategoryCommandRequest,
  IEditCategoryCommandRequest,
  EditCategoryCommandRequest,
  GetCategoryByIdQueryRequest,
  GetKnowledgeDataTemplateByIdCommandRequest,
  GetAllKnowledgeDataTemplatesByFilterQueryRequest,
  CreateKnowledgeDataTemplateCommandRequest,
  ICreateKnowledgeDataTemplateCommandRequest,
  EditKnowledgeDataTemplateCommandRequest,
  KnowledgeDataTemplate,
  OrderBy,
} from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class KnowledgeTemplateHandler implements ITableDataFetcher<KnowledgeDataTemplate> {
  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<KnowledgeDataTemplate>> {
    return this.GetAllKnowledgeTemplatesByFilter(tableRequest);
  }

  GetAllKnowledgeTemplatesByFilter(model: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllKnowledgeDataTemplatesByFilterQueryRequest(model);
    request.includeCategory = true;
    return client.getAllKnowledgeDataTemplateByFilter(request);
  }

  GetTop100KnowledgeCategories() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllCategoryByFilterQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'name', ascending: false }));
    return client.getAllCategoryByFilter(request);
  }

  GetKnowledgeTemplateDetailsById(id: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeDataTemplateByIdCommandRequest();
    request.id = id;
    return client.getKnowledgeDataTemplateById(request);
  }

  AddKnowledgeTemplate(values: ICreateKnowledgeDataTemplateCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    var request = new CreateKnowledgeDataTemplateCommandRequest(values);
    return client.createKnowledgeDataTemplate(request);
  }

  EditKnowledgeTemplate(values: IEditCategoryCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditKnowledgeDataTemplateCommandRequest(values);
    return client.editKnowledgeDataTemplate(request);
  }
}
