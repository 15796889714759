import * as React from "react";
import { Filter, IFilter, Knowledge } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';

import { List } from "antd";
import { KnowledgeListItem } from "components/Knowledge/KnowledgeListView/KnowledgeListTable";
import PublicKnowledgeHandler from "components/Knowledge/PublicKnowledgeHandler";


export interface Props {
    children?: React.ReactNode;
}

export interface State {
}

export default class PublicKnowledgeList extends React.Component<Props, State> {
    handler: PublicKnowledgeHandler;

    constructor(props: Props) {
        super(props)

        this.state = {
        }
        this.handler = new PublicKnowledgeHandler(props);

    }

    render() {
        return (
            <SimpleViewList<Knowledge>
                grid={{ gutter: 12, sm: 1, md: 1, lg: 2 }}
                dataFetcher={this.handler}
                pagination={{ position: "both", showTotal: (x) => `${x} Results`, pageSize: 10, showSizeChanger: true }}
                renderItem={x => <List.Item>
                    <KnowledgeListItem knowledge={x} isOnPublic={true} />
                </List.Item>} />
        )
    }
}
