import { IActivateUserCommandRequest, ActivateUserCommandRequest } from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';

export default class ActivateUserHandler {
  ActivateUser(model: IActivateUserCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new ActivateUserCommandRequest(model);

    return client.activateUser(request);
  }
}
