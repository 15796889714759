import * as React from 'react';
import Table, { ColumnProps } from 'antd/lib/table';
import { IAuditDetail } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import AuditHandler from 'components/Admin/AuditHandler';
export interface Props {
  children?: React.ReactNode;
  details: IAuditDetail[];
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
}

export default class AuditDetailsTable extends React.Component<Props, State> {
  handler: AuditHandler;
  config = ConfigService.getSync();

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
    };

    this.handler = new AuditHandler(props);
  }

  componentDidMount() {
    this.handler.GetTop100Jurisdictions().then(x => {
      const jurisdictions: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions });
    });
  }

  auditDetailsTableColumns: Array<ColumnProps<IAuditDetail>> = [
    {
      title: 'Event',
      dataIndex: 'auditEvent',
    },
    {
      title: 'Entity',
      dataIndex: 'entityName',
    },
    {
      title: 'fieldName',
      dataIndex: 'fieldName',
    },
    {
      title: 'Old',
      dataIndex: 'oldValue',
    },
    {
      title: 'New',
      dataIndex: 'newValue',
    },
  ];

  render() {
    return (
      <Table<IAuditDetail>
        dataSource={this.props.details}
        columns={this.auditDetailsTableColumns}
      />
    );
  }
}
