import * as React from 'react';
import LogoImg from '../../../assets/images/logo_bar.png';
import { NavLink } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';
import { Input, Select } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import SimpleSelect from 'components/Forms/SimpleSelect';

export const DisplayInput = styled(Input)`
  &:disabled{
    background-color: #F0F2F5 ;
    color: black;
  }
`;

export const DisplayTextArea = styled(TextArea)`
  &:disabled{
    background-color: #F0F2F5 ;
    color: black;
  }
`;

export const DisplaySelect = styled(SimpleSelect)`
  &:disabled{
    background-color: #F0F2F5 ;
    color: black;
  }
`;