import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Role, RoleListView, Operations } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import UserHandler from '../../UserHandler';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import RoleHandler from 'components/Admin/RoleHandler';
import DetailsData from 'Core/models/DetailsData';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { NavButton } from 'components/Forms/NavButton';
import { Row, Col, Button, Icon } from 'antd';
import { ScrollableTable } from '../../../Forms/DataGrid/SimpleTable/SimpleTable.Styled';
export interface Props {
    children?: React.ReactNode;
}

export interface State {
    countries: IFilterSelectOption[];
    jurisdictions: IFilterSelectOption[];
    roles: IFilterSelectOption[];
}

export default class RolesListTable extends React.Component<Props, State> {
    handler: RoleHandler;
    tableRef = React.createRef<SimpleTable<any>>();

    constructor(props: Props) {
        super(props);

        this.state = {
            countries: [],
            jurisdictions: [],
            roles: [],
        };
        this.handler = new RoleHandler(props);
    }

    removeRole = (role: Role): Promise<any> => {
        return this.handler.removeRole({ id: role.id }).then(x => {
            NotificationService.success(`Removed "${role.name}" role `);
            this.tableRef.current?.refresh();
        });
    };

    componentDidMount() {
        this.handler.GetTop100Jurisdictions().then(x => {
            const jurisdictions: IFilterSelectOption[] =
                x.results?.map(y => ({
                    text: y.name,
                    value: y.id,
                })) ?? [];
            this.setState({ jurisdictions });
        });
    }

    rolesListTableColumns: Array<ColumnProps<RoleListView>> = [
        {
            title: 'Name',
            dataIndex: 'name',
            sorter: true,
        },
        {
            title: 'Description',
            dataIndex: 'description',
            sorter: true,
        },
        {
            title: 'Jurisdiction',
            dataIndex: 'jurisdictionName',
        },
        {
            title: 'Status',
            dataIndex: 'status',
        },
        {
            title: '',
            width: '200px',
            render: (text: any, record: Role, index: number) => {
                return (
                    <Row type="flex" justify="space-between">
                        <Col >
                            <ShowIfHavePermission operations={[Operations.Edit_Role]}>
                                <NavButton
                                    type="default"
                                    to={{
                                        pathname: 'roles/' + encodeURIComponent(record.name ?? ""),
                                        state: new DetailsData(DetailsMode.Edit, record.id),
                                    }}>
                                    <Icon type="edit" />
                                </NavButton>
                            </ShowIfHavePermission>
                        </Col>
                        <Col >
                            <ShowIfHavePermission operations={[Operations.Remove_Role]}>
                                <ConfirmRemoveButton warningMessage={<><p>Remove Role?</p></>} onConfirm={() => this.removeRole(record)}>
                                    <Icon type="delete" />
                                </ConfirmRemoveButton>
                            </ShowIfHavePermission>
                        </Col>
                    </Row >
                );
            },
        },
    ];

    render() {
        return (
            <ScrollableTable>
                <SimpleTable<RoleListView>
                    tableKey="id"
                    ref={this.tableRef}
                    dataFetcher={this.handler}
                    columns={this.rolesListTableColumns}
                />
            </ScrollableTable>

        );
    }
}
