import * as React from 'react';
import PageContainer from 'components/Layout/PageContainer';
import KnowledgeCategoriesListView from 'components/Knowledge/KnowledgeCategoriesListView';
import KnowledgeTemplateListView from 'components/Knowledge/KnowledgeTemplateListView';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class KnowledgeTemplateListViewPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Knowledge Template">
        <KnowledgeTemplateListView />
      </PageContainer>
    );
  }
}
