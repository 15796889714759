import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import AuthContext from 'Context/AuthContext';
import Logout from 'components/UserManagement/Logout';
export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class LogoutPageWithoutRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    this.context.Update(undefined);
  }

  render() {
    return <Logout />;
  }
}

const LogoutPage = withRouter(LogoutPageWithoutRouter);
export default LogoutPage;



