import { GetFilteredJurisdictionsQueryRequest } from "Core/Api/Api";
import { SecuredClient } from "Core/Api/SecuredClient";
import TableRequest from "Core/TableUtility/Models/TableRequest";


export default class JurisdictionHandler {
  constructor(props?: any) { }


  GetJurisdictions() {
    const client = new SecuredClient();
    const request: TableRequest = { amountPerPage: 1000 };
    client.showLoading = false;
    client.handleGeneralError = false;
    const model = new GetFilteredJurisdictionsQueryRequest(request);
    return client.getFilteredJurisdictions(model);
  }
}
