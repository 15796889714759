import PageContainer from 'components/Layout/PageContainer';
import { ProfileView } from 'components/UserProfile/ProfileView';
import UserDirectoryListView from 'components/UserProfile/UserDirectoryListView';
import React from 'react';


interface Props {
}

export default ({ }: Props) => {
  return (<PageContainer>
    <ProfileView />
  </PageContainer>);
}