import React, { FunctionComponent } from 'react';
import { Avatar, Badge, Menu, Icon } from 'antd';
import { NavLink } from 'react-router-dom';
import MenuAvatar from '../MenuAvatar';
import styled from 'styled-components';
import UserIconSvg from '../../../assets/images/user.svg';
import ScreenHelper from 'components/Styled/ScreenHelper';

const StyledNavLink = styled(NavLink)`
${ScreenHelper.down('sm')} {
     i {
       margin-top:60px;
     }
    }
`;

const LoginBar: FunctionComponent = () => {
  return (
    <StyledNavLink style={{ fontWeight: 700, fontSize: '16px', textDecoration: "none" }} to="/login">
      <Icon type="login" />
    Login
    </StyledNavLink>
  );
};

export default LoginBar;



