import { Button, Icon, Row, Spin } from 'antd';
import SimpleSelect from 'components/Forms/SimpleSelect';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import PublicKnowledgeHandler from 'components/Knowledge/PublicKnowledgeHandler';
import { FilterType, IFilter, JoinType, Knowledge } from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import Utility from 'Core/Utility';
import { debounce } from 'lodash';
import React, { useState } from 'react';

interface Props {
  knowledgeSelected: (knowledge: Knowledge) => void
  filteredJurisdictionId?: string;
}

let basicSearchFields = ["title", "description", "templateSearchData", "category.Name"];

export default (props: Props) => {
  let [searchedKnowledges, setSearchedKnowledges] = useState<Knowledge[] | undefined>(undefined);
  let [selectedKnowledge, setSelectedKnowledge] = useState<Knowledge | undefined>(undefined);
  let [options, setOptions] = useState<ISelectOption[] | undefined>(undefined);
  let [fetching, setFetching] = useState<boolean>(false);

  let handler = new PublicKnowledgeHandler(props);

  let searchKnowledge = debounce((value?: string) => {

    if (!value) {
      setOptions([]);
      setSearchedKnowledges([]);
      return;
    }

    let request = Utility.CreateTableRequest();

    let nameFilter: IFilter = {
      field: "description",
      filterType: FilterType.Group,
      groupFilters: []
    }
    basicSearchFields.forEach(x => nameFilter.groupFilters!.push({ field: x, filterType: FilterType.Contains, value: value, joinType: JoinType.Or }))
    request.filters!.push(nameFilter);

    if (props.filteredJurisdictionId) {
      request!.filters!.push({ field: "jurisdictionId", filterType: FilterType.Equal, value: props.filteredJurisdictionId, joinType: JoinType.And });
    }

    setFetching(true);
    handler.GetData(request).then(x => {

      let options: ISelectOption[] | undefined = x.results?.map(knowledge => ({
        value: knowledge.id,
        text: `${knowledge.title}`
      }));

      setSearchedKnowledges(x.results);
      setOptions(options);
    }, x => {
      setOptions([]);
      setSearchedKnowledges([]);
    }).finally(() => setFetching(false));
  }, 800);

  let valueChanged = (value?: string) => {
    if (!value) {
      setSelectedKnowledge(undefined);
    } else {

      let selectedKnowledge = searchedKnowledges?.find(x => x.id == value);

      if (selectedKnowledge) {
        setSelectedKnowledge(selectedKnowledge);
      }
    }
  }

  let knowledgeSelected = () => {
    if (selectedKnowledge) {
      props.knowledgeSelected?.call(undefined, selectedKnowledge);
    }
  }

  let renderNoContent = () => {
    return fetching ? <Spin size="small" /> : <>No Knowledge found</>;
  }

  let renderSuffixButton = () => {
    return (
      <Button type="primary" style={{ display: "inline-block" }} icon="plus" onClick={x => knowledgeSelected()} />
    )
  }

  return (
    <><SimpleSelect style={{ width: "92%", display: "inline-block" }} notFoundContent={renderNoContent()} options={options} onSearch={searchKnowledge} onChange={valueChanged}></SimpleSelect>{renderSuffixButton()}</>
  );
}