import { Layout, Row } from 'antd';
import * as React from 'react';
import Sidebar from '../AdminLayout/Sidebar';
import { RoutesRenderer } from '../MainContent';
import ILayout from '../../../Core/models/ILayout';
const { Header, Content, Footer } = Layout;

export interface Props {
  children?: React.ReactNode;
  layout: React.Component<ILayout>;
}

export interface State {}

export default class MainContent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Content>
        <Row type="flex" align="middle" justify="center" />
      </Content>
    );
  }
}



