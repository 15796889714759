import * as React from 'react';
import { Row, Col, Button, Descriptions } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuditHandler from '../AuditHandler';
import NotificationService from 'Core/NotificationService';
import { IAuditHeader } from 'Core/Api/Api';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import { AuditDetailsTable } from '.';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  auditHeader: IAuditHeader;
}

export class AuditDetailsViewWithoutRouter extends React.Component<Props, State> {
  handler: AuditHandler;
  config = ConfigService.getSync();

  constructor(props: Props) {
    super(props);

    this.state = { auditHeader: {} };
    this.handler = new AuditHandler(props);
  }

  componentDidMount() {
    this.GetAuditDetails();
  }

  GetAuditDetails = () => {
    const urlState = this.props.location.state as DetailsData | undefined;

    const id = urlState?.id ?? this.props.match.params['id'];

    if (!id) {
      NotificationService.error('Audit Record not found');
    }

    this.handler.GetAuditDetailsById(id).then(x => {
      this.setState({ auditHeader: x.header ?? {} });
    });
  };

  render() {
    const header = this.state.auditHeader;
    const auditMoment = moment(header.auditTime);
    return (
      <>
        <Row>
          <Col sm={12} xs={12}>
            <Descriptions
              column={2}
              title={`${header.operation} - ${header.auditedUserName ?? 'Guest'}`}>
              <Descriptions.Item label="Operation" span={2}>
                {header.operation}
              </Descriptions.Item>
              <Descriptions.Item label="Time">
                <p title={auditMoment.fromNow()}>
                  {auditMoment.format(this.config.Display.DateTimeFormat)}
                </p>
              </Descriptions.Item>
              <Descriptions.Item label="User">{header.auditedUserName}</Descriptions.Item>
              <Descriptions.Item label="Status">{header.auditStatus}</Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <AuditDetailsTable details={header.auditDetails ?? []} />
          </Col>
        </Row>
        <Row style={{ marginTop: "20px" }}>
          <Col md={3}>
            <Button onClick={() => this.props.history.goBack()} type="default">
              Back
            </Button>
          </Col>
        </Row>
      </>
    );
  }
}

const AuditDetailsView = withRouter(AuditDetailsViewWithoutRouter);

export default AuditDetailsView;
