import { Modal, Button } from 'antd';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import AuthContext from 'Context/AuthContext';
import { KnowledgeModel, IChangeKnowledgeStatusCommandRequest, KnowledgeStatus, IKnowledgeModel, Operations, IDeleteKnowledgeCommandRequest } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import Utility from 'Core/Utility';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

interface Props {
  knowledge?: IKnowledgeModel;
  newStatus?: KnowledgeStatus;
  knowledgeDeletedCallback?: () => void;
}

export default ({ knowledge, knowledgeDeletedCallback, newStatus }: Props) => {

  const handler: KnowledgeHandler = new KnowledgeHandler({});;
  const { Update, data: user } = useContext(AuthContext);
  const history = useHistory();


  const renderPublishMessage = () => {
    return (<>
      <p>
        This will remove the knowledge. This cannot be restore.
        </p>
      <p>Are you Sure?</p>
    </>);

  }

  const onDeleteClicked = (event: React.SyntheticEvent) => {
    event.preventDefault();

    Modal.confirm({
      title: `Delete "${knowledge?.title}"`,
      content: renderPublishMessage(),
      okText: `Delete`,
      cancelText: 'Cancel',
      onOk: () => deleteKnowledge(),
    });

  }

  const deleteKnowledge = () => {
    let request: IDeleteKnowledgeCommandRequest = {
      id: knowledge?.id,
    }

    handler.DeleteKnowledge(request).then(x => {
      knowledgeDeletedCallback?.call(null);
      history.goBack();
      NotificationService.success(`"${knowledge?.title}" Deleted`, null, 10000, true);
    });
  };

  let canDelete = Utility.HasOperations(user, [Operations.Delete_Own_Knowledge]) && knowledge?.authorId == user?.id;
  canDelete = canDelete || Utility.HasOperations(user, [Operations.Delete_Others_Knowledge]) && knowledge?.authorId != user?.id;
  if (canDelete) {
    return <Button type="danger" onClick={onDeleteClicked}>Delete</Button>;
  } else {
    return <></>;
  }
}