import * as React from 'react';
import AuthContext from '../../../Context/AuthContext';
import { Knowledge, SlimUser } from '../../../Core/Api/Api';
import AuthUser from 'Core/models/AuthUser';
import TokenService from 'Core/TokenService';
import ConfigService from 'Core/ConfigService';
import { withRouter, RouteComponentProps } from 'react-router';
import LogRocket from 'logrocket';
import Utility from 'Core/Utility';
import Cookies from 'js-cookie';
import NewKnowledgeShareContext from '../../../Context/NewKnowledgeShareContext';

export interface Props {
  children?: React.ReactNode;
}

export interface State {
  knowledge?: Knowledge;
}

export default class NewKnowledgeShareContextProvider extends React.Component<Props, State> {
  update: (knowledgeId: any) => void;

  constructor(props: Props) {
    super(props);

    this.state = { knowledge: undefined };
    this.update = knowledge => this.setState({ knowledge: knowledge });
  }

  render() {
    return (
      <NewKnowledgeShareContext.Provider value={{ Update: this.update, data: this.state.knowledge }}>
        {this.props.children}
      </NewKnowledgeShareContext.Provider>
    );
  }
}






