import { Button } from 'antd';
import React, { Component } from 'react';
import { AdminLayout, ClassicLayout } from '../Layout';
import logo from './../../assets/images/logo.svg';
import './app.css';
import '../../assets/styles/react-transitions.css';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import MainContent, { RoutesRenderer } from '../Layout/MainContent';
import AuthContextProvider from '../UserManagement/AuthContextProvider';
import './ant-design-overrides.less';
import LogRocketWrapper from 'components/LogRocketWrapper';
import NavigationEventNotifier from '../Shared/NavigationEventNotifier';
import ErrorView from '../Error/ErrorView';
import Userback from 'components/Userback';
import { Helmet } from 'react-helmet';
import AnalyticsWrapper from 'components/AnalyticsWrapper';
import CollapsedMenuContextProvider from 'components/Layout/AdminLayout/CollapsedMenuContextProvider';
import UserPreferenceContextProvider from 'components/UserManagement/UserPreferenceContextProvider';

import '@syncfusion/ej2-base/styles/material.css';
import '@syncfusion/ej2-buttons/styles/material.css';
import '@syncfusion/ej2-dropdowns/styles/material.css';
import '@syncfusion/ej2-inputs/styles/material.css';
import '@syncfusion/ej2-navigations/styles/material.css';
import '@syncfusion/ej2-popups/styles/material.css';
import '@syncfusion/ej2-splitbuttons/styles/material.css';
import "@syncfusion/ej2-react-pdfviewer/styles/material.css";
import "@syncfusion/ej2-notifications/styles/material.css";

class App extends Component {
  public render() {
    return (
      <div className="App">
        <AuthContextProvider>
          <UserPreferenceContextProvider>
            <LogRocketWrapper>
              <Userback>
                <BrowserRouter>
                  <AnalyticsWrapper>
                    <ErrorView>
                      <CollapsedMenuContextProvider>
                        <NavigationEventNotifier>
                          <div className="transition-container">
                            <RoutesRenderer defaultLayout={AdminLayout} />
                          </div>
                        </NavigationEventNotifier>
                      </CollapsedMenuContextProvider>
                    </ErrorView>
                  </AnalyticsWrapper>
                </BrowserRouter>
              </Userback>
            </LogRocketWrapper>
          </UserPreferenceContextProvider>
        </AuthContextProvider>
        <Helmet titleTemplate=" %s - JURIST" defaultTitle="JURIST" />
      </div>
    );
  }
}

export default App;
