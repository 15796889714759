import React from 'react';
import { Form, Row, Input, Col, Button, Select, Typography, Icon, Tooltip } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
  UserStatus,
  SlimUser,
  User,
  EditUserCommandRequest,
  AddUserCommandRequest,
  IRole,
  IRoleListView,
  Operations,
  GetUserByIdModel,
  RegisterUserCommandRequest,
} from '../../../Core/Api/Api';

import {
  singleColFormColSize,
  FullWithButton,
  doubleColFormColSize,
  singleCol2FormColSize2,
  singleCol2FormColSizeForUserForm,
} from '../../Forms/Form.Styled';
import ISelectOption from '../../Forms/SimpleSelect/ISelectOption';
import UserHandler from 'components/Admin/UserHandler';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import FormControl from 'components/Forms/FormControl';
import MaskedInput from 'components/Forms/MaskedInput';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AuthContext from 'Context/AuthContext';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import Utility from 'Core/Utility';
import TextArea from 'antd/lib/input/TextArea';
import { TitlesTextBox } from 'components/Forms/TitlesTextBox';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { SubmitButton } from 'components/Forms/SubmitButton';

const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  jurisdictions: ISelectOption[];
  roles: ISelectOption[];
  AllRoles: IRoleListView[];
  status: ISelectOption[];
  user?: GetUserByIdModel;
  detailsMode: DetailsMode;
}

export class UserRegistrationFormFormWithoutFormAndRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: UserHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      jurisdictions: [],
      roles: [],
      AllRoles: [],
      status: [],
      detailsMode: DetailsMode.New,
    };
    this.handler = new UserHandler(props);
  }

  componentDidMount() {
    this.getJurisdictions();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
  }

  getStatus() {
    var status: ISelectOption[] = [];
    Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
    this.setState({ status });
  }

  getJurisdictions() {
    this.handler.GetTop100Jurisdictions().then(x => {
      const jurisdictions: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions });
    });
  }


  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, values: RegisterUserCommandRequest) => {
      if (error) {
        return;
      }

      const request = new RegisterUserCommandRequest();
      request.emailAddress = values.emailAddress;
      request.firstName = values.firstName;
      request.lastName = values.lastName;
      request.title = values.title;
      request.department = values.department;
      request.organization = values.organization;
      request.phoneNumber = values.phoneNumber;
      request.notes = values.notes;
      request.jurisdictionId = values.jurisdictionId;

      this.handler.RegisterUser(request).then(x => {
        this.props.history.push("/");
        NotificationService.success(`Successful Registration`, "Your request has been submitted. Registrations are subject to approvals and will be reviewed by a Jurisdiction admin.", 20, true);
      });

    });
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col>
          <Form layout="vertical" onSubmit={this.onSubmit}>
            <CenterAlignedRow>
              <Col><Typography.Title level={1}>Request Access</Typography.Title></Col>
            </CenterAlignedRow>
            <Row>
              <Col md={24} xl={24} >

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[
                    ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                      ...ValidationUtility.required('email is required'),
                      ...ValidationUtility.email(),
                    ]),
                  ]}
                  name="emailAddress"
                  label="Email">
                  <Input disabled={this.state.detailsMode == DetailsMode.Edit} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Confirmation email is required'),
                  ...ValidationUtility.compare(this.props.form, "emailAddress", "Confirmation email has to be the same")
                  ]}
                  name="confirmEmailAddress"
                  label="Confirmation Email">
                  <Input />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('first name is required')]}
                  name="firstName"
                  label="First Name">
                  <Input />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('last name is required')]}
                  name="lastName"
                  label="Last Name">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="title"
                  label="Title">
                  <TitlesTextBox />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="organization"
                  label="Organization">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="department"
                  label="Department">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('contact number is required')]}
                  name="phoneNumber"
                  label="Contact Number">
                  <MaskedInput mask="(999) 999-9999" />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[
                    ...ValidationUtility.required('juristiction is required')
                  ]}
                  name="jurisdictionId"
                  label={<><span>Jurisdiction</span><Tooltip placement="topLeft" title="You can select which Jurisdiction you wish belong to."><Icon style={{ paddingTop: '5px', paddingLeft: '5px' }} type="info-circle"></Icon></Tooltip></>}>
                  <SimpleSelect
                    style={{ width: '100%' }}
                    options={this.state.jurisdictions}></SimpleSelect>
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="notes"
                  label="Notes">
                  <TextArea rows={3} />
                </FormControl>
              </Col>
            </Row>

            <Row>
              <Col span={7}>
                <Button onClick={() => this.props.history.goBack()} type="default">
                  Cancel
                </Button>
              </Col>
              <Col span={3}>
                <SubmitButton type="primary" htmlType="submit">
                  Register
                </SubmitButton>
              </Col>

            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const UserRegistrationFormFormWithRouter = Form.create<Props>({
  name: 'UserRegistrationForm',
})(UserRegistrationFormFormWithoutFormAndRouter);

const UserRegistrationFormForm = withRouter(UserRegistrationFormFormWithRouter);

export default UserRegistrationFormForm;
