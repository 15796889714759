import React, { useDebugValue } from 'react';
import { UpdatableContext } from './UpdatableContext';
import AuthUser from 'Core/models/AuthUser';
import { UserPreference } from 'Core/Api/Api';

const data = undefined;
const Update = () => { };

const UserPreferenceContext = React.createContext<UpdatableContext<UserPreference>>({ data, Update });

export default UserPreferenceContext;



