import React from 'react';
import { SlimUser, SwaggerException, IFilter, Filter, Operations, AccessLevel } from '../Api/Api';

import _, { map, stubString } from 'lodash';
import AuthUser from 'Core/models/AuthUser';
import { matchPath } from 'react-router';
import AppRoute from 'Core/models/AppRoute';
import { Moment, max } from 'moment';
import { Tooltip } from 'antd';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import path from 'path';
import ReactGA from 'react-ga';
import { match } from 'assert';
import { error } from 'console';
import DetailsMode from 'Core/models/DetailsMode';
import { AccessLevelType } from './AccessLevelType';
import ShowIfTrue from 'components/Forms/ShowIfTrue';

export default class Utility {
  static getIntValueOfAccessLevel(accessLevel: AccessLevel) {
    switch (accessLevel) {
      case AccessLevel.Level1:
        return 1;
      case AccessLevel.Level2:
        return 2;
      case AccessLevel.Level3:
        return 3;
      case AccessLevel.Level4:
        return 4;
      case AccessLevel.Level5:
        return 5;
      default:
        return 0;
    }
  }

  static RenderAccessLevelDescriptions(accessLevel: AccessLevel, maxWidth: string = "300px") {
    switch (accessLevel) {
      case AccessLevel.Level1:
        return <p style={{ maxWidth: maxWidth, overflow: "visible", wordBreak: 'break-word' }}>Applies to data that is not
          openly published but can be made available via open record requests.
          Direct access to this data is restricted to authenticated and authorized
          employees but can be made available to external parties e.g. reports,
          procedures. Limited data may contain redactions to protect confidential
          material.</p>;
      case AccessLevel.Level2:
        return <p style={{ maxWidth: maxWidth, overflow: "visible", wordBreak: 'break-word' }} >Applies to data that is
          intended for use among authorized donor agencies. This will include
          reports, policies, procedures etc.</p>;
      case AccessLevel.Level3:
        return <p style={{ maxWidth: maxWidth, overflow: "visible", wordBreak: 'break-word' }}>Applies to data
          that is not openly published but can be made available via open record
          requests. Direct access to this data is restricted to authenticated and
          authorized employees. Data not to be shared externally.</p>;
      case AccessLevel.Level4:
        return <p style={{ maxWidth: maxWidth, overflow: "visible", wordBreak: 'break-word' }}>Applies to data that
          must be kept private based on legislation, standards, policies,
          contractual agreements, or based on its proprietary worth.</p>;
      case AccessLevel.Level5:
        return <p style={{ maxWidth: maxWidth, overflow: "visible", wordBreak: 'break-word' }}>Applies to data that is
          intended for use only by authorized personnel, and unauthorized
          disclosure reasonably could be expected to cause exceptionally grave
          damage to the organization.</p>;
      case AccessLevel.Public:
        return <p style={{ maxWidth: maxWidth, overflow: "visible", wordBreak: 'break-word' }}>Applies to data that is readily available to the public with anonymous access.</p>;
    }
  }

  static changeFilterFieldValue(field: string, filters: IFilter[] | undefined, newValueOrSetterFunction: any | Function, depth: number = -1) {
    if (!filters) { return; }
    if (!newValueOrSetterFunction) { return; }
    if (!field) { return; }

    filters.forEach(filter => {
      if (filter.field == field) {
        filter.value = !!newValueOrSetterFunction?.prototype ? newValueOrSetterFunction(filter.value) : newValueOrSetterFunction;
      }

      if (filter.groupFilters?.length && depth <= -1 || depth > 0) {
        Utility.changeFilterFieldValue(field, filter.groupFilters, newValueOrSetterFunction, --depth);
      }

    })
  }

  static ClipTextLength(text: string, maxLength: number): string {
    if (!text) return text;

    if (text.length >= maxLength ?? 0) {
      return text.substring(0, maxLength - 3) + "...";
    } else {
      return text;
    }
  }

  static voidmethod(): void {

  }

  static makeKnowledgeDocumentFilterIngored(tableRequest: TableRequest): TableRequest {
    Utility.setdocumentFilterToIgnore(tableRequest.filters);
    return tableRequest;
  }

  static setdocumentFilterToIgnore(filters: IFilter[] | undefined) {
    if (!filters) { return; }

    for (const filter of filters) {
      if (filter.field == "documents") {
        filter.ignore = true;
      }
      Utility.setdocumentFilterToIgnore(filter.groupFilters);
    }
  }

  static logSearchToAnalytics(tableRequest: TableRequest, path: string) {
    const filters = tableRequest.filters;
    if (filters) {
      try {
        const titleSearchTerm = Utility.getFirstFieldValueFromFilters(filters, 'title');
        if (titleSearchTerm) {
          var category = path?.includes("public-knowledge") ? "Public Knowledge" : "Internal Knowledge";

          ReactGA.event({
            category: category,
            action: 'Search Tearm',
            label: titleSearchTerm,
            value: 1
          });
        }
      } catch (e) {

      }
    }
  }

  static renderDateWithReference(createDate?: Moment, dateFormat?: string): React.ReactNode {
    return (
      <Tooltip title={createDate?.fromNow()}>
        <span>{createDate?.format(dateFormat)}</span>
      </Tooltip>
    );
  }

  static getFirstFieldValueFromFilters(filters: IFilter[] | undefined, field: string): string | undefined {
    if (!filters || !filters.length) return undefined;

    const found = filters.find(x => x.field == field);

    if (found) {
      return found.value;
    } else {
      return Utility.getFirstFieldValueFromFilters(filters.flatMap(x => x.groupFilters ?? []), field);
    }

  }

  static getFieldValuesFromFilters(filters: IFilter[] | undefined, field: string, list: string[] | undefined = undefined): string[] | undefined {
    if (!filters || !filters.length) return list;

    if (!list) {
      list = [];
    }

    const found = filters.filter(x => x.field == field);

    if (found && found.length) {
      found.forEach(x => x.value && list?.push(x.value!));

    }
    let completedList = Utility.getFieldValuesFromFilters(filters.flatMap(x => x.groupFilters ?? []), field, list);
    return completedList;
  }

  static convertToFullPath(routes?: AppRoute[], basePath: string = ''): AppRoute[] | undefined {
    return routes?.map(x => {
      const path = this.combinePath(basePath, x.path as string);
      return { ...x, path, routes: this.convertToFullPath(x.routes, path) };
    });
  }
  public static combinePath(...args: string[]) {
    let path = args[0];

    if (!args[0]) {
      return args[1] || args[0];
    }

    return (
      args
        .reduce((previousPath: string, nextpath: string) => {
          return nextpath.indexOf('/') === 0 && nextpath === '/'
            ? previousPath
            : previousPath + '/' + nextpath;
        }, '')
        // this is not an ideal solutions but it should work for now
        .replace('//', '/')
    );
  }

  public static isPathOrSubpathAMatch(path: string, match: AppRoute): boolean {
    return (
      !!matchPath(path, match) ||
      (match.routes?.some(x => Utility.isPathOrSubpathAMatch(path, x)) ?? false)
    );
  }

  public static isPathOrSubPathAccessible(user: AuthUser, route: AppRoute): boolean {
    return (
      !!this.HasOperations(user, route.requiredOperations) ||
      (route.routes?.some(x => Utility.isPathOrSubPathAccessible(user, x)) ?? false)
    );
  }

  public static downloadURI(uri, name) {
    let link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  public static GetMatchingRoute(path: string, match: AppRoute): AppRoute | undefined {
    if (!!matchPath(path, match)) {
      return match;
    } else {
      match.routes?.filter(x => Utility.GetMatchingRoute(path, x))?.pop();
    }
  }

  public static CreateTableRequest(): TableRequest {
    let request = new TableRequest();
    request.filters = [];
    request.orderBy = [];
    return request;
  }

  public static HasOperations(user?: AuthUser, requiredOperations?: string[]) {
    // if no operations are supplied, it is a public page
    if (!requiredOperations || requiredOperations.length === 0) {
      return true;
    }

    // if operations are supplied and the user is not logged in, they need to login
    if ((user === null || user === undefined) && requiredOperations.length > 0) {
      return false;
    }

    // search through all required operations to see if the user has all of them

    for (const requiredOperation of requiredOperations) {
      if (user && user.operations && user.operations.length > 0) {
        const foundOperation = user.operations.filter(x => x.toString() === requiredOperation);

        // an operation that was required was not in the users list, they don't have permission
        if (!foundOperation || foundOperation.length === 0) {
          return false;
        }
      } else {
        // the user has no operations so it must be that the need permission
        return false;
      }
    }

    // to reach this point means that all operations needed where found in the users operations
    return true;
  }



  public static GetOperationForAccessLevel(mode: DetailsMode, accessLevel: AccessLevelType) {

    let accesslevelName = "";
    if (typeof accessLevel == 'string' && accessLevel.length > 1 && accessLevel != 'Public') {
      let length = accessLevel.length;
      accesslevelName = accessLevel.substr(length - 1, 1);
    } else if (accessLevel == 'Public') {
      accesslevelName = 'Public';
    } else {
      accesslevelName = accessLevel.toString();
    }


    let operationsTypeName: string = Utility.GetOperationTypeName(mode);
    let operationName = `${operationsTypeName} Access Level ${accesslevelName}`;
    //Edit Access Level 1
    //Edit Access Level 2
    return (operationName as Operations);
    // let operation = Operations[operationName];
    // return operation;
  }

  static GetOperationTypeName(mode: DetailsMode): string {
    switch (mode) {
      case DetailsMode.Edit:
        return 'Edit';
      case DetailsMode.New:
        return 'Create';
      case DetailsMode.View:
        return 'View';
      case DetailsMode.Download:
        return 'Download';
      default:
        return 'View';
        break;
    }
  }

  public static RandomNumber(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  public static getErrorMessage(error: SwaggerException) {
    if (error.response) {
      return JSON.parse(error.response).message;
    } else return undefined;
  }

  /**
   * Converts the given enum to a map of the keys to the values.
   * @param enumeration The enum to convert to a map.
   */
  public static enumToMap(enumeration: any): Map<any, any | any> {
    const map = new Map<string, string | number>();
    for (let key in enumeration) {
      //TypeScript does not allow enum keys to be numeric
      if (!isNaN(Number(key))) continue;

      const val = enumeration[key] as string | number;

      //TypeScript does not allow enum value to be null or undefined
      if (val !== undefined && val !== null) map.set(key, val);
    }
    return map;
  }

  public static enumToSelectionOptionArray(enumeration: any): { text: string; value: any }[] {
    const list: { text: string; value: any }[] = [];
    this.enumToMap(enumeration).forEach((x, y) => list.push({ text: x, value: y }));
    return list;
  }

  /**
   * https://gist.github.com/nicbell/6081098#file-object-compare-js
   *
   * @static
   * @param {*} obj1
   * @param {*} obj2
   * @returns
   * @memberof Utility
   */
  public static Compare(obj1, obj2) {
    return _.isEqual(obj1, obj2);
    // Loop through properties in object 1
    // tslint:disable-next-line:forin
    for (let p in obj1) {
      // Check property exists on both objects
      if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {
        return false;
      }

      switch (typeof obj1[p]) {
        // Deep compare objects
        case 'object':
          if (!Utility.Compare(obj1[p], obj2[p])) {
            return false;
          }
          break;
        // Compare function code
        case 'function':
          if (
            // tslint:disable-next-line:triple-equals
            typeof obj2[p] === 'undefined' ||
            // tslint:disable-next-line:triple-equals
            (p != 'compare' && obj1[p].toString() != obj2[p].toString())
          ) {
            return false;
          }
          break;
        // Compare values
        default:
          // tslint:disable-next-line:triple-equals
          if (obj1[p] !== obj2[p]) {
            return false;
          }
      }
    }

    // Check object 2 for any extra properties
    for (let p in obj2) {
      if (!obj2.hasOwnProperty(p)) {
        continue;
      }
      if (!obj1.hasOwnProperty(p)) {
        return false;
      }
    }
    return true;
  }

  static cache: any[] = [];

  public static cacheInMemory<Type>(name: string, data: Type) {
    return Utility.cache[name] = data;
  }

  public static getFromCache<Type>(name: string): Type {
    return Utility.cache[name] as Type;
  }
  public static getFormattedDescription(description?: string): string {
    var div = document.createElement("div");
    div.innerHTML = description ? description : "";
    let descText: string = Utility.truncate(div.innerText, 80, '...');
    return descText.substring(0, 100);
  }
  public static truncate = (str, max, suffix): string => str.length < max ? str : `${str.substr(0, str.substr(0, max - suffix.length).lastIndexOf(' '))}${suffix}`;
  constructor() { }
}
