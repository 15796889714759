import * as React from 'react';
import { Row, Col, Button } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { KnowledgeTemplateListFilterBar, KnowledgeTemplateListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Operations } from 'Core/Api/Api';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {}

export class KnowledgeTemplateListViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  AddNewKnowledgeDataTemplate = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('templates/s/new', newDetailsData);
  };

  render() {
    return (
      <TableFilterContextProvider>
        <Row>
          <Col md={24}>
            <ShowIfHavePermission operations={[Operations.Create_Knowledge_Data_Template]}>
              <Button type="primary" onClick={this.AddNewKnowledgeDataTemplate}>
                New Template
              </Button>
            </ShowIfHavePermission>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <KnowledgeTemplateListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <KnowledgeTemplateListTable />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const KnowledgeTemplateListView = withRouter(KnowledgeTemplateListViewWithoutRouter);

export default KnowledgeTemplateListView;
