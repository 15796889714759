import * as React from 'react';
import { Layout, Col, Row } from 'antd';
import AppMenu from '../../AppMenu';
import AppRoute from '../../../../Core/models/AppRoute';
import Logo from '../../../Styled/Logo';
import UserBar from '../../UserBar';
import { logoLayoutSizes, menuLayoutSizes, authBarLayoutSizes, LightHeader } from './TopBar.Styled';
import AuthBar from 'components/Layout/AuthBar';

const { Header } = Layout;

export interface Props {
  children?: React.ReactNode;
  menu: AppRoute[];
}

export interface State {}

export default class TopBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <LightHeader>
        <Row type="flex" justify="center" align="middle">
          <Col {...logoLayoutSizes}>
            <Logo disableLinking={false} />
          </Col>
          <Col {...menuLayoutSizes}>
            <Row type="flex" justify="center">
              <Col>
                <AppMenu mode="horizontal" menu={this.props.menu} />
              </Col>
            </Row>
          </Col>
          <Col {...authBarLayoutSizes}>
            <AuthBar />
          </Col>
        </Row>
      </LightHeader>
    );
  }
}



