import React, { SyntheticEvent } from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router-dom';
import * as H from 'history';
import { ButtonProps } from 'antd/lib/button';

interface Props extends ButtonProps {
  children: React.ReactNode;
  to: H.LocationDescriptorObject<any>;

}

export default ({ children, to, ...rest }: Props) => {

  const history = useHistory();

  const onClick = (event: SyntheticEvent) => {
    history.push(to);
  }
  return <Button type="link" onClick={onClick} {...rest}>
    {children}
  </Button>;
}