import { Col, Form, Row, Typography, Input, Button, Upload, Icon, Modal, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ISelectOption from '../../../components/Forms/SimpleSelect/ISelectOption';
import AuthContext from '../../../Context/AuthContext';
import DetailsMode from '../../../Core/models/DetailsMode';
import NotificationService from '../../../Core/NotificationService';
import React, { ReactElement } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AddUserCommandRequest,
  SlimUser,
  User,
  Knowledge,
  IEditJurisdictionCommandRequest,
  KnowledgeModel,
  GetKnowledgeByIdQueryResponse,
  DocumentModel,
  IDeleteCommentCommandRequest,
  IDeleteDocumentCommandRequest,
  IEditCategoryCommandRequest,
  KnowledgeCategory,
  Jurisdiction,
} from '../../../Core/Api/Api';
import {
  doubleColFormColSize,
  FullWithButton,
  singleColFormColSize,
  singleCol2FormColSize,
  CategoryColSize,
} from '../../Forms/Form.Styled';
import KnowledgeHandler from '../KnowledgeHandler';
import FormControl from '../../../components/Forms/FormControl';
import ValidationUtility from '../../../Core/ValidationUtility';
import TextArea from 'antd/lib/input/TextArea';
import { RightAlignedRow } from '../../../components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from '../../../components/Styled/Layout/LeftAlignedRow';
import SimpleSelect from '../../../components/Forms/SimpleSelect';
import SimpleUpload from '../../../components/Forms/SimpleUpload';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import KnowledgeJurisdictionsHandler from '../KnowledgeJurisdictionsHandler';
import { SubmitButton } from '../../../components/Forms/SubmitButton';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import {
  align,
  font,
  fontColor,
  fontSize,
  formatBlock,
  hiliteColor,
  horizontalRule,
  lineHeight,
  list,
  paragraphStyle,
  table,
  template,
  textStyle,
  image,
  link,
} from 'suneditor/src/plugins';
import Select from 'react-select';
import AG from '../../../assets/images/flags/AG.png';
import BB from '../../../assets/images/flags/BB.png';
import BS from '../../../assets/images/flags/BS.png';
import CA from '../../../assets/images/flags/CA.png';
import CC from '../../../assets/images/flags/CC.png';
import DM from '../../../assets/images/flags/DM.png';
import GD from '../../../assets/images/flags/GD.png';
import JM from '../../../assets/images/flags/JM.png';
import KN from '../../../assets/images/flags/KN.png';
import LC from '../../../assets/images/flags/LC.png';
import TT from '../../../assets/images/flags/TT.png';
import VC from '../../../assets/images/flags/VC.png';
import GY from '../../../assets/images/flags/GY.png';
import BZ from '../../../assets/images/flags/BZ.png';
import AI from '../../../assets/images/flags/AI.png';
import BM from '../../../assets/images/flags/BM.png';
import KY from '../../../assets/images/flags/KY.png';
import MS from '../../../assets/images/flags/MS.png';
import TC from '../../../assets/images/flags/TC.png';
import VG from '../../../assets/images/flags/VG.png';
import ECSC from '../../../assets/images/flags/ECSC.png';
import { CANCELLED } from 'dns';
const { Title } = Typography;

type onRemoveFunc = (file: UploadFile) => void | boolean | Promise<void | boolean> | undefined;
type beforeUploadFunc = (file: RcFile, FileList: RcFile[]) => boolean | PromiseLike<void>;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  knowledgeJurisdiction?: Jurisdiction;
  detailsMode: DetailsMode;
  selectedFlag?: string;
}
type Data = {
  value: string;
  text: string;
  icon: ReactElement;
};
const data: Data[] = [
  {
    value: 'AG',
    text: 'Antigua and Barbuda',
    icon: <img src={AG}></img>,
  },
  {
    value: 'BZ',
    text: 'Belize',
    icon: <img src={BZ}></img>,
  },
  {
    value: 'BS',
    text: 'The Bahamas',
    icon: <img src={BS}></img>,
  },
  {
    value: 'BB',
    text: 'Barbados',
    icon: <img src={BB}></img>,
  },
  {
    value: 'CA',
    text: 'Canada',
    icon: <img src={CA}></img>,
  },
  {
    value: 'CC',
    text: 'CCJ',
    icon: <img src={CC}></img>,
  },
  {
    value: 'DM',
    text: 'Dominica',
    icon: <img src={DM}></img>,
  },
  {
    value: 'GD',
    text: 'Grenada',
    icon: <img src={GD}></img>,
  },
  {
    value: 'GY',
    text: 'Guyana',
    icon: <img src={GY}></img>,
  },
  {
    value: 'JM',
    text: 'Jamaica',
    icon: <img src={JM}></img>,
  },
  {
    value: 'KN',
    text: 'Saint Kitts and Nevis',
    icon: <img src={KN}></img>,
  },
  {
    value: 'LC',
    text: 'Saint Lucia',
    icon: <img src={LC}></img>,
  },
  {
    value: 'VC',
    text: 'Saint Vincent and the Grenadines',
    icon: <img src={VC}></img>,
  },
  {
    value: 'TT',
    text: 'Trinidad and Tobago',
    icon: <img src={TT}></img>,
  },
  {
    value: 'AI',
    text: 'Anguilla',
    icon: <img src={AI}></img>,
  },
  {
    value: 'BM',
    text: 'Bermuda',
    icon: <img src={BM}></img>,
  },
  {
    value: 'KY',
    text: 'Cayman Islands',
    icon: <img src={KY}></img>,
  },
  {
    value: 'MS',
    text: 'Montserrat',
    icon: <img src={MS}></img>,
  },
  {
    value: 'TC',
    text: 'Turks and Caicos Islands',
    icon: <img src={TC}></img>,
  },
  {
    value: 'VG',
    text: 'British Virgin Islands',
    icon: <img src={VG}></img>,
  },
  {
    value: 'EC',
    text: 'Eastern Caribbean Supreme Court (ECSC)',
    icon: <img src={ECSC}></img>,
  },
];
export class KnowledgeJurisdictionsDetailsFormWithoutFormAndRouter extends React.Component<
  Props,
  State
> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: KnowledgeJurisdictionsHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      detailsMode: DetailsMode.New,
      selectedFlag: 'CA',
    };
    this.handler = new KnowledgeJurisdictionsHandler(props);
  }

  componentDidMount() {
    this.GetJurisdictionDetails();
  }

  GetJurisdictionDetails() {
    const urlState = this.props.location.state as { id: string; mode: DetailsMode } | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetJurisdictionDetailsById(urlState.id).then(
        x => {
          this.setState({
            knowledgeJurisdiction: x.jurisdiction,
            detailsMode: DetailsMode.Edit,
            selectedFlag: x.jurisdiction?.flagIconPathOrName,
          });
          this.props.form.setFieldsValue(x.jurisdiction ?? {});
          this.props.form.setFieldsValue({
            flagIconPathOrName: data.find(d => d.value === x.jurisdiction?.flagIconPathOrName),
          });
        },
        x => NotificationService.error(x.message)
      );
    } else {
      this.setState({ detailsMode: DetailsMode.New, selectedFlag: 'AG' });
    }
  }

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    var me = this;
    this.props.form.validateFieldsAndScroll((error, values: IEditJurisdictionCommandRequest) => {
      if (error) {
        return;
      }

      if (this.state.detailsMode == DetailsMode.Edit) {
        values.id = this.state.knowledgeJurisdiction?.id;
        const icon: Data = (values.flagIconPathOrName as unknown) as Data;
        values.flagIconPathOrName = icon.value;
        this.handler.EditJurisdiction(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Updated ${values.name}`);
        });
      } else {
        const icon: Data = (values.flagIconPathOrName as unknown) as Data;
        values.flagIconPathOrName = icon.value;
        this.handler.AddJurisdiction(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Added ${values.name}`);
        });
      }
    });
  };
  handleChange = (e: Data) => {
    this.setState({ selectedFlag: e.value });
  };
  renderOption = (option: Readonly<Data>) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      {option.icon}
      <span style={{ marginLeft: 5 }}>{option.text}</span>
    </div>
  );
  render() {
    var { getFieldDecorator } = this.props.form;
    const { knowledgeJurisdiction } = this.state;

    return (
      <LeftAlignedRow>
        <Col md={10}>
          <Form layout="horizontal" onSubmit={this.onSubmit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="name"
              label="Name"
              rules={[...ValidationUtility.required('Name is required')]}>
              <Input disabled={this.state.detailsMode == DetailsMode.Edit ? true : false} />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="shortDescription"
              label="Short Description(To be shown on overview page)"
              rules={[...ValidationUtility.required('Short description is required')]}>
              <Input />
            </FormControl>

            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="description"
              label="Description"
              rules={[...ValidationUtility.required('Description is required')]}>
              <SunEditor
                setDefaultStyle="font-family: Arial; font-size: 12px;"
                lang="en"
                setContents={knowledgeJurisdiction && knowledgeJurisdiction.description}
                width="100%"
                setOptions={{
                  showPathLabel: false,
                  buttonList: [
                    ['font', 'fontSize', 'formatBlock'],
                    ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                    ['fontColor'],
                    ['align', 'list'],
                    ['table'],
                  ],
                  formats: ['p', 'div', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
                }}
              />
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              name="flagIconPathOrName"
              label="Flag Icon"
              rules={[...ValidationUtility.required('Flag Icon is required')]}>
              <Select
                placeholder="Select Option"
                options={data}
                onChange={e => this.handleChange(e as Data)}
                getOptionLabel={this.renderOption as any}
              />
            </FormControl>
            <Row>
              <Col span={3}>
                <Button
                  type="default"
                  onClick={() => this.props.history.goBack()}
                  htmlType="button">
                  Cancel
                </Button>
              </Col>
              <Col offset={1} span={3}>
                <SubmitButton type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Col>
            </Row>
          </Form>
        </Col>
      </LeftAlignedRow>
    );
  }
}

const KnowledgeJurisdictionsDetailsFormWithRouter = Form.create<Props>({
  name: 'KnowledgeCategoryDetails',
})(KnowledgeJurisdictionsDetailsFormWithoutFormAndRouter);

const KnowledgeJurisdictionsDetailsForm = withRouter(KnowledgeJurisdictionsDetailsFormWithRouter);

export default KnowledgeJurisdictionsDetailsForm;
