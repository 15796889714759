import PageContainer from 'components/Layout/PageContainer';
import ConfigService from 'Core/ConfigService';
import React from 'react';
import Iframe from 'react-iframe';

interface Props {
}

export default ({ }: Props) => {
  var config = ConfigService.getSync();

  return (<PageContainer title="JuristProject.Org"><Iframe scrolling={'no'} width="100%" height="2000px" styles={{ width: "100%", height: "100%" }} url={config.SiteInfo.JuristPageEmbebbedUrl ?? "https://juristproject.org/kms-embed-test/"} frameBorder={0} allowFullScreen></Iframe ></PageContainer>);
}