import * as React from 'react';
import * as SurveyJSCreator from 'survey-creator';
import * as SurveyKo from 'survey-knockout';
import 'survey-creator/survey-creator.css';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'select2/dist/js/select2.js';
import 'jquery-bar-rating';
import 'pretty-checkbox/dist/pretty-checkbox.css';

import * as widgets from 'surveyjs-widgets';
import { random } from 'lodash';
import { SurveyCreator } from 'survey-creator';

SurveyJSCreator.StylesManager.applyTheme('default');

export interface Props {
  children?: React.ReactNode;
  onChange?: Function;
  prefix?: string;
  value?: string;
}

export interface State {
  value?: string;
}

export default class SurveyCreatorControl extends React.Component<Props, State> {
  surveyCreator?: SurveyCreator;
  defaultTemplateString =
    '{"pages":[{"name":"page1","questionTitleLocation":"hidden"}],"showTitle":false,"showPageTitles":false,"showCompletedPage":false,"showQuestionNumbers":"off","questionErrorLocation":"bottom","checkErrorsMode":"onValueChanged","questionsOnPageMode":"singlePage"}';
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  saveMySurvey = () => {
    this.surveyCreator?.JSON?.pages?.forEach(pages => {
      pages.elements?.forEach(question => {
        question.name = (this.props.prefix ?? random(11111, 99999, false)) + question.name;
      });
    });
    this.props.onChange?.call(this, JSON.stringify(this.surveyCreator?.JSON));
  };

  componentDidUpdate = (prevProps: Props, prevState: State) => {
    if (prevProps.value != this.props.value) {
      if (!prevProps.value && this.surveyCreator) {
        this.surveyCreator.JSON = JSON.parse(this.props.value ?? this.defaultTemplateString);
      }
    }
  };

  componentDidMount() {
    let options = {
      questionTypes: ['text', 'checkbox', 'radiogroup', 'dropdown', 'comment'],
      showEmbededSurveyTab: false,
      showJSONEditorTab: false,
      showLogicTab: false,
      showPropertyGrid: true,
      showPagesToolbox: true,
      isAutoSave: true,
      pageEditingMode: 'single',
    };
    this.surveyCreator = new SurveyJSCreator.SurveyCreator(null, options);
    this.surveyCreator.saveSurveyFunc = this.saveMySurvey;
    this.surveyCreator.haveCommercialLicense = true;
    this.surveyCreator.showDesignerTab = true;
    this.surveyCreator.showToolbox = true;
    this.surveyCreator.JSON = JSON.parse(this.props.value ?? this.defaultTemplateString);
    this.changeTabNames(this.surveyCreator);
    this.surveyCreator.render('surveyCreatorContainer');
  }

  changeTabNames(surveyCreator: SurveyJSCreator.SurveyCreator) {
    surveyCreator.tabs().find(x => x.title == 'Survey Designer').title = 'Designer';
    surveyCreator.tabs().find(x => x.title == 'Test Survey').title = 'Preview';
  }

  render() {
    return <div id="surveyCreatorContainer" />;
  }
}
