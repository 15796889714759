import * as React from 'react';
import { Row, Col, Card, Form } from 'antd';
import FilterTextbox from '../FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from '../FilterDatePicker';
import { FilterType } from 'Core/Api/Api';
import FilterDateRangePicker from '../FilterDateRangePicker';
import FilterSelectbox from '../FilterSelectbox';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { RounedForm } from './FilterBar.Styled';
import { FormProps } from 'antd/lib/form';

export interface Props extends FormProps {
    children?: React.ReactNode;
    formLayout?: "inline"|"horizontal"|"vertical"|undefined;
}

const formItemLayout = {
  labelCol: {

    xs: { span: 10 },
    xl: { span: 5 }

    },
    wrapperCol: {
        xs: { span: 14 },
        xl: { span: 19 },
    },

};

export interface State { }

export default class FilterBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const { children, ...rest } = this.props;
    return (
      <LeftAlignedRow>
        <Col span={24}>
                <RounedForm {...formItemLayout} labelAlign="right" layout={this.props.formLayout ?? "inline"} {...rest} >
            {children}
          </RounedForm>
        </Col>
      </LeftAlignedRow>
    );
  }
}
