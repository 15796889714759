/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import _, { isArray } from 'lodash';
//import '../../App/SynfusionGlobalImports';
import JQuery from 'jquery';

declare var BoldReportViewerComponent: any;

var viewerStyle = {
  'height': '700px',
  'width': '100%'
};


interface Props {
  fileName: string;
  dataSource?: any | [];

}

export default ({ fileName, dataSource }: Props) => {

  let formatedDataSource = isArray(dataSource) ? dataSource : [dataSource];
  let [LocalBoldReportViewerLoaded, setLocalBoldReportViewerLoaded] = useState<any>(false);

  useEffect(() => {
    import('../../App/SynfusionGlobalImports').then(x => {
      setLocalBoldReportViewerLoaded(true);
    });
  }, [])

  useEffect(() => {
    if (dataSource) {
      //jQuery("#reportviewer-container").data("boldReportViewer").refresh();
    }
  }, [dataSource])


  return (
    <div style={viewerStyle}>
      {LocalBoldReportViewerLoaded &&
        <BoldReportViewerComponent
          id="reportviewer-container"
          reportServiceUrl={'/api/ReportViewer'}
          processingMode={"Local"}
          dataSources={formatedDataSource}
          reportPath={fileName}>
        </BoldReportViewerComponent>}
    </div>
  );
}