import React, { ChangeEvent } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import FilterFieldWrapper from '../FilterFieldWrapper';
import { FilterFieldWrapperProps, MarkedFilter } from '../FilterFieldWrapper/FilterFieldWrapper';
import { TextInputChangeEventData } from 'react-native';
import debounce from 'lodash/debounce';
import {
  DatePickerProps,
  RangePickerValue,
  RangePickerPresetRange,
} from 'antd/lib/date-picker/interface';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;

export interface FilterDatePickerProps extends FilterFieldWrapperProps, DatePickerProps { }

export default class FilterDatePicker extends FilterFieldWrapper<FilterDatePickerProps> {
  constructor(props: FilterDatePickerProps) {
    super(props);
    this.UpdateFilter = debounce(this.UpdateFilter.bind(this), 700);
  }

  onChange = (dates: moment.Moment | null, dateStrings: string): void => {
    if (!dates) {
      this.UpdateFilter(undefined);
    } else {
      this.UpdateFilter(dates.toISOString(true));
    }
  };

  protected getInitalValueAndSetfield(tableRequest: TableRequest) {

    const foundFilters = tableRequest.filters?.filter((x: MarkedFilter) => x.filterId == this.props.filterFields);

    if (foundFilters && foundFilters.length && (foundFilters[0] as MarkedFilter).orginalValue) {
      const foundFilter: MarkedFilter = foundFilters[0];
      let value = moment(foundFilter.orginalValue);
      this.setState({ intialValue: value, intialValueSet: true })
    } else {
      this.setState({ intialValueSet: true })
    }
  }

  render() {
    if (!this.state.intialValueSet) return <></>;
    const { filterFields, filterJoin, filterType, onFilterUpdated, ...inputProps } = this.props;
    return <DatePicker onChange={this.onChange} defaultValue={this.state.intialValue} {...inputProps} />;
  }
}



