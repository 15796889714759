import { Col, Row } from 'antd';
import PageContainer from 'components/Layout/PageContainer';
import SharedKnowledgeListView from 'components/SharedKnowledge/SharedKnowledgeListView';
import UserRegistrationFormForm from 'components/UserManagement/UserRegistrationFormForm';
import * as React from 'react';
import { RouteComponentProps, RouteProps } from 'react-router-dom';
import styled from 'styled-components';


export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

const TallCol = styled(Col)`
  height: 100%;
  min-height: 200px;
`;

export default class SharedKnowledgeListPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    let state: { from?: string; noPermission?: boolean } = {};
  }

  render() {
    return (
      <PageContainer title="Shared Knowledges">
        <SharedKnowledgeListView />
      </PageContainer>
    );
  }
}



