import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
  GetFilteredJurisdictionsQueryRequest,
  AuditHeader,
  GetAllAuditHeaderByFilterModel,
  GetAllAuditTypesQueryRequest,
  GetAllCategoryByFilterQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetKnowledgeByIdQueryRequest,
  IEditKnowledgeCommandRequest,
  OrderBy,
  Knowledge,
  EditKnowledgeCommandRequest,
  IDeleteDocumentCommandRequest,
  GetAllKnowledgeDataTemplatesByFilterQueryRequest, GetKnowledgeSummaryReportDataQueryRequest, IGetKnowledgeSummaryReportDataQueryRequest, IGetKnowledgeFileReportDataQueryRequest, GetKnowledgeFileReportDataQueryRequest, GetAllKnowledgeDataTemplatesForPublicByFilterQueryRequest, IChangeKnowledgeStatusCommandRequest, ChangeKnowledgeStatusCommandRequest, IDeleteKnowledgeCommandRequest, DeleteKnowledgeCommandRequest, CanUserAccessKnowledgeQueryRequest, GetKnowledgeByNameQueryRequest
} from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class KnowledgeHandler implements ITableDataFetcher<Knowledge> {
  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<Knowledge>> {
    return this.getAllKnowledgeByFilter(tableRequest);
  }

  getAllKnowledgeByFilter(request?: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ? request : new TableRequest();
    request.amountPerPage = request?.amountPerPage ?? 10;
    const getAllAuditHeaderByFilterModel = new GetAllAuditHeaderByFilterModel(request);
    return client.getAllKnowledgeByFilter(getAllAuditHeaderByFilterModel);
  }

  GetTop100KnowledgeCategories() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllCategoryByFilterQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'name', ascending: false }));
    return client.getAllCategoryByFilter(request);
  }
  GetTop100KnowledgeJurisdictions() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredJurisdictionsQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'name', ascending: false }));
    var result = client.getFilteredJurisdictions(request);
    return result;
  }
  GetTop100KnowledgeTemplates() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllKnowledgeDataTemplatesByFilterQueryRequest();
    request.amountPerPage = 50;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'name', ascending: false }));
    return client.getAllKnowledgeDataTemplateByFilter(request);
  }

  GetTop100KnowledgeTemplatesForPublic() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllKnowledgeDataTemplatesForPublicByFilterQueryRequest();
    request.amountPerPage = 50;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'name', ascending: false }));
    return client.getAllKnowledgeDataTemplateForPublicByFilter(request);
  }

  GetAllAuditTypes() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllAuditTypesQueryRequest();
    return client.getAllAuditTypes(request);
  }

  GetFilteredRoles(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 20;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRolesByFilter(request);
  }

  GetKnowledgeDetailsById(id: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeByIdQueryRequest();
    request.knowledgeId = id;
    return client.getKnowledgeById(request);
  }

  GetKnowledgeDetailsByName(name: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeByNameQueryRequest();
    request.knowledgeName = name;
    return client.getKnowledgeByName(request);
  }

  AddKnowledge(values: IEditKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    const request = new FormData();
    request.append('title', values.title!);
    if (values.description) {
      request.append('description', values.description);
    }

    if (values.templateDataJSON) {
      request.append('templateDataJSON', values.templateDataJSON);

    }
    if (values.knowledgeDataTemplateId) {
      request.append('knowledgeDataTemplateId', values.knowledgeDataTemplateId);
    }
    request.append('categoryId', values.categoryId!);
    request.append('accessLevel', values.accessLevel!);
    values.documents?.forEach(x => {
      const t = x as File;
      request.append('documents', t['originFileObj'], t.name);
    });
    return client.addKnowledge(request as any);
  }

  EditKnowledge(values: IEditKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditKnowledgeCommandRequest(values);
    return client.editKnowledge(request);
  }

  removeDocument(values: IDeleteDocumentCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditKnowledgeCommandRequest(values);
    return client.deleteDocument(request);
  }

  GetKnowledgeSummaryReportData(values?: IGetKnowledgeSummaryReportDataQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeSummaryReportDataQueryRequest(values);
    return client.getKnowledgeSummaryReportData(request);
  }

  GetKnowledgeContributorReportData(values?: IGetKnowledgeSummaryReportDataQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeSummaryReportDataQueryRequest(values);
    return client.getKnowledgeContributorReportData(request);
  }

  CanAccessKnowledge(id: string, showLoading = true, handleGeneralError = true) {
    const client = new SecuredClient();
    client.showLoading = showLoading;
    client.handleGeneralError = handleGeneralError;
    const request = new CanUserAccessKnowledgeQueryRequest({ knowledgeId: id });
    return client.canUserAccessKnowledge(request);
  }

  GetKnowledgeFileReportData(values?: IGetKnowledgeFileReportDataQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeFileReportDataQueryRequest(values);
    return client.getKnowledgeFileReportData(request);
  }

  AddDocumentsToKnowledge(values: IEditKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    const request = new FormData();
    request.append('knowledgeId', values.id!);
    values.documents?.forEach(x => {
      const t = x as File;
      request.append('documents', t, t.name);
    });
    return client.addDocumentsToKnowledge(request as any);
  }

  ChangeKnowledgeStatus(values?: IChangeKnowledgeStatusCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new ChangeKnowledgeStatusCommandRequest(values);
    return client.changeKnowledgeStatus(request);
  }

  DeleteKnowledge(values?: IDeleteKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new DeleteKnowledgeCommandRequest(values);
    return client.deleteKnowledge(request);
  }

}
