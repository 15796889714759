import React, { SyntheticEvent } from 'react';
import { Row, Form, Input, Button, Col, Tooltip, Icon } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import FormItem from 'antd/lib/form/FormItem';
import PasswordResetHandler from './PasswordResetHandler';
import { IResetPasswordCommandRequest, IRequestPasswordResetCommandRequest } from 'Core/Api/Api';
import { RouteComponentProps, NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import NotificationService from 'Core/NotificationService';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

export class RequestPasswordResetFormWithoutFormAndRouter extends React.Component<Props, State> {
  handler: PasswordResetHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new PasswordResetHandler(props);
  }

  goBack = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  onSumbit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(
      (error, values: IRequestPasswordResetCommandRequest) => {
        if (error) return;

        this.handler.RequestResetPassword(values).then(() => {
          this.props.history.push('/login');
          NotificationService.success('Password reset password email has been sent.');
        });
      }
    );
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col>
          <Form onSubmit={this.onSumbit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label={<span>
                Email Address&nbsp;
                <Tooltip title="Enter the email address you registered to reset your password.">
                  <Icon type="question-circle-o" />
                </Tooltip>
              </span>}
              name="emailAddress">
              <Input></Input>
            </FormControl>
            <FormItem>
              <CenterAlignedRow>
                <Col>
                  <SubmitButton htmlType="submit">Send Reset Password Email</SubmitButton>
                </Col>
              </CenterAlignedRow>
              <CenterAlignedRow>
                <Col>
                  <Button type="link" onClick={this.goBack}>
                    Go Back
                  </Button>
                </Col>
              </CenterAlignedRow>
            </FormItem>
          </Form>
        </Col>
      </Row>
    );
  }
}

const RequestPasswordResetFormWithoutRouter = Form.create<Props>({ name: 'ForgetPassword' })(
  RequestPasswordResetFormWithoutFormAndRouter
);

const RequestPasswordResetForm = withRouter(RequestPasswordResetFormWithoutRouter);

export default RequestPasswordResetForm;
