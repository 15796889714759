import React from 'react';
import { Row, Col } from 'antd';
import { Container } from './styles';
import { TableRequestMethod } from 'components/Forms/DataGrid/SimpleViewList/SimpleViewList';
import { Knowledge } from 'Core/Api/Api';
import SimpleKnowledgeList from './SimpleKnowledgeList';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';

interface Props {
  title?: string;
  DataFetcherMethod?: TableRequestMethod<Knowledge>
}

const SimpleKnowledgeListView: React.FC<Props> = (props) => {
  return (
    <Row>
      <Col>
        <Row>
          <Col> <h1>{props.title}</h1></Col>
        </Row>
        <Row>
          <TableFilterContextProvider>
            <Col><SimpleKnowledgeList DataFetcherMethod={props.DataFetcherMethod} /></Col>
          </TableFilterContextProvider>
        </Row>
      </Col>

    </Row>
  );
};

export default SimpleKnowledgeListView;
