import * as React from 'react';
import KnowledgeCommentHandler from 'components/Knowledge/KnowledgeCommentHandler';
import { Form, Row, Button } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import TextArea from 'antd/lib/input/TextArea';
import FormControl from 'components/Forms/FormControl';
import ValidationUtility from 'Core/ValidationUtility';
import DetailsMode from 'Core/models/DetailsMode';
import { AddCommentCommandRequest } from 'Core/Api/Api';
import NotificationService from 'Core/NotificationService';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps {
  children?: React.ReactNode;
  knowledgeId: string;
  replyCommentId?: string;
  commentAddedUpdated?: Function;
  Canceled?: Function;
  showCancel?: boolean;
}

export interface State { }

export class CommentFormWithoutForm extends React.Component<Props, State> {
  handler: KnowledgeCommentHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new KnowledgeCommentHandler(props);
  }

  onCancel = (event: React.SyntheticEvent) => {
    if (this.props.Canceled) {
      this.props.Canceled();
    }
  };

  onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, values: AddCommentCommandRequest) => {
      if (error) {
        return;
      }
      values.knowledgeId = this.props.knowledgeId;
      values.replyCommentId = this.props.replyCommentId;

      this.handler.AddComment(values).then(x => {
        NotificationService.success(`Comment Added`);
        this.props.form.setFieldsValue({ comment: '' });
        if (this.props.commentAddedUpdated) {
          this.props.commentAddedUpdated();
        }
      });
    });
  };

  render() {
    var { getFieldDecorator } = this.props.form;

    return (
      <Row>
        <Form layout="vertical" onSubmit={this.onSubmit}>
          <FormControl
            getFieldDecorator={getFieldDecorator}
            rules={[...ValidationUtility.required('comment is required')]}
            name="comment"
            label="comment">
            <TextArea rows={3} />
          </FormControl>
          <Form.Item>
            <SubmitButton htmlType="submit" type="primary">
              Add Comment
            </SubmitButton>
            {this.props.showCancel && (
              <Button style={{ marginLeft: '10px' }} onClick={this.onCancel} type="danger">
                Cancel
              </Button>
            )}
          </Form.Item>
        </Form>
      </Row>
    );
  }
}

const CommentForm = Form.create<Props>({
  name: 'Comment',
})(CommentFormWithoutForm);

export default CommentForm;
