import * as React from 'react';
import styled from 'styled-components';
import { Transfer } from 'antd';
import SimpleTransfer from '.';
import Text from 'antd/lib/typography/Text';

export const StrechSimpleTransfer = styled(SimpleTransfer)`
  /* .ant-transfer-list {
    min-width: 50vw;
  } */
`;
