import DetailsMode from '../DetailsMode';

export default class DetailsData {
  constructor(mode?: DetailsMode, id?: string, pageTitle?: string, extraData?: any) {
    this.mode = mode ?? DetailsMode.New;
    this.id = id;
    this.pageTitle = pageTitle;
    this.extraData = extraData;
  }
  mode: DetailsMode;
  id?: string;
  pageTitle?: string;
  extraData?: any;
}
