import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { User, SlimUser, Operations, UserProfileListItemModel, IUserProfileListItemModel } from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
import { Row, Col, Button, Icon } from 'antd';
import { NavButton } from 'components/Forms/NavButton';
import ProfileHandler from 'components/UserProfile/ProfileHandler';
import DetailsData from 'Core/models/DetailsData';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  roles: IFilterSelectOption[];
}

export default class UserDirectoryListTable extends React.Component<Props, State> {
  handler: ProfileHandler;
  tableRef = React.createRef<SimpleTable<any>>();

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      roles: [],
    };

    this.handler = new ProfileHandler(props);
  }

  userDirectoryListTableColumns: Array<ColumnProps<IUserProfileListItemModel>> = [
    {
      title: 'Name',
      render: (text: any, record: IUserProfileListItemModel, index: number) => {
        const state: DetailsData = {
          mode: DetailsMode.View,
          id: record.id ?? record.userName,
          pageTitle: `${record?.title ?? ""} ${record.firstName} ${record.lastName} Profile`
        }
        const path = `./user-profile/${encodeURI(record.userName ?? "User")}`;

        return <NavLink to={{ pathname: path, state }} >{`${record?.title ?? ""} ${record.firstName} ${record.lastName}`}</NavLink>;
      },
    },
    {
      title: 'Department',
      dataIndex: 'department',
    },
    {
      title: 'Organization',
      dataIndex: 'organization',
    },
    {
      title: 'Juristiction',
      dataIndex: 'jurisdictionName',
    },
    {
      render: (text: any, record: IUserProfileListItemModel, index: number) => {

        const state: DetailsData = {
          mode: DetailsMode.View,
          id: record.id ?? record.userName,
          pageTitle: `${record?.title ?? ""} ${record.firstName} ${record.lastName} Profile`
        }
        const path = `./user-profile/${encodeURI(record.userName ?? "User")}`;
        return (
          <NavLink
            to={{
              pathname: path,
              state: state,
            }}>
            <Button><Icon type="read" /></Button>
          </NavLink>
        );
      },
    }
  ];

  render() {
    return (
      <SimpleTable<IUserProfileListItemModel>
        tableKey="id"
        ref={this.tableRef}
        dataFetcher={this.handler}
        columns={this.userDirectoryListTableColumns}
      />
    );
  }
}
