import { Knowledge } from 'Core/Api/Api';
import React, { useDebugValue } from 'react';
import { UpdatableContext } from './UpdatableContext';

const data = undefined;
const Update = () => { };

const NewKnowledgeShareContext = React.createContext<UpdatableContext<Knowledge>>({ data, Update });

export default NewKnowledgeShareContext;



