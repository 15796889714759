import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form, Icon, Button, Tooltip, Radio } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, IFilter, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility';
import RoleHandler from 'components/Admin/RoleHandler';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import ShowIfTrue from './../../../Forms/ShowIfTrue';
import UserHandler from 'components/Admin/UserHandler';
import { RadioChangeEvent } from 'antd/lib/radio';
import { TableFilterContext } from 'components/Forms/DataGrid/TableFilterContextProvider';
import { MarkedFilter } from 'components/Forms/DataGrid/FilterFieldWrapper/FilterFieldWrapper';
import { orderBy } from 'lodash';

export interface Props {
  children?: React.ReactNode;
  isPublic?: boolean;
}
const documentTypes = [
  { text: 'Audio', value: 'aac,ogg,m4a,wav,aiff,ftr,flac,wma,oga,pcm' },
  { text: 'Documents', value: 'pdf,docx,xslx,csv' },
  { text: 'Video', value: 'mp4,mov,mkv,mp3' },
];
const knowledgeStatus = [
  { text: 'Draft', value: 0 },
  { text: 'Published', value: 1 },
];
const mineType = [
  'mp4',
  'mov',
  'mkv',
  'mp3',
  'aac',
  'ogg',
  'm4a',
  'wav',
  'aiff',
  'ftr',
  'flac',
  'wma',
  'oga',
  'pcm',
  'pdf',
  'docx',
  'xslx',
  'csv',
];

export interface State {
  categories: IFilterSelectOption[];
  jurisdiction: IFilterSelectOption[];
  advance?: boolean;
  joinType: JoinType;
  joinTypes?: { label: string; value: any }[];
}
export default class AdvanceKnowledgeListFilterBar extends React.Component<Props, State> {
  handler: KnowledgeHandler;
  userHandler: UserHandler;
  basicSearchFields = 'title,description,templateSearchData,category.Name,documents.Name,documents';

  static contextType = TableFilterContext;
  context!: React.ContextType<typeof TableFilterContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
      jurisdiction: [],
      joinType: JoinType.And,
    };
    this.handler = new KnowledgeHandler(props);
    this.userHandler = new UserHandler(props);
  }

  componentDidMount() {
    this.getCategories();
    this.getJurisdictions();
    this.getJoinTypes();
  }

  getJurisdictions() {
    this.userHandler.GetTop100Jurisdictions().then(x => {
      const jurisdiction: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdiction: orderBy(jurisdiction, 'text', 'asc') });
    });
  }

  componentDidUpdate() {
    if (this.context.data && this.state.advance == undefined) {
      debugger;
      if (
        this.context.data?.filters?.some(
          (x: MarkedFilter) => x.filterId && x.filterId != this.basicSearchFields
        )
      ) {
        this.setState({ advance: true });
      } else {
        this.setState({ advance: true });
      }
    }
  }

  getJoinTypes() {
    let joinTypes =
      Utility.enumToSelectionOptionArray(JoinType).map(y => ({
        label: y.text.toUpperCase(),
        value: y.value.toLowerCase(),
      })) ?? [];
    this.setState({ joinTypes });
  }

  getCategories() {
    this.handler.GetTop100KnowledgeCategories().then(x => {
      const categories: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories: orderBy(categories, 'text', 'asc') });
    });
  }

  JoinTypeChange = (event: RadioChangeEvent) => {
    if (event.target.value == 'and') {
      this.setState({ joinType: JoinType.And });
    } else {
      this.setState({ joinType: JoinType.Or });
    }
  };

  render() {
    return (
      <FilterBar>
        <ShowIfTrue condition={!this.state.advance}>
          <Row>
            <Col span={24}>
              <Form.Item labelAlign="left">
                <FilterTextbox
                  filterFields={this.basicSearchFields}
                  filterType={FilterType.Contains}
                  filterJoin={JoinType.Or}
                  allowClear={true}
                  splitValue={true}
                  placeholder={'Search'}
                  suffix={
                    <Tooltip title="Search title, description, category">
                      <Icon type="search" style={{ color: 'rgba(0,0,0,.45)' }} />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </ShowIfTrue>
        <Row justify={this.state.advance ? 'space-between' : 'end'} type="flex">
          <ShowIfTrue condition={this.state.advance}>
            <Col md={12} xs={{ span: 16 }}>
              <span>Filter Type: </span>
              <Radio.Group
                options={this.state.joinTypes}
                onChange={this.JoinTypeChange}
                value={this.state.joinType}
              />
            </Col>
          </ShowIfTrue>
          <Col style={{ textAlign: 'right' }} xs={this.state.advance ? { span: 8 } : 12} md={12}>
            {/* <Button type="link" onClick={x => this.setState({ advance: !this.state.advance })}>
              {this.state.advance ? 'Basic' : 'Advanced'} Search
            </Button> */}
          </Col>
        </Row>
        <ShowIfTrue condition={this.state.advance}>
          <Row>
            <Col sm={12} xs={24}>
              <Form.Item labelAlign="left">
                <FilterTextbox
                  filterFields="title"
                  filterType={FilterType.Contains}
                  filterJoin={this.state.joinType}
                  allowClear={true}
                  splitValue={true}
                  placeholder={'Search Title'}
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24}>
              <Form.Item labelAlign="left">
                <FilterDateRangePicker
                  style={{ width: '100%' }}
                  filterFields="creationDate"
                  filterType={FilterType.Group}
                  filterJoin={this.state.joinType}
                  allowClear={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row></Row>
          {/* <Row>
          <Col span={24}>
            <Form.Item labelAlign="left">
              <FilterTextbox
                filterFields="createdBy"
                filterJoin={this.state.joinType}
                suffix={<InfoIcon text="Search Author" />}
              />
            </Form.Item>
          </Col>
        </Row> */}
          <Row>
            <Col md={6} sm={12} xs={24}>
              <Form.Item labelAlign="left">
                <FilterSelectbox
                  filterFields="categoryId"
                  filterType={FilterType.Equal}
                  showSearch={true}
                  mode={'multiple'}
                  allowClear={true}
                  filterJoin={this.state.joinType}
                  placeholder={'Select Categories'}
                  options={this.state.categories}
                />
              </Form.Item>
            </Col>
            <Col md={8} sm={12} xs={24}>
              <Form.Item labelAlign="left">
                <FilterSelectbox
                  filterFields="jurisdictionId"
                  filterType={FilterType.Equal}
                  showSearch={true}
                  mode={'multiple'}
                  allowClear={true}
                  filterJoin={this.state.joinType}
                  placeholder={'Select Jurisdiction'}
                  options={this.state.jurisdiction}
                />
              </Form.Item>
            </Col>
            <Col md={10} xs={24}>
              <Form.Item labelAlign="left">
                <FilterTextbox
                  filterFields="description,templateSearchData"
                  filterType={FilterType.Contains}
                  allowClear={true}
                  splitValue={true}
                  filterJoin={this.state.joinType}
                  placeholder={'Search Description'}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row></Row>
          <Row>
            <Col md={6} sm={11} xs={24}>
              <Form.Item labelAlign="left">
                <FilterSelectbox
                  filterFields="Documents.DataFile.Extension"
                  filterType={FilterType.Contains}
                  showSearch={true}
                  mode="multiple"
                  allowClear={true}
                  suffixIcon={<Icon type="down"></Icon>}
                  valueTransform={x => (x as string[]).flatMap(a => [...a.split(',')])}
                  filterJoin={JoinType.Or}
                  placeholder={'Search File Type'}
                  options={documentTypes}
                />
              </Form.Item>
            </Col>
            {!this.props.isPublic && (
              <Col md={8} sm={12} xs={24}>
                <Form.Item labelAlign="left">
                  <FilterSelectbox
                    filterFields="status"
                    filterType={FilterType.Equal}
                    showSearch={true}
                    mode="multiple"
                    allowClear={true}
                    suffixIcon={<Icon type="down"></Icon>}
                    filterJoin={this.state.joinType}
                    placeholder={'Search Knowledge Status'}
                    options={knowledgeStatus}
                  />
                </Form.Item>
              </Col>
            )}
            <Col md={10} xs={24}>
              <Form.Item labelAlign="left">
                <FilterTextbox
                  filterFields="documents"
                  filterType={FilterType.Contains}
                  allowClear={true}
                  filterJoin={this.state.joinType}
                  placeholder={'Search Documents'}
                  ignore={true}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row></Row>
        </ShowIfTrue>
      </FilterBar>
    );
  }
}
