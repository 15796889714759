import { KnowledgeSummaryReportView } from 'components/Knowledge/KnowledgeSummaryReportView';
import PageContainer from 'components/Layout/PageContainer';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
}

export default ({ }: Props) => {
  return (
    <PageContainer title="Knowledge Summary Report">
      <KnowledgeSummaryReportView />
    </PageContainer>
  )
}