import { Modal, Button } from 'antd';
import Card, { CardProps } from 'antd/lib/card';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import NewKnowledgeShareContext from 'Context/NewKnowledgeShareContext';
import { AccessLevel, Knowledge } from 'Core/Api/Api';
import AuthUser from 'Core/models/AuthUser';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import Utility from 'Core/Utility';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';

interface Props extends CardProps {
  knowledge?: Knowledge;
  user?: AuthUser;
  pathRoute?: string;
  hasPermission?: boolean;
}

export default ({ knowledge, user, pathRoute, hasPermission, ...rest }: Props) => {

  let newKnowledgeShareContext = useContext(NewKnowledgeShareContext);
  let [isCheckingAccess, setIsCheckingAccess] = useState<boolean>(false);
  let handler: KnowledgeHandler = new KnowledgeHandler({});
  let history = useHistory();

  let getNavigationState = (item: Knowledge, detailsMode: DetailsMode = DetailsMode.Edit) => {
    let path = pathRoute;
    if (detailsMode == DetailsMode.Edit) {
      path = path?.replace("/v", "");
    }
    return {
      pathname: `/${path}/${encodeURIComponent(item.title ?? '')}`,
      state: new DetailsData(detailsMode, item.id, item.title),
    }
  }

  let navToKnowledgeDetails = (event: React.SyntheticEvent) => {
    event.persist();
    event.stopPropagation();
    const isPublic = knowledge?.accessLevel == AccessLevel.Public;
    const { pathname, state } = getNavigationState(knowledge!, DetailsMode.View);

    if (isPublic || hasPermission) {
      history.push(pathname, state);
    } else {
      // Check if user has access
      handler.CanAccessKnowledge(knowledge?.id!).then(x => {
        setIsCheckingAccess(true);
        if (x.canAccess) {
          history.push(pathname, state);
        } else {
          Modal.confirm({
            title: 'No Access',
            content: renderNoAccessContent,
            okText: 'Request Access',
            maskClosable: true,
            cancelText: 'Cancel',
            onOk: () => requestAccessClicked(),
          });
        }

      }).finally(() => setIsCheckingAccess(false));
    }
  }

  let renderNoAccessContent = (
    <>
      <p>You do not have access to view knowledge.</p>
      <p>
        <ul>
          <li>You can login with credentials that do. <Button style={{ padding: '0px' }} type="link" onClick={e => history.push("/login") == Utility.voidmethod() && Modal.destroyAll() == Utility.voidmethod()} >Click here to Login</Button></li>
          <li>If you do not have a login or desire to access knowledge, you can request access. <Button style={{ padding: '0px' }} type="link" onClick={e => requestAccessClicked() == Utility.voidmethod() && Modal.destroyAll() == Utility.voidmethod()} >Click here to request access</Button></li>
        </ul>
      </p>
    </>
  );

  let requestAccessClicked = () => {
    if (user?.id) {
      newKnowledgeShareContext.Update(knowledge);
    } else {
      history.push("/register");
    }
  }

  return <Card {...rest} onClick={navToKnowledgeDetails} loading={isCheckingAccess} />;
}