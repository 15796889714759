import React from 'react';
import { Form, Row, Input, Col, Button, Select, Typography, Descriptions, Tag } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import UserHandler from '../UserHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
    UserStatus,
    SlimUser,
    User,
    EditUserCommandRequest,
    AddUserCommandRequest,
    IRole,
    IRoleListView,
    Operations,
    GetUserByIdModel,
    GetUserRegistrationByIdModel,
    IApproveKnowledgeShareCommandRequest,
    IRejectKnowledgeShareCommandRequest,
    KnowledgeShare,
    KnowledgeShareStatus,
    AccessLevel,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import {
    singleColFormColSize,
    FullWithButton,
    doubleColFormColSize,
    singleCol2FormColSize2,
    singleCol2FormColSizeForUserForm,
} from '../../Forms/Form.Styled';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import RegistrationHandler from '../RegistrationHandler';
import TextArea from 'antd/lib/input/TextArea';
import { ApprovalCard } from './ApprovalCard';
import { SubmitButton } from 'components/Forms/SubmitButton';
import KnowledgeShareHandler from '../KnowledgeShareHandler';
import { UserProfileLink } from 'components/UserProfile/UserProfileLink';
import { HideContentSimpleSelect } from 'components/Knowledge/KnowledgeDetailsForm/KnowledgeDetailsForm.Styled';
import { SimpleKnowledgeTable } from './SimpleKnowledgeTable';
import { DisplayInput, DisplayTextArea } from 'components/Styled/Form/DisabledFields';
import ConfigService from 'Core/ConfigService';
import moment from 'moment';

const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
    children?: React.ReactNode;
}

export interface State {
    status: ISelectOption[];
    detailsMode: DetailsMode;
    accessLevels?: ISelectOption[];
    knowledgeShare?: KnowledgeShare,
    canApprove?: boolean;
}

export class KnowledgeShareApprovalDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    config = ConfigService.getSync();
    handler: KnowledgeShareHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            status: [],
            detailsMode: DetailsMode.New,
        };
        this.handler = new KnowledgeShareHandler(props);
    }

    componentDidMount() {
        this.getStatus();
        this.getKnowledgeShare();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
    }

    getKnowledgeShare() {
        const urlState = this.props.location.state as { id: string; mode: DetailsMode } | undefined;
        const query = new URLSearchParams(this.props.location.search);

        if ((urlState && urlState.mode == DetailsMode.View) || !!query.get("uid")) {
            let id = urlState?.id ?? query.get("id")!;
            this.handler.GetSharedKnowledgeRequestById(id).then(
                x => {
                    this.setState({ knowledgeShare: x.knowledgeShare, detailsMode: DetailsMode.View, canApprove: this.canUserApprove(x.knowledgeShare!) });
                    this.props.form.setFieldsValue({ ...x.knowledgeShare, expiryDate: moment(x?.knowledgeShare?.expiryDate).format(this.config.Display.DateFormat) } ?? {});
                },
                x => NotificationService.error(x.message)
            );
        } else {
            this.setState({ detailsMode: DetailsMode.New });
        }
    }

    getAccessLevel() {
        var accessLevels: ISelectOption[] = [];
        Utility.enumToMap(AccessLevel).forEach((x, y) => {
            if (x == 'NoAccess') return;
            let text = (x as string).replace('Level', 'Level ');
            accessLevels.push({ text: <div>{text} <i>{this.accessLevelDescription(x)}</i></div>, value: y });
        });
        accessLevels.push({ text: "Select Specific Knowledge(s)...", value: -1 });
        this.setState({ accessLevels });
    }

    accessLevelDescription = (accessLevel?: AccessLevel) => {
        if (!accessLevel) {
            return <></>;
        }
        return Utility.RenderAccessLevelDescriptions(accessLevel, "100%");
    }

    canUserApprove(knowledgeShare: KnowledgeShare) {

        let isApprovable = knowledgeShare.knowledgeShareStatus == KnowledgeShareStatus.Pending;
        let hasThisUserAlreadyApproved = knowledgeShare.knowledgeShareApprovals?.some(x => x.approverId == this.context.data?.id);
        let isInJurisdiction = knowledgeShare.jurisdictionId == this.context.data?.jurisdictionId;
        return isApprovable && !hasThisUserAlreadyApproved && isInJurisdiction;
    }

    getStatus() {
        var status: ISelectOption[] = [];
        Utility.enumToMap(KnowledgeShareStatus).forEach((x, y) => status.push({ text: x, value: y }));
        this.setState({ status });
    }

    approve = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: IApproveKnowledgeShareCommandRequest) => {
            if (error && error.roles) {
                return;
            }

            const request: IApproveKnowledgeShareCommandRequest = {};
            request.id = this.state.knowledgeShare?.id;
            request.comment = values.comment;
            this.handler.ApproveKnowledgeShare(request).then(x => {
                this.props.history.goBack();
                NotificationService.success(`Approved ${this.state.knowledgeShare?.description}`, null, 10, true);
            });

        });
    };

    reject = (event: React.FormEvent) => {
        event.preventDefault();

        this.props.form.validateFieldsAndScroll((error, values: IRejectKnowledgeShareCommandRequest) => {
            if (error && error.comment) {
                return;
            }

            const request: IRejectKnowledgeShareCommandRequest = {};
            request.id = this.state.knowledgeShare?.id;
            request.comment = values.comment;

            this.handler.RejectKnowledgeShare(request).then(x => {
                this.props.history.goBack();
                NotificationService.success(`Rejected ${this.state.knowledgeShare?.description}`, null, 10, true);
            });
        });
    };

    render() {
        var { getFieldDecorator } = this.props.form;
        return (
            <Row>
                <Col>
                    <Form layout="horizontal" >
                        <Row gutter={16}>
                            <Col xl={10} offset={1} sm={16}>
                                <Row>
                                    <Col md={24} >
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="description"
                                            label="Subject">
                                            <DisplayInput disabled={true} />
                                        </FormControl>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="notes"
                                            label="Notes">
                                            <DisplayTextArea disabled={true} />
                                        </FormControl>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="requestorName"
                                            label="Requestor">
                                            <Tag closable={false}><UserProfileLink userId={this.state.knowledgeShare?.requesterId} >{this.state.knowledgeShare?.requester?.firstName} {this.state.knowledgeShare?.requester?.lastName}</UserProfileLink></Tag>
                                        </FormControl>

                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="accessors"
                                            label="Accessors">
                                            <>
                                                {this.state.knowledgeShare?.accessors?.map(accessor => (
                                                    <Tag closable={false}><UserProfileLink userId={accessor.userId} >{`${accessor.user?.firstName} ${accessor.user?.lastName}`}</UserProfileLink></Tag>

                                                ))}
                                            </>
                                        </FormControl>
                                        <FormControl
                                            getFieldDecorator={getFieldDecorator}
                                            name="expiryDate"
                                            label="Access End Date">
                                            <DisplayInput disabled={true} />
                                        </FormControl>
                                        <ShowIfTrue condition={this.state.knowledgeShare?.requestedAccessLevel}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('Access Level is required')]}
                                                name="requestedAccessLevel"

                                                extra={<span>{this.accessLevelDescription(this.props.form.getFieldValue("accessLevel"))}</span>}
                                                label="Access Level">
                                                <HideContentSimpleSelect disabled={true} style={{ background: "#F0F2F5", color: "black" }} options={this.state.accessLevels}></HideContentSimpleSelect>
                                            </FormControl>
                                        </ShowIfTrue>

                                        <ShowIfTrue condition={!!this.state.knowledgeShare?.knowledgeShareKnowledges?.length}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                name="Knowledges"

                                                extra={<span>{this.accessLevelDescription(this.props.form.getFieldValue("accessLevel"))}</span>}
                                                label="Knowledges">
                                                <SimpleKnowledgeTable knowledges={this.state.knowledgeShare?.knowledgeShareKnowledges?.map(x => x.knowledge!) ?? []} />
                                            </FormControl>
                                        </ShowIfTrue>

                                        <ShowIfTrue condition={this.state.detailsMode != DetailsMode.New}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                name="knowledgeShareStatus"
                                                label="Status">
                                                <DisplayInput disabled={true} />
                                            </FormControl>
                                        </ShowIfTrue>

                                    </Col>
                                </Row>

                            </Col>
                            <Col xl={{ span: 11, offset: 1 }} sm={17}>
                                <Typography.Title level={3}>Approvals</Typography.Title>
                                {this.state.knowledgeShare?.knowledgeShareApprovals?.map(approval => {
                                    return (<Row>
                                        <ApprovalCard approval={approval} />
                                    </Row>)
                                })}
                                <ShowIfTrue condition={this.state.canApprove}>
                                    <Row style={{ border: "1px solid lightgray", padding: '30px', marginTop: '10px' }}>
                                        <Typography.Text>
                                            <ul>
                                                <li>Comments are required</li>
                                                <li>For Rejection, comments will be sent the user explaining why the request was rejected.</li>
                                            </ul>
                                        </Typography.Text>
                                        <Col span={24}>
                                            <FormControl
                                                getFieldDecorator={getFieldDecorator}
                                                rules={[...ValidationUtility.required('comment is required')]}
                                                name="comment"
                                                label="Comments">
                                                <TextArea rows={4} />
                                            </FormControl>
                                        </Col>

                                        <Col span={24}>
                                            <SubmitButton type="primary" onClick={this.approve}>
                                                Approve
                                            </SubmitButton>
                                            <SubmitButton type="danger" onClick={this.reject} style={{ marginLeft: '20px' }}>
                                                Reject
                                            </SubmitButton>
                                        </Col>
                                    </Row>
                                </ShowIfTrue>
                            </Col>
                        </Row>
                    </Form>


                </Col>
            </Row>
        );
    }
}

const KnowledgeShareApprovalDetailsFormWithRouter = Form.create<Props>({
    name: 'KnowledgeShareApprovalDetailsForm',
})(KnowledgeShareApprovalDetailsFormWithoutFormAndRouter);

const KnowledgeShareApprovalDetailsForm = withRouter(KnowledgeShareApprovalDetailsFormWithRouter);

export default KnowledgeShareApprovalDetailsForm;
