import * as React from 'react';
import ConfirmResetPasswordForm from 'components/UserManagement/ConfirmResetPasswordForm';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class ResetPasswordPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return <ConfirmResetPasswordForm />;
  }
}
