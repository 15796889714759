import * as React from 'react';
import { Layout, Row, Col } from 'antd';
import { AppRoutes } from '../../../Core/AppRoutes';
import TopBar from './TopBar';
import ILayout from '../../../Core/models/ILayout';
const { Content, Footer } = Layout;

export interface Props extends ILayout {
  children?: React.ReactNode;
}

export interface State {}

export default class ClassicLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Layout className="react-transition fade-in" style={{ animationDuration: '750ms' }}>
        <TopBar menu={AppRoutes} />
        <Row type="flex" justify="center" style={{ minHeight: '85vh' }}>
          <Content>
            <Row type="flex" align="middle" justify="center">
              <Col md={24}>{this.props.children}</Col>
            </Row>
          </Content>
        </Row>
        <Footer>Footer</Footer>
      </Layout>
    );
  }
}



