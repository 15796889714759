import React from 'react';
import { Form, Row, Input, Col, Button, Select, Typography } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import UserHandler from '../UserHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
  UserStatus,
  SlimUser,
  User,
  EditUserCommandRequest,
  AddUserCommandRequest,
  IRole,
  IRoleListView,
  Operations,
  GetUserByIdModel,
  RoleStatus,
  RoleListView,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import {
  singleColFormColSize,
  FullWithButton,
  doubleColFormColSize,
  singleCol2FormColSize2,
  singleCol2FormColSizeForUserForm,
} from '../../Forms/Form.Styled';
import MaskedInputV2 from 'components/Forms/MaskedInputV2';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import AuthContext from 'Context/AuthContext';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { SubmitButton } from 'components/Forms/SubmitButton';


const { Title } = Typography;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  jurisdictions: ISelectOption[];
  roles: ISelectOption[];
  AllRoles: IRoleListView[];
  status: ISelectOption[];
  user?: GetUserByIdModel;
  detailsMode: DetailsMode;
}

export class UserDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: UserHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      jurisdictions: [],
      roles: [],
      AllRoles: [],
      status: [],
      detailsMode: DetailsMode.New,
    };
    this.handler = new UserHandler(props);
  }

  componentDidMount() {
    this.getJurisdictions();
    this.getRoles();
    this.getStatus();
    this.getUserDetails();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.user !== prevState.user) {
      let jurisdictionId = this.context.data?.jurisdictionId ?? this.state.user?.jurisdictionId;
      this.updateRoleList(jurisdictionId);
      this.props.form.setFieldsValue({
        roleIds: this.state.user?.roleIds ?? [],
      });
    }
  }

  getUserDetails() {
    const urlState = this.props.location.state as { userId: string; mode: DetailsMode } | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetUserById(urlState.userId).then(
        x => {
          this.setState({ user: x.user, detailsMode: DetailsMode.Edit });
          this.props.form.setFieldsValue(x.user ?? {});
        },
        x => NotificationService.error(x.message)
      );
    } else {
      this.setState({ detailsMode: DetailsMode.New });
    }
  }

  getStatus() {
    var status: ISelectOption[] = [];
    Utility.enumToMap(UserStatus).forEach((x, y) => status.push({ text: x, value: y }));
    this.setState({ status });
  }

  getJurisdictions() {
    this.handler.GetTop100Jurisdictions().then(x => {
      const jurisdictions: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions });
    });
  }

  getRoles() {
    this.handler.GetFilteredRoles().then(x => {
      // Filter out disabled roles unless it is currently assigned
      this.setState({ AllRoles: x.results ?? [] }, () => {
        let jurisdictionId = this.context.data?.jurisdictionId ?? this.state.user?.jurisdictionId;
        this.updateRoleList(jurisdictionId);
        this.props.form.setFieldsValue({
          roleIds: this.state.user?.roleIds ?? [],
        });
      });
    });
  }

  updateRoleList = (jurisdictionId?: string) => {
    let isActiveOrAssigned = (x: IRoleListView) => x.status == RoleStatus.Active || this.state.user?.roleIds?.some(y => y == x.id);

    let filteredRoles: IRoleListView[];
    if (jurisdictionId) {
      filteredRoles = this.state.AllRoles.filter(isActiveOrAssigned).filter(x => x.jurisdictionId == jurisdictionId) ?? [];
    } else {
      filteredRoles = this.state.AllRoles ?? [];
    }

    const roles: IFilterSelectOption[] =
      filteredRoles?.map(y => ({
        text: y.name,
        value: y.id,
      })) ?? [];
    this.setState({ roles });
    this.props.form.setFieldsValue({ roleIds: null })
  };

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, values: GetUserByIdModel) => {
      if (error) {
        return;
      }

      if (this.state.detailsMode == DetailsMode.Edit) {
        const request = new EditUserCommandRequest();
        request.id = this.state.user?.id;
        request.firstName = values.firstName;
        request.lastName = values.lastName;
        request.title = values.title;
        request.organization = values.organization;
        request.phoneNumber = values.phoneNumber;
        request.status = values.status;

        if (this.context.data?.jurisdictionId) {
          request.jurisdictionId = this.context.data.jurisdictionId;
        } else {
          request.jurisdictionId = values.jurisdictionId;
        }

        this.setRoles(request, values.roleIds as any);

        console.log(values, request);
        this.handler.EditUser(request).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Updated ${x.user?.username}`, undefined, 10, true);
        });
      } else {
        const request = new AddUserCommandRequest();
        request.emailAddress = values.emailAddress;
        request.firstName = values.firstName;
        request.lastName = values.lastName;
        request.title = values.title;
        request.organization = values.organization;
        request.phoneNumber = (values as any).phoneNumber;

        if (this.context.data?.jurisdictionId) {
          request.jurisdictionId = this.context.data.jurisdictionId;
        } else {
          request.jurisdictionId = values.jurisdictionId;
        }

        this.setRoles(request, values.roleIds as any);
        console.log(request);
        this.handler.AddUser(request).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Added ${x.newUser?.username}`, undefined, 10, true);
        });
      }
    });
  };

  CanCreateNonRegionUser(): boolean {
    return Utility.HasOperations(this.context.data, [Operations.Edit_User]);
  }

  setRoles(request: { roles?: string[] }, arg1: any) {
    if (arg1.map) {
      request.roles = arg1;
    } else {
      request.roles = [];
      request.roles.push(arg1);
    }
  }

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col>
          <Form layout="vertical" onSubmit={this.onSubmit}>
            <Row>
              <Col md={24} xl={8} >

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[
                    ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                      ...ValidationUtility.required('email is required'),
                      ...ValidationUtility.email(),
                    ]),
                  ]}
                  name="emailAddress"
                  label="Email / Username">
                  <Input disabled={this.state.detailsMode == DetailsMode.Edit} />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('first name is required')]}
                  name="firstName"
                  label="First Name">
                  <Input />
                </FormControl>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('last name is required')]}
                  name="lastName"
                  label="Last Name">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="title"
                  label="Title">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="organization"
                  label="Organization">
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('contact number is required'),
                  ...ValidationUtility.maxItemCount(10, "contact number is not valid")]}
                  name="phoneNumber"
                  label="Contact Number">

                  <MaskedInputV2 format="(###) ###-####" />
                </FormControl>

              </Col>
              <Col offset={1} md={24} xl={8} >
                <ShowIfTrue condition={!this.context.data?.jurisdictionId}>
                  <FormControl
                    getFieldDecorator={getFieldDecorator}
                    rules={[
                      ...ValidationUtility.If(!this.CanCreateNonRegionUser(), [
                        ...ValidationUtility.required('juristiction is required'),
                      ]),
                    ]}
                    name="jurisdictionId"
                    label="Juristiction">
                    <SimpleSelect
                      style={{ width: '100%' }}
                      onChange={this.updateRoleList}
                      options={this.state.jurisdictions}></SimpleSelect>
                  </FormControl>
                </ShowIfTrue>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('role is required')]}
                  name="roleIds"
                  label="Role">
                  <SimpleSelect options={this.state.roles}></SimpleSelect>
                </FormControl>
                <ShowIfTrue condition={this.state.detailsMode != DetailsMode.New}>
                  <FormControl
                    getFieldDecorator={getFieldDecorator}
                    rules={[...ValidationUtility.required('status is required')]}
                    name="status"
                    label="Status">
                    <SimpleSelect options={this.state.status}></SimpleSelect>
                  </FormControl>
                </ShowIfTrue>
              </Col>
            </Row>

            <Row>
              <Col span={2}>
                <Button onClick={() => this.props.history.goBack()} type="default">
                  Cancel
                </Button>
              </Col>
              <Col span={2}>
                <SubmitButton type="primary" htmlType="submit">
                  Submit
                </SubmitButton>
              </Col>

            </Row>
          </Form>
        </Col>
      </Row>
    );
  }
}

const UserDetailsFormWithRouter = Form.create<Props>({
  name: 'UserDetails',
})(UserDetailsFormWithoutFormAndRouter);

const UserDetailsForm = withRouter(UserDetailsFormWithRouter);

export default UserDetailsForm;
