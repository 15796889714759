import { Drawer } from 'antd';
import NewKnowledgeShareContext from 'Context/NewKnowledgeShareContext';
import { KnowledgeShare } from 'Core/Api/Api';
import React, { useContext } from 'react';
import KnowledgeShareDetailsForm from '../KnowledgeShareDetailsForm';
import { useWindowSize } from '../../../Core/useWindowSize/index';
import styled from 'styled-components';
import ScreenHelper from 'components/Styled/ScreenHelper';
interface Props {
  showDraw?: boolean;
  onFinished?: Function;
}

const SizedDrawer = styled(Drawer)`

  .ant-drawer-content-wrapper {
    width: 40% !important;
    ${ScreenHelper.down('xl')} {
      width: 65% !important;
    }
    ${ScreenHelper.down('sm')} {
      width: 95% !important;
    }

  }
`;

export default ({ showDraw, onFinished }: Props) => {

  let data = useContext(NewKnowledgeShareContext);
  let size = useWindowSize()

  let requestCompleted = (knowledgeShare: KnowledgeShare) => {
    onFinished?.call(null);
    data.Update(undefined);
  }

  let onClose = (x: any) => {
    onFinished?.call(null);
    data.Update(undefined);
  }

  return <SizedDrawer title="New Knowledge Share" width="max-content" onClose={onClose} destroyOnClose={true} visible={!!data.data || showDraw}>
    <KnowledgeShareDetailsForm onCancel={onClose} fullWith={true} NewRequestKnowledge={data.data} onRequestComplete={requestCompleted} />
  </SizedDrawer>
}