import * as React from 'react';
import * as Survey from 'survey-react';
import 'survey-react/survey.css';
import { SurveyModel } from 'survey-react';
import * as widgets from 'surveyjs-widgets';

import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker.js';
import 'select2/dist/js/select2.js';
import styled from 'styled-components';
import { forEach } from 'lodash';
window['$'] = window['jQuery'] = $;

console.log();
Survey.StylesManager.applyTheme('default');

const StyledSurveyContainer = styled.div`
  .sv_main.sv_default_css {
    .sv_complete_btn {
      display: none;
    }
    .sv_body {
      border-top: none;
      background-color: #f0f2f5;
    }
    .sv_p_root > .sv_row:nth-child(2n + 1),
    .sv_p_root > .sv_row:nth-child(2n) {
      background-color: transparent;
    }
  }
`;

export interface Props {
  children?: React.ReactNode;
  templateJSON?: string;
  value?: string;
  mode?: 'display' | 'edit';
  onChange?: Function;
}

export interface State {
  template?: string;
  model?: SurveyModel;
  value?: string;
}

export default class SurveyControl extends React.Component<Props, State> {
  static defaultProps: Props = {
    mode: 'edit',
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      model: this.setupModel(),
    };
  }

  componentDidMount() {
    var model = this.setupModel();
    this.setState({ model });
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.templateJSON != prevProps.templateJSON) {
      const model = this.setupModel();

      this.setState({
        value: this.props.value,
        model,
        template: this.props.templateJSON,
      });
    }

    if (this.props.value != prevProps.value && !prevProps.value) {
      if (this.state.model) {
        this.state.model.data = JSON.parse(this.props.value ?? '{}');
      }
    }
  }

  setupModel() {
    const model = new Survey['Model'](JSON.parse(this.props.templateJSON ?? '{}')) as SurveyModel;
    model.onCompleting.add((s, o) => (o.allowComplete = false));
    model.onValueChanged.add(this.onSurveyUpdated);
    model.checkErrorsMode = 'onValueChanged';
    model.onErrorCustomText.add((sender, option) => { if (option.name == "required") option.text = "This field is required"; })

    model.questionTitleTemplate = '{require}{title}:';
    model.data = JSON.parse(this.props.value ?? '{}');

    if (this.props.mode == 'display') {
      model.mode = 'display';
    }

    return model;
  }

  isSurveyValid() {
    var pages = this.state.model?.visiblePages;
    for (var i = 0; i < (pages?.length ?? 0); i++) {
      // hasError(true, false) - show error visually, and do not focus the first error.
      if (pages![i].hasErrors(true, true)) {
      }
    }
    const questions = this.state.model?.getAllQuestions();
    const errors: Survey.SurveyError[] = [];

    // if (questions?.length ?? 0 > 0) {
    //   this.state.model!.data.isValid = false;
    //   return false;
    // }
    questions?.forEach(question => {
      const error = this.state.model?.validateQuestion(question) ?? question.errors?.pop();
      if (error) {
        question.hasErrors(true, false);
        errors.push(error!);
      }
    });

    if (errors?.length) {
      this.state.model!.data.isValid = false;
    } else {
      this.state.model!.data.isValid = true;
    }
    return errors.length == 0;
  }

  onSurveyUpdated = (survey: SurveyModel, options) => {
    var json = JSON.stringify(survey.data);
    this.props.onChange?.call(this, json);
  };

  render() {
    if (this.props.mode == 'display') {
      return (
        <>
          {this.props.templateJSON && this.props.value && (
            <StyledSurveyContainer>
              <Survey.Survey model={this.state.model} />
            </StyledSurveyContainer>
          )}
        </>
      );
    } else {
      return (
        <>
          {this.props.templateJSON && (
            <StyledSurveyContainer>
              <Survey.Survey model={this.state.model} />
            </StyledSurveyContainer>
          )}
        </>
      );
    }
  }
}
