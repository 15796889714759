import * as React from 'react';
import LogoImg from '../../../assets/images/logo_bar.png';
import { NavLink } from 'react-router-dom';
import styled, { StyledComponent } from 'styled-components';
export interface LogoProps {
  linkUrl?: string;
  disableLinking?: boolean;
  style?: React.CSSProperties;
}

const SLogoBackground = styled.div`
  height: 115px;
  background-color: white;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  margin-top: -15px;
  padding-top: 15px;
`;

const SLogo = styled.div`
  height: 117px;
  background: url(${LogoImg});
  background-size: 50%;
  background-position: center center;
  background-repeat: no-repeat;
`;

export default class Logo extends React.PureComponent<LogoProps, any> {
  public render() {
    return (
      <SLogoBackground>
        <SLogo {...this.props}>
          {!this.props.disableLinking && <NavLink to={this.props.linkUrl || '/'} />}
        </SLogo>
      </SLogoBackground>
    );
  }
}
