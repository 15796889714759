import * as React from 'react';
import PageContainer from 'components/Layout/PageContainer';
import PublicKnowledgeListView from 'components/Knowledge/PublicKnowledgeListView';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class EmbeddedPublicKnowledgeListViewPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PublicKnowledgeListView embedded={true} />
    );
  }
}
