import * as React from 'react';
import { Operations } from 'Core/Api/Api';
import AuthContext from 'Context/AuthContext';
import Utility from 'Core/Utility';

export interface Props {
  children?: React.ReactNode;
  operations: Operations[];
}

export interface State {
  show: boolean;
}

export default class ShowIfHavePermission extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidMount() {
    this.setState({ show: Utility.HasOperations(this.context.data, this.props.operations) });
  }

  render() {
    return <>{this.state.show && this.props.children}</>;
  }
}
