import { FAQView } from 'components/Information/FAQView';
import PageContainer from 'components/Layout/PageContainer';
import React from 'react';

interface Props {
}

export default ({ }: Props) => {
  return (<PageContainer title="Frequently Asked Questions" >
    <FAQView />
  </PageContainer >);
}