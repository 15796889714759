import * as React from 'react';
import { Transfer } from 'antd';
import { TransferItem, TransferProps, ListStyle } from 'antd/lib/transfer';

type voidMethod = () => void;
type defaultListStyleType = ((style: ListStyle) => React.CSSProperties) | React.CSSProperties;

export interface Props extends Omit<Omit<TransferProps, 'onChange'>, 'listStyle'> {
  dataSource: TransferItem[];
  onChange?: Function;
  value: [];
  listStyle: defaultListStyleType | voidMethod;
}

export interface State {
  value: [];
}

export default class SimpleTransfer extends React.Component<Props, State> {
  static defaultProps: Props = {
    ...Transfer.defaultProps,
    ...{
      dataSource: [],
      render: x => x.title!,
      showSearch: true,
      titles: ['Avaliable', 'Assigned'],
      value: [],
      listStyle: {
        width: '350px',
        height: '300px',
      },
    },
  };

  handlOnChange = nextTargetKeys => {
    const onchange = this.props.onChange ?? Function;
    onchange(nextTargetKeys);
    this.setState({ value: nextTargetKeys });
  };

  constructor(props: Props) {
    super(props);
    this.state = { value: props.value };
  }

  filterOptions = (inputValue: string, item: TransferItem) => {
    var lowerCase = inputValue?.toLowerCase();
    return item?.title?.toLowerCase()?.indexOf(lowerCase ?? "") != -1;
  }

  render() {
    let { onChange, listStyle, ...rest } = this.props;
    if (listStyle) {
      listStyle = listStyle as defaultListStyleType;
    }
    return (
      <Transfer
        filterOption={this.filterOptions}
        {...rest}

        onChange={this.handlOnChange}
        listStyle={listStyle}
        targetKeys={this.props.value}
      />
    );
  }
}
