import * as React from 'react';
import UserDetailsForm from 'components/Admin/UserDetailsForm';
import PageContainer from 'components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class UsersDetailPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Users">
        <UserDetailsForm />
      </PageContainer>
    );
  }
}
