import { Row, Col } from 'antd';
import React from 'react';
import jury_box from '../../../assets/images/Gavel.jpg'
import { BarbadosJurisdictionInfoView } from '../JurisdictionInfoView/BarbadosJurisdictionInfoView';
import { CCJJurisdictionInfoView } from '../JurisdictionInfoView/CCJJurisdictionInfoView';
import { EasternCaribbeanJurisdictionInfoView } from '../JurisdictionInfoView/EasternCaribbeanJurisdictionInfoView';
import { GuyanaJurisdictionInfoView } from '../JurisdictionInfoView/GuyanaJurisdictionInfoView';
import { CanadianJurisdictionInfoView } from '../JurisdictionInfoView/CanadianJurisdictionInfoView';
import { Collapse } from 'antd';
import { AboutImage } from './AboutusView.Styled';
const { Panel } = Collapse;

interface Props {
}

export default ({ }: Props) => {
    return (
        <Row>
            <Col span={22} offset={1}>
                <AboutImage src={jury_box} ></AboutImage>
                <h2>JUDICIAL REFORM AND INSTITUTIONAL STREGTHENING (JURIST) PROJECT </h2>
                <p>The Judicial Reform and Institutional Strengthening (JURIST) Project is a multi-year (2014-2023) regional judicial reform initiative funded under an arrangement with the Government of Canada. The Project is being implemented on behalf of Global Affairs Canada (GAC) and the Conference of Heads of Judiciary of CARICOM (the Conference), by the Caribbean Court of Justice (CCJ), which was appointed by the Conference as its Regional Executing Agency (REA). The Government of Canada provided Cdn$19.4M, while the CCJ and other regional partners will contribute Cdn$1 .4M and Cdn$2.8M respectively.</p>
                <p>The ultimate goal of the Project is to establish a judicial system that is more responsive to the needs of women, men, youth and the poor. To achieve this goal, the Project is working with judiciaries in the region to support their respective efforts to improve court governance and administration, including the introduction of technology to expedite the management of cases from filing to disposition. Resolving cases fairly, efficiently, and in a timely manner will improve public confidence in the justice system, foster gender equality throughout the courts, reduce backlogs and delays, and make the region more attractive to foreign and domestic investment.</p>
                <h3>Our Approach </h3>
                <p>The JURIST Project organizes regular collaborative planning meetings with Chief Justices and key stakeholders in the justice system in each participating country. Such an inclusive approach allows participants to identify their respective priorities for judicial reform. By consulting stakeholders, the JURIST Project gives voice to their concerns and needs. The initiatives that are funded as a result of this process not only respond to the priorities that are identified, but also have the support and involvement of the stakeholders. These collaborative planning meetings encourage judicial leadership, and local support and buy-in of initiatives, and continue to be a mainstay of the JURIST Project.</p>
                <p>Judiciaries are committed to developing strategies and programs to inform the public about the roles and responsibilities of the courts, the services provided, and how to access these services and programs. There is also support for judicial officers to receive the necessary training to effectively and efficiently manage court administration and case disposition. The need to integrate gender equality throughout the court administration process, including sensitization training for judicial officers is also key to judicial reform efforts throughout the region. </p>
                <p>These priorities are incorporated into a Work Plan and budget that is approved annually by the JURIST Project Steering Committee (PSC). The results of completed initiatives are shared with other participating countries; best practices are identified; and implementing similar initiatives in other judiciaries is encouraged. </p>
                <p>The partnerships that the Project have developed with the judiciaries, Bar Associations, Police, Directors of Public Prosecutions (DPPs) and non-governmental organizations (NGOs) through these collaborative planning meetings remain key to the success of each initiative supported by the JURIST Project and for the overall success of JURIST as a regional judicial reform Project.</p>
                <h3>Expected Results</h3>
                <h4>Ultimate outcome</h4>
                <p>A judicial system that is more responsive to the needs of women, men, youth and the poor. </p>
                <h4>Intermediate Outcome </h4>
                <p>Strengthened, customer-focused and gender-responsive court and judicial service delivery by trial and appellate courts (including the Caribbean Court of Justice) across 14 English Speaking Caribbean countries. </p>
                <h3>Immediate Outcomes and Key Activities </h3>
                <p><b>Immediate Outcome 1: </b> Improved capacity of judicial officers and court administration personnel to deliver gender-responsive court services.</p>
                <p><i>Activities:</i></p>
                <ul>
                    <li>Develop knowledge management infrastructure;</li>
                    <li>Develop training modules on gender-responsive and environmentally sound court administration and adjudication;</li>
                    <li>Deliver training of court administrators and support staff in the core competencies of court administrators (including Performance Management, Strategic Planning, Budgeting and Financial Management, Human Resources Management); and</li>
                    <li>Deliver training of judges, judicial officers and judicial support staff (in areas such as case- flow management, gender sensitivity, human rights etc).</li>
                </ul>
                <p><b>Immediate Outcome 2: </b>Improved systems, court procedures and gender-sensitive policies</p>
                <p><i>Activities:</i></p>
                <ul>
                    <li>Develop delay and backlog reduction mechanisms, including gender responsive court­annexed alternative dispute resolution;</li>
                    <li>Re-engineer business processes to include gender-sensitive administrative and customer service procedures, policies, and case-flow management processes;</li>
                    <li>Develop Regional Court Performance standards;</li>
                    <li>Develop Regional Court Statistical reporting systems;</li>
                    <li>Develop Protocols for operating specialized courts/divisions developed;</li>
                    <li>Develop Model guidelines for dealing with sexual offences where children are involved and for the court's handling of sexual offences cases and sexual offences victims; and</li>
                    <li>Establish Court administration policies and procedures (e.g. Finance, records, ICT and process management).</li>
                </ul>
                <p><b>Immediate Outcome 3: </b>Strengthened capacity of the courts to undertake public education and awareness programs that are responsive to the needs of women, men, youth, business and the poor. </p>
                <p><i>Activities:</i></p>
                <ul>
                    <li>Develop information programmes;</li>
                    <li>Develop gender-sensitive Public Information Strategy/Protocol;</li>
                    <li>Develop Public information and awareness programmes ;</li>
                    <li>Design and implement training programmes geared towards the service delivery;</li>
                    <li>Provide customer service representation to assist female, male and youth court users;</li>
                    <li>Develop redress and complaints mechanism;</li>
                    <li>Develop mechanisms for public feedback and evaluation ;</li>
                    <li>Develop internal procedures and mechanisms to assess and monitor compliance with customer service and performance guidelines;</li>
                    <li>Train customer service officers, clerks and court administrators in customer service delivery; and</li>
                    <li>Provide technical assistance to Courts to develop gender-sensitive and customer focussed strategic and operations plans.</li>
                </ul>
                <p>For further information on the JURIST Project please visit their website at <a href="https://www.juristproject.org" >www.juristproject.org</a></p>


            </Col>
        </Row>);
}