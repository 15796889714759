import * as React from 'react';
import { Modal } from 'antd';
import KnowledgeJurisdictionsDetailsForm from '../../../components/Knowledge/KnowledgeJurisdictionsDetailsForm';
import PageContainer from '../../../components/Layout/PageContainer';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class KnowledgeJurisdictionsEditPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer title="Jurisdiction Details">
        <KnowledgeJurisdictionsDetailsForm />
      </PageContainer>
    );
  }
}
