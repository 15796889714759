import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Form, Col, Input, Button, Row, Icon, Steps } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { NavLink, withRouter, RouteComponentProps } from 'react-router-dom';
import ConfigService from 'Core/ConfigService';
import NotificationService from 'Core/NotificationService';
import ValidationUtility from 'Core/ValidationUtility';
import ActivateUserHandler from './ActivateUserHandler';
import { IActivateUserCommandRequest } from 'Core/Api/Api';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import SimpleSelect from 'components/Forms/SimpleSelect';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import UserPreferenceHandler from 'components/UserProfile/UserPreferenceHandler';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  token?: string;
  emailAddress?: string;
  currentPage?: number;
  categories: ISelectOption[];
  model?: IActivateUserCommandRequest;

}

export class ActivateUserFormWithoutFormAndRouter extends React.Component<Props, State> {
  handler: ActivateUserHandler;
  knowledgeHandler: KnowledgeHandler;


  constructor(props: Props) {
    super(props);
    this.state = { currentPage: 0, categories: [] };
    this.handler = new ActivateUserHandler();
    this.knowledgeHandler = new KnowledgeHandler(props);

  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const emailAddress = query.get('emailAddress');
    const token = query.get('token');

    if (!emailAddress || !token) {
      this.props.history.push('/');
      const errorMessage =
        'Please contact administrator to activate your users ' +
        ConfigService.getSync().SiteInfo.ContactUsEmail;
      NotificationService.error('Invalid Url', errorMessage, 0, false);
    } else {
      this.setState({ token: token, emailAddress: emailAddress });
      this.getCategories();
    }

  }

  getCategories() {
    this.knowledgeHandler.GetTop100KnowledgeCategories().then(x => {
      const categories: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories });
    });
  }

  nextPage = (direction: 'Forward' | 'Back') => {
    if (direction == 'Forward') {
      this.props.form.validateFields(["confirmationPassword", "password"], (errors, values) => {
        if (errors) return;

        this.setState({ currentPage: 1, model: values });

      })
    } else {
      var model = this.props.form.getFieldsValue();
      this.setState({ currentPage: 0, model: { ...this.state.model, ...model } }, () => {
        this.props.form.setFieldsValue(this.state.model ?? {});
      });

    }


  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((errors, values: IActivateUserCommandRequest) => {
      if (errors) return;
      values = { ...this.state.model, ...values };
      values.token = this.state.token;
      values.emailAddress = this.state.emailAddress;

      this.handler.ActivateUser(values).then(x => {
        this.props.history.push('/login');
        NotificationService.success('User has been activated');
      });
    });
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    var form = this.props.form;
    var requiredValidations = ValidationUtility.required;
    var passwordValidations = ValidationUtility.password;
    var compareValidations = ValidationUtility.compare;
    var maxItemCountValidations = ValidationUtility.maxItemCount;
    return (
      <CenterAlignedRow>
        <Col>
          <p style={{ textAlign: 'left' }}>
            <h3>Instructions</h3>
            <ul>
              <li>Before you can log in you must create a password.</li>
              <li>You can optional set preference of knowledge filters for your dashboard.</li>
              <li>You can change your preferences after activating your account</li>
            </ul>
          </p>
        </Col>
        <Col>
          <Form onSubmit={this.onSubmit}>
            <Steps style={{ textAlign: "left" }} current={this.state.currentPage}>
              <Steps.Step status={this.state.currentPage == 0 ? 'process' : 'finish'} title="Password" icon={<Icon type="solution" />} />
              <Steps.Step status={this.state.currentPage == 1 ? 'process' : 'wait'} title="Preferences" icon={<Icon type="smile-o" />} />
            </Steps>
            <ShowIfTrue condition={this.state.currentPage == 0} >
              <FormControl
                rules={[...passwordValidations(), ...requiredValidations('')]}
                getFieldDecorator={getFieldDecorator}
                name="password"
                label="Password">
                <Input.Password type="password" />
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...compareValidations(form, 'password', 'Passwords do not match'), ...requiredValidations()]}
                name="confirmationPassword"
                label="Confirm Password">
                <Input.Password type="password" />
              </FormControl>
            </ShowIfTrue>
            <ShowIfTrue condition={this.state.currentPage == 1} >
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...maxItemCountValidations(3, "Cannot have more than three categories")]}
                label="Recently Added Knowledge Categories"
                name="recentlyAddedKnowledgeCategories">
                <SimpleSelect
                  options={this.state.categories} mode="multiple" maxTagCount={3}></SimpleSelect>
              </FormControl>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                label="Recently Modified Knowledge Categories"
                rules={[...maxItemCountValidations(3, "Cannot have more than three categories")]}
                name="recentlyModifiedKnowledgeCategories">
                <SimpleSelect
                  options={this.state.categories} mode="multiple" maxTagCount={3}></SimpleSelect>
              </FormControl>

            </ShowIfTrue>
            <Form.Item>
              <ShowIfTrue condition={this.state.currentPage == 0}>
                <Row>
                  <Col>
                    <Button onClick={() => this.nextPage("Forward")}>Set Preferences</Button>
                  </Col>
                </Row>
              </ShowIfTrue>
              <ShowIfTrue condition={this.state.currentPage == 1}>
                <Row>
                  <Col>
                    <Button onClick={() => this.nextPage("Back")}>Back</Button>
                  </Col>
                </Row>
              </ShowIfTrue>
              <Row>
                <Col>
                  <SubmitButton type="primary" htmlType="submit">Activate</SubmitButton>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Col>
      </CenterAlignedRow>
    );
  }
}

const ActivateUserFormWithRouter = Form.create<Props>({ name: 'ActivateUser' })(
  ActivateUserFormWithoutFormAndRouter
);

const ActivateUserForm = withRouter(ActivateUserFormWithRouter);

export default ActivateUserForm;
