import * as React from 'react';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import TableFilterContext from './TableFilterContext';
import { FilterHistoryWrapper } from '../FilterHistoryWrapper';
import { uniqueId } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Utility from 'Core/Utility';
import { FilterType, JoinType } from 'Core/Api/Api';


export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
  beforeUpdate?(tableRequest: TableRequest): TableRequest;
  tableRequestName?: string;
  persistOnSubPathOnly?: boolean;
}

export interface State {
  tableRequest?: TableRequest;
  tableRequestName: string;
  unregisterListenCallback: Function;
}

export class TableFilterContextProviderWithoutRouter extends React.Component<Props, State> {
  static defaultProps = {
    persistOnSubPathOnly: true,
  };

  update: (updateTableRequest: TableRequest) => void;
  prefix = "TableRequest_";

  constructor(props: Props) {
    super(props);
    const tableRequest = this.GetRequestFromSession(props.tableRequestName, props);
    this.state = { tableRequest, tableRequestName: props.tableRequestName ?? uniqueId("TableRequest_"), unregisterListenCallback: () => { } };
    this.update = this.updateTableRequest;
  }

  componentDidMount() {
    let callback = this.props.history.listen((location) => {
      this.SetTableRequestInSession(this.state.tableRequest ?? { filters: [] }, location);
    });

    this.setState({ unregisterListenCallback: callback });
  }

  componentWillUnmount() {
    this.state.unregisterListenCallback?.call(this);
  }

  GetRequestFromSession(tableRequestName?: string, props?: Props) {
    let currentTableRequest = this.initTableRequestFromUrl(props);
    const key = this.prefix + tableRequestName ?? this.state.tableRequestName ?? uniqueId("TableRequest_");
    const tableRequestString = sessionStorage.getItem(key);
    if (tableRequestString) {

      currentTableRequest = { ...currentTableRequest, ...JSON.parse(tableRequestString) as TableRequest };

      if (currentTableRequest) {
        currentTableRequest = props?.beforeUpdate ? props.beforeUpdate(currentTableRequest)
          : currentTableRequest;
        return currentTableRequest;
      } else {
        return currentTableRequest;
      }

    }
    return currentTableRequest;
  }

  initTableRequestFromUrl = (props?: Props) => {
    var params = new URLSearchParams(props?.location.search);
    var tableRequest: TableRequest = {};

    tableRequest.filters = [];

    if (params.has("jur") || params.has("jurisdictionId")) {
      tableRequest.filters.push({ field: 'jurisdictionId', value: params.get("jur") ?? params.get("jurisdictionId") ?? undefined, filterType: FilterType.Equal, joinType: JoinType.And })
    }
    if (params.has("al") || params.has("accessLevel")) {
      tableRequest.filters.push({ field: 'accessLevel', value: params.get("al") ?? params.get("accessLevel") ?? undefined, filterType: FilterType.Equal, joinType: JoinType.And })
    }
    if (params.has("cat") || params.has("categoryId")) {
      tableRequest.filters.push({ field: 'categoryId', value: params.get("cat") ?? params.get("categoryId") ?? undefined, filterType: FilterType.Equal, joinType: JoinType.And })
    }

    return tableRequest;

  }

  updateTableRequest = (newTableRequest: TableRequest) => {
    let mergedTableRequest = this.props.beforeUpdate
      ? this.props.beforeUpdate(newTableRequest)
      : newTableRequest;

    this.setState({ tableRequest: mergedTableRequest });
  };

  SetTableRequestInSession(TableRequest: TableRequest, location: any) {
    const key = this.prefix + this.state.tableRequestName;

    if (this.props.persistOnSubPathOnly) {

      const isSupPath = Utility.isPathOrSubpathAMatch(location.pathname, this.props.match);
      if (isSupPath) {
        sessionStorage.setItem(key, JSON.stringify(TableRequest));

      } else {
        sessionStorage.removeItem(key);
      }

    } else {
      sessionStorage.setItem(key, JSON.stringify(TableRequest));
    }
  }

  render() {
    return (
      <TableFilterContext.Provider value={{ Update: this.update, data: this.state.tableRequest }}>
        {this.props.children}

      </TableFilterContext.Provider>
    );
  }
}

const TableFilterContextProvider = withRouter(TableFilterContextProviderWithoutRouter);

export default TableFilterContextProvider;

