import * as React from 'react';
import { Row, Col, Button, Dropdown, Icon, Menu } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { AuditListFilterBar, AuditListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Operations } from 'Core/Api/Api';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

export class AuditListViewWithoutRouter extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  goToUserLoginReport = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('audits/v/user-login-report', newDetailsData);
  };

  renderUserReportButtons = () => {
    return (
      <Menu>
        <Menu.Item onClick={x => this.goToUserLoginReport()} key="1">User Login Report</Menu.Item>
      </Menu >
    )
  }

  render() {
    return (
      <TableFilterContextProvider>
        <Row>
          <Col>
            <ShowIfHavePermission operations={[Operations.Generate_User_Reports]}>
              <Dropdown overlayStyle={{ marginLeft: "5px" }} overlay={this.renderUserReportButtons()}>
                <Button style={{ marginLeft: "5px" }} size="large" type="primary" >
                  Reports <Icon type="down" />
                </Button>
              </Dropdown>
            </ShowIfHavePermission>
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <AuditListFilterBar />
          </Col>
        </Row>
        <Row>
          <Col md={24}>
            <AuditListTable />
          </Col>
        </Row>
      </TableFilterContextProvider>
    );
  }
}

const AuditListView = withRouter(AuditListViewWithoutRouter);

export default AuditListView;
