import * as React from 'react';
import { CenterAlignedRow } from 'components/Styled/Layout/CenterAlignedRow';
import { Col, Form, Input, Row, Button } from 'antd';
import FormControl from 'components/Forms/FormControl';
import { FormComponentProps } from 'antd/lib/form';
import { withRouter, RouteComponentProps, NavLink } from 'react-router-dom';
import ValidationUtility from 'Core/ValidationUtility';
import PasswordResetHandler from '../RequestPasswordResetForm/PasswordResetHandler';
import { IResetPasswordCommandRequest } from 'Core/Api/Api';
import FormItem from 'antd/lib/form/FormItem';
import NotificationService from 'Core/NotificationService';
import ConfigService from 'Core/ConfigService';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  token?: string;
  emailAddress?: string;
}

export class ConfirmResetPasswordFormWithoutFormsAndRouter extends React.Component<Props, State> {
  handler: PasswordResetHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new PasswordResetHandler(props);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const emailAddress = query.get('emailAddress');
    const token = query.get('token');

    if (!emailAddress || !token) {
      this.props.history.push('user/reset-password');
      const errorMessage =
        'Please try reseting your password again or if this issue persist contact us at ' +
        ConfigService.getSync().SiteInfo.ContactUsEmail;
      NotificationService.error('Invalid Url', errorMessage, 0, false);
    } else {
      this.setState({ token: token, emailAddress: emailAddress });
    }
  }

  onSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    console.log('values', this.props.form.getFieldsError());
    this.props.form.validateFields((error, values: IResetPasswordCommandRequest) => {
      if (error) return;
      values.token = this.state.token;
      values.emailAddress = this.state.emailAddress;
      this.handler.ResetPassword(values).then(() => {
        this.props.history.push('/login');
        NotificationService.success('Password has been reset');
      });
    });
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    var passwordValidations = ValidationUtility.password;
    var compareValidations = ValidationUtility.compare;
    return (
      <CenterAlignedRow>
        <Col>
          <Form onSubmit={this.onSubmit}>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              label="Password"
              name="password"
              rules={[...passwordValidations()]}>
              <Input type="password" />
            </FormControl>
            <FormControl
              getFieldDecorator={getFieldDecorator}
              rules={[...compareValidations(this.props.form, 'password', 'Passwords do not match')]}
              label="Confirm Password"
              name="confirmationPassword">
              <Input type="password" />
            </FormControl>
            <FormItem>
              <Row>
                <Col>
                  <SubmitButton htmlType="submit">Reset Password</SubmitButton>
                </Col>
              </Row>
              <CenterAlignedRow>
                <Col>
                  <NavLink to="/login">Go to Login</NavLink>
                </Col>
              </CenterAlignedRow>
            </FormItem>
          </Form>
        </Col>
      </CenterAlignedRow>
    );
  }
}

const ConfirmResetPasswordFormWithoutRouter = Form.create<Props>({
  name: 'ConfirmResetPassword',
})(ConfirmResetPasswordFormWithoutFormsAndRouter);

const ConfirmResetPasswordForm = withRouter(ConfirmResetPasswordFormWithoutRouter);

export default ConfirmResetPasswordForm;
