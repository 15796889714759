import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import {
  User,
  GetAllUsersByFilterQueryRequest,
  SlimUser,
  GetFilteredJurisdictionsQueryRequest,
  OrderBy,
  GetFilteredCountriesQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetUserByIdQueryRequest,
  EditUserCommandRequest,
  AddUserCommandRequest,
  RemoveUserCommandRequest,
  IRemoveUserCommandRequest,
  GenerateEnabledUsersCsvCommandRequest,
  IGenerateEnabledUsersCsvCommandRequest, GetAllUsersReportDataQueryRequest, IGetAllUserInfoForPublicByFilterQueryRequest, GetAllUserInfoForPublicByFilterQueryRequest, IGetUserLoginReportDataQueryRequest, GetUserLoginReportDataQueryRequest, RegisterUserCommandRequest, GetAllUserRegistrationByFilterQueryRequest, SlimRegistration, GetUserRegistrationByIdQueryRequest, ApproveUserRegistrationCommandRequest, RejectUserRegistrationCommandRequest, SlimKnowledgeShare, IAddKnowledgeShareCommandRequest, AddKnowledgeShareCommandRequest, IApproveKnowledgeShareCommandRequest, ApproveKnowledgeShareCommandRequest, IRejectKnowledgeShareCommandRequest, RejectKnowledgeShareCommandRequest
} from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { SecuredClient } from 'Core/Api/SecuredClient';

export default class SharedKnowledgeHandler implements ITableDataFetcher<SlimKnowledgeShare> {

  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<SlimKnowledgeShare>> {
    return this.GetAllSharedKnowledgeByFilter(tableRequest);
  }

  GetAllSharedKnowledgeByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllSharedKnowledgeByFilterQueryRequest = new GetAllUserRegistrationByFilterQueryRequest(request);
    return client.getAllSharedKnowledgeByFilter(getAllSharedKnowledgeByFilterQueryRequest);
  }

}
