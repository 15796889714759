import { KnowledgeFileReportView } from 'components/Knowledge/KnowledgeFileReportView';
import PageContainer from 'components/Layout/PageContainer';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
}

export default ({ }: Props) => {
  return (
    <PageContainer title="Knowledge Storage Report">
      <KnowledgeFileReportView />
    </PageContainer>
  )
}