import * as React from 'react';
import { Layout, Row, Col } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../Styled/ScreenHelper';

export const PaddedCol = styled(Col)`
  margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 5px;
  margin-left: 20px;
  ${ScreenHelper.down('sm')} {
      margin: 10px 10px;
      float: none;
    }
`;
