import * as React from 'react';
import { Comment, Avatar, Tooltip, Button, Tag, Typography, Alert } from 'antd';
import moment from 'moment';
import { KnowledgeComment, Operations } from 'Core/Api/Api';
import { timingSafeEqual } from 'crypto';
import Utility from 'Core/Utility';
import ConfigService from 'Core/ConfigService';
import CommentForm from '../../CommentForm';
import TextClip from 'components/Forms/TextClip';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';

export interface Props {
  children?: React.ReactNode;
  comment: KnowledgeComment;
  addReplyComment?: Function;
}

export interface State {
  addReplying: boolean;
}

export default class CommentItem extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { addReplying: false };
  }

  onReplytoClick = (event: React.SyntheticEvent) => {
    this.setState({ addReplying: true });
  };

  onRepliedToComment = () => {
    this.setState({ addReplying: false });
    if (this.props.addReplyComment) {
      this.props.addReplyComment();
    }
  };

  render() {
    return (
      <Comment
        actions={[
          <ShowIfHavePermission operations={[Operations.Is_Authenticated]}>
            {!this.state.addReplying && (
              <span key="comment-list-reply-to-0" onClick={this.onReplytoClick}>
                Reply to
              </span>
            )}
          </ShowIfHavePermission>,
        ]}
        author={this.props.comment.authorName}
        avatar={
          <Avatar
            src={`https://ui-avatars.com/api/?name=${
              this.props.comment.authorName
            }&rounded=true&background=d0ea${this.props.comment.createdById?.substr(0, 2)}`}
            alt={this.props.comment.authorName}
          />
        }
        content={
          <>
            {this.renderCommentContent()}
            {this.state.addReplying && (
              <CommentForm
                commentAddedUpdated={this.onRepliedToComment}
                replyCommentId={this.props.comment.id}
                knowledgeId={this.props.comment.knowledgeId!}
                showCancel={true}
                Canceled={() => this.setState({ addReplying: false })}
              />
            )}
          </>
        }
        datetime={Utility.renderDateWithReference(
          moment(this.props.comment.creationDate),
          ConfigService.getSync().Display.DateTimeFormat
        )}
      />
    );
  }
  renderCommentContent() {
    if (!this.props.comment.parent) {
      return (
        <p style={{ background: '#fdfeff', padding: '7px', margin: '5px', borderRadius: '4px' }}>
          {this.props.comment.comment}
        </p>
      );
    } else {
      let parent = this.props.comment.parent;

      return (
        <>
          <Alert
            type="info"
            message={
              <>
                {parent.authorName}
                <Typography.Text type="secondary">
                  {Utility.renderDateWithReference(
                    moment(parent.creationDate),
                    ConfigService.getSync().Display.DateTimeFormat
                  )}{' '}
                </Typography.Text>
              </>
            }
            description={<TextClip maxLength={200} text={parent.comment} />}
          />
          <p style={{ background: '#fdfeff', padding: '7px', margin: '5px', borderRadius: '4px' }}>
            {this.props.comment.comment}
          </p>
        </>
      );
    }
  }
}
