import * as React from 'react';
import { List, Empty } from 'antd';
import {
  KnowledgeComment,
  GetAllCommentsForKnowledgeByFilterQueryResponse,
  IGetAllCommentsForKnowledgeByFilterQueryResponse,
  IGetAllCommentsForKnowledgeByFilterQueryRequest,
} from 'Core/Api/Api';
import { CommentItem } from '.';
import KnowledgeCommentHandler from 'components/Knowledge/KnowledgeCommentHandler';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export interface Props {
  children?: React.ReactNode;
  knowledgeId: string;
  updateCounter: number;
}

export interface State {
  currentTableRequest?: IGetAllCommentsForKnowledgeByFilterQueryResponse;
  comments?: KnowledgeComment[];
}

export default class CommentsList extends React.Component<Props, State> {
  handler: KnowledgeCommentHandler;

  constructor(props: Props) {
    super(props);

    this.state = {};
    this.handler = new KnowledgeCommentHandler(props);
  }

  componentDidMount() {
    this.handler
      .getAllCommentByFilter({ knowledgeId: this.props.knowledgeId, amountPerPage: 3 })
      .then(this.UpdateListData);
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.updateCounter != this.props.updateCounter) {
      this.RefreshCommentList();
    }
  }

  UpdateListData = (tableRequest: GetAllCommentsForKnowledgeByFilterQueryResponse) => {
    this.setState({ comments: tableRequest.results, currentTableRequest: tableRequest });
  };

  pageChange = (page, pageSize) => {
    var request = this.state.currentTableRequest as IGetAllCommentsForKnowledgeByFilterQueryRequest;
    request.amountPerPage = pageSize;
    request.pageNumber = page;
    request.knowledgeId = this.props.knowledgeId;

    this.handler.getAllCommentByFilter(request).then(this.UpdateListData);
  };

  RefreshCommentList = () => {
    let request = this.state.currentTableRequest as IGetAllCommentsForKnowledgeByFilterQueryRequest;
    request.pageNumber = 1;
    this.handler.getAllCommentByFilter(request).then(this.UpdateListData);
  };

  render() {
    let total = this.state.currentTableRequest?.count;
    let pageNumber = this.state.currentTableRequest?.pageNumber;
    let amountPerPage = this.state.currentTableRequest?.amountPerPage;
    let dataSource = this.state.comments;
    return (
      <>
        {dataSource && (
          <List<KnowledgeComment>
            itemLayout="horizontal"
            pagination={{
              onChange: this.pageChange,
              total,
              pageSize: amountPerPage,
              current: pageNumber,
              hideOnSinglePage: true,
              pageSizeOptions: ['3', '5', '10', '20'],
            }}
            rowKey="id"
            dataSource={this.state.comments}
            renderItem={x => <CommentItem addReplyComment={this.RefreshCommentList} comment={x} />}
          />
        )}
        {!dataSource && (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>No Comments. Be the first</Empty>
        )}
      </>
    );
  }
}
