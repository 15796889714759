import * as React from 'react';
import { ColumnProps } from 'antd/lib/table';
import {
  User,
  SlimUser,
  AuditHeader,
  Knowledge,
  KnowledgeCategory,
  Operations,
  Jurisdiction,
} from 'Core/Api/Api';
import SimpleTable from 'components/Forms/DataGrid/SimpleTable';
import { NavLink } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import DetailsMode from '../../../../Core/models/DetailsMode';
import moment from 'moment';
import ConfigService from 'Core/ConfigService';
import DetailsData from 'Core/models/DetailsData';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import KnowledgeJurisdictionHandler from '../../../Knowledge/KnowledgeJurisdictionsHandler';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Button, Col, Icon, Row, Typography } from 'antd';
import Utility from 'Core/Utility';
import Popup from '../../../Shared/Popup/Popup';
import ConfirmRemoveButton from 'components/Forms/ConfirmRemoveButton';
import NotificationService from 'Core/NotificationService';
import { JurisdictionFlag } from 'components/Shared/JurisdictionFlag';
export interface Props {
  children?: React.ReactNode;
}

export interface State {
  countries: IFilterSelectOption[];
  jurisdictions: IFilterSelectOption[];
  roles: IFilterSelectOption[];
  isPopupHide?: boolean;
  popupContent?: string;
  popupTitle?: string;
}

export default class KnowledgeJurisdictionsListTable extends React.Component<Props, State> {
  handler: KnowledgeJurisdictionHandler;
  config = ConfigService.getSync();
  tableRef = React.createRef<SimpleTable<Jurisdiction>>();

  constructor(props: Props) {
    super(props);

    this.state = {
      countries: [],
      jurisdictions: [],
      roles: [],
      isPopupHide: true,
      popupContent: '',
      popupTitle: '',
    };
    this.handler = new KnowledgeJurisdictionHandler(props);
  }

  componentDidMount() {}
  handleInfoButton = (name, description) => {
    this.setState({ isPopupHide: false, popupContent: description, popupTitle: name });
  };
  handlePopupClose = () => {
    this.setState({ isPopupHide: true });
  };
  removeJurisdiction = (jurisdiction: Jurisdiction) => {
    return this.handler.RemoveJurisdiction({ id: jurisdiction.id }).then(x => {
      NotificationService.success(`Removed "${jurisdiction.name} " jurisdiction`);
      this.tableRef.current?.refresh();
    });
  };
  knowledgeCategoriesListTableColumns: Array<ColumnProps<KnowledgeCategory>> = [
    {
      title: 'Name',
      dataIndex: 'name',
      render: (text: any, record: Jurisdiction, index: number) => {
        return (
          <>
            <div style={{ display: 'flex' }}>
              <div style={{ paddingRight: '5px' }}>
                <JurisdictionFlag jurisdictionId={record.id} />
              </div>
              <div>
                <span>{record?.name}</span>
              </div>
            </div>
          </>
        );
      },
    },
    {
      title: 'Short Description',
      render: (text: any, record: Jurisdiction, index: number) => {
        return <>{Utility.getFormattedDescription(record.shortDescription)}</>;
      },
    },
    {
      title: '',
      render: (text: any, record: Jurisdiction, index: number) => {
        return (
          <Row gutter={10} type="flex" justify="space-between">
            <Col>
              <ShowIfHavePermission operations={[Operations.Add_Edit_Jurisdiction]}>
                <NavLink
                  style={{ paddingRight: '4px' }}
                  to={{
                    pathname: `/jurisdictions/${record.name}`,
                    state: new DetailsData(DetailsMode.Edit, record.id),
                  }}>
                  <Button>
                    <Icon type="edit" />
                  </Button>
                </NavLink>
              </ShowIfHavePermission>
            </Col>
            <Col>
              <ShowIfHavePermission operations={[Operations.Add_Edit_Jurisdiction]}>
                <ConfirmRemoveButton
                  warningMessage={
                    <>
                      <p>Remove Jurisidction?</p>
                    </>
                  }
                  onConfirm={() => this.removeJurisdiction(record)}>
                  <Icon type="delete" />
                </ConfirmRemoveButton>
              </ShowIfHavePermission>
            </Col>
            <Col>
              <Button onClick={() => this.handleInfoButton(record.name, record.description)}>
                <Icon type="read" />
              </Button>
            </Col>
          </Row>
        );
      },
    },
  ];

  render() {
    const { isPopupHide, popupContent, popupTitle } = this.state;
    return (
      <>
        <Popup
          isHTMLCode={true}
          content={popupContent}
          isHide={isPopupHide}
          title={popupTitle}
          onClose={this.handlePopupClose}></Popup>
        <SimpleTable<Jurisdiction>
          ref={this.tableRef}
          tableKey="id"
          dataFetcher={this.handler}
          columns={this.knowledgeCategoriesListTableColumns}
        />
      </>
    );
  }
}
