import * as React from 'react';
import PageContainer from 'components/Layout/PageContainer';
import PublicKnowledgeListView from 'components/Knowledge/PublicKnowledgeListView';
import KnowledgeDashboardView from 'components/Knowledge/KnowledgeDashboardView';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class KnowledgeDashboardPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <PageContainer hideBackButton={true} title="Caribbean Judicial Information System">
        <KnowledgeDashboardView />
      </PageContainer>
    );
  }
}
