import * as React from 'react';
import { Row, Col, Button, List, Dropdown, Icon, Menu } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { AdvanceKnowledgeListFilterBar, KnowledgeListFilterBar, KnowledgeListTable, KnowledgeList } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { Filter, IFilter, Knowledge, Operations, UserStatus } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import { KnowledgeListItem } from './KnowledgeListTable';
import KnowledgeHandler from '../KnowledgeHandler';
import { debounce } from 'lodash';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AuthContext from 'Context/AuthContext';
import NewKnowledgeShareContextProvider from 'components/SharedKnowledge/NewKnowledgeShareContextProvider';
import { KnowledgeShareDetailsFormDrawer } from 'components/SharedKnowledge/KnowledgeShareDetailsFormDrawer';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State { }

export class KnowledgeListViewWithoutRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  debouncedLogSearchToAnalytics: Function = debounce(Utility.logSearchToAnalytics, 1000);

  constructor(props: Props) {
    super(props);

    this.state = {};

  }

  AddNewKnowledge = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('knowledge/s/new', newDetailsData);
  };

  goToKnowledgeSummaryReport = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('knowledge/v/reports/knowledge-summary-report', newDetailsData);
  };

  goToKnowledgeStorageReport = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('knowledge/v/reports/knowledge-storage-report', newDetailsData);
  };

  goToKnowledgeContributorReport = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('knowledge/v/reports/knowledge-contributor-report', newDetailsData);
  };



  renderUserReportButtons = () => {
    return (
      <Menu>
        <ShowIfHavePermission operations={[Operations.Generate_Knowledge_Summary_Reports]}><Menu.Item className={"ant-dropdown-menu-item"} onClick={x => this.goToKnowledgeSummaryReport()} key="1">Knowledge Summary Report</Menu.Item></ShowIfHavePermission>
        <ShowIfHavePermission operations={[Operations.Generate_Knowledge_Storage_Reports]}><Menu.Item className={"ant-dropdown-menu-item"} onClick={x => this.goToKnowledgeStorageReport()} key="2">Knowledge Storage Report</Menu.Item></ShowIfHavePermission>
        <ShowIfHavePermission operations={[Operations.Generate_Knowledge_Contributor_Reports]}><Menu.Item className={"ant-dropdown-menu-item"} onClick={x => this.goToKnowledgeContributorReport()} key="3">Knowledge Contributor Report</Menu.Item></ShowIfHavePermission>
      </Menu >
    )
  }

  beforeFilterUpdate = (tableRequest: TableRequest) => {
    Utility.makeKnowledgeDocumentFilterIngored(tableRequest);
    this.debouncedLogSearchToAnalytics(tableRequest, this.props.location.pathname)
    return tableRequest;
  }

  private CanAccessAnyReport(): boolean {
    return Utility.HasOperations(this.context.data, [Operations.Generate_Knowledge_Summary_Reports])
      || Utility.HasOperations(this.context.data, [Operations.Generate_Knowledge_Storage_Reports])
      || Utility.HasOperations(this.context.data, [Operations.Generate_Knowledge_Contributor_Reports]);
  }

  render() {
    return (
      <NewKnowledgeShareContextProvider>
        <TableFilterContextProvider tableRequestName="KnowledgeListViewTable" persistOnSubPathOnly={true} beforeUpdate={this.beforeFilterUpdate}>
          <Row type="flex" justify="center" align="middle">
            <Col md={16}>
              <ShowIfHavePermission operations={[Operations.Create_Knowledge]}>
                <Button type="primary" onClick={this.AddNewKnowledge}>
                  Create Knowledge
            </Button>
              </ShowIfHavePermission>
              <ShowIfTrue condition={this.CanAccessAnyReport()}>
                <Dropdown overlayStyle={{ marginLeft: "5px" }} overlay={this.renderUserReportButtons()}>
                  <Button style={{ marginLeft: "5px", marginRight: "5px", marginTop: "0px", marginBottom: "0px" }} size="default" type="primary" >
                    Reports <Icon type="down" />
                  </Button>
                </Dropdown>
              </ShowIfTrue>

            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle">
            <Col md={16}>
              <AdvanceKnowledgeListFilterBar />
            </Col>
          </Row>
          <Row>
            <Col offset={1} md={22}>
              <KnowledgeList pathRoute="knowledge" />
            </Col>
          </Row>


        </TableFilterContextProvider>
        <KnowledgeShareDetailsFormDrawer />
      </NewKnowledgeShareContextProvider>
    );
  }


}

const KnowledgeListView = withRouter(KnowledgeListViewWithoutRouter);

export default KnowledgeListView;
