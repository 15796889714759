import * as React from 'react';
import ConfigService from 'Core/ConfigService';

export interface Props {
  children?: React.ReactNode;
}

export interface State {}

export default class Userback extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    ConfigService.get().then(config => {
      const Userback = (window as any).Userback || {};
      Userback.access_token = config.userback.ApiKey;
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = 'https://static.userback.io/widget/v1.js';
      script.async = true;
      script.defer = true;
      (window as any).Userback = Userback;

      if (config.userback.enabled) {
        document.body.appendChild(script);
      }
    });
  }

  render() {
    return <>{this.props.children}</>;
  }
}
