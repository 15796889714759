import { Card } from 'antd';
import { IKnowledgeShareApproval, IUserRegistrationApprovalModel } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import moment from 'moment';
import React from 'react';
import ConfigService from 'Core/ConfigService';

interface Props {
  approval?: IKnowledgeShareApproval;
}

export default ({ approval }: Props) => {

  return (<Card style={{ backgroundColor: 'white', marginTop: '10px' }} title={approval?.approvalType} extra={Utility.renderDateWithReference(moment(approval?.creationDate), ConfigService.getSync().Display.DateTimeFormat)} >
    <p>Comment: {approval?.comment}</p>
    <p>By: {approval?.approverName}</p>
    <Card.Meta ></Card.Meta>
  </Card>);
}