import { Button, Card, Col, Descriptions, Divider, Row, Skeleton, Spin } from 'antd';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import AuthContext from 'Context/AuthContext';
import { IGetMyUserProfileQueryResponse, Operations } from 'Core/Api/Api';
import DetailsData from 'Core/models/DetailsData';
import Utility from 'Core/Utility';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ChangePasswordForm from '../ChangePasswordForm';
import ProfileHandler from '../ProfileHandler';
import UserProfileForm from '../UserProfileForm';
import NumberFormat from 'react-number-format';
import ButtonGroup from 'antd/lib/button/button-group';
import UpdatePreferenceForm from '../UpdatePreferenceForm';

type ProfileMode = "View" | "Edit" | "ChangePassword";

interface Props {
}

export default (props: Props) => {
  const authContext = useContext(AuthContext);
  const location = useLocation<DetailsData | undefined>();
  const [profile, setProfile] = useState<IGetMyUserProfileQueryResponse>();
  const [editMode, setEditMode] = useState<ProfileMode>("View");

  const handler = new ProfileHandler(props);


  useEffect(() => {
    const detailsData = location.state as DetailsData | undefined;

    if (detailsData) {
      handler.GetProfile(detailsData.id!).then(x => setProfile(x));
    } else {
      handler.GetMyProfile().then(x => setProfile(x));
    }


  }, [location])

  const getImageUrl: (IGetMyUserProfileQueryResponse) => string = (profile: IGetMyUserProfileQueryResponse) => {
    return profile?.imageUrl ?? `https://ui-avatars.com/api/?name=${profile?.firstName}  ${profile?.lastName}&rounded=true&background=d0ea${profile?.id?.substr(0, 2)}&size=256`
  }

  const editButton = () => {
    const isSameUser = authContext.data?.id == profile?.id;
    const isSuperAdmin = !authContext.data?.jurisdictionId;
    const CanEditUser = Utility.HasOperations(authContext.data, [Operations.Edit_User]);
    const isInSameJurisdiction = authContext.data?.jurisdictionId == profile?.jurisdictionId;
    const showEditButton = isSameUser || ((isSuperAdmin || isInSameJurisdiction) && CanEditUser)
    if (showEditButton && editMode == "View") {
      return (<Button onClick={StartEditProfile}>
        Edit
      </Button>);
    } else {
      return <></>;
    }
  }

  const changePasswordButton = () => {
    const isSameUser = !!authContext.data && authContext.data?.id == profile?.id;

    const showChangePasswordButton = isSameUser;
    if (showChangePasswordButton && editMode == "View") {
      return (<Button onClick={StartChangePassword}>
        Change Password
      </Button>);
    } else {
      return <></>;
    }
  }

  const StartEditProfile = () => {
    setEditMode("Edit");
  }

  const StartChangePassword = () => {
    setEditMode("ChangePassword");
  }

  const resetView = () => {
    document.getElementById("user-form")?.scrollIntoView();
  }

  const updatedProfile = (profile: IGetMyUserProfileQueryResponse) => {
    setProfile(profile);
    setEditMode("View");
    resetView();
  }

  const updatePassword = () => {
    setEditMode("View");
    resetView();
  }

  const cancelUpdateProfile = () => {
    setEditMode("View");
    resetView();
  }

  const renderCardTitle = () => {
    return (<Row id="user-form" type="flex" justify="space-between">
      <Col>
        User Information
    </Col>
      <Col>
        <Row>
          <ButtonGroup>{changePasswordButton()}{editButton()}</ButtonGroup>
          <Col></Col>
        </Row>
      </Col></Row>);
  }

  return (
    <Skeleton loading={!profile} >
      <Row>
        <Col>
          <Row>
            <Col span={10}>
              <Card
                style={{ backgroundColor: 'white' }}
                title={renderCardTitle()}
                actions={[]}
                cover={<img style={{ width: 150, height: 150, margin: 'auto', marginTop: '10px' }} alt="example" src={getImageUrl(profile)} />}
              >
                <Descriptions style={{ display: editMode == "View" ? 'inherit' : 'none' }} className="react-transition fade-in" column={1}>
                  <Descriptions.Item label="Email Address">{profile?.userName}</Descriptions.Item>
                  <Descriptions.Item label="Name">{`${profile?.firstName}  ${profile?.lastName}`}</Descriptions.Item>
                  <Descriptions.Item label="Jurisdiction">{profile?.jurisdictionName}</Descriptions.Item>
                  <Descriptions.Item label="Organization">{profile?.organization}</Descriptions.Item>
                  <Descriptions.Item label="Title">{profile?.title}</Descriptions.Item>
                  <Descriptions.Item label="Department">{profile?.department}</Descriptions.Item>
                  <Descriptions.Item label="Ohter Number">{profile?.otherPhoneNumber && <NumberFormat format="(###) ###-#####" value={profile?.otherPhoneNumber} displayType={"text"} />}</Descriptions.Item>
                  <Descriptions.Item label="Other Number Type">{profile?.otherPhoneNumberType}</Descriptions.Item>
                  <Descriptions.Item label="Work Phone">{profile?.phoneNumber && <NumberFormat format="(###) ###-#####" value={profile?.phoneNumber} displayType={"text"} />}</Descriptions.Item>
                </Descriptions>

                <div style={{ display: editMode == "Edit" ? 'inherit' : 'none' }} className="react-transition fade-in">
                  <UserProfileForm canceledUpdate={cancelUpdateProfile} profileUpdated={updatedProfile} profile={profile} />
                </div>

                <div style={{ display: editMode == "ChangePassword" ? 'inherit' : 'none' }} className="react-transition fade-in" >
                  <ChangePasswordForm cancelled={cancelUpdateProfile} />
                </div>

              </Card>
            </Col>
            <Col offset={1} span={10}>
              <ShowIfTrue condition={authContext.data?.id == profile?.id}>
                <Card title="User Preferences" style={{ backgroundColor: 'white' }}>
                  <UpdatePreferenceForm userId={profile?.id}></UpdatePreferenceForm>
                </Card>
              </ShowIfTrue>
            </Col>
          </Row>
          <Row>
            <Divider />
          </Row>
          <Row>

          </Row>
        </Col>
        <ShowIfTrue condition={editMode}>
          <Col >
          </Col>
        </ShowIfTrue>
      </Row></Skeleton >);
}


