import * as React from "react";
import ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from "react-router-dom";
import AuthContext from "Context/AuthContext";
import AuthUser from "Core/models/AuthUser";
import ConfigService from "Core/ConfigService";

export interface Props extends RouteComponentProps {
    children?: React.ReactNode
}

export interface State {
    currentUser?: AuthUser;
}

export class AnalyticsWrapperWithoutRouter extends React.Component<Props, State> {
    static contextType = AuthContext;
    context!: React.ContextType<typeof AuthContext>;

    constructor(props: Props) {
        super(props)

        this.state = {
        }
    }

    async componentDidMount() {
        const config = await ConfigService.get();
        ReactGA.initialize(config.Analytics.TrackingId, {});

        this.props.history.listen(location => {
            console.log("navi: ", location.pathname, " user:", this.context.data?.username);
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
        })
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevState.currentUser != this.context.data) {
            if (this.context.data) {
                ReactGA.set({
                    userName: this.context.data.username,
                    sessionId: this.context.data.sessionId,
                    jurisdictionId: this.context.data.jurisdictionId
                })
            }
        }
    }

    render() {
        return (
            <>{this.props.children}</>
        )
    }
}


const AnalyticsWrapper = withRouter(AnalyticsWrapperWithoutRouter);
export default AnalyticsWrapper;