import { Col, Form, Row, Typography, Input, Button, Upload, Icon, Modal, Descriptions } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import AuthContext from 'Context/AuthContext';
import DetailsMode from 'Core/models/DetailsMode';
import NotificationService from 'Core/NotificationService';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AddUserCommandRequest,
  SlimUser,
  User,
  Knowledge,
  IEditKnowledgeCommandRequest,
  KnowledgeModel,
  GetKnowledgeByIdQueryResponse,
  DocumentModel,
  IDeleteCommentCommandRequest,
  IDeleteDocumentCommandRequest,
  AccessLevel,
  KnowledgeDataTemplate,
  IChangeKnowledgeStatusCommandRequest,
  KnowledgeStatus,
  IKnowledgeModel,
  Operations,
} from '../../../Core/Api/Api';
import {
  doubleColFormColSize,
  FullWithButton,
  singleColFormColSize,
  singleCol2FormColSize,
} from '../../Forms/Form.Styled';
import KnowledgeHandler from '../KnowledgeHandler';
import FormControl from 'components/Forms/FormControl';
import ValidationUtility from 'Core/ValidationUtility';
import TextArea from 'antd/lib/input/TextArea';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import SimpleSelect from 'components/Forms/SimpleSelect';
import SimpleUpload from 'components/Forms/SimpleUpload';
import { UploadFile, RcFile } from 'antd/lib/upload/interface';
import Utility from 'Core/Utility';
import SurveyControl from 'components/Forms/SurveyControl';
import SurveyCreatorControl from 'components/Forms/SurveyCreatorControl';
import { debounce } from 'lodash';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { PublishButton } from './PublishButton';
import { DeleteButton } from './DeleteButton';
import { SubmitButton } from 'components/Forms/SubmitButton';
import { HideContentSimpleSelect } from './KnowledgeDetailsForm.Styled';
import { CancelButton } from 'components/Forms/CancelButton';

const { Title } = Typography;

type onRemoveFunc = (file: UploadFile) => void | boolean | Promise<void | boolean> | undefined;
type beforeUploadFunc = (file: RcFile, FileList: RcFile[]) => boolean | PromiseLike<void>;

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  knowledge?: IKnowledgeModel;
  files?: UploadFile[];
  categories?: ISelectOption[];
  accessLevels?: ISelectOption[];
  detailsMode: DetailsMode;
  beforeUpload: beforeUploadFunc;
  onRemove?: onRemoveFunc;
  templateSelectOptions?: ISelectOption[];
  templates?: KnowledgeDataTemplate[];
  selectedTemplateJSON?: string;
}

export class KnowledgeDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;
  freeTextTemplate = { text: 'Free Text', value: undefined };
  handler: KnowledgeHandler;
  surveyControlRef: React.RefObject<SurveyControl>;
  surveyControl: SurveyControl | null = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      beforeUpload: () => false,
      detailsMode: DetailsMode.New,
      templateSelectOptions: [this.freeTextTemplate],
    };
    this.handler = new KnowledgeHandler(props);
    this.surveyControlRef = React.createRef<SurveyControl>();
  }

  componentDidMount() {
    this.getCategories();
    this.getTemplates();
    this.getAccessLevel();
    this.getKnowledgeDetails();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.selectedTemplateJSON != prevState.selectedTemplateJSON) {
      this.surveyControlRef = React.createRef();
    }
  }

  getAccessLevel() {
    var accessLevels: ISelectOption[] = [];
    Utility.enumToMap(AccessLevel).forEach((x, y) => {
      if (x == 'NoAccess') return;
      let text = (x as string).replace('Level', 'Level ');
      accessLevels.push({ text: <div>{text} <i>{this.accessLevelDescription(x)}</i></div>, value: y });
    });
    this.setState({ accessLevels });
  }

  accessLevelDescription = (accessLevel?: AccessLevel) => {
    if (!accessLevel) {
      return <></>;
    }
    return Utility.RenderAccessLevelDescriptions(accessLevel, "100%");
  }

  getKnowledgeDetails() {
    const urlState = this.props.location.state as { id: string; mode: DetailsMode } | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetKnowledgeDetailsById(urlState.id).then(x => {
        const files: UploadFile[] = this.createFileList(x.knowledge?.documents) ?? [];

        this.setState(
          { knowledge: x.knowledge, detailsMode: DetailsMode.Edit, files: files },
          this.setTemplateOptions
        );
        this.props.form.setFieldsValue({ ...x.knowledge, documents: [...files] } ?? {});
        this.props.form.setFieldsValue({ templateDataJSON: x.knowledge?.templateDataJSON });     
        //if in edit mode the files will be uploaded and removed at the same time
        this.setState({
          beforeUpload: debounce(this.confirmBeforeUploadFiles, 700),
          onRemove: this.confirmBeforeRemoveFile,
        });
      });
    } else {
      this.setState({ detailsMode: DetailsMode.New });
    }
  }

  confirmBeforeUploadFiles: beforeUploadFunc = (file, files) => {
    Modal.confirm({
      title: 'Upload Files',
      content: `This will upload ${files.length} file(s) to "${this.state.knowledge?.title}"`,
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: () => this.uploadFilesToKnowledge(files),
    });

    return Promise.reject().finally(() => {
      this.props.form.setFieldsValue({ documents: [...(this.state.files ?? [])] } ?? {});
    });
  };

  confirmBeforeRemoveFile: onRemoveFunc = (file: UploadFile) => {
    Modal.confirm({
      title: 'Remove File',
      content: `This will remove "${file.name}" from "${this.state.knowledge?.title}"`,
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk: () => this.removeFileFromKnowledge(file),
    });

    return Promise.resolve(false).finally(() => {
      this.props.form.setFieldsValue({ documents: [...(this.state.files ?? [])] } ?? {});
    });
  };

  removeFileFromKnowledge = (file: UploadFile) => {
    var request: IDeleteDocumentCommandRequest = {
      id: file.uid,
    };

    this.handler.removeDocument(request).then(x => {
      var files = this.createFileList(x) ?? [];

      this.setState({ files: [...files] });
      this.props.form.setFieldsValue({ documents: [...files] } ?? {});
    });
  };

  uploadFilesToKnowledge = (files: RcFile[]) => {
    var request: IEditKnowledgeCommandRequest = {
      id: this.state.knowledge?.id,
      documents: files,
    };

    this.handler.AddDocumentsToKnowledge(request).then(x => {
      var files = this.createFileList(x) ?? [];

      this.setState({ files: [...files] });
      this.props.form.setFieldsValue({ documents: [...files] } ?? {});
    });
  };

  private createFileList(x?: DocumentModel[]): UploadFile<any>[] | undefined {
    if (!x) return undefined;

    return (
      x.map(file => ({
        key: file.id,
        uid: file.id!,
        name: file.name!,
        type: file.mimeType!,
        url: file.url!,
        size: 0,
      })) ?? []
    );
  }

  getCategories() {
    this.handler.GetTop100KnowledgeCategories().then(x => {
      const categories: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ categories });
    });
  }

  setTemplateOptions() {
    var { templates } = this.state;
    var templateId =
      this.state.knowledge?.knowledgeDataTemplateId ??
      this.props.form.getFieldValue('knowledgeDataTemplateId');
    var categoryId =
      this.state.knowledge?.categoryId ?? this.props.form.getFieldValue('categoryId');

    if (categoryId) {
      let avaliableTemplates = templates?.filter(
        x => x.knowledgeCategoryId == categoryId || x.id == templateId
      );
      const templateSelectOptions: ISelectOption[] =
        avaliableTemplates?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      templateSelectOptions.unshift(this.freeTextTemplate);
      this.setState({ templateSelectOptions }, () => this.changeTemplate(templateId));
    } else {
      this.setState({ templateSelectOptions: undefined });
    }
  }

  getTemplates() {
    this.handler.GetTop100KnowledgeTemplatesForPublic().then(x => {
      this.setState({ templates: x.results }, this.setTemplateOptions);
    });
  }

  onCategoryChange = (value?: string) => {
    if (!value) {
      this.props.form.setFieldsValue({ knowledgeDataTemplateId: undefined });
      return;
    }

    let avaliableTemplates = this.state.templates?.filter(x => x.knowledgeCategoryId == value);

    const templateSelectOptions: ISelectOption[] =
      avaliableTemplates?.map(y => ({
        text: y.name,
        value: y.id,
      })) ?? [];

    templateSelectOptions.unshift(this.freeTextTemplate);
    this.setState({ templateSelectOptions }, () => {
      this.props.form.setFieldsValue({ knowledgeDataTemplateId: undefined });
    });
  };

  changeTemplate = (value: string) => {
    if (!value) {
      this.setState({ selectedTemplateJSON: undefined });
      return;
    }
    let template = this.state.templates?.find(x => x.id === value);
    this.setState({ selectedTemplateJSON: template?.templateJSON ?? undefined }, () => {
      this.props.form.setFieldsValue({ templateDataJSON: this.state.knowledge?.templateDataJSON });
    });
  };

  onSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    const isSurveyValid = this.surveyControl?.isSurveyValid() ?? true;

    this.props.form.validateFieldsAndScroll((error, values: IEditKnowledgeCommandRequest) => {
      if (error || !isSurveyValid) {
        return;
      }

      if (this.state.detailsMode == DetailsMode.Edit) {
        values.id = this.state.knowledge?.id;

        this.handler.EditKnowledge(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Updated ${values.title}`, null, 10000, true);
        });
      } else {
        this.handler.AddKnowledge(values).then(x => {
          this.props.history.goBack();
          NotificationService.success(`Added ${values.title}`, null, 10000, true);
        });
      }
    });
  };

  updateKnowledgeStatus = (status: KnowledgeStatus) => {
    this.setState({ knowledge: { ...this.state.knowledge, status: status } });
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row>
        <Col>
          <ShowIfTrue condition={this.state.detailsMode == DetailsMode.Edit}>
            <Row>
              <Col span={2}>
                <Typography.Text>Status: {this.state.knowledge?.status}</Typography.Text>
              </Col>
            </Row>
            <LeftAlignedRow style={{ marginBottom: "20px" }}>

              <Col span={2}>
                <ShowIfTrue condition={this.state.knowledge?.status == KnowledgeStatus.Published}>
                  <PublishButton knowledgeStatusUpdatedCallback={this.updateKnowledgeStatus} knowledge={this.state.knowledge} newStatus={KnowledgeStatus.Draft} />
                </ShowIfTrue>
                <ShowIfTrue condition={this.state.knowledge?.status == KnowledgeStatus.Draft}>
                  <PublishButton knowledgeStatusUpdatedCallback={this.updateKnowledgeStatus} knowledge={this.state.knowledge} newStatus={KnowledgeStatus.Published} />
                </ShowIfTrue>
              </Col>
              <Col span={1}><DeleteButton knowledge={this.state.knowledge} /></Col>

            </LeftAlignedRow>
          </ShowIfTrue>
          <LeftAlignedRow>
            <Col md={12}>
              <Form {...singleCol2FormColSize} layout="vertical" onSubmit={this.onSubmit}>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="title"
                  label="Title"
                  rules={[...ValidationUtility.required('Title is required')]}>
                  <Input />
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Category is required')]}
                  name="categoryId"
                  label="Category">
                  <SimpleSelect
                    onChange={this.onCategoryChange}
                    options={this.state.categories}></SimpleSelect>
                </FormControl>

                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  rules={[...ValidationUtility.required('Access Level is required')]}
                  name="accessLevel"
                  extra={<span>{this.accessLevelDescription(this.props.form.getFieldValue("accessLevel"))}</span>}
                  label="Access Level">
                  <HideContentSimpleSelect options={this.state.accessLevels}></HideContentSimpleSelect>
                </FormControl>


                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="knowledgeDataTemplateId"
                  label="Description Type">
                  <SimpleSelect
                    allowUndefinedValue={true}
                    placeholder="Select a template or leave empty for free text description"
                    onChange={this.changeTemplate}
                    options={this.state.templateSelectOptions}></SimpleSelect>
                </FormControl>

                {!!!this.props.form.getFieldValue('knowledgeDataTemplateId') && (
                  <FormControl
                    getFieldDecorator={getFieldDecorator}
                    name="description"
                    label="Description"
                    rules={[...ValidationUtility.required('Description is required')]}>
                    <TextArea />
                  </FormControl>
                )}

                {!!this.props.form.getFieldValue('knowledgeDataTemplateId') && (
                  <FormControl
                    getFieldDecorator={getFieldDecorator}
                    colon={false}

                    rules={[
                      ...ValidationUtility.If(
                        !!this.props.form.getFieldValue('knowledgeDataTemplateId'),
                        ValidationUtility.required('Template must be filled out')
                      ),
                    ]}
                    name="templateDataJSON"
                    label="&nbsp;Please fill out reqired fields below">
                    <SurveyControl
                      ref={x => (this.surveyControl = x)}
                      templateJSON={this.state.selectedTemplateJSON}></SurveyControl>
                  </FormControl>)
                }

                <FormControl getFieldDecorator={getFieldDecorator} label="Attachments" name="documents">
                  <SimpleUpload
                    multiple
                    accept="audio/*,video/*,image/*,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,"
                    onRemove={this.state.onRemove}
                    beforeUpload={this.state.beforeUpload}>
                    <Button>
                      <Icon type="upload" /> Click to Upload
                    </Button>
                  </SimpleUpload>
                </FormControl>
                <Row >
                  <Col md={7} lg={4} xs={7} sm={5}>
                    <CancelButton isFormDirty={this.props.form.isFieldsTouched()} type="default" />
                  </Col>
                  <Col md={6} lg={4} xs={5} sm={5} offset={1}>
                    <SubmitButton type="primary" htmlType="submit">
                      Submit
                    </SubmitButton>
                  </Col>
                </Row>

              </Form>
            </Col>
          </LeftAlignedRow>
        </Col>
      </Row>
    );
  }
}

const KnowledgeDetailsFormWithRouter = Form.create<Props>({
  name: 'KnowledgeDetails',
})(KnowledgeDetailsFormWithoutFormAndRouter);

const KnowledgeDetailsForm = withRouter(KnowledgeDetailsFormWithRouter);

export default KnowledgeDetailsForm;
