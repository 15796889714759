import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
  AuditHeader,
  GetAllAuditHeaderByFilterModel,
  GetAllAuditTypesQueryRequest,
  GetAllCategoryByFilterQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetKnowledgeByIdQueryRequest,
  IEditKnowledgeCommandRequest,
  OrderBy,
  Knowledge,
  EditKnowledgeCommandRequest,
  IDeleteDocumentCommandRequest,
  KnowledgeComment,
  GetAllCommentsForKnowledgeByFilterQueryRequest,
  IAddCommentCommandRequest,
  AddCommentCommandRequest,
  IEditCommentCommandRequest,
  EditCommentCommandRequest,
  DeleteDocumentCommandRequest,
  IGetAllCommentsForKnowledgeByFilterQueryRequest,
} from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class KnowledgeCommentHandler {
  constructor(props?: any) {}

  getAllCommentByFilter(request: IGetAllCommentsForKnowledgeByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllCommentsForKnowledgeByFilterModel = new GetAllCommentsForKnowledgeByFilterQueryRequest(
      request
    );
    return client.getAllCommentsForKnowledgeByFilterQuery(getAllCommentsForKnowledgeByFilterModel);
  }

  AddComment(values: IAddCommentCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    var request = new AddCommentCommandRequest(values);
    return client.addComment(request);
  }

  EditComment(values: IEditCommentCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditCommentCommandRequest(values);
    return client.editComment(request);
  }

  removeDocument(values: IDeleteDocumentCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new DeleteDocumentCommandRequest(values);
    return client.deleteComment(request);
  }
}
