import { Row, Col } from 'antd';
import UserHandler from 'components/Admin/UserHandler';
import { RDLReportViewer } from 'components/Reports/RDLReportViewer';
import AuthContext from 'Context/AuthContext';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import AdvanceUserLoginReportFilterBar from './AdvanceUserLoginReportFilterBar';

const Wrap = styled.div``;

interface Props {

}



export default ({ }: Props) => {

  const { data: user } = useContext(AuthContext);

  const handler: UserHandler = new UserHandler({});
  const [dataSource, setDataSource] = useState<[] | undefined>();

  const generateReport = (tableRequest?: TableRequest) => {
    setDataSource(undefined);
    handler.GetUserLoginReportData(tableRequest).then(x => {
      let sources: any = [];
      let filterDataItem = {
        Users: x.users,
        Jurisdiction: x.jurisdictions,
        CreationDateRange: x.creationDateRange,
        ReportDate: x.reportDate,
        Total: x.count,
      };

      sources.push({ name: 'userLoginDataSource', value: x.results });
      sources.push({ name: 'filterDataSource', value: [filterDataItem] });

      setDataSource(sources);
    })
  }

  return (<Row>
    <Col>
      <Row>
        <Col>
          <AdvanceUserLoginReportFilterBar user={user} generateReport={generateReport}></AdvanceUserLoginReportFilterBar>
        </Col>
      </Row>
      <Row>
        <Col>
          {dataSource && < RDLReportViewer dataSource={dataSource} fileName="UserLoginReport" />}
        </Col>
      </Row>
    </Col>
  </Row>)
}