import * as React from 'react';
import { Layout, Row, Col, PageHeader } from 'antd';
import styled from 'styled-components';
import ScreenHelper from '../../../Styled/ScreenHelper';

export const CenteredTitlePageHeader = styled(PageHeader)`
  .ant-page-header-heading-title {
    text-align: center;
    float: none;
    font-size: 1.8375em;
    color: #044666;
    padding-bottom: 3px;
  }
`;
