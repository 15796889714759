import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import {
  AuditHeader,
  GetAllAuditHeaderByFilterModel,
  GetAllAuditTypesQueryRequest,
  GetAllCategoryByFilterQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetKnowledgeByIdQueryRequest,
  IEditKnowledgeCommandRequest,
  OrderBy,
  Knowledge,
  EditKnowledgeCommandRequest,
  IDeleteDocumentCommandRequest,
  GetAllPublicKnowledgeByFilterQueryRequest,
} from 'Core/Api/Api';
import { SecuredClient } from 'Core/Api/SecuredClient';
import TableRequest from 'Core/TableUtility/Models/TableRequest';

export default class PublicKnowledgeHandler implements ITableDataFetcher<Knowledge> {
  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<Knowledge>> {
    return this.getAllKnowledgeByFilter(tableRequest);
  }

  getAllKnowledgeByFilter(request?: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ? request : new TableRequest();
    request.amountPerPage = request?.amountPerPage ?? 10;
    const getAllPublicKnowledgeByFilterQueryRequest = new GetAllPublicKnowledgeByFilterQueryRequest(
      request
    );
    return client.getAllPublicKnowledgeByFilter(getAllPublicKnowledgeByFilterQueryRequest);
  }

  GetTop100KnowledgeCategories() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllCategoryByFilterQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'name', ascending: false }));
    return client.getAllCategoryByFilter(request);
  }

  GetAllAuditTypes() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetAllAuditTypesQueryRequest();
    return client.getAllAuditTypes(request);
  }

  GetFilteredRoles(request?: GetAllRolesByFilterQueryRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    request = request ?? new GetAllRolesByFilterQueryRequest();
    request.amountPerPage = 20;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getAllRolesByFilter(request);
  }

  GetKnowledgeDetailsById(id: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetKnowledgeByIdQueryRequest();
    request.knowledgeId = id;
    return client.getKnowledgeById(request);
  }

  AddKnowledge(values: IEditKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    const request = new FormData();
    request.append('title', values.title!);
    request.append('description', values.description!);
    request.append('categoryId', values.categoryId!);
    request.append('accessLevel', values.accessLevel!);
    values.documents?.forEach(x => {
      const t = x as File;
      request.append('documents', t['originFileObj'], t.name);
    });
    return client.addKnowledge(request as any);
  }

  EditKnowledge(values: IEditKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditKnowledgeCommandRequest(values);
    return client.editKnowledge(request);
  }

  removeDocument(values: IDeleteDocumentCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new EditKnowledgeCommandRequest(values);
    return client.deleteDocument(request);
  }

  AddDocumentsToKnowledge(values: IEditKnowledgeCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;

    const request = new FormData();
    request.append('knowledgeId', values.id!);
    values.documents?.forEach(x => {
      const t = x as File;
      request.append('documents', t, t.name);
    });
    return client.addDocumentsToKnowledge(request as any);
  }
}
