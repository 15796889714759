import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
interface Props {
}

export default ({ }: Props) => {
  let history = useHistory();
  useEffect(() => {
    history.goBack();
  }, [])
  return <></>;
}