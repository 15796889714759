export enum mime {
  'audio/aac' = 'aac',
  'application/x-abiword' = 'abw',
  'application/octet-stream' = 'arc',
  'video/x-msvideo' = 'avi',
  'application/vnd.amazon.ebook' = 'azw',
  'application/x-bzip' = 'bz',
  'application/x-bzip2' = 'bz2',
  'application/x-csh' = 'csh',
  'text/css' = 'css',
  'text/csv' = 'csv',
  'application/msword' = 'doc',
  'application/epub+zip' = 'epub',
  'image/gif' = 'gif',
  'text/html' = 'html',
  'image/x-icon' = 'ico',
  'text/calendar' = 'ics',
  'application/java-archive' = 'jar',
  'image/jpeg' = 'jpg',
  'application/javascript' = 'js',
  'application/json' = 'json',
  'audio/midi' = 'midi',
  'video/mpeg' = 'mpeg',
  'audio/mpeg' = 'mp3',
  'application/vnd.apple.installer+xml' = 'mpkg',
  'application/vnd.oasis.opendocument.presentation' = 'odp',
  'application/vnd.oasis.opendocument.spreadsheet' = 'ods',
  'application/vnd.oasis.opendocument.text' = 'odt',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' = 'docx',
  'audio/ogg' = 'oga',
  'video/ogg' = 'ogv',
  'application/ogg' = 'ogx',
  'application/pdf' = 'pdf',
  'application/vnd.ms-powerpoint' = 'ppt',
  'application/x-rar-compressed' = 'rar',
  'application/rtf' = 'rtf',
  'application/x-sh' = 'sh',
  'image/svg+xml' = 'svg',
  'application/x-shockwave-flash' = 'swf',
  'application/x-tar' = 'tar',
  'image/tiff' = 'tiff',
  'font/ttf' = 'ttf',
  'application/vnd.visio' = 'vsd',
  'audio/x-wav' = 'wav',
  'audio/webm' = 'weba',
  'video/webm' = 'webm',
  'image/webp' = 'webp',
  'font/woff' = 'woff',
  'font/woff2' = 'woff2',
  'application/xhtml+xml' = 'xhtml',
  'application/vnd.ms-excel' = 'xls',
  'application/xml' = 'xml',
  'application/vnd.mozilla.xul+xml' = 'xul',
  'application/zip' = 'zip',
  'video/3gpp' = '3gp',
  'video/3gpp2' = '3g2',
  'application/x-7z-compressed' = '7z',
  'video/x-matroska' = 'mkv',
  'image/png' = 'png',
  'video/quicktime' = 'mov',
}
