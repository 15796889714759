import * as React from 'react';
import TableRequest from './Models/TableRequest/TableRequest';
import {
  ColumnProps,
  PaginationConfig,
  SorterResult,
  TableCurrentDataSource,
} from 'antd/lib/table';
import { FilterType, JoinType } from '../Api/Api';
import equals from 'lodash/isEqual';

export default class TableUtility {
  static IsSameTableRequest(data: TableRequest | undefined, tableRequest: TableRequest): boolean {
    if (data === tableRequest || (!data && !tableRequest)) {
      return true;
    }

    // if the filters arn't basically the same return
    if ((data!.filters || []).length !== (tableRequest.filters || []).length) {
      return false;
    }

    return equals(data, tableRequest);
  }
  /**
   * Create TableRequest from antd table change properties
   * @static
   * @template ModelType
   * @param {PaginationConfig} pagination
   * @param {Record<keyof ModelType, string[]>} filters
   * @param {SorterResult<ModelType>} [sorter]
   * @returns {TableRequest}
   * @memberof TableUtility
   */
  public static GetTableRequest<ModelType>(
    pagination: PaginationConfig,
    filters: Partial<Record<keyof ModelType, string[]>>,
    sorter?: SorterResult<ModelType>,
    currentTableRequest?: TableRequest
  ): TableRequest {
    const tableRequest: TableRequest = currentTableRequest || {};
    tableRequest.amountPerPage = pagination.pageSize;
    tableRequest.pageNumber = pagination.current;
    tableRequest.filters = tableRequest.filters || [];
    // Formatting filters
    for (const key in filters) {
      if (filters.hasOwnProperty(key)) {
        const value = filters[key];
        tableRequest.filters.push({
          field: key,
          filterType: FilterType.Contains,
          value: value![0],
        });
      }
    }

    // format sorter
    if (sorter && sorter.field) {
      tableRequest.orderBy = tableRequest.orderBy || [];
      tableRequest.orderBy.push({
        field: sorter.field,
        ascending: sorter.order === 'ascend',
      });
    }

    return tableRequest;
  }
}



