import * as React from 'react';
import { Modal, Button } from 'antd';



interface IPopupProps {
    isHide?: boolean,
    isHTMLCode?: boolean,
    title?: string
    content?: string,
    onClose(): void
}
interface IPopupState {

}

export default class Popup extends React.Component<IPopupProps, IPopupState> {
    constructor(props: IPopupProps) {
        super(props);
        this.state = {};
    }
    handleCloseButton = () => {
        this.props.onClose();
    }
    render() {
        const { title, content, isHTMLCode, isHide } = this.props;
        return (
            <>
                <Modal
                    bodyStyle={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
                    onCancel={this.handleCloseButton}
                    centered
                    title={title}
                    visible={!isHide}
                    footer={[
                        <Button key="back" onClick={this.handleCloseButton} >
                            Close
                        </Button>
                    ]}>
                    {isHTMLCode ? <p dangerouslySetInnerHTML={{ __html: content ? content : "" }}></p> : content}
                </Modal>
            </>);
    }
}