import * as React from 'react';
import { Operations, AccessLevel } from 'Core/Api/Api';
import AuthContext from 'Context/AuthContext';
import Utility from 'Core/Utility';
import DetailsMode from 'Core/models/DetailsMode';

export interface Props {
  children?: React.ReactNode;
  accessLevel: AccessLevel;
  mode: DetailsMode;
  juristictionId?: string;
}

export interface State {
  show: boolean;
}

export default class ShowIfHaveAccessLevel extends React.Component<Props, State> {
  public static defaultProps: Props = {
    accessLevel: AccessLevel.NoAccess,
    mode: DetailsMode.View,
  };

  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      show: false,
    };
  }

  componentDidMount() {

    // If public then show
    if (this.props.accessLevel == AccessLevel.Public && (this.props.mode == DetailsMode.View || this.props.mode == DetailsMode.Download)) {
      this.setState({ show: true });
      return;
    }

    if (this.props.juristictionId && (this.props.juristictionId !== this.context.data?.jurisdictionId)) {
      this.setState({ show: false });
      return;
    }

    // find access level number
    let length = this.props.accessLevel.length;
    let accesslevelName = this.props.accessLevel.substr(length - 1, 1);

    // If it is Public then it is not a number
    if (this.props.accessLevel == AccessLevel.Public) {
      accesslevelName = 'Public';
    }


    let operationsTypeName: string = this.getOperationTypeName(this.props.mode);
    let op = Operations;
    let operationName = `${operationsTypeName} Access Level ${accesslevelName}`;
    //Edit Access Level 1
    //Edit Access Level 2
    let operation = Operations[operationName];

    this.setState({ show: Utility.HasOperations(this.context.data, [operationName]) });
  }

  getOperationTypeName(mode: DetailsMode): string {
    switch (mode) {
      case DetailsMode.Edit:
        return 'Edit';
      case DetailsMode.New:
        return 'Create';
      case DetailsMode.View:
        return 'View';
      case DetailsMode.Download:
        return 'Download';
      default:
        return 'View';
        break;
    }
  }

  render() {
    return <>{this.state.show && this.props.children}</>;
  }
}
