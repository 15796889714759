import React from 'react';
import RDLReportViewer from '../../../components/Reports/RDLReportViewer/RDLReportViewer';
import { EnabledUserReportView } from 'components/Admin/UserListView';
import PageContainer from 'components/Layout/PageContainer';

interface Props {
}

export default ({ }: Props) => {
  return (
    <PageContainer title="User Reports">
      <EnabledUserReportView />

    </PageContainer>
  );
}