import * as React from 'react';
import { ValidationRule } from 'antd/lib/form';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { SurveyModel } from 'survey-react';

export default class ValidationUtility {
  public static required(message?: string): ValidationRule[] {
    message = message ?? 'This is required';
    return [{ required: true, message }];
  }

  public static If(expression: boolean, rules: ValidationRule[]): ValidationRule[] {
    if (expression) return rules;
    else return [];
  }

  public static pattern(pattern: RegExp, message?: string): ValidationRule[] {
    message = message ?? 'input not properly formated';
    return [{ required: true, message }];
  }

  public static email(message?: string): ValidationRule[] {
    const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    message = message ?? 'email address is not correct';
    return [...this.pattern(emailRegex, message)];
  }

  public static password(): ValidationRule[] {
    const validator = (rule, value, callback) => {
      const hasNumberRegex = /\d/;
      const hasLowerCaseRegex = /[a-z]/;
      const hasUpperCaseRegex = /[A-Z]/;
      const atleast6CharactersRegex = /(\S).{6,}/;

      const hasNumber = hasNumberRegex.test(value);
      const hasLowerCase = hasLowerCaseRegex.test(value);
      const hasUpperCase = hasUpperCaseRegex.test(value);
      const atleast6Characters = atleast6CharactersRegex.test(value);

      if (hasNumber && hasLowerCase && hasUpperCase && atleast6Characters) {
        callback();
      } else {
        const messages = (
          <ul style={{ listStyle: 'arrow' }}>
            <li style={{ color: hasNumber ? 'green' : 'red' }}>Must have atleast one number</li>
            <li style={{ color: hasLowerCase ? 'green' : 'red' }}>
              Must have one lower case character
            </li>
            <li style={{ color: hasUpperCase ? 'green' : 'red' }}>
              Must have one upper case character
            </li>
            <li style={{ color: atleast6Characters ? 'green' : 'red' }}>
              Must be atleast 6 characters long
            </li>
          </ul>
        );

        callback(messages);
      }
    };

    return [{ validator: validator }];
  }

  public static compare(
    form: WrappedFormUtils,
    otherFieldName: string,
    message?: React.ReactNode
  ): ValidationRule[] {
    const validator = (rule, value, callback) => {
      message = message ?? otherFieldName + ' must be equal to ' + (rule.field ?? 'other field');
      const otherFieldValue = form.getFieldValue(otherFieldName);

      if (value !== otherFieldValue) {
        return callback(message);
      } else {
        return callback();
      }
    };
    return [{ validator: validator }];
  }

  public static maxItemCount(
    maxItem: number,
    message?: React.ReactNode
  ): ValidationRule[] {
    const validator = (rule, value, callback) => {
      message = message ?? `${rule.field} cannot have more than ${maxItem}`;

      if (value && value.length) {
        if (value.length < maxItem) {
          return callback(message);
        } else {
          return callback();
        }
      } else {
        return callback();
      }

    };
    return [{ validator: validator }];
  }

  public static SurveyJSHasQuestions(
    message?: React.ReactNode
  ): ValidationRule[] {
    const validator = (rule, value, callback) => {
      message = message ?? `${rule.field} must have atleast one question`;

      if (!value) return callback();

      const survey: SurveyModel = JSON.parse(value);
      const hasQuestions = survey.pages.some(page => page.elements?.length);

      if (!hasQuestions) {
        return callback(message);
      } else {
        return callback();
      }

    };
    return [{ validator: validator }];
  }


}


