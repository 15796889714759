import React from 'react';
import { Form, Row, Input, Col, Button, Select, Transfer } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import FormControl from 'components/Forms/FormControl';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import RoleHandler from '../RoleHandler';
import ValidationUtility from '../../../Core/ValidationUtility';
import SimpleSelect from '../../Forms/SimpleSelect';
import {
  UserStatus,
  SlimUser,
  User,
  EditUserCommandRequest,
  AddUserCommandRequest,
  Role,
  RoleStatus,
  Operations,
  IOperation,
  RoleDetailsModel,
  EditRoleCommandRequest,
  AddRoleCommandRequest,
} from '../../../Core/Api/Api';
import Utility from 'Core/Utility';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import { singleColFormColSize, FullWithButton } from '../../Forms/Form.Styled';
import MaskedInput from 'components/Forms/MaskedInput';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import DetailsMode from 'Core/models/DetailsMode';
import DetailsData from 'Core/models/DetailsData';
import NotificationService from 'Core/NotificationService';
import TextArea from 'antd/lib/input/TextArea';
import { TransferItem } from 'antd/lib/transfer';
import AuthContext from 'Context/AuthContext';
import { delay } from 'lodash';
import SimpleTransfer, { StrechSimpleTransfer } from 'components/Forms/SimpleTransfer';
import { SubmitButton } from 'components/Forms/SubmitButton';

export interface Props extends FormComponentProps, RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  jurisdictions: ISelectOption[];
  operations: TransferItem[];
  status: ISelectOption[];
  role?: RoleDetailsModel;
  detailsMode: DetailsMode;
}

export class RoleDetailsFormWithoutFormAndRouter extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  handler: RoleHandler;

  constructor(props: Props) {
    super(props);

    this.state = {
      jurisdictions: [],
      operations: [],
      status: [],
      detailsMode: DetailsMode.New,
    };
    this.handler = new RoleHandler(props);
  }

  componentDidMount() {
    this.getJurisdictions();
    this.getOperations();
    this.getStatus();
    this.getRoleDetails();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.role != this.state.role) {
    }
  }

  getRoleDetails() {
    const urlState = this.props.location.state as DetailsData | undefined;

    if (urlState && urlState.mode == DetailsMode.Edit) {
      this.handler.GetRoleById(urlState.id).then(
        x => {
          this.setState({ role: x.role, detailsMode: DetailsMode.Edit }, () => {
            this.props.form.setFieldsValue(x.role ?? {});
          });
        },
        x => NotificationService.error(x.message)
      );
    } else {
      this.setState({ detailsMode: DetailsMode.New });
    }
  }

  getStatus() {
    var status: ISelectOption[] = [];
    Utility.enumToMap(RoleStatus).forEach((x, y) => status.push({ text: x, value: y }));
    this.setState({ status });
  }

  getJurisdictions() {
    this.handler.GetTop100Jurisdictions().then(x => {
      const jurisdictions: ISelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdictions }, () => {
        this.props.form.setFieldsValue({ jurisdictionId: this.state.role?.jurisdictionId });
      });
    });
  }

  getOperations() {
    this.handler.GetOperations().then(x => {
      let operations: TransferItem[] =
        (x.operations ?? []).map(x => ({ key: x.name!, title: x.name! })) ?? [];
      this.setState({ operations }, () => {
        this.props.form.setFieldsValue({ operations: this.state.role?.operations } ?? {});
      });
    });
  }

  // onJurisdictionChange = (value: any) => {
  //   // This is done because the value doesn't seem to update on deselect
  //   delay(this.updateOperationsList, 10, value);
  // };

  onSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll(
      (error, values: AddRoleCommandRequest & EditRoleCommandRequest) => {
        if (error) {
          return;
        }
        console.log(values);

        if (this.state.detailsMode == DetailsMode.Edit) {
          values.id = this.state.role?.id;
          values.jurisdictionId = this.context.data?.jurisdictionId ?? values.jurisdictionId;
          console.log(values.id, this.state.role?.id);
          this.handler.EditRole(values).then(x => {
            this.props.history.goBack();
            NotificationService.success(`Updated ${x.role?.name}`, undefined, 5000, true);
          });
        } else {
          // Can only set jurisdition if the user is not in one
          if (this.context.data?.jurisdictionId) {
            values.jurisdictionId = this.context.data?.jurisdictionId;
          } else {
            values.jurisdictionId = this.props.form.getFieldValue('jurisdictionId');
          }
          this.handler.AddRole(values).then(x => {
            this.props.history.goBack();
            NotificationService.success(`Added ${x.role?.name}`, undefined, 5000, true);
          });
        }
      }
    );
  };

  render() {
    var { getFieldDecorator } = this.props.form;
    return (
      <Row type="flex">
        <Form layout="vertical" onSubmit={this.onSubmit}>
          <Row>
            <Col>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[
                  ...ValidationUtility.If(this.state.detailsMode == DetailsMode.New, [
                    ...ValidationUtility.required('Name is required'),
                  ]),
                ]}
                name="name"
                label="Name">
                <Input disabled={this.state.detailsMode == DetailsMode.Edit} />
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Description is required')]}
                name="description"
                label="Description">
                <TextArea />
              </FormControl>
            </Col>
          </Row>
          <Row>
            {!this.context.data?.jurisdictionId && (
              <Col>
                <FormControl
                  getFieldDecorator={getFieldDecorator}
                  name="jurisdictionId"
                  label="Juristiction">
                  <SimpleSelect options={this.state.jurisdictions}></SimpleSelect>
                </FormControl>
              </Col>
            )}
          </Row>
          <Row>
            <Col>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('Operations is required')]}
                name="operations"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Operations">
                <StrechSimpleTransfer
                  listStyle={{
                    width: 250,
                    height: 300,
                  }}
                  dataSource={this.state.operations}
                  render={x => x.title!}
                />
              </FormControl>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormControl
                getFieldDecorator={getFieldDecorator}
                rules={[...ValidationUtility.required('status is required')]}
                name="status"
                label="Status">
                <SimpleSelect options={this.state.status}></SimpleSelect>
              </FormControl>
            </Col>
          </Row>
          <Row>
            <Col span={2}>
              <Button onClick={() => this.props.history.goBack()} type="default">
                Cancel
              </Button>
            </Col>
            <Col offset={1} span={2}>
              <SubmitButton type="primary" htmlType="submit">
                Submit
              </SubmitButton>
            </Col>
          </Row>
        </Form>
      </Row>
    );
  }
}

const RoleDetailsFormWithRouter = Form.create<Props>({
  name: 'RoleDetails',
})(RoleDetailsFormWithoutFormAndRouter);

const RoleDetailsForm = withRouter(RoleDetailsFormWithRouter);

export default RoleDetailsForm;
