import * as React from "react";
import { Filter, IFilter, Knowledge } from 'Core/Api/Api';
import Utility from 'Core/Utility';
import SimpleViewList from 'components/Forms/DataGrid/SimpleViewList';
import KnowledgeHandler from "components/Knowledge/KnowledgeHandler";
import { List } from "antd";
import { TableRequestMethod } from "components/Forms/DataGrid/SimpleViewList/SimpleViewList";
import SimpleKnowledgeListItem from "../SimpleKnowledgeListItem";


export interface Props {
    children?: React.ReactNode;
    pathRoute?: string;
    isOnPublic?: boolean;
    DataFetcherMethod?: TableRequestMethod<Knowledge>
}

export interface State {
}

export default class SimpleKnowledgeList extends React.Component<Props, State> {
    handler: KnowledgeHandler;

    constructor(props: Props) {
        super(props)

        this.state = {
        }
        this.handler = new KnowledgeHandler(props);

    }

    render() {
        return (
            <SimpleViewList<Knowledge>
                grid={{ gutter: 12, sm: 1, md: 2, lg: 3 }}
                dataFetcher={this.props.DataFetcherMethod}
                scrollToTop={false}
                pagination={{ position: "bottom", showTotal: (x) => `${x} Results`, pageSize: 3, showSizeChanger: false }}
                renderItem={x => <List.Item>
                    <SimpleKnowledgeListItem knowledge={x} isOnPublic={true} />
                </List.Item>} />
        )
    }
}
