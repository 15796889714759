import * as React from 'react';
import { Row, Col, Button, Icon } from 'antd';
import TableFilterContextProvider from 'components/Forms/DataGrid/TableFilterContextProvider';
import { KnowledgeListFilterBar, KnowledgeListTable } from '.';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router';
import DetailsData from 'Core/models/DetailsData';
import DetailsMode from 'Core/models/DetailsMode';
import { RightAlignedRow } from 'components/Styled/Layout/RightAlignedRow';
import { LeftAlignedRow } from 'components/Styled/Layout/LeftAlignedRow';
import { AdvanceKnowledgeListFilterBar } from '../KnowledgeListView';
import Utility from 'Core/Utility';
import PublicKnowledgeList from './PublicKnowledgeList';
import { debounce } from 'lodash';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import { DashboardLinks } from './DashboardLinks';
import { UserPreferenceKnowledgeListsView } from '../UserPreferenceKnowledgeListsView';
import NewKnowledgeShareContextProvider from 'components/SharedKnowledge/NewKnowledgeShareContextProvider';
import { KnowledgeShareDetailsFormDrawer } from 'components/SharedKnowledge/KnowledgeShareDetailsFormDrawer';
import ShowIfHavePermission from 'components/Forms/ShowIfHavePermission';
import { Operations } from 'Core/Api/Api';

export interface Props extends RouteComponentProps {
  children?: React.ReactNode;
}

export interface State {
  isSearchingForKnowledge?: boolean;
  showAccessRequestDraw?: boolean;
}

export class KnowledgeDashboardViewWithoutRouter extends React.Component<Props, State> {
  debouncedLogSearchToAnalytics: Function = debounce(Utility.logSearchToAnalytics, 1000);

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  checkIfActuallySearchingForKnowledge(tableRequest: TableRequest) {
    const isSeaching: boolean = tableRequest && !!tableRequest.filters?.length;
    this.setState({ isSearchingForKnowledge: isSeaching });
  }

  AddSharedKnowledgeRequest = () => {
    const newDetailsData = new DetailsData();
    newDetailsData.mode = DetailsMode.New;
    this.props.history.push('/knowledge-share-new', newDetailsData);
  };

  beforeFilterUpdate = (tableRequest: TableRequest) => {
    Utility.makeKnowledgeDocumentFilterIngored(tableRequest);
    this.debouncedLogSearchToAnalytics(tableRequest, this.props.location.pathname);
    this.checkIfActuallySearchingForKnowledge(tableRequest);
    return tableRequest;
  };

  render() {
    return (
      <NewKnowledgeShareContextProvider>
        <TableFilterContextProvider
          tableRequestName="PublicKnowledgeList"
          beforeUpdate={this.beforeFilterUpdate}>
          <Row type="flex" justify="center" align="middle">
            <Col md={16} xs={24}>
              <AdvanceKnowledgeListFilterBar isPublic={true} />
            </Col>
          </Row>
          <ShowIfHavePermission operations={[Operations.Is_Authenticated]}>
            <Row type="flex" justify="center" align="middle">
              <Col md={16} xs={24}>
                <Button
                  style={{ marginBottom: '10px' }}
                  prefix="plus"
                  type="primary"
                  onClick={x => this.setState({ showAccessRequestDraw: true })}>
                  Create Shared Knowledge Request
                </Button>
              </Col>
            </Row>
          </ShowIfHavePermission>
          <Row>
            <ShowIfTrue condition={this.state.isSearchingForKnowledge}>
              <Col xs={24} md={{ span: 22, offset: 1 }}>
                <PublicKnowledgeList />
              </Col>
            </ShowIfTrue>
            <ShowIfTrue condition={!this.state.isSearchingForKnowledge}>
              <Col xs={24} md={{ span: 22, offset: 1 }}>
                <DashboardLinks />
                <UserPreferenceKnowledgeListsView />
              </Col>
            </ShowIfTrue>
          </Row>
        </TableFilterContextProvider>
        <KnowledgeShareDetailsFormDrawer
          showDraw={this.state.showAccessRequestDraw}
          onFinished={x => this.setState({ showAccessRequestDraw: false })}
        />
      </NewKnowledgeShareContextProvider>
    );
  }
}

const KnowledgeDashboardView = withRouter(KnowledgeDashboardViewWithoutRouter);

export default KnowledgeDashboardView;
