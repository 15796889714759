import * as React from 'react';
import styled from 'styled-components';
import { Card, Col, Row } from 'antd';

export const KnowledgeCard = styled(Card)`
    margin-top: 10px;
    margin-bottom: 10px;
    margin-right: 10px;
`;

export const TitleRow = styled(Row)`
    margin-bottom: 20px;
`;

export const CategoryCol = styled(Col)`
    font-size: .72em;
    color: white;

`;

export const TimeText = styled.p`
    color: #C7C7C7;
`;