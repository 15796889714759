import ScreenHelper from "components/Styled/ScreenHelper";
import styled from "styled-components";

export const AboutImage = styled.img`
  float: right;
  margin: 30px;
  height: 350px;

  ${ScreenHelper.down('sm')} {
      height: 50vh;
      margin: auto 10px;
      float: none;
    }



`;
