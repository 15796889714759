import * as React from 'react';
import styled from 'styled-components';
import { Layout, Form, Button, Icon } from 'antd';
import { ColProps } from 'antd/lib/col';


export const PinnerIcon = styled(Icon)`
 svg:hover{
  filter: drop-shadow( 1px 1px 1px rgba(0, 0, 0, 0.473));
 }

`