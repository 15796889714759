import ITableDataFetcher from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataFetcher';
import {
  User,
  GetAllUsersByFilterQueryRequest,
  SlimUser,
  GetFilteredJurisdictionsQueryRequest,
  OrderBy,
  GetFilteredCountriesQueryRequest,
  GetAllRolesByFilterQueryRequest,
  GetUserByIdQueryRequest,
  EditUserCommandRequest,
  AddUserCommandRequest,
  RemoveUserCommandRequest,
  IRemoveUserCommandRequest,
  GenerateEnabledUsersCsvCommandRequest,
  IGenerateEnabledUsersCsvCommandRequest, GetAllUsersReportDataQueryRequest, IGetAllUserInfoForPublicByFilterQueryRequest, GetAllUserInfoForPublicByFilterQueryRequest, IGetUserLoginReportDataQueryRequest, GetUserLoginReportDataQueryRequest, RegisterUserCommandRequest, GetAllUserRegistrationByFilterQueryRequest, SlimRegistration, GetUserRegistrationByIdQueryRequest, ApproveUserRegistrationCommandRequest, RejectUserRegistrationCommandRequest, SlimKnowledgeShare, IAddKnowledgeShareCommandRequest, AddKnowledgeShareCommandRequest, IApproveKnowledgeShareCommandRequest, ApproveKnowledgeShareCommandRequest, IRejectKnowledgeShareCommandRequest, RejectKnowledgeShareCommandRequest
} from 'Core/Api/Api';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import ITableDataResponse from 'components/Forms/DataGrid/SimpleTable/Models/ITableDataResponse';
import { SecuredClient } from 'Core/Api/SecuredClient';

export default class KnowledgeShareHandler implements ITableDataFetcher<SlimKnowledgeShare> {

  constructor(props?: any) { }

  GetData(tableRequest: TableRequest): Promise<ITableDataResponse<SlimKnowledgeShare>> {
    return this.GetAllUserKnowledgeShareRequestByFilter(tableRequest);
  }

  GetAllUserKnowledgeShareRequestByFilter(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllUserRegistrationByFilterQueryRequest = new GetAllUserRegistrationByFilterQueryRequest(request);
    return client.getAllUserKnowledgeShareRequestByFilter(getAllUserRegistrationByFilterQueryRequest);
  }

  GetAllUserReportData(request: TableRequest) {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const getAllUsersReportDataQueryRequest = new GetAllUsersReportDataQueryRequest(request);
    return client.getAllUsersReportData(getAllUsersReportDataQueryRequest);
  }

  GetTop100Jurisdictions() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredJurisdictionsQueryRequest();
    request.amountPerPage = 100;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredJurisdictions(request);
  }

  GetTop500Countries() {
    const client = new SecuredClient();
    client.showLoading = false;
    client.handleGeneralError = true;
    const request = new GetFilteredCountriesQueryRequest();
    request.amountPerPage = 500;
    request.orderBy = [];
    request.orderBy.push(new OrderBy({ field: 'creationDate', ascending: false }));
    return client.getFilteredCountries(request);
  }



  GetSharedKnowledgeRequestById(knowledgeShareId: string) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    const request = new GetUserRegistrationByIdQueryRequest();
    request.id = knowledgeShareId;
    return client.getUserSharedKnowledgeRequestById(request);
  }

  AddSharedKnowledgeRequest(model: IAddKnowledgeShareCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new AddKnowledgeShareCommandRequest(model);
    return client.addSharedKnowledgeRequest(request);
  }

  ApproveKnowledgeShare(model: IApproveKnowledgeShareCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new ApproveKnowledgeShareCommandRequest(model);
    return client.approveKnowledgeShare(request);
  }

  RejectKnowledgeShare(model: IRejectKnowledgeShareCommandRequest) {
    const client = new SecuredClient();
    client.showLoading = true;
    client.handleGeneralError = true;
    let request = new RejectKnowledgeShareCommandRequest(model);
    return client.rejectKnowledgeShare(request);
  }


}
