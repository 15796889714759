import * as React from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import ScreenHelper from 'components/Styled/ScreenHelper';

export const logoLayoutSizes = {
  xs: { span: 12, order: 2, offset: 5 },
  sm: { span: 12, order: 2, offset: 5 },
  md: { span: 12, order: 2, offset: 5 },
  lg: { span: 3, order: 1, offset: 0 },
  xl: { span: 3, order: 1, offset: 0 },
};

export const menuLayoutSizes = {
  xs: { span: 1, order: 1 },
  sm: { span: 1, order: 1 },
  md: { span: 1, order: 1 },
  lg: { span: 18, order: 2 },
  xl: { span: 15, order: 2 },
};

export const authBarLayoutSizes = {
  xs: { span: 6, order: 3 },
  sm: { span: 6, order: 3 },
  md: { span: 6, order: 3 },
  lg: { span: 3, order: 3, push: 1 },
  xl: { span: 6, order: 3 },
};

export const LightHeader = styled(Layout.Header)`
  &.ant-layout-header {
    background-color: #fff;
    ${ScreenHelper.down('sm')} {
    padding-left:10px;
    padding-right:10px;
  }
  }
`;



