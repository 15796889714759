import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import InfoIcon from 'components/Forms/InfoIcon';
import FilterDatePicker from 'components/Forms/DataGrid/FilterDatePicker';
import { FilterType, JoinType, AuditStatus } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import UserHandler from '../../UserHandler';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import Utility from 'Core/Utility';
import RoleHandler from 'components/Admin/RoleHandler';
import AuditHandler from 'components/Admin/AuditHandler';

export interface Props {
    children?: React.ReactNode;
}

export interface State {
    auditStatus: IFilterSelectOption[];
    auditTypes: IFilterSelectOption[];
}
export default class AuditListFilterBar extends React.Component<Props, State> {
    handler: AuditHandler;
    roleHandler: RoleHandler;

    constructor(props: Props) {
        super(props);

        this.state = {
            auditStatus: [],
            auditTypes: [],
        };
        this.handler = new AuditHandler(props);
        this.roleHandler = new RoleHandler(props);
    }

    componentDidMount() {
        this.getAuditTypes();
        this.getAuditStatus();
    }

    getAuditTypes() {
        this.handler.GetAllAuditTypes().then(x => {
            const auditTypes: IFilterSelectOption[] =
                x.results?.map(y => ({
                    text: y,
                    value: y,
                })) ?? [];
            this.setState({ auditTypes });
        });
    }

    getAuditStatus() {
        const auditStatus = Utility.enumToSelectionOptionArray(AuditStatus);
        this.setState({ auditStatus });
    }

    render() {
        return (
            <FilterBar>
                <Row>
                    <Col md={10} xs={24}>
                        <Form.Item labelAlign="right">
                            <FilterSelectbox
                                filterFields="operation"
                                filterType={FilterType.Equal}
                                showSearch={true}
                                allowClear={true}
                                mode="multiple"
                                placeholder={'Select Operation'}
                                options={this.state.auditTypes}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={10} xs={24}>
                        <Form.Item labelAlign="right">
                            <FilterTextbox
                                filterFields="auditedUserName"
                                filterJoin={JoinType.And}
                                suffix={<InfoIcon text="Search name" />}
                                placeholder="User"
                            />
                        </Form.Item>
                    </Col>
                    <Col md={10} xs={24}>
                        <Form.Item labelAlign="right">
                            <FilterDateRangePicker
                                filterFields="auditTime"
                                filterType={FilterType.Group}
                                allowClear={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={10} xs={24}>
                        <Form.Item labelAlign="right">
                            <FilterSelectbox
                                filterFields="auditStatus"
                                filterType={FilterType.Equal}
                                placeholder={'Select Status'}
                                filterOption={true}
                                showSearch={true}
                                allowClear={true}
                                options={this.state.auditStatus}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </FilterBar>
        );
    }
}
