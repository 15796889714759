import * as React from 'react';
import FilterBar from 'components/Forms/DataGrid/FilterBar';
import { Row, Col, Form, Icon, Button, Tooltip } from 'antd';
import FilterTextbox from 'components/Forms/DataGrid/FilterTextbox';
import { AccessLevel, FilterType, JoinType } from 'Core/Api/Api';
import FilterDateRangePicker from 'components/Forms/DataGrid/FilterDateRangePicker';
import FilterSelectbox from 'components/Forms/DataGrid/FilterSelectbox';
import IFilterSelectOption from 'components/Forms/DataGrid/FilterSelectbox/IFilterSelectOption';
import KnowledgeHandler from 'components/Knowledge/KnowledgeHandler';
import TableRequest from 'Core/TableUtility/Models/TableRequest';
import { TableFilterContext } from 'components/Forms/DataGrid/TableFilterContextProvider';
import UserHandler from 'components/Admin/UserHandler';
import AuthUser from 'Core/models/AuthUser';
import ISelectOption from 'components/Forms/SimpleSelect/ISelectOption';
import Utility from 'Core/Utility';
import ShowIfTrue from 'components/Forms/ShowIfTrue';
import moment from 'moment';

export interface Props {
  children?: React.ReactNode;
  generateReport?: (tableRequest?: TableRequest) => void;
  user?: AuthUser;
}
const documentTypes = [
  { text: 'Audio', value: 'aac,ogg,m4a,wav,aiff,ftr,flac,wma,oga,pcm' },
  { text: 'Documents', value: 'pdf,docx,xslx,csv' },
  { text: 'Video', value: 'mp4,mov,mkv,mp3' },
];

export interface State {
  categories: IFilterSelectOption[];
  jurisdiction: IFilterSelectOption[];
  users: IFilterSelectOption[];
  advance?: boolean;
}
export default class AdvanceUserLoginReportFilterBar extends React.Component<Props, State> {
  handler: KnowledgeHandler;
  userhandler: UserHandler;

  static contextType = TableFilterContext;
  context!: React.ContextType<typeof TableFilterContext>;

  constructor(props: Props) {
    super(props);

    this.state = {
      categories: [],
      jurisdiction: [],
      users: [],
    };
    this.handler = new KnowledgeHandler(props);
    this.userhandler = new UserHandler(props);
  }

  componentDidMount() {
    this.getJurisdiction();
    this.getUserInfos();
  }

  SendTableRequest = () => {
    this.props.generateReport?.call(this, this.context.data);
  };

  getJurisdiction() {
    this.userhandler.GetTop100Jurisdictions().then(x => {
      const jurisdiction: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: y.name,
          value: y.id,
        })) ?? [];
      this.setState({ jurisdiction });
    });
  }

  getUserInfos() {
    this.userhandler.GetUserInfoForPublic({}).then(x => {
      const users: IFilterSelectOption[] =
        x.results?.map(y => ({
          text: `${y.firstName} ${y.lastName}`,
          value: y.id,
        })) ?? [];
      this.setState({ users });
    });
  }

  render() {
    return (
      <FilterBar>
        <Row>
          <Row>
            <Col span={5}>
              <Form.Item labelAlign="left">
                <FilterDateRangePicker
                  style={{ width: '100%' }}
                  filterFields="creationDate"
                  filterType={FilterType.Group}
                  filterJoin={JoinType.And}
                  allowClear={true}
                  ranges={{
                    Today: [moment(), moment()],
                    'This Month': [moment().startOf('month'), moment().endOf('month')],
                    '7 Days': [moment().subtract(7, 'days'), moment()],
                    '30 Days': [moment().subtract(30, 'days'), moment()],
                    '90 Days': [moment().subtract(90, 'days'), moment()],
                    '180 Days': [moment().subtract(180, 'days'), moment()],
                    '365 Days': [moment().subtract(365, 'days'), moment()],
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <ShowIfTrue condition={this.props.user && !this.props.user.jurisdictionId}>
            <Col span={5}>
              <Form.Item labelAlign="left">
                <FilterSelectbox
                  filterFields="auditedUser.JurisdictionId"
                  filterType={FilterType.Equal}
                  filterJoin={JoinType.And}
                  allowClear={true}
                  placeholder={'Jurisdiction'}
                  options={this.state.jurisdiction}
                />
              </Form.Item>
            </Col>
          </ShowIfTrue>
        </Row>
        <Row></Row>
        <Row>
          <Col span={5}>
            <Form.Item labelAlign="left">
              <FilterSelectbox
                filterFields="auditedUser.Id"
                filterType={FilterType.Equal}
                filterJoin={JoinType.And}
                allowClear={true}
                mode="multiple"
                placeholder={'User'}
                options={this.state.users}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row></Row>
        <Row>
          <Col>
            <Button type="primary" onClick={this.SendTableRequest}>
              Generate Report
            </Button>
          </Col>
        </Row>
      </FilterBar>
    );
  }
}
