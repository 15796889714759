import { KnowledgeContributorReportView } from 'components/Knowledge/KnowledgeContributorReportView';
import PageContainer from 'components/Layout/PageContainer';
import React from 'react';
import styled from 'styled-components';

const Wrap = styled.div``;

interface Props {
}

export default ({ }: Props) => {
  return (
    <PageContainer title="Knowledge Contributor Report">
      <KnowledgeContributorReportView />
    </PageContainer>
  )
}