import * as React from 'react';
import AuthContext from '../../../Context/AuthContext';
import UserBar from '../UserBar';
import LoginBar from '../LoginBar';

export interface Props {
  children?: React.ReactNode;
}

export interface State { }

export default class AuthBar extends React.Component<Props, State> {
  static contextType = AuthContext;
  context!: React.ContextType<typeof AuthContext>;

  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  render() {
    const userData = this.context.data;
    return !!userData ? <UserBar /> : <LoginBar />;
  }
}



