import React, { useDebugValue } from 'react';
import { UpdatableContext } from './UpdatableContext';
import AuthUser from 'Core/models/AuthUser';

const data = undefined;
const Update = () => {};

const AuthContext = React.createContext<UpdatableContext<AuthUser>>({ data, Update });

export default AuthContext;



