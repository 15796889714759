import { Layout, Row, Col, Icon } from 'antd';
import * as React from 'react';
import Sidebar from './Sidebar';
import { AppRoutes } from '../../../Core/AppRoutes';
import ILayout from '../../../Core/models/ILayout';
import AuthBar from '../AuthBar';
import { LightHeader } from '../ClassicLayout/TopBar/TopBar.Styled';
import Title from 'antd/lib/typography/Title';
import { NavLink } from 'react-router-dom';
import Paragraph from 'antd/lib/typography/Paragraph';
import { TallCol } from './AdminLayout.Styled';
import Logo from 'components/Styled/Logo';
import AppMenu from '../AppMenu';
import CollapsedMenu from '../CollapsedMenu';
import { MainLogo } from './Sidebar/Sidebar';
import { LayoutFooter } from '../LayoutFooter';

const { Header, Footer } = Layout;

export interface Props extends ILayout {
  children?: React.ReactNode;
}

export interface State { }

export default class AdminLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  public render() {
    return (
      <Layout
        className="react-transition fade-in"
        style={{ minHeight: '100vh', animationDuration: '750ms' }}>
        <Sidebar menu={AppRoutes} />
        <Layout>
          <LightHeader style={{ color: 'green', height: '115px' }}>
            <Row type="flex" justify="space-between" >
              <Col xs={4} sm={4} md={0}>
                <CollapsedMenu menu={AppRoutes} />
              </Col>
              <Col xs={{ span: 10 }} sm={{ span: 10, offset: 1 }} md={0} >
                <MainLogo style={{ margin: "auto", height: "115px", width: "180px" }}></MainLogo>
              </Col>
              <Col xs={{ span: 9 }} sm={{ span: 7, offset: 3 }} md={0}>
                <AuthBar />
              </Col>
            </Row>
            <Row type="flex" justify="end" align="middle">
              <Col xl={{ span: 16, pull: 0 }} md={{ span: 16, pull: 0 }}>
                <Logo linkUrl="/Dashboard" />
              </Col>
              <Col md={5} sm={{ span: 5 }}>
                <AuthBar />
              </Col>
            </Row>
          </LightHeader>
          <Row style={{ backgroundColor: 'white' }}>
            <TallCol>{this.props.children}</TallCol>
          </Row>
          <LayoutFooter />
        </Layout>
      </Layout>
    );
  }
}

